import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material';

@Component({
  selector: 'app-upload-logo',
  templateUrl: './upload-logo.component.html',
  styleUrls: ['./upload-logo.component.scss']
})
export class UploadLogoComponent implements OnInit {
  selectedFile: File;
  isUploading: Boolean = false;

  constructor(private bottomSheet: MatBottomSheetRef<UploadLogoComponent>) {}

  ngOnInit() {}

  onFileChanged(event: any) {
    this.selectedFile = event.target.files[0];
    this.bottomSheet.dismiss(this.selectedFile);
  }
}
