import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ClientService } from '@app/core/client.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-staff-member',
  templateUrl: './add-staff-member.component.html',
  styleUrls: ['./add-staff-member.component.scss']
})
export class AddStaffMemberComponent implements OnInit {
  @Input() public editStaffMember: any; // only if editing
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  staffMemberFormGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private snackBar: MatSnackBar,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.staffMemberFormGroup = this.formBuilder.group({
      staffId: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      name: ['', Validators.required],
      shorthand: ['', Validators.required],
      secret: ['']
    });

    if (this.editStaffMember) {
      this.staffMemberFormGroup.addControl('id', new FormControl(this.editStaffMember.id, Validators.required));
      this.staffMemberFormGroup.get('staffId').setValue(this.editStaffMember.staffId);
      this.staffMemberFormGroup.get('name').setValue(this.editStaffMember.name);
      this.staffMemberFormGroup.get('shorthand').setValue(this.editStaffMember.shorthand);
      this.staffMemberFormGroup.get('secret').setValue(this.editStaffMember.secret);
    }
  }

  add() {
    if (!this.staffMemberFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    this.clientService.addStaffMember(this.staffMemberFormGroup.value).subscribe(resp => {
      this.passEntry.emit(resp);
      this.activeModal.dismiss('');
    });
  }

  save() {
    if (!this.staffMemberFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    this.clientService.editStaffMember(this.staffMemberFormGroup.value).subscribe(resp => {
      this.passEntry.emit(resp);
      this.activeModal.dismiss('');
    });
  }
}
