import { Component, OnInit, ViewChild } from '@angular/core';
import { ReservationService } from '@app/reservation/reservation.service';
import { Subject } from 'rxjs';
import { Reservation } from '@app/reservation/reservation.model';
import { AddReservationComponent } from '../add-reservation/add-reservation.component';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material';
import * as deLocale from 'date-fns/locale/de/index.js';
import { AuthenticationService, resStatus } from '@app/core';
import { CancelMsgBoxComponent } from '../../cancel-msg-box/cancel-msg-box.component';

@Component({
  selector: 'app-pending-reservations',
  templateUrl: './pending-reservations.component.html',
  styleUrls: ['./pending-reservations.component.scss']
})
export class PendingReservationsComponent implements OnInit {
  @ViewChild('pendingReservations', { static: false }) table: any;
  loadingReservationList = true;
  reservations: any = [];
  columns = [{ name: 'Uhrzeit' }, { name: 'Gast' }, { name: 'Personen' }, { name: 'Tisch' }, { name: 'Status' }];
  activeClient: any;
  editing: any = {};
  settings: any = [];
  smsSettings: any = [];
  resStatusList: any[] = [];
  allStatus: any[] = resStatus;

  // Layout
  readonly LAYOUT = {
    XXS: 450,
    XS: 768,
    SM: 992,
    MD: 1200,
    LG: 1600,
    XL: 1920,
    XXL: 2560
  };
  layout: number = this.LAYOUT.MD;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private reservationService: ReservationService,
    private modalService: NgbModal,
    private snackBar: MatSnackBar,
    public activeModal: NgbActiveModal,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.getSmsSettings();
    this.authService
      .getClientResSettings(this.authService.activeClientId)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((settings: any) => {
        this.settings = settings;
      });
    this.getReservations();
    this.getAllStatus();
  }

  getAllStatus() {
    this.reservationService
      .getResStatus()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((resStatusList: any) => {
        this.resStatusList = resStatusList;
        this.resStatusList.forEach((item: any) => {
          item['type'] = 'single';
        });
        this.allStatus = this.allStatus.concat(this.resStatusList);
      });
  }

  getReservations() {
    this.loadingReservationList = true;
    this.reservationService
      .getPendingReservations()
      .toPromise()
      .then((data: any) => {
        this.loadingReservationList = false;
        this.editing = {}; // Reset editing because of new rows
        this.reservations = data;
        setTimeout(() => {
          if (this.table && this.table.rowDetail) {
            // this.table.rowDetail.expandAllRows();
            this.table.recalculate();
          }
        }, 100);
      });
  }

  editReservation(reservation: Reservation) {
    const appendContainer = 'body';

    const modalRef = this.modalService.open(AddReservationComponent, {
      size: 'lg',
      container: appendContainer
    });

    modalRef.componentInstance.reservation = reservation;
    modalRef.componentInstance.isWalkIn = !reservation.gastId ? true : false;
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      this.getReservations();
      this.snackBar.open('Reservierung erfolgreich geändert', '', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
      modalRef.close();
    });
  }

  onRowActivate(event: any) {
    if (event.type === 'click' && event.column.prop !== 'status') {
      this.editReservation(event.row);
    }
  }

  toggleExpandGroup(group: any) {
    this.table.groupHeader.toggleExpandGroup(group);
  }

  onSort(event: any) {
    // emulate a server request with a timeout
    this.loadingReservationList = true;
    setTimeout(() => {
      const rows = [...this.reservations];
      const sort = event.sorts[0];
      rows.sort((a, b) => {
        return a[sort.prop].localeCompare(b[sort.prop]) * (sort.dir === 'desc' ? -1 : 1);
      });

      this.reservations = rows;
      this.loadingReservationList = false;
    }, 1000);
  }

  updateStatus(
    status: string,
    cell: any,
    rowIndex: number,
    reservationId: number,
    reservation: any,
    statusChangeMsg: any
  ) {
    this.editing[rowIndex + '-' + cell] = false;
    // this.reservations[rowIndex][cell] = status;
    const index = this.reservations.findIndex((x: any) => x.id === reservationId);
    this.reservations[index].status = status;
    this.reservations = [...this.reservations];
    const reservData: any = { ...this.reservations[index] };
    if (reservData.guestData) {
      reservData.guestData.guestInform = true;
      if (this.smsSettings && this.smsSettings.reservationActive) {
        reservData.guestData.guestInformSMS = true;
      }
    }
    reservData.guestCount = reservData.peopleCount;
    reservData.statusChangeMsg = statusChangeMsg;
    this.reservationService
      .editReservation(reservData)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        () => {
          if (status == 'confirmed' || status == 'canceled') {
            this.updateReservation(status.toUpperCase(), reservation);
          }
          this.getReservations();
          this.snackBar.open('Status wurde erfolgreich geändert', '', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        },
        err => {
          this.snackBar.open(`Status konnte nicht geändert werden (${err.error.msg})`, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      );
  }

  updateReservation(status: string, reservation: any) {
    const referrer = reservation.referrer ? reservation.referrer : '';
    if (referrer && referrer.includes('Reservierung mit Google')) {
      this.reservationService
        .getupdateGoogleReseration(status, reservation)
        .takeUntil(this.ngUnsubscribe)
        .subscribe((settings: any) => {
          if (settings) {
            this.snackBar.open(`Reservation ${status} From Google`, '', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          }
        });
    }
  }
  callUpdateStatus(status: string, cell: any, rowIndex: number, reservation: any) {
    let reservationId = reservation.id;
    let oldStatus = reservation.status;

    if (status == 'canceled' && this.settings.remindGuests && this.settings.remindGuestsEmail) {
      let cancelMsg = '';
      if (oldStatus === 'pending' && status === 'canceled') {
        cancelMsg = this.settings.declineText;
      }
      if (oldStatus === 'confirmed' && status === 'canceled') {
        cancelMsg = this.settings.cancelText;
      }
      const modalRef = this.modalService.open(CancelMsgBoxComponent, { windowClass: 'onboarding-modal' });
      modalRef.componentInstance.msg = cancelMsg;
      modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
        this.updateStatus(status, cell, rowIndex, reservationId, reservation, receivedEntry.msg);
      });
    } else {
      this.updateStatus(status, cell, rowIndex, reservationId, reservation, '');
    }
  }
  getSmsSettings() {
    this.reservationService
      .getSmsSettings()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((smsSettings: any) => {
        this.smsSettings = smsSettings;
      });
  }
}
