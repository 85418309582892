import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ChangePasswordService } from './change-password.service';
import { MatSnackBar } from '@angular/material';
import { AuthenticationService } from '@app/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  message: string;
  constructor(
    private formBuilder: FormBuilder,
    private changePasswordService: ChangePasswordService,
    private snackBar: MatSnackBar,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.oldPassword = 'password';
    this.newPassword = 'password';
    this.confirmPassword = 'password';
  }

  changeType(feildName: string, show: boolean) {
    // console.log('Event => ', event)
    switch (feildName) {
      case 'oldPassword':
        this.oldPassword = show ? 'text' : 'password';
        break;
      case 'newPassword':
        this.newPassword = show ? 'text' : 'password';
        break;
      case 'confirmPassword':
        this.confirmPassword = show ? 'text' : 'password';
        break;
    }
  }

  changePassword() {
    let isValid = true;
    this.message = '';
    // console.log(this.changePasswordForm.controls);
    if (!this.changePasswordForm.valid) {
      for (let key in this.changePasswordForm.controls) {
        if (!this.changePasswordForm.controls[key].valid) {
          isValid = false;
          this.changePasswordForm.controls[key].markAsTouched({ onlySelf: true });
        }
      }
    }

    if (isValid) {
      const formObject = this.changePasswordForm.value;
      if (formObject.newPassword === formObject.confirmPassword) {
        const { email, id } = JSON.parse(localStorage.getItem('credentials'));
        const data = {
          oldPassword: formObject.oldPassword,
          newPassword: formObject.newPassword,
          email,
          clientId: id
        };
        this.changePasswordService.changePassword(data).subscribe(
          (response: any) => {
            this.snackBar.open('Password Changed Succesfully', '', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            setTimeout(() => {
              this.authenticationService.logout();
              this.router.navigate(['/login']);
            }, 2000);
          },
          (err: any) => {
            this.snackBar.open(err.error.msg, '', {
              duration: 2000,
              panelClass: ['snackbar-error']
            });
          }
        );
      } else {
        this.message = 'New Password and Confirm Password are different';
        return;
      }
    }
  }
}
