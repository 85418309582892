import { Injectable } from '@angular/core';
import { AuthenticationService, CredentialsService } from '@app/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { environment } from '@env/environment';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class AccountingService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private credentialsService: CredentialsService
  ) {}

  getBillingcase() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `accounting/billingcase/${this.authService.activeClientId}`, {
        headers: headers
      })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  getInvoices() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `accounting/invoices/${this.authService.activeClientId}`, {
        headers: headers
      })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  downloadInvoice(invoice: any, type: string = 'pdfFile', prefix: string = 'Rechnung_') {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    this.http
      .get(
        environment.serverUrl + `accounting/invoices/download/${type}/${invoice.id}/${this.authService.activeClientId}`,
        { headers, responseType: 'blob' }
      ) //set response Type properly (it is not part of headers)
      .toPromise()
      .then(blob => {
        saveAs(blob, prefix + invoice.id + '.pdf');
      })
      .catch(err => console.error('download error = ', err));
  }

  saveBillingcase(data: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    if (!data.clientId) {
      data.clientId = this.authService.activeClientId;
    }

    return this.http
      .post<any>(environment.serverUrl + `accounting/billingcase/`, data, {
        headers: headers
      })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  sendCommonMailIban(data: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    data['name'] = this.authService.client.name;
    if (!data.clientId) {
      data.clientId = this.authService.activeClientId;
    }
    return this.http
      .post<any>(environment.serverUrl + `accounting/sendMailIban/`, data, {
        headers: headers
      })
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }
}
