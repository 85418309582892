import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material';
import { MenucardsService } from '../menucards.service';

@Component({
  selector: 'app-add-size',
  templateUrl: './add-size.component.html',
  styleUrls: ['./add-size.component.scss']
})
export class AddSizeComponent implements OnInit {
  @Input() public editSizeData: any; // only if editing
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  sizeFormGroup: FormGroup;
  error: string;
  addObservable: any;
  editObservable: any;
  deleteObservable: any;
  priceCategories: any = [];

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    public activeModal: NgbActiveModal,
    private snackBar: MatSnackBar,
    public formBuilder: FormBuilder,
    private menucardService: MenucardsService
  ) {
    this.sizeFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      internalName: ['', Validators.required],
      priceCategory: ['', Validators.required]
    });
  }

  ngOnInit() {
    if (this.editSizeData) {
      this.sizeFormGroup.addControl('id', new FormControl(this.editSizeData.id, Validators.required));
      this.sizeFormGroup.get('name').setValue(this.editSizeData.name);
      this.sizeFormGroup.get('internalName').setValue(this.editSizeData.internalName);
      this.sizeFormGroup.get('priceCategory').setValue(this.editSizeData.priceCategory);
    }

    this.getPriceCategories();
  }

  getPriceCategories() {
    this.menucardService.getPriceCategories().subscribe((cats: any) => {
      this.priceCategories = cats;
    });
  }

  add() {
    if (!this.sizeFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    this.error = null;
    this.addObservable = this.menucardService
      .addSize(this.sizeFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          this.error = err.error.msg;
        }
      );
  }

  save() {
    if (!this.sizeFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    this.error = null;
    this.addObservable = this.menucardService
      .saveSize(this.sizeFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          this.error = err.error.msg;
        }
      );
  }
}
