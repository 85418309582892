import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoTextPipe } from './info-text.pipe';

@NgModule({
  declarations: [InfoTextPipe],
  providers: [InfoTextPipe],
  exports: [InfoTextPipe]
})
export class InfoTextModule {}
