import { IAfterGuiAttachedParams } from '@ag-grid-enterprise/all-modules';
import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'tags-renderer',
  template: `
    <span
      class="badge badge-default"
      *ngFor="let tag of params.data.tags"
      [style.background-color]="tag.color"
      style="color: white"
      >{{ tag.name }}</span
    >
  `
})
export class TagsRendererComponent implements ICellRendererAngularComp, OnDestroy {
  params: any;

  constructor() {}

  ngOnDestroy(): void {}
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}

  agInit(params: any): void {
    this.params = params;
  }
}
