import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { ManageItemsComponent } from './manage-items/manage-items.component';
import { MenucardsComponent } from './menucards.component';
import { ManageComponent } from './manage/manage.component';
import { SettingsComponent } from './settings/settings.component';
import { CsvImportComponent } from './csv-import/csv-import.component';
const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'client/:clientId/menucards',
      data: {
        breadcrumb: 'Speisekarten'
      },
      resolve: {
        client: ClientResolverService
      },
      children: [
        {
          path: 'overview',
          children: [
            {
              path: '',
              component: MenucardsComponent,
              data: {
                title: extract('Meine Speisekarten'),
                breadcrumb: 'Meine Speisekarten'
              }
            },
            {
              path: 'manage/:menuId',
              component: ManageComponent,
              data: {
                title: extract('Speisekarte verwalten'),
                breadcrumb: 'Speisekarte verwalten'
              }
            }
          ]
        },
        {
          path: 'items',
          component: ManageItemsComponent,
          data: {
            title: extract('Artikel verwalten'),
            breadcrumb: 'Artikel verwalten'
          }
        },
        {
          path: 'settings',
          component: SettingsComponent,
          data: {
            title: extract('Einstellungen'),
            breadcrumb: 'Einstellungen'
          }
        },
        {
          path: 'import',
          component: CsvImportComponent,
          data: {
            title: extract('CSV-Import'),
            breadcrumb: 'CSV-Import'
          }
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class MenucardsRoutingModule {}
