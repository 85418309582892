import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { OrdersComponent } from './orders.component';
import { GastroPaySettingsComponent } from '@app/gastro-pay/gastro-pay-settings/gastro-pay-settings.component';
import { ExportComponent } from './export/export.component';
import { AffiliateComponent } from './affiliate/affiliate.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { StatisticsComponent } from './statistics/statistics.component';
const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'client/:clientId/orders',
      data: {
        breadcrumb: 'Bestellsystem'
      },
      resolve: {
        someKey: ClientResolverService
      },
      children: [
        {
          path: '',
          redirectTo: 'overview',
          pathMatch: 'full'
        },
        {
          path: 'overview',
          component: OrdersComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Bestellungen',
            breadcrumb: 'Übersicht',
            permissions: {
              only: ['admin', 'manageOrders'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'statistic',
          component: StatisticsComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Statistik',
            breadcrumb: 'Statistik',
            permissions: {
              only: ['admin', 'manageOrders'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'settings',
          component: GastroPaySettingsComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Einstellungen',
            breadcrumb: 'Einstellungen',
            permissions: {
              only: ['admin', 'manageOrderSettings'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'export',
          component: ExportComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Exportieren',
            breadcrumb: 'Exportieren',
            permissions: {
              only: ['admin', 'manageOrders'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'affiliate',
          component: AffiliateComponent,
          data: {
            title: extract('Affiliate'),
            breadcrumb: 'Affiliate'
          }
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class OrdersRoutingModule {}
