import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleTranslatePipe } from './google-translate.pipe';

@NgModule({
  declarations: [GoogleTranslatePipe],
  providers: [GoogleTranslatePipe],
  exports: [GoogleTranslatePipe]
})
export class GoogleTranslateModule {}
