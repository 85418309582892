import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract, AuthenticationGuard } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { OpeningHoursComponent } from './opening-hours/opening-hours.component';
import { NgxPermissionsGuard } from 'ngx-permissions';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'client/:clientId/opening-hours',
      component: OpeningHoursComponent,
      canActivate: [NgxPermissionsGuard],
      data: {
        title: 'Öffnungszeiten',
        breadcrumb: 'Öffnungszeiten',
        permissions: {
          only: ['admin', 'manageOpeningHours'],
          redirectTo: {
            navigationCommands: ['403'],
            navigationExtras: {
              replaceUrl: false
            }
          }
        }
      }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class OpeningHoursRoutingModule {}
