import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface GoogleObj {
  q: string[];
  target: string;
}

@Injectable({
  providedIn: 'root'
})
export class GoogleCloudTranslateService {
  private readonly API_KEY = 'AIzaSyCCtqoiEBklEYVOCIawFD-R3HkzC30fqAg';
  private readonly API_URL = 'https://translation.googleapis.com/language/translate/v2?key=';

  constructor(private http: HttpClient) {}

  translateData(data: any[], targetLanguage: string, properties: string[]): Observable<any> {
    console.log('data ==>', data);
    const requestData: { q: any[]; target: string } = {
      q: [],
      target: targetLanguage
    };

    // Extract texts from specified properties
    properties.forEach(prop => {
      console.log('prop ===>', prop);
      console.log('data.length ===>', data.length);
      if (data.length > 0) {
        data.forEach(item => {
          console.log('item ===>', item);
          if (item[prop]) {
            requestData.q.push(item[prop]);
          }
        });
      } else {
        if (data[prop]) {
          requestData.q.push(data[prop]);
        }
      }
    });

    return this.http.post(this.API_URL + this.API_KEY, requestData);
  }

  translateDataForNestedArray(data: any[], targetLanguage: string): Observable<any> {
    const requestData = {
      q: data,
      target: targetLanguage
    };

    return this.http.post(this.API_URL + this.API_KEY, requestData);
  }
}
