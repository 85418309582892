import { Pipe, PipeTransform } from '@angular/core';
import { HandleStateForPipeService } from '../handle-state-for-pipe.service';

@Pipe({
  name: 'infoText'
})
export class InfoTextPipe implements PipeTransform {
  constructor(private stateService: HandleStateForPipeService) {}

  transform(value: string, args?: any[]): string {
    // For each argument
    // tslint:disable-next-line:forin
    for (const key in args) {
      console.log(args[key]);
      const hoursLabel = args[key] / 60 > 1 ? 'Stunden' : 'Stunde';
      value = value.replace('[' + key + ']', args[key] + ' ' + hoursLabel);
    }
    this.stateService.updateState(true);
    return value;
  }
}
