import { Component, OnInit, ViewChild } from '@angular/core';
import { GastroPayService } from '@app/gastro-pay/gastro-pay.service';
import { Subscription, Subject } from 'rxjs';
import { SocketService } from '@app/socket.service';
import { MatSnackBar } from '@angular/material';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { filter } from 'rxjs/operators';
import { SatDatepickerRangeValue } from 'saturn-datepicker';

import moment from 'moment';
moment.locale('de');

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit {
  csvOptions: any = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: false,
    headers: [
      'ID',
      'Datum',
      'Uhrzeit',
      'Lieferdatum',
      'Besteller',
      'Betrag',
      'Gebühr',
      'Zahlart',
      'Typ',
      'Liefergebühr',
      'Trinkgeld',
      'Status',
      'Kontostand'
    ],
    showTitle: false,
    title: '',
    useBom: false,
    removeNewLines: true,
    keys: [
      'id',
      'dateFormatted',
      'timeFormatted',
      'desiredFormatted',
      'person',
      'subtotal',
      'commissionFee',
      'paymentMethodText',
      'typeText',
      'deliveryFee',
      'tip',
      'statusText',
      'balance'
    ]
  };

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  exportSorting: string = '1';
  allOrders: any[] = [];
  allOrdersCopy: any[] = [];
  filterFrom: string = '';
  filterTo: string = '';
  now: any = new Date();

  pendingOrders: any;
  finishedOrders: any;
  inprogressOrders: any;
  currentDate = new Date();
  tableLimit: number = 1;
  filterStats: any = {
    orders: {
      count: 0,
      total: 0,
      cash: 0,
      gastropay: 0,
      online: 0,
      commission: 0,
      delivery: 0,
      tip: 0
    }
  };
  stats: any = {
    orders: {
      total: 0,
      yesterday: 0,
      today: 0
    },
    balance: {
      total: 0
    },
    sum: {
      total: 0,
      yesterday: 0,
      today: 0
    }
  };
  date: any = {
    from: '',
    to: ''
  };
  pushedPayouts: any[] = [];
  pushedOrders: any[] = [];

  private _ordersSub: Subscription;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private gastropayService: GastroPayService,
    private socketService: SocketService,
    private snackBar: MatSnackBar,
    private modalService: NgbModal,
    private clientResolver: ClientResolverService
  ) {}

  ngOnInit() {
    this.getOrders(true); //true
    this.getStats();

    const audio = new Audio();
    if (this.clientResolver.client.gastropaySettings) {
      audio.src = '/assets/sound/' + this.clientResolver.client.gastropaySettings.notificationSound + '.wav';
    } else {
      audio.src = '/assets/sound/bell.wav';
    }
    audio.load();

    // Get socket messages
    this._ordersSub = this.socketService.newOrder.takeUntil(this.ngUnsubscribe).subscribe((res: any) => {
      audio.play();
      this.snackBar.open('Neue Bestellung eingetroffen', '', {
        duration: 2000,
        panelClass: ['snackbar-info']
      });
      this.getOrders(true);
      this.getStats();
    });
  }

  getArrayCopy(arr: any[]) {
    let csvOrders: any[] = [];
    arr.forEach(row => {
      if (row.status !== 'canceled') {
        let tempRow = { ...row };
        tempRow.subtotal = `${tempRow.subtotal}`.replace('.', ',');
        tempRow.commissionFee = `${tempRow.commissionFee}`.replace('.', ',');
        tempRow.deliveryFee = `${tempRow.deliveryFee}`.replace('.', ',');
        tempRow.tip = `${tempRow.tip}`.replace('.', ',');
        tempRow.balance = `${tempRow.balance}`.replace('.', ',');
        csvOrders.push(tempRow);
      }
    });
    /*
    csvOrders.sort(function (a, b) {
      return a.desiredTime.localeCompare(b.desiredTime);
    });
    console.log(158, csvOrders);
    */
    return csvOrders;
  }

  getStats() {
    this.gastropayService
      .getShortStats()
      .takeUntil(this.ngUnsubscribe)
      .subscribe(stats => {
        this.stats = stats;
      });
  }

  getOrders(initialStart: boolean = false) {
    console.log(644, this.exportSorting);

    this.pushedOrders = [];
    this.allOrders = [];
    this.pendingOrders = [];
    this.finishedOrders = [];
    this.inprogressOrders = [];

    if (initialStart) {
      this.date.end = moment().format('YYYY-MM-DD');
      this.date.begin = moment()
        .subtract(6, 'days')
        .format('YYYY-MM-DD');
    }
    this.getOwnCashOrders(initialStart);
    //
  }

  getOrderlistPdf() {
    let orders: any = [];
    this.allOrders.forEach(order => {
      orders.push({
        sorting:
          this.exportSorting === '1' && order.deliveryTimestamp
            ? moment(order.deliveryTimestamp).format('YYYY-MM-DD HH:mm')
            : moment(order.createdAt).format('YYYY-MM-DD HH:mm'),
        createdAt: moment(order.createdAt).format('DD.MM.YYYY HH:mm'),
        deliveryTimestamp: order.deliveryTimestamp
          ? moment(order.deliveryTimestamp).format('DD.MM.YYYY HH:mm')
          : moment(order.createdAt).format('DD.MM.YYYY HH:mm'),
        lastName: order.lastName,
        firstName: order.firstName,
        subtotal:
          parseFloat(`${order.subtotal}`)
            .toFixed(2)
            .replace('.', ',') + ' €',
        tip:
          parseFloat(`${order.tip}`)
            .toFixed(2)
            .replace('.', ',') + ' €',
        commissionFee:
          parseFloat(`${order.commissionFee}`)
            .toFixed(2)
            .replace('.', ',') + ' €',
        deliveryFee:
          parseFloat(`${order.deliveryFee}`)
            .toFixed(2)
            .replace('.', ',') + ' €',
        paymentMethodText: order.paymentMethodText,
        typeText: order.typeText,
        statusText: order.status
          .replace('confirmed', 'fertig')
          .replace('pending', 'ausstehend')
          .replace('canceled', 'storniert')
      });
    });

    orders.sort(function(a: any, b: any) {
      const keyA = new Date(a.sorting),
        keyB = new Date(b.sorting);
      // Compare the 2 dates
      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
      return 0;
    });

    const bis = moment(orders[0].sorting).format('DD.MM.YYYY');
    const von = moment(orders[orders.length - 1].sorting).format('DD.MM.YYYY');
    const adresse: any = {
      z1: `Kunden-Nr.: ${this.clientResolver.client.id}`,
      z2: this.clientResolver.client.name,
      z3: this.clientResolver.client.street,
      z4: this.clientResolver.client.zipCode,
      z5: this.clientResolver.client.location
    };
    // return false;

    this.gastropayService
      .createOrderlistPdf({
        sortBy: this.exportSorting === '1' ? 'Lieferdatum' : 'Bestelldatum',
        orders,
        von,
        bis,
        adresse,
        stats: {
          umsatz:
            parseFloat(`${this.filterStats.orders.total}`)
              .toFixed(2)
              .replace('.', ',') + ' €',
          anzahl: this.filterStats.orders.count,

          bar:
            parseFloat(`${this.filterStats.orders.cash}`)
              .toFixed(2)
              .replace('.', ',') + ' €',
          online:
            (parseFloat(`${this.filterStats.orders.online}`) + parseFloat(`${this.filterStats.orders.gastropay}`))
              .toFixed(2)
              .replace('.', ',') + ' €',
          gebuehren:
            parseFloat(`${this.filterStats.orders.commission}`)
              .toFixed(2)
              .replace('.', ',') + ' €',
          liefergebuehr:
            parseFloat(`${this.filterStats.orders.delivery}`)
              .toFixed(2)
              .replace('.', ',') + ' €',
          trinkgeld:
            parseFloat(`${this.filterStats.orders.tip}`)
              .toFixed(2)
              .replace('.', ',') + ' €'
        }
      })
      .takeUntil(this.ngUnsubscribe)
      .subscribe(res => {
        window.open(res.file);
        // window.location.assign(res.file);
        //console.log(199, res);
      });
  }

  getOwnCashOrders(initialStart: boolean = false) {
    const from = moment(this.date.begin).format('YYYY-MM-DD');
    const to = moment(this.date.end).format('YYYY-MM-DD');

    this.gastropayService
      .getOwnCashOrders([], from, to, this.exportSorting === '1' ? 'L' : '')
      //.getOwnOrders(true) //blank ones!
      .takeUntil(this.ngUnsubscribe)
      .subscribe(res => {
        res.forEach((order: any) => {
          //if (order.paymentMethod === 'cash') {
          if (!order.desiredTime) {
            order.desiredTime = order.createdAt;
          }
          this.pushOrder(order);
          //}
        });
        console.log('getOwnCashOrders: this.allOrders', this.allOrders);

        this.getOwnTransactionOrders(initialStart);
      });
  }

  getOwnTransactionOrders(initialStart: boolean = false) {
    const from = moment(this.date.begin).format('YYYY-MM-DD');
    const to = moment(this.date.end).format('YYYY-MM-DD');

    this.gastropayService
      .getOwnTransactionOrders([], from, to, this.exportSorting === '1' ? 'L' : '')
      .takeUntil(this.ngUnsubscribe)
      .subscribe(res => {
        console.log('ende getOwnTransactionOrders', res);

        res.orders.forEach((order: any) => {
          if (order.paymentTransaction) {
            if (order.paymentTransaction.gastropayOrders) {
              order.paymentTransaction.gastropayOrders.paymentTransaction = { ...order.paymentTransaction };
              if (!order.paymentTransaction.gastropayOrders.desiredTime) {
                order.paymentTransaction.gastropayOrders.desiredTime =
                  order.paymentTransaction.gastropayOrders.createdAt;
              }
              delete order.paymentTransaction.gastropayOrders.paymentTransaction.gastropayOrders;
            }
            if (order.paymentTransaction.gastropayOrders && order.type != 'refund') {
              this.pushOrder(order.paymentTransaction.gastropayOrders, order.balance);
            } else {
              if (order.type == 'refund') {
                this.pushOrder(res.refundOrders[order.foreignId], order.balance);
              } else {
                if (order.type == 'payout') {
                  /*
                  if (!this.pushedPayouts.includes(order.createdAt)) {
                    this.allOrders.push({
                      id: order.payoutId,
                      createdAt: order.createdAt,
                      firstName: '',
                      lastName: 'Auszahlung',
                      subtotal: order.value,
                      deliveryFee: 0,
                      commissionFee: 0,
                      paymentMethod: '',
                      type: 'payout',
                      status: 'confirmed',
                      balance: 0.00001
                    });
                    this.pushedPayouts.push(order.createdAt);
                  }
                  */
                }
              }
            }
          } else {
            if (order.type == 'payout') {
              if (!this.pushedPayouts.includes(order.createdAt)) {
                this.allOrders.push({
                  id: order.payoutId,
                  createdAt: order.createdAt,
                  firstName: '',
                  lastName: 'Auszahlung',
                  subtotal: order.value,
                  deliveryFee: 0,
                  commissionFee: 0,
                  paymentMethod: '',
                  type: 'payout',
                  status: 'confirmed',
                  balance: 0.00001
                });
                this.pushedPayouts.push(order.createdAt);
              }
            }
          }
        });
        console.log('getOwnTransactionOrders: this.allOrders', this.allOrders);
        this.allOrders = [...this.allOrders];
        this.tableLimit = 50;

        //this.loading = false;
        this.getFilterStats();

        /*
        if (this.filterBy !== '' && this.filterBy) {
          this.filterOrdersBy('client', this.filterBy);
        }
        */
      });
  }

  pushOrder(order: any, balance: number = 0) {
    if (!this.pushedOrders.includes(order.id)) {
      if (!order.paymentId) {
        order.paymentId = '';
      }
      if (!order.zipCode) {
        order.zipCode = '';
      }

      if (order.gastropayTransaction !== undefined && order.gastropayTransaction !== null) {
        order.balance = order.gastropayTransaction.balance;
      } else {
        order.balance = balance;
      }
      if (order.firstName || order.lastName) {
        order.person = order.firstName + ' ' + order.lastName;
      } else {
        order.person = '';
      }
      order.paymentMethodText = '';
      if (order.paymentMethod === 'cash') {
        order.paymentMethodText = 'Bar';
      }
      if (order.paymentMethod === 'gastropay') {
        order.paymentMethodText = 'Gastropay';
      }
      if (order.paymentMethod === 'online' && order.paymentTransaction) {
        order.paymentMethodText = order.paymentTransaction.paymentMethod;
      }
      if (order.paymentMethodText === 'paypal') {
        order.paymentMethodText = 'PayPal';
      }
      if (order.paymentMethodText === 'applepay') {
        order.paymentMethodText = 'Apple Pay';
      }
      if (order.paymentMethodText === 'creditcard') {
        order.paymentMethodText = 'Kreditkarte';
      }

      order.typeText = '';
      if (order.type === 'delivery') {
        order.typeText = 'Lieferung';
      }
      if (order.type === 'takeaway') {
        order.typeText = 'Abholung';
      }

      order.statusText = '';
      if (order.type === 'pending') {
        order.statusText = 'Ausstehend';
      }
      if (order.type === 'confirmed') {
        order.statusText = 'Fertig';
      }
      if (order.type === 'canceled') {
        order.statusText = 'Storniert';
      }

      // let date = moment(order.createdAt);
      // const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });
      // const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(date);

      order.dateFormatted = moment(order.createdAt).format('DD.MM.YYYY');
      order.timeFormatted = moment(order.createdAt).format('H:mm');
      order.desiredFormatted =
        moment(order.desiredTime).format('DD.MM.YYYY') + ' ' + moment(order.desiredTime).format('H:mm');

      if (order.status === 'pending' && !order.deliveryTime && order.deliveryTime !== 0) {
        this.pendingOrders.push(order);
      }
      if (order.status === 'pending' && (order.deliveryTime || order.deliveryTime === 0)) {
        this.inprogressOrders.push(order);
      }
      if (order.status === 'confirmed' || order.status === 'canceled') {
        this.finishedOrders.push(order);
      }
      this.allOrders.push(order);
      this.pushedOrders.push(order.id);
    }
  }

  /*
    exportArrayToCsv() {
      this.csvAllOrders = [];

      this.allOrders.forEach((row: any) => {
        this.csvAllOrders.push({
          id: row.id,
          date: row.createdAt,
          status: row.status,
          type: row.type,
          paymentMethod: row.paymentMethod,
          paymentId: row.paymentId,
          subtotal: row.subtotal,
          deliveryFee: row.deliveryFee,
          commissionFee: row.commissionFee,
          balance: row.gastropayTransaction,
          firstName: row.firstName,
          lastName: row.lastName,
          street: row.street,
          zipCode: row.zipCode,
          city: row.city,
          email: row.email,
          phone: row.phone,
        });
      }, this);
    }
    */
  arrayToCSV(objArray: any = []) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map(value => `"${value}"`)
        .join(',')}` + '\r\n';

    return array.reduce((str: any, next: any) => {
      str +=
        `${Object.values(next)
          .map(value => `"${value}"`)
          .join(',')}` + '\r\n';
      return str;
    }, str);
  }

  filterDates(filterBy: string, event: any) {
    let filterDate = moment(event.value._d).format('YYYY-MM-DD');

    if (filterDate) {
      this.filterOrdersBy(filterBy, filterDate);
    }
  }

  getFilterStats() {
    this.filterStats.orders.count = 0;
    this.filterStats.orders.total = 0;
    this.filterStats.orders.delivery = 0;
    this.filterStats.orders.commission = 0;
    this.filterStats.orders.cash = 0;
    this.filterStats.orders.gastropay = 0;
    this.filterStats.orders.online = 0;
    this.filterStats.orders.tip = 0;

    this.allOrders.forEach((order: any) => {
      if (order.type != 'payout' && order.status != 'canceled' && order.status != 'paying') {
        this.filterStats.orders.count++;
        if (order.type === 'confirmed') {
          order.statusText = 'Fertig';
        }
        this.filterStats.orders.delivery = this.filterStats.orders.delivery + this.returnPrice(order.deliveryFee);
        this.filterStats.orders.total =
          this.filterStats.orders.total + this.returnPrice(order.subtotal) + this.returnPrice(order.deliveryFee);
        this.filterStats.orders.commission = this.filterStats.orders.commission + this.returnPrice(order.commissionFee);
        this.filterStats.orders.tip = this.filterStats.orders.tip + this.returnPrice(order.tip);

        if (order.paymentMethod === 'cash') {
          this.filterStats.orders.cash = this.filterStats.orders.cash + this.returnPrice(order.subtotal);
        }
        if (order.paymentMethod === 'gastropay') {
          this.filterStats.orders.gastropay = this.filterStats.orders.gastropay + this.returnPrice(order.subtotal);
        }
        if (order.paymentMethod === 'online') {
          this.filterStats.orders.online = this.filterStats.orders.online + this.returnPrice(order.subtotal);
        }
      }
    });
    //console.log('getFilterStats',this.allOrders);
  }

  filterOrdersBy(filterBy: string = '', filterVal: string = '') {
    switch (filterBy) {
      case 'from':
        this.date.begin = new Date(filterVal);
        // this.getOrders(false);

        break;

      case 'to':
        this.date.end = new Date(filterVal);
        this.date = { begin: new Date(this.date.begin), end: new Date(this.date.end) };
        this.getOrders(false);

        break;

      default:
        /*
        this.allOrders = [...this.allOrdersCopy];
        this.allOrdersCopy = [];
        if (this.table) {
          this.table.offset = 0;
        }
        */
        break;
    }
  }
  setDates(type: string) {
    if (type == 'today') {
      this.filterDates('from', { value: { _d: new Date() } });
      this.filterDates('to', { value: { _d: new Date() } });
    }
    if (type == 'yesterday') {
      let yesterday: Date = new Date();
      yesterday.setDate(yesterday.getDate() - 1);

      this.filterDates('from', { value: { _d: yesterday } });
      this.filterDates('to', { value: { _d: yesterday } });
    }
    if (type == 'thisWeek') {
      const date = new Date();
      this.filterDates('from', { value: { _d: this.getMonday(date) } });

      const date2 = new Date();
      this.filterDates('to', { value: { _d: this.getSunday(date2) } });
    }
    if (type == 'lastSeven') {
      let date = new Date();
      date.setDate(date.getDate() - 6);
      this.filterDates('from', { value: { _d: date } });
      date.setDate(date.getDate() + 6);
      this.filterDates('to', { value: { _d: date } });
    }
    if (type == 'lastWeek') {
      let date = new Date();
      date.setDate(date.getDate() - 7);
      this.filterDates('from', { value: { _d: this.getMonday(date) } });
      this.filterDates('to', { value: { _d: this.getSunday(date) } });
    }
    if (type == 'lastMonth') {
      let date = new Date();
      let startDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      let endDate = new Date(date.getFullYear(), date.getMonth(), 1);
      endDate.setDate(endDate.getDate() - 1);

      this.filterDates('from', { value: { _d: startDate } });
      this.filterDates('to', { value: { _d: endDate } });
    }
    if (type == 'thisMonth') {
      let date = new Date();
      let startDate = new Date(date.getFullYear(), date.getMonth(), 1);
      let endDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      endDate.setDate(endDate.getDate() - 1);

      this.filterDates('from', { value: { _d: startDate } });
      this.filterDates('to', { value: { _d: endDate } });
    }
  }
  getMonday(d: any) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }
  getSunday(d: any) {
    d = new Date(d);
    const s = moment(d).day(7);
    return s;
  }

  returnPrice(price: any) {
    if (typeof price == 'string') {
      return parseFloat(price.replace(',', '.'));
    } else {
      return price || 0;
    }
  }

  sortExportData(event: any) {
    this.getOrders();

    /*
    if (this.exportSorting == 1) {
      // console.log('1 sortExportData', this.exportSorting);
      this.allOrders.sort(function(a, b) {
        let c: any = new Date(a.desiredTime);
        let d: any = new Date(b.desiredTime);
        return d - c;
      });
      console.log('desired this.allOrders', this.allOrders);
    } else {
      // console.log('0 sortExportData', this.exportSorting);
      this.allOrders.sort((a, b) => b.id - a.id);
      // console.log('id this.allOrders', this.allOrders);
    }
    */
  }

  onDateRangesChange() {
    // this.date.begin = filterVal;
    this.getOrders(false);
  }
}
