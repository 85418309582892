import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-disabled-navitem',
  templateUrl: './disabled-navitem.component.html',
  styleUrls: ['./disabled-navitem.component.scss']
})
export class DisabledNavitemComponent implements OnInit {
  @Input() public link: string;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}
