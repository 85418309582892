import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { MatStepperModule, MatSlideToggleModule, MatAutocompleteModule } from '@angular/material';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RegisterComponent } from './register/register.component';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgSelectModule } from '@ng-select/ng-select';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
// import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { CommonOuterModule } from '@app/shared/common-outer.module';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    LoginRoutingModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    AngularSvgIconModule,
    NgSelectModule,
    RecaptchaModule, //this is the recaptcha main module
    RecaptchaFormsModule, //this is the module for form incase form validation
    CommonOuterModule,
    // RecaptchaV3Module,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  // providers: [
  //   {
  //     provide: RECAPTCHA_V3_SITE_KEY,
  //     useValue: '6Le8asYpAAAAAORNj-xzW40u32GyKgTNk8-jTBFA'
  //   }
  // ],
  declarations: [LoginComponent, ForgotPasswordComponent, ResetPasswordComponent, RegisterComponent]
})
export class LoginModule {}
