import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { AddPricecategoryComponent } from '../add-pricecategory/add-pricecategory.component';
import { MenucardsService } from '../menucards.service';
import { AddSizeComponent } from '../add-size/add-size.component';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  priceCategories: any = [];
  sizes: any = [];
  tags: any = [];
  tagsForm: FormGroup;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private modalService: NgbModal,
    private snackBar: MatSnackBar,
    private menucardService: MenucardsService,
    private formBuilder: FormBuilder
  ) {
    this.tagsForm = this.formBuilder.group({
      color: ['#f44336', Validators.required],
      name: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.getPriceCategories();
    this.getSizes();
    this.getTags();
  }

  getPriceCategories() {
    this.menucardService.getPriceCategories().subscribe((cats: any) => {
      this.priceCategories = cats;
    });
  }

  addPricecategory() {
    const modalRef = this.modalService.open(AddPricecategoryComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      this.priceCategories.push(receivedEntry);
      const snackBarRef = this.snackBar.open('Preiskategorie erfolgreich hinzugefügt', 'Ok', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
      modalRef.close();
    });
  }

  addSize() {
    const modalRef = this.modalService.open(AddSizeComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      this.sizes.push(receivedEntry);
      const snackBarRef = this.snackBar.open('Größe erfolgreich hinzugefügt', 'Ok', {
        duration: 3000,
        panelClass: ['snackbar-success']
      });
      modalRef.close();
    });
  }

  editSize(size: any) {
    const modalRef = this.modalService.open(AddSizeComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.editSizeData = size;
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      // Find in array and update
      const index = this.sizes.findIndex((s: any) => s.id === receivedEntry.id);
      this.sizes[index] = receivedEntry;

      const snackBarRef = this.snackBar.open('Größe erfolgreich gespeichert', 'Ok', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
      modalRef.close();
    });
  }

  getSizes() {
    this.menucardService.getClientSizes().subscribe((sizes: any) => {
      this.sizes = sizes;
    });
  }

  deletePricecategory(priceCategoryId: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Preiskategorie löschen';
    modalRef.componentInstance.message = `Sind Sie sicher dass Sie die Preiskategorie löschen möchten? Diese Aktion ist unwiederruflich.`;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      (result: any) => {
        if (result === 'ok') {
          this.menucardService.deletePricecategory(priceCategoryId).subscribe(
            () => {
              // Find in array
              const index = this.priceCategories.findIndex((p: any) => p.id === priceCategoryId);
              this.priceCategories.splice(index, 1);

              this.snackBar.open('Preiskategorie wurde erfolgreich gelöscht', '', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            },
            (err: any) => {
              this.snackBar.open(err.error.msg, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
              });
            }
          );
        }
      },
      () => {}
    );
  }

  deleteSize(sizeId: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Größe löschen';
    modalRef.componentInstance.message = `Sind Sie sicher dass Sie die Größe löschen möchten? Diese Aktion ist unwiederruflich.`;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      (result: any) => {
        if (result === 'ok') {
          this.menucardService.deleteSize(sizeId).subscribe(
            () => {
              // Find in array
              const index = this.sizes.findIndex((s: any) => s.id === sizeId);
              this.sizes.splice(index, 1);

              this.snackBar.open('Größe  wurde erfolgreich gelöscht', '', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            },
            (err: any) => {
              this.snackBar.open(err.error.msg, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
              });
            }
          );
        }
      },
      () => {}
    );
  }

  editPricecategory(category: any) {
    const modalRef = this.modalService.open(AddPricecategoryComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.editPricecategory = category;
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      const index = this.priceCategories.findIndex((pc: any) => pc.id === receivedEntry.id);
      this.priceCategories[index] = receivedEntry;
      const snackBarRef = this.snackBar.open('Preiskategorie erfolgreich gespeichert', 'Ok', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
      modalRef.close();
    });
  }

  getTags() {
    this.menucardService.getTags().subscribe((tags: any) => {
      this.tags = tags;
    });
  }

  changeTagColor(event: any) {
    this.tagsForm.controls['color'].setValue(event);
  }

  addTag() {
    const color = this.tagsForm.controls['color'].value;
    const name = this.tagsForm.controls['name'].value;
    this.menucardService.addTag(color, name).subscribe((tag: any) => {
      this.tags.push(tag);
      this.tagsForm.reset();
    });
  }

  deleteTag(tagId: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Tag löschen';
    modalRef.componentInstance.message = `Sind Sie sicher dass Sie den Tag löschen möchten? Diese Aktion ist unwiederruflich.`;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      (result: any) => {
        if (result === 'ok') {
          this.menucardService.deleteTag(tagId).subscribe(
            () => {
              // Find in array
              const index = this.tags.findIndex((s: any) => s.id === tagId);
              this.tags.splice(index, 1);

              this.snackBar.open('Tag wurde erfolgreich gelöscht', '', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            },
            (err: any) => {
              this.snackBar.open(err.error.msg, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
              });
            }
          );
        }
      },
      () => {}
    );
  }
}
