import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { DocumentsComponent } from './documents.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'client/:clientId/documents',
      data: {
        breadcrumb: 'GastroMarketing',
        transPath: 'ShellMenu.GuestMarketing.Title'
      },
      resolve: {
        someKey: ClientResolverService
      },
      children: [
        {
          path: '',
          component: DocumentsComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Documents',
            breadcrumb: 'Documents',
            transPath: 'ShellMenu.GuestMarketing.Documents.Title',
            permissions: {
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentsRoutingModule {}
