import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MyEntryService } from '../my-entry.service';
import { NewCompanyService } from '../../new-company/new-company.service';
import { MatSnackBar } from '@angular/material';
import moment from 'moment';
import { EventsService } from '../../events/events.service';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss']
})

// declare var ol: any;
export class ContactDetailsComponent implements OnInit {
  masterDataForm: FormGroup;
  loadedData: any;
  districtArray: any;
  locationArray: any;
  locationId: number;
  countryId: number;
  districtId: number;
  stateId: number;
  latitude: number;
  longitude: number;
  timer: number;
  timeoutVal: number;
  map: any;
  startDate: any;
  vectorLayer: any;
  newLocationPopup: boolean;
  tempLat: number;
  tempLong: number;

  constructor(
    private formBuilder: FormBuilder,
    private myEntryService: MyEntryService,
    private newCompanyService: NewCompanyService,
    private snackBar: MatSnackBar,
    private eventsService: EventsService
  ) {
    this.masterDataForm = this.formBuilder.group({
      name: ['', Validators.required],
      internalName: [''],
      telefon: ['', Validators.required],
      street: ['', Validators.required],
      email: ['', Validators.email],
      zipCode: ['', Validators.required],
      place: [''],
      homepage: [''],
      district: [''],
      establishedAt: [''],
      shortDescription: ['']
    });

    this.loadedData = null;
    this.startDate = moment().toDate();

    this.myEntryService.getClientMasterData().subscribe((response: any) => {
      this.loadedData = response;
      this.ngOnInit();
    });
  }

  ngOnInit() {
    this.districtArray = [];
    this.timer = this.timeoutVal = 500;
    this.vectorLayer = null;
    this.newLocationPopup = false;

    if (this.loadedData) {
      for (let key in this.masterDataForm.controls) {
        this.masterDataForm.get(key).setValue(this.loadedData[key]);
      }
      this.locationId = this.loadedData.locationId;
      this.countryId = this.loadedData.countryId;
      this.districtId = this.loadedData.stadtteilId;
      this.stateId = this.loadedData.stateId;
      this.latitude = this.loadedData.latitude;
      this.longitude = this.loadedData.longitude;

      this.setMap();

      this.newCompanyService.getGeoLocations({ zips: this.loadedData.zipCode }).subscribe(locations => {
        if (locations && locations.length) {
          const locationInfo = locations.filter((location: any) => location.id === this.locationId);
          if (locationInfo && locationInfo.length) {
            this.masterDataForm.get('place').setValue(locationInfo[0].name);
          }
        }
      });

      this.newCompanyService.getDistrict({ locationId: this.locationId }).subscribe(districts => {
        if (districts && districts.length) {
          this.districtArray = districts;
          const districtData = this.districtArray.filter((district: any) => district.id === this.districtId);
          if (districtData && districtData.length) {
            this.masterDataForm.get('district').setValue(districtData[0].name);
          } else {
            this.masterDataForm.get('district').setValue('');
          }
        }
      });
    }
  }

  /* common function */
  checkForTyping() {
    window.clearTimeout(this.timer);
  }

  /* Master data Function Starts */
  fetchLocation() {
    window.clearTimeout(this.timer);
    const zips = this.masterDataForm.value.zipCode;
    if (zips) {
      this.timer = window.setTimeout(() => {
        this.newCompanyService.getGeoLocations({ zips }).subscribe(locations => {
          if (locations && locations.length) {
            let locationArray = locations;
            const newArray: any = [];

            locationArray = locationArray.filter((each: any) => {
              const locationArray = each.zips.split(',');
              if (locationArray.length > 1) {
                newArray.push(each);
              } else if (each.zips.startsWith(zips)) {
                return each;
              }
            });

            if (newArray && newArray.length) {
              for (let j = 0; j < newArray.length; j++) {
                const newZips = newArray[j].zips.split(',');
                for (let z = 0; z < newZips.length; z++) {
                  if (newZips[z].startsWith(zips)) {
                    const element = { ...newArray[j] };
                    element['zips'] = newZips[z];
                    locationArray.push(element);
                  }
                }
              }
            }
            this.locationArray = locationArray;
          } else {
            this.locationArray = [];
          }
        });
      }, this.timeoutVal);
    }
  }

  fetchLatLng() {
    window.clearTimeout(this.timer);
    if (this.masterDataForm.value.street) {
      this.timer = window.setTimeout(() => {
        // Call Google API
        let address = ``;
        address += this.masterDataForm.value.street ? `${this.masterDataForm.value.street}, ` : '';
        address += this.masterDataForm.value.zipCode;
        address += this.masterDataForm.value.place ? ` ${this.masterDataForm.value.place}, Germany` : ', Germany';

        this.eventsService.getGoogleLocationData(address).subscribe((response: any) => {
          if (response) {
            const { results } = response;
            this.latitude = results[0].geometry.location.lat;
            this.longitude = results[0].geometry.location.lng;
          }
          this.setLayer(this.latitude, this.longitude);
          this.setCenter();
        });
      }, this.timeoutVal);
    }
  }

  selectZipCode(zipCode: any) {
    this.masterDataForm.get('zipCode').setValue(zipCode.zips);
    this.masterDataForm.get('place').setValue(zipCode.name);
    this.locationId = zipCode.id;
    this.countryId = zipCode.countryId;
    this.stateId = zipCode.stateId;
    this.fetchDistrict(this.locationId);

    // Call Google API
    let address = ``;
    address += this.masterDataForm.value.street ? `${this.masterDataForm.value.street}, ` : '';
    address += this.masterDataForm.value.zipCode;
    address += this.masterDataForm.value.place ? ` ${this.masterDataForm.value.place}, Germany` : ', Germany';

    this.eventsService.getGoogleLocationData(address).subscribe((response: any) => {
      if (response) {
        const { results } = response;
        this.latitude = results[0].geometry.location.lat;
        this.longitude = results[0].geometry.location.lng;
      } else {
        this.latitude = zipCode.latitude;
        this.longitude = zipCode.longitude;
      }
      this.setLayer(this.latitude, this.longitude);
      this.setCenter();
    });
  }

  fetchDistrict(locationId: number) {
    if (locationId) {
      this.districtArray = [];
      this.newCompanyService.getDistrict({ locationId }).subscribe(districts => {
        if (districts && districts.length) {
          this.districtArray = districts;
          this.masterDataForm.get('district').setValue('');
        }
      });
    }
  }

  changeDistrict(distirct: any) {
    if (distirct) {
      const distirctObjectArray = this.districtArray.filter((each: any) => each.name === distirct);
      if (distirctObjectArray && distirctObjectArray.length) {
        this.districtId = distirctObjectArray[0].id;
      }
    }
  }

  submitContactData() {
    if (!this.masterDataForm.valid) {
      for (let key in this.masterDataForm.controls) {
        if (!this.masterDataForm.controls[key].valid) {
          this.masterDataForm.controls[key].markAsTouched({ onlySelf: true });
        }
      }
      return;
    }

    const masterData = {
      ...this.masterDataForm.value,
      locationId: this.locationId,
      countryId: this.countryId,
      stadtteilId: this.districtId,
      stateId: this.stateId,
      latitude: this.latitude,
      longitude: this.longitude
    };

    this.myEntryService.saveClientMasterData(masterData).subscribe(
      (response: any) => {
        this.snackBar.open('Updated Successfully', 'Ok', {
          duration: 3000,
          panelClass: ['snackbar-success']
        });
      },
      (err: any) => {
        console.log('Error => ', err);
      }
    );
  }

  setCenter() {
    var view = this.map.getView();
    view.setCenter(ol.proj.fromLonLat([this.longitude, this.latitude]));
  }

  setMap() {
    this.map = new ol.Map({
      target: 'map',
      controls: ol.control.defaults({
        attributionOptions: {
          collapsible: false
        }
      }),
      layers: [
        new ol.layer.Tile({
          source: new ol.source.OSM()
        })
      ],
      view: new ol.View({
        center: ol.proj.fromLonLat([this.longitude, this.latitude]),
        zoom: 17
      })
    });

    this.setLayer(this.latitude, this.longitude);

    this.map.on('click', (args: any) => {
      const lonlat = ol.proj.transform(args.coordinate, 'EPSG:3857', 'EPSG:4326');
      this.tempLat = lonlat[1];
      this.tempLong = lonlat[0];
      this.newLocationPopup = true;
      this.setLayer(this.tempLat, this.tempLong);
    });
  }

  setLayer(latitude: number, longitude: number) {
    this.vectorLayer ? this.map.removeLayer(this.vectorLayer) : '';

    this.vectorLayer = new ol.layer.Vector({
      source: new ol.source.Vector({
        features: [
          new ol.Feature({
            geometry: new ol.geom.Point(ol.proj.fromLonLat([longitude, latitude]))
          })
        ]
      }),
      style: new ol.style.Style({
        image: new ol.style.Icon({
          // anchor: [0.5, 0.5],
          // anchorXUnits: 'fraction',
          // anchorYUnits: 'fraction',
          src: 'assets/icons/marker.png'
        })
      })
    });

    this.map.addLayer(this.vectorLayer);
  }

  cancel() {
    this.setLayer(this.latitude, this.longitude);
    this.setCenter();
    this.newLocationPopup = false;
  }

  setNewLocation() {
    this.latitude = this.tempLat;
    this.longitude = this.tempLong;

    const masterData = {
      latitude: this.latitude,
      longitude: this.longitude,
      setCoOrdinates: true
    };

    this.myEntryService.saveClientMasterData(masterData).subscribe(
      (response: any) => {
        this.newLocationPopup = false;
        this.setCenter();
        this.snackBar.open('Location Updated Successfully', 'Ok', {
          duration: 3000,
          panelClass: ['snackbar-success']
        });
      },
      (err: any) => {
        console.log('Error => ', err);
      }
    );
  }
}
