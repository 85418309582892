import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GastroAppService } from '../gastro-app.service';
import { MatBottomSheet } from '@angular/material';
import { UploadLogoComponent } from '../upload-logo/upload-logo.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-gastro-app-update-logo',
  templateUrl: './gastro-app-update-logo.component.html',
  styleUrls: ['./gastro-app-update-logo.component.scss']
})
export class GastroAppUpdateLogoComponent implements OnInit {
  client: any;
  loading: Boolean = false;
  errorMessage: string;

  constructor(
    private router: Router,
    private GastroAppService: GastroAppService,
    private bottomSheet: MatBottomSheet,
    private zone: NgZone,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.getGastroAppStats();
  }

  getGastroAppStats() {
    this.GastroAppService.getGastroRestaurantSettings().subscribe({
      next: item => {
        this.client = item;
      },
      error: err => {
        this.errorMessage = err.meassage;
      }
    });
  }

  logoOptions() {
    let clientId = this.client.betriebId;
    const bottomSheetRef = this.bottomSheet.open(UploadLogoComponent);
    // subscribe to observable that emit event when bottom sheet closes
    bottomSheetRef.afterDismissed().subscribe(dataFromChild => {
      if (dataFromChild.type.split('/')[0] === 'image') {
        this.zone.run(() => {
          this.loading = true;
          const uploadData = new FormData();
          uploadData.append('file', dataFromChild, dataFromChild.name);
          this.GastroAppService.uploadLogo(uploadData, clientId).subscribe((settings: any) => {
            this.loading = false;
            if (settings.betriebId) {
              this.getGastroAppStats();
            }
          });
        });
      }
    });
  }
}
