import { Component, OnInit } from '@angular/core';
import { GastroPayService } from '@app/gastro-pay/gastro-pay.service';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';

import * as jspdf from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';

import moment from 'moment';
import { AccountingService } from '@app/accounting/accounting.service';
moment.locale('de');

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit {
  invoices: any[] = [];

  constructor(private accountingService: AccountingService) {}

  ngOnInit() {
    this.getInvoices();
  }
  getInvoices() {
    this.accountingService.getInvoices().subscribe((data: any) => {
      this.invoices = data || [];
    });
  }

  returnPrice(price: any) {
    if (typeof price == 'string') {
      return parseFloat(price.replace(',', '.'));
    } else {
      return price || 0;
    }
  }
  translateMethod(text: string) {
    // 'moneyTransfer','lastschrift','onlinePayment','Storno','rechnung','kreditkarte','sofortüberweisung','paypal','teilstorno','verrechnung'
    return text
      .replace('verrechnung', 'Verrechnet am')
      .replace('moneyTransfer', 'Überweisung am')
      .replace('lastschrift', 'Lastschrift am')
      .replace('Storno', 'Storniert am')
      .replace('rechnung', 'Auf Rechnung')
      .replace('kreditkarte', 'Kreditkarte am')
      .replace('sofortüberweisung', 'Sofortüberweisung am')
      .replace('paypal', 'PayPal am')
      .replace('teilstorno', 'Teilweise storniert');
  }
}
