import { Component, OnInit } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { ClientService } from '@app/core/client.service';
import { MatSnackBar } from '@angular/material';

import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
moment.locale('de');

@Component({
  selector: 'app-feedback-statistic',
  templateUrl: './statistic.component.html',
  styleUrls: ['./statistic.component.scss']
})
export class StatisticComponent implements OnInit {
  private ngUnsubscribe: Subject<any> = new Subject();
  public feedbacks: any[] = [];
  public thisMonth: number = 0;
  public lastMonth: number = 0;

  public showChart: boolean = false;
  public loading: boolean = true;
  public showMonths: number = 1200;
  showStatisticDetails: boolean = false;

  barChartOptions: ChartOptions = {
    responsive: true
  };
  barChartLabels: Label[] = [];
  barChartLabels1: Label[] = [];
  barChartLabels2: Label[] = [];
  barChartLabels3: Label[] = [];
  barChartLabels4: Label[] = [];
  barChartLabels5: Label[] = [];
  barChartType: ChartType = 'line';
  barChartLegend = true;
  barChartPlugins: any[] = [];

  barChartData: ChartDataSets[] = [{ data: [], label: '' }];
  barChartData1: ChartDataSets[] = [{ data: [], label: '' }];
  barChartData2: ChartDataSets[] = [{ data: [], label: '' }];
  barChartData3: ChartDataSets[] = [{ data: [], label: '' }];
  barChartData4: ChartDataSets[] = [{ data: [], label: '' }];
  barChartData5: ChartDataSets[] = [{ data: [], label: '' }];

  barChartColors: Color[] = [
    {
      borderColor: 'rgba(0, 0, 0, 0.40)',
      backgroundColor: 'rgba(0, 0, 0, 0.20)'
    }
  ];

  barChartColors1: Color[] = [
    {
      borderColor: 'rgba(0, 150, 136, 0.40)',
      backgroundColor: 'rgba(0, 150, 136, 0.20)'
    }
  ];
  barChartColors2: Color[] = [
    {
      borderColor: 'rgba(0, 92, 150, 0.40)',
      backgroundColor: 'rgba(0, 92, 150, 0.20)'
    }
  ];
  barChartColors3: Color[] = [
    {
      borderColor: 'rgba(150, 0, 138, 0.40)',
      backgroundColor: 'rgba(150, 0, 138, 0.20)'
    }
  ];
  barChartColors4: Color[] = [
    {
      borderColor: 'rgba(194, 37, 70, 0.40)',
      backgroundColor: 'rgba(194, 37, 70, 0.20)'
    }
  ];
  barChartColors5: Color[] = [
    {
      borderColor: 'rgba(231, 103, 0, 0.40)',
      backgroundColor: 'rgba(231, 103, 0, 0.20)'
    }
  ];
  barChartColorsForLines: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255, 255, 255, 0.5)'
    }
  ];

  stars: any[] = [
    {
      label: 'Gesamteindruck',
      value: 0,
      ratings: []
    },
    {
      label: 'Service',
      value: 0,
      ratings: []
    },
    {
      label: 'Sauberkeit',
      value: 0,
      ratings: []
    },
    {
      label: 'Essen',
      value: 0,
      ratings: []
    },
    {
      label: 'Ambiente',
      value: 0,
      ratings: []
    },
    {
      label: 'Preis/Leistung',
      value: 0,
      ratings: []
    }
  ];

  dates: any = {
    from: '',
    to: ''
  };

  tab: any;

  datesAI: any = {
    from: '',
    to: ''
  };
  question: string = '';
  result: string = '';
  showErrorMessage: boolean = false;
  disableBtn: boolean;
  accessFeedbackAI: boolean = false;
  reviews: any[] = [];

  constructor(
    private clientService: ClientService,
    private snackBar: MatSnackBar,
    public translate: TranslateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const tab = +params['tab'];
      if (!isNaN(tab) && params['google'] === 'true') {
        this.tab = tab;
        this.showStatisticDetails = true;
      }
    });
    this.getFeedbacks();
    this.getPremiumBookings();
  }

  getFeedbacks() {
    this.loading = true;
    this.dates.to = moment().format('YYYY-MM-DD');
    this.dates.from = moment()
      .subtract(1, 'year')
      .format('YYYY-MM-DD');
    this.lastMonth = 0;
    this.thisMonth = 0;

    this.clientService
      .getFeedbacks(this.dates.from, this.dates.to)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(res => {
        this.feedbacks = res.reverse() || [];
        this.feedbacks.forEach((row: any) => {
          this.loading = false;
          if (row.generalRating) {
            this.stars[0].ratings.push(row.generalRating);
          }
          if (row.serviceRating) {
            this.stars[1].ratings.push(row.serviceRating);
          }
          if (row.sauberkeitRating) {
            this.stars[2].ratings.push(row.sauberkeitRating);
          }
          if (row.essenRating) {
            this.stars[3].ratings.push(row.essenRating);
          }
          if (row.ambienteRating) {
            this.stars[4].ratings.push(row.ambienteRating);
          }
          if (row.preisLeistungRating) {
            this.stars[5].ratings.push(row.preisLeistungRating);
          }

          if (
            moment(row.postedAt).format('YMM') >=
            moment()
              .subtract(1, 'months')
              .format('YMM')
          ) {
            this.lastMonth++;
          }
          if (moment(row.postedAt).format('YMM') >= moment().format('YMM')) {
            this.thisMonth++;
          }
        });

        this.setRatings();
        console.log('res', this.feedbacks);
      });
  }

  setRatings() {
    this.showChart = false;
    this.loading = true;

    this.barChartLabels = [];
    this.barChartLabels1 = [];
    this.barChartLabels2 = [];
    this.barChartLabels3 = [];
    this.barChartLabels4 = [];
    this.barChartLabels5 = [];
    let tempBarChartData: any[] = [{}, {}, {}, {}, {}, {}];
    let tempBarChartDataI: any[] = [{}, {}, {}, {}, {}, {}];

    this.stars.forEach((star: any) => {
      star.value =
        star.ratings.reduce(function(acc: any, val: any) {
          return acc + val;
        }, 0) / star.ratings.length;
    });
    this.loading = false;
    this.feedbacks.forEach((feedback: any) => {
      let key = moment(feedback.postedAt).format('MM.Y');

      if (
        moment(feedback.postedAt).format('YMM') >=
        moment()
          .subtract(this.showMonths, 'months')
          .format('YMM')
      ) {
        if (feedback.generalRating) {
          if (!this.barChartLabels.includes(key)) {
            this.barChartLabels.push(key);
          }
          if (tempBarChartData[0][key]) {
            tempBarChartData[0][key] = tempBarChartData[0][key] + feedback.generalRating;
            tempBarChartDataI[0][key].count++;
          } else {
            tempBarChartData[0][key] = feedback.generalRating;
            tempBarChartDataI[0][key] = { count: 1 };
          }
        }
        if (feedback.serviceRating) {
          if (!this.barChartLabels1.includes(key)) {
            this.barChartLabels1.push(key);
          }
          if (tempBarChartData[1][key]) {
            tempBarChartData[1][key] = tempBarChartData[1][key] + feedback.serviceRating;
            tempBarChartDataI[1][key].count++;
          } else {
            tempBarChartData[1][key] = feedback.serviceRating;
            tempBarChartDataI[1][key] = { count: 1 };
          }
        }
        if (feedback.sauberkeitRating) {
          if (!this.barChartLabels2.includes(key)) {
            this.barChartLabels2.push(key);
          }
          if (tempBarChartData[2][key]) {
            tempBarChartData[2][key] = tempBarChartData[2][key] + feedback.sauberkeitRating;
            tempBarChartDataI[2][key].count++;
          } else {
            tempBarChartData[2][key] = feedback.sauberkeitRating;
            tempBarChartDataI[2][key] = { count: 1 };
          }
        }
        if (feedback.essenRating) {
          if (!this.barChartLabels3.includes(key)) {
            this.barChartLabels3.push(key);
          }
          if (tempBarChartData[3][key]) {
            tempBarChartData[3][key] = tempBarChartData[3][key] + feedback.essenRating;
            tempBarChartDataI[3][key].count++;
          } else {
            tempBarChartData[3][key] = feedback.essenRating;
            tempBarChartDataI[3][key] = { count: 1 };
          }
        }
        if (feedback.ambienteRating) {
          if (!this.barChartLabels4.includes(key)) {
            this.barChartLabels4.push(key);
          }
          if (tempBarChartData[4][key]) {
            tempBarChartData[4][key] = tempBarChartData[4][key] + feedback.ambienteRating;
            tempBarChartDataI[4][key].count++;
          } else {
            tempBarChartData[4][key] = feedback.ambienteRating;
            tempBarChartDataI[4][key] = { count: 1 };
          }
        }
        if (feedback.preisLeistungRating) {
          if (!this.barChartLabels5.includes(key)) {
            this.barChartLabels5.push(key);
          }
          if (tempBarChartData[5][key]) {
            tempBarChartData[5][key] = tempBarChartData[5][key] + feedback.preisLeistungRating;
            tempBarChartDataI[5][key].count++;
          } else {
            tempBarChartData[5][key] = feedback.preisLeistungRating;
            tempBarChartDataI[5][key] = { count: 1 };
          }
        }
      }
    });

    let tempData: any[] = [];
    let tempDataI: any[] = [];

    tempData[0] = Object.values(tempBarChartData[0]);
    tempDataI[0] = Object.values(tempBarChartDataI[0]);

    tempData[1] = Object.values(tempBarChartData[1]);
    tempDataI[1] = Object.values(tempBarChartDataI[1]);

    tempData[2] = Object.values(tempBarChartData[2]);
    tempDataI[2] = Object.values(tempBarChartDataI[2]);

    tempData[3] = Object.values(tempBarChartData[3]);
    tempDataI[3] = Object.values(tempBarChartDataI[3]);

    tempData[4] = Object.values(tempBarChartData[4]);
    tempDataI[4] = Object.values(tempBarChartDataI[4]);

    tempData[5] = Object.values(tempBarChartData[5]);
    tempDataI[5] = Object.values(tempBarChartDataI[5]);
    /*
      tempData.forEach((row: any) => {
      */
    /*
        j = 0;
        tempData[i].forEach((val: any) => {
          console.log('A', i,j,'val:'+val,'count:'+tempDataI[i][j].count);
          val = val / tempDataI[i][j].count;
          tempData[i][j] = val;
          tempDataI[i][j].count = 1;
          console.log('B', i,j,'val:'+val,'count:'+tempDataI[i][j].count);
        });
        i++;
        */
    //});

    for (let i = 0; i <= 5; i++) {
      console.log(i, tempData[i], tempDataI[i]);
      // more statements
    }

    let i = 0;
    tempData.forEach((row: any) => {
      let j = 0;
      row.forEach((val: any) => {
        tempData[i][j] = val / tempDataI[i][j].count;
        j++;
      });
      i++;
    });

    /*
      console.log('A', tempData[0][0], tempDataI[0][0].count);
      tempData[0][0] = tempData[0][0] / tempDataI[0][0].count;
      console.log('B', tempData[0][0], tempDataI[0][0].count);
      */

    //console.log('A', i,j,'val:'+val,'count:'+tempDataI[i][j].count);

    this.barChartData = [
      {
        data: tempData[0],
        label: 'Gesamteindruck'
      }
    ];

    this.barChartData1 = [
      {
        data: tempData[1],
        label: 'Service'
      }
    ];

    this.barChartData2 = [
      {
        data: tempData[2],
        label: 'Sauberkeit'
      }
    ];

    this.barChartData3 = [
      {
        data: tempData[3],
        label: 'Essen'
      }
    ];

    this.barChartData4 = [
      {
        data: tempData[4],
        label: 'Ambiente'
      }
    ];

    this.barChartData5 = [
      {
        data: tempData[5],
        label: 'Preis/Leistung'
      }
    ];

    this.showChart = true;
  }

  selectedTabChange(e: any) {
    if (e.index === 1) {
      this.showStatisticDetails = true;
    }
  }

  // Function to validate To and From date

  validateDateRange() {
    this.refresh();
    if (moment(this.datesAI.from).isAfter(this.datesAI.to)) {
      this.showErrorMessage = true;
      return;
    } else {
      this.showErrorMessage = false;
    }
  }

  setDates(type: string) {
    if (type == 'today') {
      this.datesAI.from = moment().format();
      this.datesAI.to = moment().format();
    }
    if (type == 'yesterday') {
      this.datesAI.from = moment()
        .subtract(1, 'days')
        .format();
      this.datesAI.to = moment()
        .subtract(1, 'days')
        .format();
    }
    if (type == 'thisWeek') {
      const date = moment();
      this.datesAI.from = date.startOf('week');
      this.datesAI.to = moment(this.datesAI.from).endOf('week');
    }
    if (type == 'lastSeven') {
      this.datesAI.from = moment()
        .subtract(6, 'days')
        .format();
      this.datesAI.to = moment().format();
    }
    if (type == 'lastWeek') {
      const date = moment().subtract(1, 'week');
      this.datesAI.from = date.startOf('week');
      this.datesAI.to = moment(this.datesAI.from).endOf('week');
    }
    if (type == 'lastMonth') {
      const date = moment().subtract(1, 'month');
      this.datesAI.from = date.startOf('month');
      this.datesAI.to = moment(this.datesAI.from).endOf('month');
    }
    if (type == 'thisMonth') {
      const date = moment();
      this.datesAI.from = date.startOf('month');
      this.datesAI.to = moment(this.datesAI.from).endOf('month');
    }
  }

  AIIntegration() {
    if (!(this.datesAI.from && this.datesAI.to && this.question)) {
      this.snackBar.open(this.translate.instant('Common.FormInvalid'), '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    const fromDate = moment(this.datesAI.from).startOf('day');
    const toDate = moment(this.datesAI.to).endOf('day');
    this.disableBtn = true;
    this.clientService
      .AIIntegration(fromDate, toDate, this.question)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        res => {
          if (res && res.result) {
            this.result = this.escapeHtml(res.result);
          } else {
            this.snackBar.open(this.translate.instant('Common.NoDataFound'), '', {
              duration: 2000,
              panelClass: ['snackbar-error']
            });
          }
          this.disableBtn = false;
        },
        (err: any) => {
          this.disableBtn = false;
          this.snackBar.open(err.error.msg, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      );
  }

  escapeHtml(unsafe: string): string {
    return unsafe
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;')
      .replace(/\n/g, '<br/>');
  }

  refresh() {
    // this.result = '';
  }

  // Function to copy text to clipboard
  copyToClipboard(text: string) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        this.snackBar.open(this.translate.instant('FeedBack.AI.DataCopiedToClipboard'), '', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      })
      .catch(err => {
        this.snackBar.open(this.translate.instant('FeedBack.AI.DataNotCopiedToClipboard'), '', {
          duration: 2000,
          panelClass: ['snackbar-error']
        });
      });
  }

  getPremiumBookings() {
    this.clientService
      .getPremiumBookings('feedbackAI')
      .takeUntil(this.ngUnsubscribe)
      .subscribe(res => {
        if (res && res.canAccess) {
          this.accessFeedbackAI = true;
        }
      });
  }
}
