import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';
import { CoreModule } from '@app/core';
import {
  MatIconModule,
  MatDatepickerModule,
  MatTabsModule,
  MatSnackBarModule,
  MatSlideToggleModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatExpansionModule,
  MatAutocompleteModule,
  MatListModule
} from '@angular/material';
import { DateFnsModule } from 'ngx-date-fns';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MomentModule } from 'ngx-moment';
import { SatNativeDateModule, SatDatepickerModule } from 'saturn-datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexModule } from '@angular/flex-layout';
import { Angular2CsvModule } from 'angular2-csv';
import { GastroAppRoutingModule } from './gastro-app-routing.module';
import { Ng5SliderModule } from 'ng5-slider';
import { GastroAppStatisticsComponent } from './gastro-app-statistics/gastro-app-statistics.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ChartsModule } from 'ng2-charts';
import { GastroAppUpdateLogoComponent } from './gastro-app-update-logo/gastro-app-update-logo.component';
import { UploadLogoComponent } from './upload-logo/upload-logo.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    CoreModule,
    SharedModule,
    MatIconModule,
    NgScrollbarModule,
    NgxDatatableModule,
    MomentModule,
    NgxDatatableModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatDatepickerModule,
    MatTabsModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatExpansionModule,
    NgSelectModule,
    Ng5SliderModule,
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    DateFnsModule.forRoot(),
    Angular2CsvModule,
    GastroAppRoutingModule,
    MatAutocompleteModule,
    ChartsModule,
    MatListModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [GastroAppStatisticsComponent, GastroAppUpdateLogoComponent, UploadLogoComponent],
  entryComponents: [UploadLogoComponent],
  providers: [NgbActiveModal]
})
export class GastroAppModule {}
