import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';
import { CoreModule } from '@app/core';
import {
  MatSlideToggleModule,
  MatCheckboxModule,
  MatIconModule,
  MatTabsModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatRadioModule,
  MatDatepickerModule,
  MatProgressSpinnerModule
} from '@angular/material';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControlErrorComponent } from '@app/shared/control-error/control-error.component';
import { MomentModule } from 'ngx-moment';
import { DateFnsModule } from 'ngx-date-fns';
import { AccountingRoutingModule } from './accounting-routing.module';
import { PaymentSettingsComponent } from './payment-settings/payment-settings.component';
import { VouchersComponent } from './vouchers/vouchers.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { PayoutComponent } from './reservations/payout/payout.component';
import { PayoutComponent as VoucherPayoutComponent } from '../vouchers/payout/payout.component';
import { PayoutComponent as TicketPayoutComponent } from '../events/payout/payout.component';
import { Angular2CsvModule } from 'angular2-csv';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    CoreModule,
    SharedModule,
    MatSlideToggleModule,
    AccountingRoutingModule,
    MatCheckboxModule,
    MatIconModule,
    MatTabsModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatRadioModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    MatDatepickerModule,
    Angular2CsvModule,
    NgxDatatableModule,
    MatProgressSpinnerModule,
    DateFnsModule.forRoot()
  ],
  declarations: [
    PaymentSettingsComponent,
    PayoutComponent,
    VouchersComponent,
    VoucherPayoutComponent,
    InvoiceListComponent,
    TicketPayoutComponent
  ],
  exports: [
    PaymentSettingsComponent,
    PayoutComponent,
    VouchersComponent,
    VoucherPayoutComponent,
    InvoiceListComponent,
    TicketPayoutComponent
  ],
  entryComponents: [ControlErrorComponent]
})
export class AccountingModule {}
