import { Component, OnInit } from '@angular/core';
import { MenucardsService } from './menucards.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddMenucardComponent } from './add-menucard/add-menucard.component';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import moment from 'moment';
import { GastroPayService } from '@app/gastro-pay/gastro-pay.service';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-menucards',
  templateUrl: './menucards.component.html',
  styleUrls: ['./menucards.component.scss']
})
export class MenucardsComponent implements OnInit {
  settings: any;
  menucards: any = [];
  menucardsArchive: any = [];
  activeClient: any;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private orderingService: GastroPayService,
    private menucardsService: MenucardsService,
    private modalService: NgbModal,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private clientResolver: ClientResolverService
  ) {
    this.activeClient = this.clientResolver.client;
  }

  ngOnInit() {
    this.getMenucards();
    this.orderingService
      .getSettings()
      .takeUntil(this.ngUnsubscribe)
      .subscribe(settings => {
        this.settings = settings;
      });
  }

  getMenucards() {
    this.menucards = [];
    this.menucardsService.getClientMenus().subscribe((menus: any) => {
      menus.forEach((menu: any) => {
        if (menu.validUntil && moment(new Date()).isAfter(menu.validUntil)) {
          this.menucardsArchive.push(menu);
          return;
        }
        this.menucards.push(menu);
        console.log(this.menucards);
      });
      // this.menucards = menus;
    });
  }

  addMenu() {
    const modalRef = this.modalService.open(AddMenucardComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      this.menucards.push(receivedEntry);
      const snackBarRef = this.snackBar.open('Speisekarte erfolgreich hinzugefügt', 'Ok', {
        duration: 3000
      });
      modalRef.close();
    });
  }

  editMenu(menu: any) {
    const modalRef = this.modalService.open(AddMenucardComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.editMenuData = { ...menu };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      // Find menu in array to update it
      const index = this.menucards.findIndex((m: any) => m.id === receivedEntry.id);
      this.menucards[index] = receivedEntry;

      const snackBarRef = this.snackBar.open('Speisekarte erfolgreich gespeichert', 'Ok', {
        duration: 3000
      });
      modalRef.close();
    });
  }

  manageMenu(id: number) {
    this.router.navigate(['manage/' + id], { relativeTo: this.route });
  }

  deleteMenu(menuId: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Speisekarte löschen';
    modalRef.componentInstance.message = `Sind Sie sicher dass Sie die Speisekarte löschen möchten? Diese Aktion ist unwiederruflich.`;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      (result: any) => {
        if (result === 'ok') {
          this.menucardsService.deleteMenucard(menuId).subscribe(
            () => {
              this.snackBar.open('Speisekarte wurde erfolgreich gelöscht', '', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
              this.getMenucards();
            },
            (err: any) => {
              this.snackBar.open(err.error.msg, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
              });
            }
          );
        }
      },
      () => {}
    );
  }

  updateMenu(menu: any) {
    this.menucardsService.saveMenucard(menu).subscribe(() => {});
  }

  previewMenu(menu: any) {
    let clientName = this.activeClient.name.replace('.', '');
    clientName = clientName.replace('/', '');
    clientName = clientName.replace('ä', 'ae');
    clientName = clientName.replace('ö', 'oe');
    clientName = clientName.replace('ü', 'ue');

    const ordershopLink =
      'https://order.gastroguide.de/' + encodeURI(clientName) + '/' + this.activeClient.id + '?preview=' + menu.id;

    window.open(ordershopLink, '_blank');
  }

  openMenu(menu: any) {
    let clientName = this.activeClient.name.replace('.', '');
    clientName = clientName.replace('/', '');
    clientName = clientName.replace('ä', 'ae');
    clientName = clientName.replace('ö', 'oe');
    clientName = clientName.replace('ü', 'ue');

    const ordershopLink =
      'https://order.gastroguide.de/' + encodeURI(clientName) + '/' + this.activeClient.id + '/' + menu.id;

    window.open(ordershopLink, '_blank');
  }

  dropMenu(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.menucards, event.previousIndex, event.currentIndex);
    this.menucardsService.reorderMenucards(this.menucards).subscribe((res: any) => {
      this.snackBar.open('Reihenfolge wurde erfolgreich gespeichert', '', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    });
  }
}
