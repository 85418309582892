import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { NotFoundComponent } from './not-found/not-found.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { Shell } from '@app/shell/shell.service';
import { ShellForbiddenComponent } from './shell-forbidden/shell-forbidden.component';

const routes: Routes = [
  {
    path: '404',
    component: NotFoundComponent,
    data: { title: extract('Seite nicht gefunden') }
  },
  {
    path: '403',
    component: ForbiddenComponent,
    data: { title: extract('Keine Berechtigung') }
  }
  /*
  Shell.childRoutes([
    {
      path: 'client/:clientId/forbidden',
      component: ShellForbiddenComponent,
      data: {
        title: 'Keine Berechtigung',
        breadcrumb: ''
      }
    }
  ])
  */
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class ErrorPagesRoutingModule {}
