import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewEncapsulation,
  ViewChild,
  ElementRef
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import 'rxjs/add/operator/takeUntil';
import { Subject } from 'rxjs';
import moment from 'moment';
moment.locale('de');
@Component({
  selector: 'app-add-special-times',
  templateUrl: './add-special-times.component.html',
  styleUrls: ['./add-special-times.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddSpecialTimesComponent implements OnInit, OnDestroy {
  @Input() public editData: any; // only if editing
  @Input() public dayLimitTemplate: any;
  @Input() public limitArray: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  specialGastroTimesGroup: FormGroup;
  private ngUnsubscribe: Subject<any> = new Subject();
  times: any = [];
  finalDayLimit: any = [];

  constructor(public activeModal: NgbActiveModal, public formBuilder: FormBuilder, private snackBar: MatSnackBar) {}

  ngOnInit() {
    let finalArray: any = [];
    this.limitArray.map((item: any) => {
      this.dayLimitTemplate.filter(function(i: any) {
        if (i.value == item.day) {
          i.show = true;
          finalArray.push(i);
        }
      });
    });
    this.finalDayLimit = finalArray;
    this.getTimesArray();
    this.specialGastroTimesGroup = this.formBuilder.group({
      day: ['', Validators.required],
      from: ['', Validators.required],
      to: ['', Validators.required],
      limitDayTemplate: [''],
      delivery: [1],
      takeaway: [1]
    });

    if (this.editData) {
      this.specialGastroTimesGroup.addControl('id', new FormControl(this.editData.id, Validators.required));
      this.specialGastroTimesGroup.get('day').setValue(this.editData.day);
      this.specialGastroTimesGroup.get('from').setValue(this.editData.from);
      this.specialGastroTimesGroup.get('to').setValue(this.editData.to);
      this.specialGastroTimesGroup.get('delivery').setValue(this.editData.delivery);
      this.specialGastroTimesGroup.get('takeaway').setValue(this.editData.takeaway);
      this.specialGastroTimesGroup.get('limitDayTemplate').setValue(this.editData.limitDayTemplate);
    }
  }
  getTimesArray() {
    let tStart = 0;
    const interval = 15;
    for (let i = 0; tStart <= 24 * 60; i++) {
      const hh = Math.floor(tStart / 60); // getting hours of day in 0-24 format
      const mm = tStart % 60; // getting minutes of the hour in 0-55 format
      const timeObj = {
        time: ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2),
        value: hh
      };
      this.times[i] = timeObj.time;
      tStart = tStart + interval;
    }
  }
  add() {
    if (!this.specialGastroTimesGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben.', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    this.passEntry.emit(this.specialGastroTimesGroup.value);
  }
  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
