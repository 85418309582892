import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { EventsService } from '../events.service';
import { MatSnackBar } from '@angular/material';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-event-dates',
  templateUrl: './add-event-dates.component.html',
  styleUrls: ['./add-event-dates.component.scss']
})
export class AddEventDatesComponent implements OnInit {
  @Input() public eventData: any;
  @Output() passNewEventDate: EventEmitter<any> = new EventEmitter();
  error: string;
  startDate: string;
  defaultTime: string;
  times: any;
  eventDateFormGroup: FormGroup;
  isEditDate: boolean;
  formInvalidError: string;

  private ngUnsubscribe: Subject<any> = new Subject();
  constructor(
    private formBuilder: FormBuilder,
    private eventsService: EventsService,
    private snackBar: MatSnackBar,
    public activeModal: NgbActiveModal,
    public translate: TranslateService
  ) {
    this.eventDateFormGroup = this.formBuilder.group(
      {
        startsAtDay: ['', Validators.required],
        startsAtHour: [''],
        endsAtDay: [''],
        endsAtHour: [''],
        salesOpeningFrom: [''],
        salesEndsOn: ['']
      },
      { validator: this.dateLessThan('startsAtDay', 'endsAtDay') }
    );
    this.loadTranslate();
    this.translate.onLangChange.subscribe((event: any) => {
      this.loadTranslate();
    });
  }

  ngOnInit() {
    this.startDate = moment().format('YYYY-MM-DD');
    this.times = [];
    this.defaultTime = moment().format('HH:mm');
    this.getTimes();
    this.isEditDate = false;

    if (this.eventData.dateInfo) {
      const appointmentData = this.eventData.dateInfo;
      this.eventDateFormGroup
        .get('startsAtDay')
        .setValue(
          appointmentData.startsAtDay && appointmentData.startsAtDay != '0000-00-00' ? appointmentData.startsAtDay : ''
        );
      this.eventDateFormGroup.get('startsAtHour').setValue(appointmentData.startsAtHour);
      this.eventDateFormGroup
        .get('endsAtDay')
        .setValue(
          appointmentData.endsAtDay && appointmentData.endsAtDay != '0000-00-00' ? appointmentData.endsAtDay : ''
        );
      this.eventDateFormGroup.get('endsAtHour').setValue(appointmentData.endsAtHour);
      this.eventDateFormGroup.get('salesOpeningFrom').setValue(appointmentData.salesOpeningFrom);
      this.eventDateFormGroup.get('salesEndsOn').setValue(appointmentData.salesEndsOn);
      this.isEditDate = true;
    }
  }

  private loadTranslate() {
    this.formInvalidError = this.translate.instant('Events&Tickets.Common.FormInvalid');
  }

  dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      const f = group.controls[from];
      const t = group.controls[to];
      if (t && t.value && moment(f.value) > moment(t.value)) {
        return {
          dates: 'Das End-Datum darf nicht vor dem Start-Datum liegen!'
        };
      }
      return {};
    };
  }
  add() {
    if (!this.eventDateFormGroup.valid) {
      this.snackBar.open(this.formInvalidError, '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    this.error = null;

    this.eventDateFormGroup.value.startsAtDay = moment(this.eventDateFormGroup.value.startsAtDay).format('YYYY-MM-DD');
    this.eventDateFormGroup.value.endsAtDay = this.eventDateFormGroup.value.endsAtDay
      ? moment(this.eventDateFormGroup.value.endsAtDay).format('YYYY-MM-DD')
      : '';

    (this.eventDateFormGroup.value.salesOpeningFrom = this.eventDateFormGroup.value.salesOpeningFrom
      ? moment(this.eventDateFormGroup.value.salesOpeningFrom).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD')),
      (this.eventDateFormGroup.value.salesEndsOn = this.eventDateFormGroup.value.salesEndsOn
        ? moment(this.eventDateFormGroup.value.salesEndsOn).format('YYYY-MM-DD')
        : moment(this.eventDateFormGroup.value.startsAtDay)
            .subtract(1, 'day')
            .format('YYYY-MM-DD'));

    this.eventsService
      .addEventDates(this.eventDateFormGroup.value, this.eventData.id)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          response['startsAtDay'] = moment(response['startsAtDay']).format('YYYY-MM-DD');
          response['endsAtDay'] = response['endsAtDay'] ? moment(response['endsAtDay']).format('YYYY-MM-DD') : '';
          this.passNewEventDate.emit(response);
        },
        err => {
          this.error = err.error.msg;
        }
      );
  }

  save() {
    if (!this.eventDateFormGroup.valid) {
      this.snackBar.open(this.formInvalidError, '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    this.error = null;

    this.eventDateFormGroup.value.startsAtDay = moment(this.eventDateFormGroup.value.startsAtDay).format('YYYY-MM-DD');
    this.eventDateFormGroup.value.endsAtDay = this.eventDateFormGroup.value.endsAtDay
      ? moment(this.eventDateFormGroup.value.endsAtDay).format('YYYY-MM-DD')
      : '';

    (this.eventDateFormGroup.value.salesOpeningFrom = this.eventDateFormGroup.value.salesOpeningFrom
      ? moment(this.eventDateFormGroup.value.salesOpeningFrom).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD')),
      (this.eventDateFormGroup.value.salesEndsOn = this.eventDateFormGroup.value.salesEndsOn
        ? moment(this.eventDateFormGroup.value.salesEndsOn).format('YYYY-MM-DD')
        : moment(this.eventDateFormGroup.value.startsAtDay)
            .subtract(1, 'day')
            .format('YYYY-MM-DD'));

    this.eventsService
      .updateEventDates(this.eventDateFormGroup.value, this.eventData.id, this.eventData.dateInfo.id)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          const formValue = { ...this.eventDateFormGroup.value };
          formValue['id'] = this.eventData.dateInfo.id;
          // response['startsAtDay'] = moment(response['startsAtDay']).format('YYYY-MM-DD');
          // response['endsAtDay'] = response['endsAtDay'] ? moment(response['endsAtDay']).format('YYYY-MM-DD') : '';
          this.passNewEventDate.emit(formValue);
        },
        err => {
          this.error = err.error.msg;
        }
      );
  }

  getTimes() {
    let tStart = 0;
    const interval = 15;
    for (let i = 0; tStart < 24 * 60; i++) {
      const hh = Math.floor(tStart / 60); // getting hours of day in 0-24 format
      const mm = tStart % 60; // getting minutes of the hour in 0-55 format

      const timeObj = {
        time: ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2),
        isClosed: true
      };
      this.times.push(timeObj);

      // console.log('get times');
      const hhNext = Math.floor((tStart + interval) / 60); // getting hours of day in 0-24 format
      const mmNext = (tStart + interval) % 60; // getting minutes of the hour in 0-55 format
      const dateObjNext = new Date();
      dateObjNext.setHours(hhNext);
      dateObjNext.setMinutes(mmNext);

      const dateObj = new Date();
      dateObj.setHours(hh);
      dateObj.setMinutes(mm);

      const defaultTimeDateObj = new Date();
      const defaultTimeSplit = this.defaultTime.split(':');
      defaultTimeDateObj.setHours(+defaultTimeSplit[0]);
      defaultTimeDateObj.setMinutes(+defaultTimeSplit[1]);

      if (dateObj < defaultTimeDateObj && dateObjNext > defaultTimeDateObj) {
        const defaultTimeObj = {
          time: this.defaultTime,
          isClosed: false
        };
        this.times.push(defaultTimeObj);
      }

      tStart = tStart + interval;
    }
    // console.log('times => ', this.times);
  }
}
