import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NewCompanyComponent } from './new-company.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  MatStepperModule,
  MatIconModule,
  MatDatepickerModule,
  MatAutocompleteModule,
  MatProgressBarModule,
  MatSlideToggleModule
} from '@angular/material';
import { AgmCoreModule } from '@agm/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { CommonOuterModule } from '@app/shared/common-outer.module';

const routes: Routes = [{ path: 'new-client', component: NewCompanyComponent }];

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [NewCompanyComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatStepperModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    NgxDropzoneModule,
    NgSelectModule,
    CommonOuterModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBvKqFb3-0ryGntQlxwSZWoO9miYqZWRkg'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class NewCompanyModule {}
