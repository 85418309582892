import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { GastroAppService } from '../gastro-app.service';
import { MatSnackBar } from '@angular/material';
import { AuthenticationService, CredentialsService } from '@app/core';
import { TranslateService } from '@ngx-translate/core';

import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import moment from 'moment';
import { map, switchMap } from 'rxjs/operators';
moment.locale('de');

@Component({
  selector: 'app-gastro-app-statistics',
  templateUrl: './gastro-app-statistics.component.html',
  styleUrls: ['./gastro-app-statistics.component.scss']
})
export class GastroAppStatisticsComponent implements OnInit {
  loading: boolean = true;
  gastroAppData: any = {};
  subscribedUser: string = '';
  totalUser: string = '';
  hasGastroAppData: boolean = false;
  selectedTimePeriod: string = 'Last30days';
  selectedOption: any[] = [];
  monthNames: any[] = [];
  clickLabel: string = '';
  subscriberLabel: string = '';
  dataType: string = '';
  last30daysName: string = '';

  chartData: ChartDataSets[] = [
    { data: [], label: '' },
    { data: [], label: '' }
  ];
  chartLabels: Label[];

  chartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{ stacked: true }],
      yAxes: [{ stacked: false }]
    }
  };

  chartColors: Color[] = [
    {
      backgroundColor: 'rgba(59, 182, 116, 0.3)',
      borderColor: 'rgb(59, 182, 116)',
      pointBackgroundColor: 'rgb(59, 182, 116)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(59, 182, 116)'
    },
    {
      backgroundColor: 'rgba(54, 162, 235, 0.3)',
      borderColor: 'rgb(54, 162, 235)',
      pointBackgroundColor: 'rgb(54, 162, 235)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgb(54, 162, 235)'
    }
  ];

  chartLegend = true;
  chartPlugins: any = [];
  chartType: ChartType = 'line';

  constructor(private GastroAppService: GastroAppService, public translate: TranslateService) {
    this.translate.onLangChange.subscribe((event: any) => {
      this.translate.setDefaultLang(event.lang);
      this.translator();
    });
  }

  ngOnInit() {
    this.loading = true;
    this.translator();
    this.getGastroAppStats();
  }

  translator() {
    this.selectedOption = [
      { value: 'Last30days', label: this.translate.instant('GastroAppStatistics.Last30days') },
      { value: 'pastYear', label: this.translate.instant('GastroAppStatistics.PastYear') }
    ];
    this.last30daysName = this.translate.instant('GastroAppStatistics.Last30days');
    this.monthNames = [
      this.translate.instant('GastroAppStatistics.Jan'),
      this.translate.instant('GastroAppStatistics.Feb'),
      this.translate.instant('GastroAppStatistics.Mar'),
      this.translate.instant('GastroAppStatistics.Apr'),
      this.translate.instant('GastroAppStatistics.May'),
      this.translate.instant('GastroAppStatistics.Jun'),
      this.translate.instant('GastroAppStatistics.Jul'),
      this.translate.instant('GastroAppStatistics.Aug'),
      this.translate.instant('GastroAppStatistics.Sep'),
      this.translate.instant('GastroAppStatistics.Oct'),
      this.translate.instant('GastroAppStatistics.Nov'),
      this.translate.instant('GastroAppStatistics.Dec')
    ];
    this.clickLabel = this.translate.instant('GastroAppStatistics.Clicks');
    this.subscriberLabel = this.translate.instant('GastroAppStatistics.SubscribedUsers');
  }

  getGastroAppStats() {
    this.GastroAppService.getGastroAppData().subscribe((data: any) => {
      if (data && data.message !== 'No Data') {
        this.gastroAppData = data;
        this.hasGastroAppData = true;
        this.getChartData(this.selectedTimePeriod);
        this.getGastroSubscriberData(data.pushApiKey, data.pushAppId);
      }
      this.loading = false;
    });
  }

  getGastroSubscriberData(pushApiKey: any, pushAppId: any) {
    this.GastroAppService.getGastroSubscriberData(pushApiKey, pushAppId).subscribe((data: any) => {
      this.subscribedUser = data.messageable_players;
      this.totalUser = data.players;
    });
  }

  getChartData(timePeriod: string) {
    this.selectedTimePeriod = timePeriod;

    this.GastroAppService.getGastroAppOutcomeStats(this.gastroAppData, timePeriod)
      .pipe(
        switchMap((outcomeStats: any) => {
          return this.GastroAppService.getGastroAppsubscriberStats(this.gastroAppData, timePeriod).pipe(
            map((subscriberStats: any) => {
              return { outcomeStats, subscriberStats };
            })
          );
        })
      )
      .subscribe(({ outcomeStats, subscriberStats }) => {
        let dayNames: string[] = [];
        let tempDataClicks: number[] = [];
        let tempDataSubscribers: number[] = [];
        let outcomeData: { [key: string]: any } = {};
        let subscriberData: { [key: string]: any } = {};

        if (timePeriod === 'Last30days') {
          this.processLast30DaysData(
            outcomeStats,
            subscriberStats,
            dayNames,
            tempDataClicks,
            tempDataSubscribers,
            outcomeData,
            subscriberData
          );
        } else {
          this.processPastYearData(
            outcomeStats,
            subscriberStats,
            dayNames,
            tempDataClicks,
            tempDataSubscribers,
            outcomeData,
            subscriberData
          );
        }

        this.chartData = [
          { data: tempDataClicks, label: this.clickLabel },
          { data: tempDataSubscribers, label: this.subscriberLabel }
        ];
        this.chartLabels = dayNames;
      });
  }

  private processLast30DaysData(
    outcomeStats: any,
    subscriberStats: any,
    dayNames: string[],
    tempDataClicks: number[],
    tempDataSubscribers: number[],
    outcomeData: any,
    subscriberData: any
  ) {
    let last30date = moment()
      .subtract(30, 'days')
      .format('YYYY-MM-DD');
    let today = moment().format('YYYY-MM-DD');
    var last30days = [];
    var startDate = moment(last30date, 'YYYY-MM-DD');
    while (!startDate.isSame(today)) {
      startDate = startDate.add(1, 'days');
      last30days.push(startDate.format('YYYY-MM-DD'));
    }

    outcomeStats.forEach((item: any) => {
      let date: any = item.date.split('-');
      let currentDate = this.monthNames[date[1] - 1] + ' ' + date[2];
      outcomeData[currentDate] = item;
    });

    subscriberStats.forEach((item: any) => {
      let date: any = item.date.split('-');
      let currentDate = this.monthNames[date[1] - 1] + ' ' + date[2];
      subscriberData[currentDate] = item;
    });

    last30days.forEach((date, i) => {
      let getdate: any = date.split('-');
      let dayName = this.monthNames[getdate[1] - 1] + ' ' + getdate[2];
      dayNames.push(dayName);
      tempDataClicks[i] = outcomeData[dayName] ? outcomeData[dayName].osClick : 0;
      tempDataSubscribers[i] = subscriberData[dayName] ? subscriberData[dayName].totalSubscribers : 0;
    });
  }

  private processPastYearData(
    outcomeStats: any,
    subscriberStats: any,
    dayNames: string[],
    tempDataClicks: number[],
    tempDataSubscribers: number[],
    outcomeData: any,
    subscriberData: any
  ) {
    let pastyearDate = moment()
      .subtract(15, 'month')
      .format('YYYY-MM-01');
    let today = moment().format('YYYY-MM-01');
    var pastYeardays = [];
    var startDate = moment(pastyearDate, 'YYYY-MM-01');
    while (!startDate.isSame(today)) {
      startDate = startDate.add(1, 'month');
      pastYeardays.push(startDate.format('YYYY-MM-01'));
    }

    outcomeStats.forEach((item: any) => {
      let currentMonthDate = this.monthNames[item.month - 1] + ' ' + item.year;
      outcomeData[currentMonthDate] = item;
    });

    subscriberStats.forEach((item: any) => {
      let currentMonthDate = this.monthNames[item.month - 1] + ' ' + item.year;
      subscriberData[currentMonthDate] = item;
    });

    pastYeardays.forEach((date, i) => {
      let getdate: any = date.split('-');
      let dayName = this.monthNames[getdate[1] - 1] + ' ' + getdate[0];
      dayNames.push(dayName);
      tempDataClicks[i] = outcomeData[dayName] ? outcomeData[dayName].osClick : 0;
      tempDataSubscribers[i] = subscriberData[dayName] ? subscriberData[dayName].totalSubscribers : 0;
    });
  }
}
