import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
import { MenucardsService } from '@app/menucards/menucards.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { MatSnackBar } from '@angular/material';
import { ItemLayerComponent } from '@app/menucards/item-layer/item-layer.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-menucard-item',
  templateUrl: './menucard-item.component.html',
  styleUrls: ['./menucard-item.component.scss']
})
export class MenucardItemComponent implements OnInit {
  @Input() public data: any = [];
  @Input() public availableSizes: any = [];
  @Input() public categoryId: number;
  @Input() public showDeleteButton = false;
  @Input() public showChoices = false;
  @Input() public showSizes = false;
  @Input() public expanded = false;
  @Output() deleteItem: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(NgSelectComponent, { static: false }) ngSelectComponent: NgSelectComponent;
  selectedSize: any; // This is only needed to reset select

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private menucardService: MenucardsService,
    private snackBar: MatSnackBar,
    private modalService: NgbModal
  ) {}

  ngOnInit() {}

  dropChoice(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

      // Save new orders in DB
      this.menucardService.reorderItemChoice(this.data.itemId, this.data.choices).subscribe(() => {});
    } else {
      // Check if choice exists already in product
      const choiceFound = this.data.choices.find((s: any) => s.id === event.item.data.id);
      if (choiceFound) {
        this.snackBar.open('Dieses Auswahlfenster existiert bereits in diesem Produkt', '', {
          duration: 2000,
          panelClass: ['snackbar-error']
        });
        return;
      }

      copyArrayItem(event.previousContainer.data, this.data.choices, event.previousIndex, event.currentIndex);

      this.menucardService
        .addItemChoice(this.data.itemId, event.item.data.id, event.currentIndex)
        .subscribe((res: any) => {
          // Save orders in DB
          this.menucardService.reorderItemChoice(this.data.itemId, this.data.choices).subscribe(() => {});
        });
    }
  }

  dropSize(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

      // Save order in DB
      this.menucardService.reorderItemSize(this.data.sizes).subscribe((res: any) => {});
    }
  }

  delete() {
    this.deleteItem.emit(this.data);
  }

  deleteItemChoice(itemId: number, choiceId: number) {
    this.menucardService.deleteItemChoice(itemId, choiceId).subscribe(() => {
      this.data.choices = this.data.choices.filter((obj: any) => {
        return obj.id !== choiceId;
      });
    });
  }

  deleteItemSize(itemId: number, sizeId: number) {
    this.menucardService.deleteItemSize(itemId, sizeId).subscribe(() => {
      this.data.sizes = this.data.sizes.filter((obj: any) => {
        return obj.id !== sizeId;
      });
    });
  }

  addSize(size: any) {
    // Check if size exists already
    const sizeFound = this.data.sizes.find((s: any) => s.id === size.id);
    if (sizeFound) {
      this.snackBar.open('Diese Größe existiert bereits in diesem Produkt', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      this.resetSizeField();
      return;
    }

    const sizeData = { ...size };
    this.resetSizeField();
    // save to DB
    this.menucardService.addItemSize(this.data.itemId, sizeData.id, 0).subscribe((res: any) => {
      console.log('size', res);
      sizeData.itemId = res.itemId;
      this.data.sizes.push(sizeData);
      console.log(this.data.sizes);
      // Save order in DB
      this.menucardService.reorderItemSize(this.data.sizes).subscribe(() => {});
    });
  }

  resetSizeField() {
    // Reset select field
    setTimeout(() => {
      this.selectedSize = null;
    });
  }

  openItem() {
    const modalRef = this.modalService.open(ItemLayerComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.product = this.data;
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      receivedEntry.sizes = this.data.sizes;
      receivedEntry.choices = this.data.choices;
      receivedEntry.price = {};
      for (let index = 0; index < receivedEntry.prices.length; index++) {
        const element = receivedEntry.prices[index];
        receivedEntry.price[element.priceCategory] = element.price;
      }
      // receivedEntry.prices = this.data.prices;
      this.data = receivedEntry;

      const snackBarRef = this.snackBar.open('Artikel erfolgreich gespeichert', 'Ok', {
        duration: 3000
      });
      modalRef.close();
    });
  }

  toggleExpand() {
    this.expanded = !this.expanded;
  }
}
