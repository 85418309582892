import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';

import { CredentialsService } from './credentials.service';
import { map, switchMap, tap, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

export interface LoginContext {
  email: string;
  password: string;
  remember?: boolean;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable()
export class AuthenticationService {
  clients: any;
  client: any;
  clientSource = new BehaviorSubject('');
  activeClient = this.clientSource.asObservable();
  activeClientId: number;

  constructor(private credentialsService: CredentialsService, private http: HttpClient, private router: Router) {}

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext): Observable<any> {
    const email = context.email;
    const password = context.password;
    const remember = context.remember;
    return this.http
      .post<any>(environment.serverUrl + `public/login`, { email, password, remember })
      .pipe(
        map((response: any) => {
          // login successful if there's a jwt token in the response
          if (response && response.token) {
            const credentials = {
              token: response.token,
              email: response.user.email,
              id: response.user.id,
              language: response.user.lang || 'GER',
              role: response.user.admin && response.user.admin.type === 'superAdmin' ? 'admin' : 'user',
              memberSince: response.user.memberSince
            };
            this.credentialsService.setCredentials(credentials, context.remember);
          }
          return of(response);
        })
      );
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.credentialsService.setCredentials();
    return of(true);
  }

  /**
   * Function, that should perform refresh token
   */
  refreshToken(): Observable<any> {
    const userId = this.credentialsService.credentials.id;
    const token = this.credentialsService.credentials ? this.credentialsService.credentials.token : null;
    return this.http
      .post<any>(environment.serverUrl + 'public/validate', { userId, token })
      .pipe(
        map((response: any) => {
          // refresh successful if there's a jwt token in the response
          if (!response || !response.token) {
            return Observable.throw(response.msg);
          }
          this.credentialsService.setCredentials(response);
          return this.credentialsService.credentials;
        }),
        catchError(error => {
          if (error && error.status === 401) {
            this.logout();
            this.router.navigate(['/login']);
            window.location.reload();
          }
          return error.status;
        })
      );
  }

  /**
   * Retrieves the list of user clients
   * @return The user clients.
   */
  getClients(refreshData: boolean = false): Observable<any> {
    if (this.clients && !refreshData) {
      return of(this.clients);
    } else {
      const cred = this.credentialsService.credentials;
      if (!cred || !cred.token) {
        this.router.navigate(['/login']);
        return;
      }
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cred.token
      });
      return this.http
        .get<any>(environment.serverUrl + `private/clients/` + cred.id, {
          headers: headers
        })
        .pipe(
          map((clientList: any) => {
            this.clients = clientList;
            return clientList;
          })
        );
    }
  }

  getUserData(): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `private/user/` + cred.id, {
        headers: headers
      })
      .pipe(
        map((user: any) => {
          return user;
        })
      );
  }

  setActiveClientId(clientId: number) {
    this.activeClientId = clientId;
  }

  getActiveClient() {
    return this.getClient(this.activeClientId);
  }

  changeActiveClient(client: any) {
    this.clientSource.next(client);
  }

  /**
   * Retrieves a private client (with sensible data, needs authentification and permission)
   * @return The user clients.
   */
  getClient(clientId: number, refreshData: boolean = false): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });

    if (this.client && this.client.id === clientId && !refreshData) {
      return of(this.client);
    } else {
      return this.http
        .get<any>(environment.serverUrl + `client/` + clientId, {
          headers: headers
        })
        .pipe(
          map((client: any) => {
            this.client = client;
            return client;
          })
        );
    }
  }

  uploadLogo(uploadData: any): Observable<any> {
    uploadData.append('clientId', this.activeClientId);
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(environment.serverUrl + `private/client/logo/`, uploadData, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getPermissions(clientId: number = null): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    if (!clientId) {
      clientId = this.activeClientId;
    }
    return this.http
      .get<any>(environment.serverUrl + `private/moderators/permissions/${clientId}/${cred.id}`, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  isClientModerator(clientId: number): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    if (!clientId) {
      clientId = this.activeClientId;
    }
    return this.http
      .get<any>(environment.serverUrl + `private/moderators/check/${clientId}/${cred.id}`, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  requestPasswordReset(email: string): Observable<any> {
    return this.http
      .post<any>(environment.serverUrl + `public/request-password-reset`, { email })
      .pipe(
        map((response: any) => {
          return of(response);
        })
      );
  }

  resetPassword(data: any): Observable<any> {
    return this.http.post<any>(environment.serverUrl + `public/reset-password`, data).pipe(
      map((response: any) => {
        return of(response);
      })
    );
  }
  getClientResSettings(clientId: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/client-res-settings/` + clientId, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
