import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { CredentialsService } from '@app/core';

@Injectable({
  providedIn: 'root'
})
export class NewCompanyService {
  constructor(private http: HttpClient, private credentialsService: CredentialsService) {}

  getDraftData(params: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http
      .get<any>(environment.serverUrl + `client/getDraftListCompany`, {
        headers: headers,
        params
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  loadDraftData(id: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http
      .get<any>(environment.serverUrl + `client/getDraftData/${id}`, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  submitForm(data: any, draftId: number) {
    return this.http
      .post<any>(environment.serverUrl + `client/submitForm/${draftId}`, data, {
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  saveData(data: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    const formData = this.objectToFormData(data);
    return this.http
      .post<any>(environment.serverUrl + `client/newCompany`, formData, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  removeDraftData(id: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http
      .delete<any>(environment.serverUrl + `client/removeDraftData/${id}`, { headers: headers })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getGeoLocations(params: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http
      .get<any>(environment.serverUrl + `public/getLocationsForMember`, {
        headers: headers,
        params
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getStreetLocation(params: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http
      .get(environment.serverUrl + `events/location`, {
        headers: headers,
        params
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  getDistrict(params: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http
      .get<any>(environment.serverUrl + `public/getDistricts`, {
        headers: headers,
        params
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getRestaurentType(params: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http
      .get<any>(environment.serverUrl + `public/getRestaurentType`, {
        headers: headers,
        params
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  objectToFormData(model: any, form: FormData = null, namespace: string = ''): FormData {
    const formData = form || new FormData();
    let formKey: string;
    for (const propertyName in model) {
      if (!model.hasOwnProperty(propertyName)) {
        continue;
      }
      formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
      if (model[propertyName] instanceof Date) {
        formData.append(formKey, model[propertyName].toISOString());
      } else if (model[propertyName] instanceof Array) {
        model[propertyName].forEach((element: any, index: number) => {
          const tempFormKey = `${formKey}[${index}]`;
          this.objectToFormData(element, formData, tempFormKey);
        });
      } else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File)) {
        this.objectToFormData(model[propertyName], formData, formKey);
      } else {
        let itemKey = model[propertyName];
        if (typeof itemKey === 'boolean' && itemKey === true) {
          itemKey = 1;
        }
        if (typeof itemKey === 'boolean' && itemKey === false) {
          itemKey = 0;
        }
        if (itemKey !== undefined) {
          formData.append(formKey, itemKey);
        }
      }
    }
    return formData;
  }
  getGeoCountries() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http
      .get<any>(environment.serverUrl + `public/getCountries`, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
