import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NewCompanyService } from '../../new-company/new-company.service';
import { MyEntryService } from '../my-entry.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-generally',
  templateUrl: './generally.component.html',
  styleUrls: ['./generally.component.scss']
})
export class GenerallyComponent implements OnInit {
  restaurantForm: FormGroup;
  cateringTypeArray: any;
  restaurantTypeArray: any;
  offerArrayList: any;
  offerTypeArray: any;
  restaurantFormMessage: string;
  loadedData: any;

  constructor(
    private formBuilder: FormBuilder,
    private newCompanyService: NewCompanyService,
    private myEntryService: MyEntryService,
    private snackBar: MatSnackBar
  ) {
    this.restaurantForm = this.formBuilder.group({
      cateringType: [''],
      offerType: ['']
    });
    this.cateringTypeArray = [];
    this.offerArrayList = [];

    this.myEntryService.getCatersAndOffer().subscribe((response: any) => {
      this.loadedData = { ...response };

      this.cateringTypeArray = response['cateringTypeArray'];
      this.offerArrayList = response['offerArrayList'];

      this.restaurantForm.get('cateringType').setValue(this.cateringTypeArray);
      this.restaurantForm.get('offerType').setValue(this.offerArrayList);
    });

    this.newCompanyService.getRestaurentType({ type: 'Typ' }).subscribe(types => {
      if (types && types.length) {
        this.restaurantTypeArray = types;
      }
    });

    this.newCompanyService.getRestaurentType({ type: 'Angebot' }).subscribe(types => {
      if (types && types.length) {
        this.offerTypeArray = types;
      }
    });
  }

  ngOnInit() {}

  save() {
    this.restaurantFormMessage = '';
    if (this.cateringTypeArray.length === 0) {
      this.restaurantFormMessage = 'Minimum One catering type must be selected';
      return;
    }

    if (this.offerArrayList.length === 0) {
      this.restaurantFormMessage = 'Minimum One Offer type must be selected';
      return;
    }

    const restaurentTypeData = {
      cateringTypeArray: this.cateringTypeArray,
      offerArrayList: this.offerArrayList
    };

    this.myEntryService.saveData({ restaurentTypeData }).subscribe(
      (response: any) => {
        this.snackBar.open('Updated Successfully', 'Ok', {
          duration: 3000,
          panelClass: ['snackbar-success']
        });
        this.loadedData = { ...restaurentTypeData };
      },
      (err: any) => {
        console.log('Error => ', err);
      }
    );
  }

  cancel() {
    this.cateringTypeArray = this.loadedData['cateringTypeArray'];
    this.offerArrayList = this.loadedData['offerArrayList'];

    this.restaurantForm.get('cateringType').setValue(this.cateringTypeArray);
    this.restaurantForm.get('offerType').setValue(this.offerArrayList);
  }

  selectedCatType() {
    this.cateringTypeArray = this.restaurantForm.value[`cateringType`];
  }

  selectedOfferType() {
    this.offerArrayList = this.restaurantForm.value[`offerType`];
  }
}
