import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { AuthenticationService, CredentialsService } from '@app/core';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class GastroAppService {
  client: any;
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private credentialsService: CredentialsService,
    public translate: TranslateService
  ) {}

  getGastroAppData() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `gastroapp/` + this.authService.activeClientId + `/gastroapp-data`, {
        headers: headers
      })
      .pipe(
        map((apiData: any) => {
          return apiData;
        })
      );
  }

  getGastroAppOutcomeStats(data: any, outcomeType: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `gastroapp/gastroapp-outcome-stats`,
        {
          appId: data.appId,
          outcomeType: outcomeType
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((apiData: any) => {
          return apiData;
        })
      );
  }

  getGastroAppsubscriberStats(data: any, subscriberType: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `gastroapp/gastroapp-subscriber-stats`,
        {
          appId: data.appId,
          subscriberType: subscriberType
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((apiData: any) => {
          return apiData;
        })
      );
  }

  getGastroSubscriberData(pushApiKey: any, pushAppId: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `gastroapp/` + pushAppId + `/` + pushApiKey + `/onsignal-view-app`, {
        headers: headers
      })
      .pipe(
        map((apiData: any) => {
          return apiData;
        })
      );
  }

  getGastroRestaurantSettings() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `gastroapp/` + this.authService.activeClientId + `/gastroapp-restaurant-data`, {
        headers: headers
      })
      .pipe(
        map((apiData: any) => {
          return apiData;
        })
      );
  }

  uploadLogo(uploadData: any, clientId: any): Observable<any> {
    uploadData.append('clientId', clientId);
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(environment.serverUrl + `gastroapp/logo/`, uploadData, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
