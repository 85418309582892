import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material';
import { MenucardsService } from '../menucards.service';

@Component({
  selector: 'app-add-pricecategory',
  templateUrl: './add-pricecategory.component.html',
  styleUrls: ['./add-pricecategory.component.scss']
})
export class AddPricecategoryComponent implements OnInit {
  @Input() public editPricecategory: any; // only if editing
  @Input() public menuId: number;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  priceCategoryFormGroup: FormGroup;
  error: string;
  addObservable: any;
  editObservable: any;
  deleteObservable: any;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    public activeModal: NgbActiveModal,
    private snackBar: MatSnackBar,
    public formBuilder: FormBuilder,
    private menucardService: MenucardsService
  ) {
    this.priceCategoryFormGroup = this.formBuilder.group({
      name: ['', Validators.required]
    });
  }

  ngOnInit() {
    if (this.editPricecategory) {
      this.priceCategoryFormGroup.addControl('id', new FormControl(this.editPricecategory.id, Validators.required));
      this.priceCategoryFormGroup.get('name').setValue(this.editPricecategory.name);
    }
  }

  add() {
    if (!this.priceCategoryFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    this.error = null;
    this.addObservable = this.menucardService
      .addPricecategory(this.priceCategoryFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          this.error = err.error.msg;
        }
      );
  }

  save() {
    if (!this.priceCategoryFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    this.error = null;
    this.addObservable = this.menucardService
      .savePricecategory(this.priceCategoryFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          this.error = err.error.msg;
        }
      );
  }
}
