import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shell-forbidden',
  templateUrl: './shell-forbidden.component.html',
  styleUrls: ['./shell-forbidden.component.scss']
})
export class ShellForbiddenComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
