import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { EventsService } from '../events.service';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { MatSnackBar } from '@angular/material';
import { AddEventComponent } from '../add-event/add-event.component';
import { EventDatesListComponent } from '../event-dates-list/event-dates-list.component';
import { AddEventDatesComponent } from '../add-event-dates/add-event-dates.component';
import { AuthenticationService, CredentialsService } from '@app/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit {
  activeEventList: any = [];
  archiveEventList: any = [];
  editEventData: any = {};
  isActiveEdit: boolean = false;
  isArchiveEdit: boolean = false;
  eventType: string;
  id: number;
  eventAddSuccessMessage: string;
  eventDeleteSuccessMessage: string;
  deleteEventModalTitle: string;
  deleteEventModalMessage: string;
  deleteEventModelButtonText: string;
  loader: boolean;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private eventsService: EventsService,
    private modalService: NgbModal,
    private snackBar: MatSnackBar,
    public translate: TranslateService
  ) {
    this.loadTranslation();
    this.translate.onLangChange.subscribe((event: any) => {
      this.loadTranslation();
    });
  }

  ngOnInit() {
    this.loader = true;
    this.editEventData = null;
    this.isActiveEdit = false;
    this.isArchiveEdit = false;
    this.getAllActiveEvents();
    this.getAllArchiveEvents();
  }

  loadTranslation() {
    this.eventAddSuccessMessage = this.translate.instant('Events&Tickets.AllEvents.AddEventSuccessMessage');
    this.eventDeleteSuccessMessage = this.translate.instant('Events&Tickets.AllEvents.DeleteEventSuccessMessage');
    this.deleteEventModalTitle = this.translate.instant('Events&Tickets.AllEvents.DeleteEventModalTitle');
    this.deleteEventModalMessage = this.translate.instant('Events&Tickets.AllEvents.DeleteEventMessage');
    this.deleteEventModelButtonText = this.translate.instant('Common.Delete');
  }

  getAllActiveEvents() {
    this.activeEventList = [];
    this.eventsService.getEvents('active').subscribe((events: any) => {
      this.loader = false;
      this.activeEventList = events;
    });
  }

  getAllArchiveEvents() {
    this.archiveEventList = [];
    this.eventsService.getEvents('archive').subscribe((events: any) => {
      this.archiveEventList = events;
    });
  }

  showDates(event: any, type: string) {
    const modalRef = this.modalService.open(EventDatesListComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.showDates = { event, type };
    modalRef.componentInstance.removedDate.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      switch (type) {
        case 'active':
          this.getAllActiveEvents();
          break;
        case 'archive':
          this.getAllArchiveEvents();
          break;
      }
      modalRef.close();
    });
  }

  addEvent() {
    // const modalRef = this.modalService.open(AddEventComponent, { windowClass: 'onboarding-modal' });
    const modalRef = this.modalService.open(AddEventComponent, {
      size: 'lg',
      container: 'body'
    });
    modalRef.componentInstance.passNewEvent.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      this.getAllArchiveEvents();
      this.getAllActiveEvents();
      const snackBarRef = this.snackBar.open(this.eventAddSuccessMessage, 'Ok', {
        duration: 3000,
        panelClass: ['snackbar-success']
      });
      modalRef.close();
    });
  }

  async editEvent(eventId: number, type: string) {
    // const [eventData, allTickets, allTicketDates] = await Promise.all([
    //   this.eventsService.getSingleEvent(eventId, type).toPromise(),
    //   this.eventsService.getAllTickets(eventId).toPromise(),
    //   this.eventsService.getAllDateTickets(type, eventId).toPromise()
    // ]);

    // localStorage.setItem(`${type}.tickets`,JSON.stringify(allTickets));
    // localStorage.setItem(`${type}.ticketDates`, JSON.stringify(allTicketDates));
    // localStorage.setItem(`${type}.eventDates`, JSON.stringify(eventData['eventDates']));

    // eventData['tickets'] = allTickets;
    // eventData['ticketDates'] = allTicketDates;

    // this.editEventData = { event:eventData, type };
    // switch (type) {
    //   case 'active':
    //     this.isActiveEdit = true;
    //     break;
    //   case 'archive':
    //     this.isArchiveEdit = true;
    //     break;
    // }

    this.router.navigate([`client/${this.authService.activeClientId}/events/edit/eventId/${eventId}`]);
  }

  cancelEvent(type: string) {
    this.getAllActiveEvents();
    this.getAllArchiveEvents();
    switch (type) {
      case 'active':
        this.isActiveEdit = false;
        break;
      case 'archive':
        this.isArchiveEdit = false;
        break;
    }
  }

  removeEvent(eventId: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = this.deleteEventModalTitle;
    modalRef.componentInstance.message = this.deleteEventModalMessage;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = this.deleteEventModelButtonText;
    modalRef.result.then(
      (result: any) => {
        if (result === 'ok') {
          this.eventsService.removeEvent(eventId).subscribe(
            (event: any) => {
              this.activeEventList = this.activeEventList.filter((event: any) => event.id !== eventId);
              this.archiveEventList = this.archiveEventList.filter((event: any) => event.id !== eventId);
              this.snackBar.open(this.eventDeleteSuccessMessage, '', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            },
            (err: any) => {
              this.snackBar.open(err.error.msg, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
              });
            }
          );
        }
      },
      () => {}
    );
  }
  archiveEvent(eventData: any, type: any) {
    const eventId = eventData.id;
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = type
      ? this.translate.instant('Events&Tickets.AllEvents.ArchiveEvent')
      : this.translate.instant('Events&Tickets.AllEvents.UnArchiveEvent');
    modalRef.componentInstance.message = type
      ? this.translate.instant('Events&Tickets.AllEvents.ArchiveEventMsg')
      : this.translate.instant('Events&Tickets.AllEvents.UnArchiveEventMsg');
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = type
      ? this.translate.instant('Events&Tickets.AllEvents.Archive')
      : this.translate.instant('Events&Tickets.AllEvents.UnArchive');
    modalRef.result.then(
      (result: any) => {
        if (result === 'ok') {
          this.eventsService.archiveEvent(eventId, type).subscribe(
            (event: any) => {
              if (type) {
                this.activeEventList = this.activeEventList.filter((event: any) => event.id !== eventId);
                this.archiveEventList.unshift(eventData);
              } else {
                this.archiveEventList = this.archiveEventList.filter((event: any) => event.id !== eventId);
                this.activeEventList.unshift(eventData);
              }
              this.snackBar.open(
                type
                  ? this.translate.instant('Events&Tickets.AllEvents.EventArchivedSuccessfully')
                  : this.translate.instant('Events&Tickets.AllEvents.EventUnArchivedSuccessfully'),
                '',
                {
                  duration: 2000,
                  panelClass: ['snackbar-success']
                }
              );
            },
            (err: any) => {
              this.snackBar.open(err.error.msg, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
              });
            }
          );
        }
      },
      () => {}
    );
  }

  changedSettings(eventId: number, field: any, isActive: boolean) {
    let settings: any = {};
    settings.updateField = field;
    settings.isActive = isActive;
    this.eventsService.saveEventSettings(eventId, settings).subscribe(res => {
      this.snackBar.open(this.translate.instant('Common.Successful'), '', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    });
  }
}
