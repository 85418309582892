import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild } from '@angular/core';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { EventsService } from '../events.service';
import { AddEventDatesComponent } from '../add-event-dates/add-event-dates.component';
import { AssignTicketsDatesComponent } from '../assign-tickets-dates/assign-tickets-dates.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-event-dates-list',
  templateUrl: './event-dates-list.component.html',
  styleUrls: ['./event-dates-list.component.scss']
})
export class EventDatesListComponent implements OnChanges {
  @Input() public showDates: any;
  @Output() public removedDate: EventEmitter<any> = new EventEmitter();
  @Output() public loaderInfo: EventEmitter<any> = new EventEmitter();
  @ViewChild('myTable', { static: false }) table: any;
  @ViewChild('myTableMobile', { static: false }) table2: any;
  rows: any[] = [];
  expanded: any = {};
  timeout: any;
  loader: boolean;
  type: string;
  event: any = {};
  eventDates: any = [];
  ticketAssignSuccessMessage: string;
  eventUpdateSuccessMessage: string;
  deleteEventDateButtonText: string;
  deleteAppointmentTitle: string;
  removeAppointmentMessage: string;
  appointmentDeleteSuccessMessage: string;
  remainingTicketsUpdateSuccessMessage: string;
  unlinkTicketTitle: string;
  unlinkTicketMessage: string;
  unlinkTicketSuccessMessage: string;

  private ngUnsubscribe: Subject<any> = new Subject();
  constructor(
    private modalService: NgbModal,
    private eventsService: EventsService,
    private snackBar: MatSnackBar,
    public translate: TranslateService
  ) {
    this.applyTranslation();
    this.translate.onLangChange.subscribe((event: any) => {
      this.applyTranslation();
    });
  }

  toggleExpandRow(row: any) {
    this.table.rowDetail.toggleExpandRow(row);
  }
  toggleExpandRow2(row: any) {
    this.table2.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event: any) {}

  ngOnChanges() {
    if (this.showDates) {
      // console.log('Load => ', this.showDates);
      this.type = this.showDates['type'];
      this.event = this.showDates['event'];
      const eventDates = JSON.parse(localStorage.getItem(`eventDates`));
      const ticketDates = JSON.parse(localStorage.getItem(`ticketDates`));
      const tickets = JSON.parse(localStorage.getItem(`tickets`));

      console.log('eventDates => ', eventDates);

      // ticketDates.forEach((eachData: any) => {
      //   eventDates.forEach((eachEventDate: any) => {
      //     if (eachData.id === eachEventDate.id) {
      //       eachEventDate['ticketDates'] = eachData['ticketDates'];
      //     }
      //   });
      // });
      this.eventDates = eventDates;
      // console.log('this.eventDates => ', this.eventDates)
      // localStorage.setItem(`${this.type}.eventDates`, JSON.stringify(this.eventDates))
    }
  }

  private applyTranslation() {
    this.ticketAssignSuccessMessage = this.translate.instant('Events&Tickets.Tickets.TicketAssignSuccessMessage');
    this.eventUpdateSuccessMessage = this.translate.instant('Events&Tickets.EventDates.EventDateUpdateSuccess');
    this.deleteEventDateButtonText = this.translate.instant('Common.Delete');
    this.deleteAppointmentTitle = this.translate.instant('Events&Tickets.EventDates.DeleteAppointment');
    this.removeAppointmentMessage = this.translate.instant('Events&Tickets.EventDates.RemoveAppointmentMessage');
    this.appointmentDeleteSuccessMessage = this.translate.instant(
      'Events&Tickets.EventDates.EventDateRemovedSuccessMessage'
    );
    this.remainingTicketsUpdateSuccessMessage = this.translate.instant(
      'Events&Tickets.Tickets.RemainingTicketsUpdateSuccessMessage'
    );
    this.unlinkTicketTitle = this.translate.instant('Events&Tickets.EventDates.DeleteTicket');
    this.unlinkTicketMessage = this.translate.instant('Events&Tickets.EventDates.UnlinkTicketMessage');
    this.unlinkTicketSuccessMessage = this.translate.instant('Events&Tickets.EventDates.UnlinkTicketSuccessMessage');
  }

  updateDate(dateInfo: any, event: any) {
    const newEvent = { ...event };
    newEvent['dateInfo'] = dateInfo;
    const modalRef = this.modalService.open(AddEventDatesComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.eventData = { ...newEvent };
    modalRef.componentInstance.passNewEventDate.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      const snackBarRef = this.snackBar.open(this.eventUpdateSuccessMessage, 'Ok', {
        duration: 3000,
        panelClass: ['snackbar-success']
      });
      if (this.type === 'active') {
        this.eventDates.map((each: any) => {
          if (each.id === receivedEntry.id) {
            Object.assign(each, receivedEntry);
          }
        });
        localStorage.setItem(`eventDates`, JSON.stringify(this.eventDates));
      }
      modalRef.close();
    });
  }

  removeEventDate(eventDateData: any) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = this.deleteAppointmentTitle;
    modalRef.componentInstance.message = this.removeAppointmentMessage;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = this.deleteEventDateButtonText;
    modalRef.result.then(
      (result: any) => {
        if (result === 'ok') {
          this.eventsService.removeEventDate(eventDateData.id, this.event.id).subscribe(
            (event: any) => {
              this.snackBar.open(this.appointmentDeleteSuccessMessage, '', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
              this.eventDates = this.eventDates.filter((event: any) => event.id !== eventDateData.id);
              localStorage.setItem(`eventDates`, JSON.stringify(this.eventDates));
            },
            (err: any) => {
              this.snackBar.open(err.error.msg, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
              });
            }
          );
        }
      },
      () => {}
    );
  }

  assignTicket(dateInfo: any) {
    const modalRef = this.modalService.open(AssignTicketsDatesComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.dateAndTicketInfo = { dateInfo, event: this.event, type: this.type };
    modalRef.componentInstance.ticketToDateResp.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      const snackBarRef = this.snackBar.open(this.ticketAssignSuccessMessage, 'Ok', {
        duration: 3000,
        panelClass: ['snackbar-success']
      });

      this.eventDates.forEach((each: any) => {
        if (each.id === receivedEntry.dateId) {
          if (Object.prototype.hasOwnProperty.call(each, 'ticketDates')) {
            each['ticketDates'].push(receivedEntry);
          } else {
            each['ticketDates'] = [receivedEntry];
          }
        }
      });

      localStorage.setItem(`eventDates`, JSON.stringify(this.eventDates));
      modalRef.close();
    });
  }

  editDateTicket(ticketData: any, dateData: any) {
    const modalRef = this.modalService.open(AssignTicketsDatesComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.dateAndTicketInfo = { dateInfo: dateData, event: this.event, ticketData };
    modalRef.componentInstance.ticketToDateResp.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      const snackBarRef = this.snackBar.open(this.remainingTicketsUpdateSuccessMessage, 'Ok', {
        duration: 3000,
        panelClass: ['snackbar-success']
      });

      const eventDates = JSON.parse(localStorage.getItem(`eventDates`));
      eventDates.forEach((each: any) => {
        if (each.id === receivedEntry.dateId) {
          const ticketDates = each['ticketDates'];
          ticketDates.forEach((ticketDate: any) => {
            ticketDate['remainingTickets'] = receivedEntry['remainingTickets'];
          });
        }
      });

      localStorage.setItem(`eventDates`, JSON.stringify(eventDates));

      modalRef.close();
    });
  }

  removeDateTicket(ticketData: any, dateData: any) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = this.unlinkTicketTitle;
    modalRef.componentInstance.message = this.unlinkTicketMessage;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = this.deleteEventDateButtonText;
    modalRef.result.then(
      (result: any) => {
        if (result === 'ok') {
          this.eventsService.removeTicketForDate(ticketData, dateData).subscribe(
            (ticketDate: any) => {
              this.snackBar.open(this.unlinkTicketSuccessMessage, '', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
              this.eventDates.forEach((each: any) => {
                if (each.id === dateData.id) {
                  each['ticketDates'] = each['ticketDates'].filter((elem: any) => elem.id !== ticketData.id);
                }
              });
              localStorage.setItem(`eventDates`, JSON.stringify(this.eventDates));
            },
            (err: any) => {
              this.snackBar.open(err.error.msg, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
              });
            }
          );
        }
      },
      () => {}
    );
  }

  getPreview(ticketData: any, dateData: any) {
    ticketData.loader = true;
    this.eventsService.getPreviewData(ticketData.ticketId, dateData.id, this.event.id).subscribe(
      (previewData: any) => {
        const blobdata = new Blob([new Uint8Array(previewData.data)], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blobdata);
        delete ticketData['loader'];
        window.open(fileURL, '_blank');
      },
      (err: any) => {
        console.log('err => ', err);
        this.snackBar.open(err.error.msg, '', {
          duration: 2000,
          panelClass: ['snackbar-error']
        });
      }
    );
  }

  preview(eventId: number, dateId: number) {
    let wholeUrl: any = window.location.href;
    wholeUrl = wholeUrl.split('//');
    let lastUrl: any = wholeUrl[1];
    lastUrl = lastUrl.split('/');
    let ticketServerUrl = '';
    switch (lastUrl[0]) {
      case 'test.gastro.digital':
        ticketServerUrl = 'http://test.tickets.gastroguide.de';
        break;
      case 'kunden.gastro.digital':
        ticketServerUrl = 'https://tickets.gastroguide.de';
        break;
      default:
        ticketServerUrl = 'http://localhost:4201';
    }
    window.open(`${ticketServerUrl}/event/${eventId}/date/${dateId}`, '_blank');
  }
}
