import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { MenucardsService } from '../menucards.service';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-add-choice',
  templateUrl: './add-choice.component.html',
  styleUrls: ['./add-choice.component.scss']
})
export class AddChoiceComponent implements OnInit {
  @Input() public editChoiceData: any; // only if editing
  @Input() public menuId: number;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  choiceFormGroup: FormGroup;
  error: string;
  addObservable: any;
  editObservable: any;
  deleteObservable: any;
  choiceTypes: any = [
    {
      label: 'Optionsfeld (Radiobutton)',
      value: 'radio',
      description: 'Der Gast hat nur eine Auswahlmöglichkeit'
    },
    {
      label: 'Auswahlliste (Checkbox)',
      value: 'checkbox',
      description: 'Der Gast hat mehrere Auswahlmöglichkeiten'
    },
    {
      label: 'Dropdownliste',
      value: 'select',
      description: 'Der Gast hat eine Auswahlmöglichkeit aus einer Klappliste'
    },
    {
      label: 'Anzahl',
      value: 'input',
      description: 'Der Gast kann eine Anzahl pro Artikel auswählen'
    }
  ];

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    public activeModal: NgbActiveModal,
    private snackBar: MatSnackBar,
    public formBuilder: FormBuilder,
    private menucardService: MenucardsService,
    private modalService: NgbModal
  ) {
    this.choiceFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      internalName: ['', Validators.required],
      description: [''],
      minQuantity: [0],
      maxQuantity: [10],
      isRequired: [false],
      hasIncludedProducts: [false],
      includedProducts: [0],
      type: ['', Validators.required]
    });
  }

  ngOnInit() {
    if (this.editChoiceData) {
      this.choiceFormGroup.addControl('id', new FormControl(this.editChoiceData.id, Validators.required));
      this.choiceFormGroup.get('name').setValue(this.editChoiceData.name);
      this.choiceFormGroup.get('internalName').setValue(this.editChoiceData.internalName);
      this.choiceFormGroup.get('description').setValue(this.editChoiceData.description);
      this.choiceFormGroup.get('minQuantity').setValue(this.editChoiceData.minQuantity);
      this.choiceFormGroup.get('maxQuantity').setValue(this.editChoiceData.maxQuantity);
      this.choiceFormGroup.get('isRequired').setValue(this.editChoiceData.isRequired);
      this.choiceFormGroup.get('type').setValue(this.editChoiceData.type);

      if (this.editChoiceData.includedProducts > 0) {
        this.choiceFormGroup.get('hasIncludedProducts').setValue(true);
        this.choiceFormGroup.get('includedProducts').setValue(this.editChoiceData.includedProducts);
      }
    }
  }

  add() {
    if (!this.choiceFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    this.error = null;
    this.addObservable = this.menucardService
      .addChoice(this.choiceFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          this.error = err.error.msg;
        }
      );
  }

  save() {
    if (!this.choiceFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    this.error = null;
    this.addObservable = this.menucardService
      .saveChoice(this.choiceFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          this.error = err.error.msg;
        }
      );
  }

  delete() {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Auswahlfenster löschen';
    modalRef.componentInstance.message = `Sind Sie sicher dass Sie das Auswahlfenster löschen möchten? Diese Aktion ist unwiederruflich.`;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      (result: any) => {
        if (result === 'ok') {
          this.menucardService.deleteChoice(this.editChoiceData.id).subscribe(
            () => {
              this.passEntry.emit('delete');
              this.activeModal.dismiss();
              this.snackBar.open('Auswahlfenster wurde erfolgreich gelöscht', '', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            },
            (err: any) => {
              this.snackBar.open(err.error.msg, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
              });
            }
          );
        }
      },
      () => {}
    );
  }

  minQuantityChange(e: any) {
    this.choiceFormGroup.get('minQuantity').setValue(e);
  }

  maxQuantityChange(e: any) {
    this.choiceFormGroup.get('maxQuantity').setValue(e);
  }

  toggleHasIncludedProducts(e: any) {
    if (!e.checked) {
      this.choiceFormGroup.get('includedProducts').patchValue(0);
    } else {
      this.choiceFormGroup.get('includedProducts').patchValue(1);
    }
  }

  includedProductsChange(e: any) {
    this.choiceFormGroup.get('includedProducts').patchValue(e);
  }

  changeType(e: any) {
    if (e.value === 'radio' || e.value === 'select') {
      if (this.choiceFormGroup.controls['includedProducts'].value !== 0) {
        this.choiceFormGroup.get('includedProducts').patchValue(1);
      }
    }
    if (e.value === 'input') {
      if (this.choiceFormGroup.controls['includedProducts'].value !== 0) {
        this.choiceFormGroup.get('includedProducts').patchValue(0);
        this.choiceFormGroup.get('hasIncludedProducts').patchValue(false);
      }
    }
  }
}
