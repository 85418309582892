import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AccountingService } from '../accounting.service';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-payment-settings',
  templateUrl: './payment-settings.component.html',
  styleUrls: ['./payment-settings.component.scss']
})
export class PaymentSettingsComponent implements OnInit {
  invoiceRecipientForm: FormGroup;
  bankdetailsForm: FormGroup;
  ibanOldValue: any;

  constructor(
    private formBuilder: FormBuilder,
    private accountingService: AccountingService,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute
  ) {
    this.invoiceRecipientForm = this.formBuilder.group({
      email: [''],
      company: [''],
      notation: [''],
      street: [''],
      zipCode: [''],
      vatId: [''],
      city: [''],
      country: ['']
    });

    this.bankdetailsForm = this.formBuilder.group({
      bankAccountOwner: [''],
      iban: [''],
      swift: [''],
      bank: [''],
      elv: ['']
    });
  }
  showTab = 0;

  ngOnInit() {
    this.getBillingcase();
    this.showTab = this.activatedRoute.snapshot.params.tab || 0;
  }

  getBillingcase() {
    this.accountingService.getBillingcase().subscribe((data: any) => {
      console.log('getBillingcase()', data);
      if (data) {
        this.invoiceRecipientForm.controls['email'].setValue(data.email);
        this.invoiceRecipientForm.controls['company'].setValue(data.company);
        this.invoiceRecipientForm.controls['notation'].setValue(data.notation);
        this.invoiceRecipientForm.controls['street'].setValue(data.street);
        this.invoiceRecipientForm.controls['zipCode'].setValue(data.zipCode);
        this.invoiceRecipientForm.controls['city'].setValue(data.city);
        this.invoiceRecipientForm.controls['vatId'].setValue(data.vatId);
        this.invoiceRecipientForm.controls['country'].setValue(data.country);
        this.bankdetailsForm.controls['bankAccountOwner'].setValue(data.bankAccountOwner);
        this.bankdetailsForm.controls['iban'].setValue(data.iban);
        this.bankdetailsForm.controls['swift'].setValue(data.swift);
        this.bankdetailsForm.controls['bank'].setValue(data.bank);
        this.bankdetailsForm.controls['elv'].setValue(data.elv);
        this.ibanOldValue = this.bankdetailsForm.value.iban;
      }
    });
  }

  saveInvoiceRecipient() {
    console.log('saveInvoiceRecipient()', this.invoiceRecipientForm.value);
    this.accountingService.saveBillingcase(this.invoiceRecipientForm.value).subscribe(() => {
      const snackBarRef = this.snackBar.open('Daten erfolgreich gespeichert', 'Ok', {
        duration: 3000
      });
    });
  }

  saveBankData() {
    if (this.ibanOldValue) {
      if (this.ibanOldValue !== this.bankdetailsForm.value.iban) {
        this.sendMailWhenIbanIsChange();
      }
    }

    this.accountingService.saveBillingcase(this.bankdetailsForm.value).subscribe(() => {
      this.ibanOldValue = this.bankdetailsForm.value.iban;
      const snackBarRef = this.snackBar.open('Daten erfolgreich gespeichert', 'Ok', {
        duration: 3000
      });
    });
  }

  sendMailWhenIbanIsChange() {
    const data = {
      oldIban: this.ibanOldValue,
      newIban: this.bankdetailsForm.value.iban
    };
    this.accountingService.sendCommonMailIban(data).subscribe((res: any) => {
      console.log('res ====>', res);
    });
  }
}
