import { Component, HostBinding, Input, OnInit, ChangeDetectorRef, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NavItem } from '@app/shell/nav-item';
import { NavService } from '@app/nav.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { DisabledNavitemComponent } from './disabled-navitem/disabled-navitem.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)'))
    ])
  ]
})
export class MenuListItemComponent implements OnInit, OnDestroy {
  expanded: boolean;
  showSidebar = true;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: NavItem;
  @Input() depth: number;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public navService: NavService,
    public router: Router,
    private modalService: NgbModal
  ) {
    this.showSidebar = window.innerWidth > 1100;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.showSidebar = window.innerWidth > 1100;
  }

  ngOnInit() {
    this.navService.currentUrl.subscribe((url: string) => {
      if (this.item.route && url) {
        // console.log(`Checking '/${this.item.route}' against '${url}'`);
        this.expanded = url.indexOf(`/${this.item.route}`) === 0;
        this.ariaExpanded = this.expanded;
        // console.log(`${this.item.route} is expanded: ${this.expanded}`);
      }
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  onItemSelected(item: NavItem) {
    // If it is disabled, show modal with alternative link
    if (item.disabled) {
      const modalRef = this.modalService.open(DisabledNavitemComponent, {
        windowClass: 'onboarding-modal'
      });
      modalRef.componentInstance.link = item.route;
      return;
    }
    if (!item.children || !item.children.length) {
      this.router.navigate([item.route]);
      if (!this.showSidebar) {
        this.navService.closeNav();
      }
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }
}
