import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from '@app/core';
import { MatStepper } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  isLoading: boolean;
  error: string;
  @ViewChild('stepper', { static: true }) private stepper: MatStepper;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    public translate: TranslateService
  ) {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', Validators.required]
    });
  }

  ngOnInit() {}

  requestReset() {
    this.isLoading = true;
    this.authService.requestPasswordReset(this.forgotPasswordForm.value.email).subscribe(
      (response: any) => {
        console.log('successs');
        this.stepper.next();
      },
      error => {
        this.isLoading = false;
        this.error = error.error.msg;
      }
    );
  }
}
