import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import AllergensAdditives from '../allergens-additives.json';
import { MenucardsService } from '../menucards.service';

@Component({
  selector: 'app-item-layer',
  templateUrl: './item-layer.component.html',
  styleUrls: ['./item-layer.component.scss']
})
export class ItemLayerComponent implements OnInit {
  @Input() public product: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  itemFormGroup: FormGroup;
  allAllergens: Array<any> = [];
  allAdditives: Array<any> = [];
  selectedAllergens: Array<string> = [];
  selectedAdditives: Array<string> = [];
  prices: any;
  priceCategories: Array<any> = [];
  imageUrl: string | ArrayBuffer;
  fileName: string;
  postObservable: any;
  tags: any[] = [];

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private menucardService: MenucardsService,
    public activeModal: NgbActiveModal
  ) {
    this.itemFormGroup = this.formBuilder.group({
      isOrderable: [true],
      isVisible: [true],
      productId: [''],
      cashregisterId: [''],
      name: ['', Validators.required],
      description: [''],
      type: ['', Validators.required],
      unitValue: [''],
      unit: [],
      deposit: [''],
      additives: [''],
      allergens: [''],
      tags: [''],
      isVegetarian: [false],
      isVegan: [false],
      price: this.formBuilder.array([]),
      file: [''],
      deletePhoto: [false]
    });

    for (const allergen of Object.keys(AllergensAdditives.allergens)) {
      const allergenObj = {
        identifier: allergen,
        label: AllergensAdditives.allergens[allergen]
      };
      this.allAllergens.push(allergenObj);
    }

    for (const additive of Object.keys(AllergensAdditives.additives)) {
      const additivesObj = {
        identifier: additive,
        label: AllergensAdditives.additives[additive]
      };
      this.allAdditives.push(additivesObj);
    }
  }

  ngOnInit() {
    if (this.product) {
      this.itemFormGroup.addControl('id', new FormControl(this.product.id, Validators.required));
      this.itemFormGroup.get('isOrderable').setValue(this.product.isOrderable);
      this.itemFormGroup.get('isVisible').setValue(this.product.isVisible);
      this.itemFormGroup.get('productId').setValue(this.product.productId);
      this.itemFormGroup.get('cashregisterId').setValue(this.product.cashregisterId);
      this.itemFormGroup.get('name').setValue(this.product.name);
      this.itemFormGroup.get('description').setValue(this.product.description);
      this.itemFormGroup.get('type').setValue(this.product.type);
      this.itemFormGroup.get('unitValue').setValue(this.product.unitValue);
      this.itemFormGroup.get('unit').setValue(this.product.unit);
      this.itemFormGroup.get('tags').setValue(this.product.tags);

      if (this.product.deposit > 0 && this.product.deposit !== '0.00') {
        this.itemFormGroup.get('deposit').setValue(this.product.deposit);
      }
      this.itemFormGroup.get('isVegetarian').setValue(this.product.isVegetarian);
      this.itemFormGroup.get('isVegan').setValue(this.product.isVegan);

      if (this.product.allergens) {
        this.selectedAllergens = this.product.allergens.split(',');
      }

      if (this.product.additives) {
        this.selectedAdditives = this.product.additives.split(',');
      }

      if (this.product.photo) {
        let photoUrl = this.product.photo;
        if (photoUrl.includes('https://cdn.gastroguide.de/')) {
          photoUrl = photoUrl.replace('https://cdn.gastroguide.de/', '');
        }
        this.fileName = photoUrl;
        this.imageUrl = 'https://cdn.gastroguide.de/' + photoUrl;
      }
    }

    this.getPriceCategories();
    this.getTags();
  }

  getTags() {
    this.menucardService.getTags().subscribe(tags => {
      this.tags = tags;
    });
  }

  createPriceCategory(cat: any): FormGroup {
    const index =
      this.product && this.product.prices ? this.product.prices.findIndex((p: any) => p.priceCategory === cat.id) : -1;
    console.log('create price', index);

    return this.formBuilder.group({
      id: [cat.id],
      price: [index !== -1 && this.product.prices[index].price > 0 ? this.product.prices[index].price : '']
    });
  }

  getPriceCategories() {
    this.menucardService.getPriceCategories().subscribe((cats: any) => {
      cats.forEach((cat: any) => {
        this.priceCategories.push(cat);
        this.prices = this.itemFormGroup.get('price') as FormArray;
        this.prices.push(this.createPriceCategory(cat));
      });
    });
  }

  addAllergen(identifier: string, isChecked: boolean) {
    if (isChecked) {
      this.selectedAllergens.push(identifier);
    } else {
      const index = this.selectedAllergens.findIndex((allergen: string) => allergen === identifier);
      this.selectedAllergens.splice(index, 1);
    }
  }

  addAdditive(identifier: string, isChecked: boolean) {
    if (isChecked) {
      this.selectedAdditives.push(identifier);
    } else {
      const index = this.selectedAdditives.findIndex((additive: string) => additive === identifier);
      this.selectedAdditives.splice(index, 1);
    }
  }

  save() {
    if (!this.itemFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    console.log('tags', this.itemFormGroup.get('tags').value);
    this.itemFormGroup.get('allergens').setValue(this.selectedAllergens.join());
    this.itemFormGroup.get('additives').setValue(this.selectedAdditives.join());

    const postValues = { ...this.itemFormGroup.value };
    const priceObj = {};
    for (let i = 0; i < postValues.price.length; i++) {
      priceObj[postValues.price[i].id] = postValues.price[i].price;
    }
    postValues.price = priceObj;

    this.postObservable = this.menucardService
      .saveItem(postValues)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          this.snackBar.open(err.error.msg, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      );
  }

  onChange(file: File) {
    if (file) {
      console.log(file);
      const img = new Image();
      img.src = window.URL.createObjectURL(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);

      img.onload = event => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        if (width < 100 && height < 100) {
          this.snackBar.open('Foto muss mindestens 100px breit und 100px hoch sein', '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
          return;
        }

        const fileTypes = ['image/jpeg', 'image/gif', 'image/jpg', 'image/png'];

        if (!fileTypes.includes(file.type)) {
          this.snackBar.open('Nicht unterstütztes Dateiformat. Nur jpg, gif und png', '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
          return;
        }

        this.fileName = file.name;
        this.itemFormGroup.get('file').setValue(file);
        this.imageUrl = reader.result;
      };
    }
  }

  removeFile() {
    this.fileName = null;
    this.itemFormGroup.get('file').setValue('');
    this.itemFormGroup.get('deletePhoto').setValue(true);
    this.imageUrl = null;
  }
}
