import { IAfterGuiAttachedParams } from '@ag-grid-enterprise/all-modules';
import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'checkbox-renderer',
  template: `
    <mat-checkbox (change)="checkedHandler($event)" [checked]="params.value"></mat-checkbox>
  `
})
export class CheckboxRendererComponent implements ICellRendererAngularComp, OnDestroy {
  params: any;
  checked: boolean;
  ngOnDestroy(): void {}
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}

  agInit(params: any): void {
    this.params = params;
  }

  checkedHandler(event: any) {
    console.log('checked', event);
    const checked = event.checked;
    this.checked = checked;
    const colId = this.params.column.colId;
    this.params.node.setDataValue(colId, checked);
  }

  getValue(): any {
    return this.checked ? 1 : 0;
  }
}
