import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DocumentsRoutingModule } from './documents-routing.module';
import { DocumentsComponent } from './documents.component';
import { SharedModule } from '@app/shared';
import { MatProgressBarModule, MatIconModule, MatTooltipModule } from '@angular/material';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AddShortURLComponent } from './add-short-url/add-short-url.component';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons/dist';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [DocumentsComponent, UploadDocumentComponent, AddShortURLComponent],
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    DocumentsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    MatProgressBarModule,
    MatIconModule,
    MatTooltipModule,
    Angular2PromiseButtonModule.forRoot({
      spinnerTpl: '<span class="fas fa-spinner fa-spin button-loading"></span>'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  entryComponents: [UploadDocumentComponent, AddShortURLComponent]
})
export class DocumentsModule {}
