import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { EventsService } from '../events.service';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-assign-tickets-dates',
  templateUrl: './assign-tickets-dates.component.html',
  styleUrls: ['./assign-tickets-dates.component.scss']
})
export class AssignTicketsDatesComponent implements OnInit {
  @Input() dateAndTicketInfo: any;
  @Output() ticketToDateResp: EventEmitter<any> = new EventEmitter();
  assignTicketDateFormGroup: FormGroup;
  event: any;
  dateInfo: any;
  error: any;
  tickets: any;
  type: string;
  ticketData: any;
  formInvalid: string;
  // currentDate: any
  private ngUnsubscribe: Subject<any> = new Subject();
  constructor(
    private formBuilder: FormBuilder,
    private eventsService: EventsService,
    private snackBar: MatSnackBar,
    public activeModal: NgbActiveModal,
    public translate: TranslateService
  ) {
    this.assignTicketDateFormGroup = this.formBuilder.group({
      ticketId: ['', Validators.required],
      remainingTickets: [0, Validators.required],
      salesOpeningFrom: [''],
      salesEndsOn: ['']
    });
    this.loadTranslation();
    this.translate.onLangChange.subscribe((event: any) => {
      this.loadTranslation();
    });
  }

  ngOnInit() {
    this.event = this.dateAndTicketInfo.event;
    this.dateInfo = this.dateAndTicketInfo.dateInfo;
    this.type = this.dateAndTicketInfo.type;
    this.tickets = JSON.parse(localStorage.getItem(`tickets`));
    // this.currentDate = moment().toDate();

    this.ticketData = this.dateAndTicketInfo.ticketData ? this.dateAndTicketInfo.ticketData : null;
    console.log('this.ticketData => ', this.ticketData);
    if (this.ticketData) {
      this.assignTicketDateFormGroup.get('remainingTickets').setValue(this.ticketData.remainingTickets);
      this.assignTicketDateFormGroup.get('salesOpeningFrom').setValue(this.ticketData.salesOpeningFrom);
      this.assignTicketDateFormGroup.get('salesEndsOn').setValue(this.ticketData.salesEndsOn);
    }
  }

  private loadTranslation() {
    this.formInvalid = this.translate.instant('Events&Tickets.Common.FormInvalid');
  }

  add() {
    if (!this.assignTicketDateFormGroup.valid) {
      this.snackBar.open(this.formInvalid, '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    const formData = this.assignTicketDateFormGroup.value;

    const tickets = JSON.parse(localStorage.getItem(`tickets`));
    const ticketDetails = tickets.filter((eachTicket: any) => eachTicket.id === formData.ticketId);

    const data = {
      eventId: this.event.id,
      dateId: this.dateInfo.id,
      remainingTickets: ticketDetails[0].amount,
      salesOpeningFrom: formData.salesOpeningFrom
        ? moment(formData.salesOpeningFrom).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'),
      salesEndsOn: formData.salesEndsOn
        ? moment(formData.salesEndsOn).format('YYYY-MM-DD')
        : moment(this.dateInfo.startsAtDay)
            .subtract(1, 'day')
            .format('YYYY-MM-DD'),
      ticketId: formData.ticketId
    };

    console.log('data => ', data);

    this.eventsService
      .addDateTicket(data)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          response['tickets'] = ticketDetails[0];
          this.ticketToDateResp.emit(response);
        },
        err => {
          this.error = err.error.message;
        }
      );
  }

  changeCount(remainingTickets: number) {
    this.assignTicketDateFormGroup.get('remainingTickets').setValue(remainingTickets);
  }

  save() {
    const { remainingTickets, salesOpeningFrom, salesEndsOn } = this.assignTicketDateFormGroup.value;

    const data = {
      remainingTickets,
      eventId: this.ticketData.eventId,
      dateId: this.ticketData.dateId,
      ticketId: this.ticketData.ticketId,
      salesOpeningFrom: salesOpeningFrom
        ? moment(salesOpeningFrom).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'),
      salesEndsOn: salesEndsOn
        ? moment(salesEndsOn).format('YYYY-MM-DD')
        : moment(this.dateInfo.startsAtDay)
            .subtract(1, 'day')
            .format('YYYY-MM-DD'),
      id: this.ticketData.id
    };

    this.eventsService
      .updateDateTicket(data)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.ticketData['remainingTickets'] = remainingTickets;
          this.ticketData['salesOpeningFrom'] = salesOpeningFrom;
          this.ticketData['salesEndsOn'] = salesEndsOn;
          this.ticketToDateResp.emit(this.ticketData);
        },
        err => {
          this.error = err.error.message;
        }
      );
  }
}
