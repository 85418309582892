import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { AuthenticationService, CredentialsService } from '@app/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private credentialsService: CredentialsService
  ) {}

  changeLanguage(data: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    // const formData = this.objectToFormData(eventData);

    return this.http
      .put<any>(environment.serverUrl + `public/changeLang`, data, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
