import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InviteComponent } from './invite.component';

const routes: Routes = [
  {
    path: 'invite/:hash',
    component: InviteComponent,
    data: { title: 'Einladung annehmen' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class InviteRoutingModule {}
