import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewRef
} from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { GastroPayService } from '@app/gastro-pay/gastro-pay.service';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SetDeliveryTimeComponent } from '../set-delivery-time/set-delivery-time.component';
import moment from 'moment';
import { differenceInMinutes } from 'date-fns';
moment.locale('de');

@Component({
  selector: 'app-order-v2',
  templateUrl: './order-v2.component.html',
  styleUrls: ['./order-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderV2Component implements OnInit {
  @Input() public data: any = [];
  @Input() public finished: boolean = false;
  @Input() public isInProgress: boolean;
  @Output() change = new EventEmitter();
  currentDateTime = new Date();
  currentDeliveryTimestamp: Date;
  constructor(
    private modalService: NgbModal,
    private gastropayService: GastroPayService,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    setInterval(() => {
      this.currentDateTime = new Date();
      if (this.cdr && !(this.cdr as ViewRef).destroyed) {
        this.cdr.detectChanges();
      }
    }, 60000);

    if (this.data.desiredTime) {
      // If order has delivery timestamp - show those
      if (this.data.deliveryTimestamp) {
        this.currentDeliveryTimestamp = new Date(this.data.deliveryTimestamp);

        // In case its IOS, parse the fulldate parts and re-create the date object.
        if (Number.isNaN(this.currentDeliveryTimestamp.getMonth())) {
          const arr = this.data.deliveryTimestamp.split(/[- :]/);
          this.currentDeliveryTimestamp = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
        }
        // If order does not have delivery timestamp - show desired time
      } else {
        this.currentDeliveryTimestamp = new Date(this.data.desiredTime);
        // In case its IOS, parse the fulldate parts and re-create the date object.
        if (Number.isNaN(this.currentDeliveryTimestamp.getMonth())) {
          const arr = this.data.desiredTime.split(/[- :]/);
          this.currentDeliveryTimestamp = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
        }
      }
    }
  }

  roundTimeToFiveMinutes(date: Date) {
    if (date) {
      // const dateObject = new Date(date);
      const dateObject = moment(date, 'YYYY-MM-DD HH:mm:ss').toDate();
      // coeff - round to 5 minutes
      const coeff = 1000 * 60 * 5;
      const rounded = new Date(Math.round(dateObject.getTime() / coeff) * coeff);
      return rounded;
    }
  }

  changeStatus(status: string) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Status ändern';
    if (status === 'canceled') {
      modalRef.componentInstance.message = `Sind Sie sicher dass Sie die Bestellung stornieren möchten?`;
    } else if (status === 'confirmed') {
      modalRef.componentInstance.message = `Sind Sie sicher dass Sie die Bestellung abschließen möchten?`;
    } else if (status === 'pending') {
      modalRef.componentInstance.message = `Sind Sie sicher dass Sie die Bestellung wieder auf offen möchten?`;
    }
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      (result: any) => {
        if (result === 'ok') {
          this.gastropayService.changeOrderStatus(this.data.id, status).subscribe(
            (res: any) => {
              this.data.status = res.status;
              this.change.emit(this.data);

              this.snackBar.open('Status wurde erfolgreich geändert', '', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            },
            err => {
              this.snackBar.open(err.error.msg, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
              });
            }
          );
        }
      },
      () => {}
    );
  }

  setDeliveryTime() {
    if (this.data.type === 'inhouse') {
      this.gastropayService.setDeliveryTime(this.data.id, 0).subscribe(
        () => {
          this.snackBar.open('Bestellung "In Arbeit" verschoben', '', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        },
        err => {
          this.snackBar.open(err.error.msg, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      );
      return;
    }
    const modalRef = this.modalService.open(SetDeliveryTimeComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.order = { ...this.data };
    modalRef.result.then(
      (res: any) => {
        if (res) {
          this.data = res;
          console.log(this.data);
          this.change.emit(this.data);
          this.data.deliveryTimestamp = new Date(res.deliveryTimestamp);
          if (moment(this.data.deliveryTimestamp).format('YYYY') == '1970') {
            this.data.deliveryTimestamp = moment()
              .add(res.deliveryTime + 1, 'minutes')
              .format('YYYY-MM-DD HH:mm:ss');
          }
          this.cdr.markForCheck();
          this.cdr.detectChanges();
        }
      },
      () => {}
    );
  }

  printBon(orderId: number) {
    this.gastropayService.printBon(orderId).subscribe(() => {
      this.snackBar.open('Druckauftrag versendet', '', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    });
  }

  addToDeliveryTime() {
    this.currentDeliveryTimestamp = new Date(this.currentDeliveryTimestamp.getTime() + 10 * 60 * 1000);
  }

  subtractFromDeliveryTime() {
    this.currentDeliveryTimestamp = new Date(this.currentDeliveryTimestamp.getTime() - 10 * 60 * 1000);
  }

  setNewDeliveryTime(): void {
    const difference = differenceInMinutes(this.currentDeliveryTimestamp, this.data.desiredTime);
    this.gastropayService.setDeliveryTime(this.data.id, difference).subscribe(res => {
      this.data.deliveryTimestamp = new Date(res.deliveryTimestamp);
      this.data.deliveryTime = res.deliveryTime;
      this.cdr.detectChanges();
      this.snackBar.open('New delivery time is set.', '', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    });
  }
}
