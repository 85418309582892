import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { GastroPayOrdersComponent } from './orders/gastro-pay-orders.component';
import { GastroPayOverviewComponent } from './gastro-pay-overview/gastro-pay-overview.component';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { GastroPayInfoComponent } from './info/gastro-pay-info.component';
import { GastroPaySettingsComponent } from './gastro-pay-settings/gastro-pay-settings.component';
const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'client/:clientId/gastropay',
      data: {
        breadcrumb: 'GastroPay'
      },
      resolve: {
        someKey: ClientResolverService
      },
      children: [
        {
          path: '',
          redirectTo: 'info',
          pathMatch: 'full'
        },
        {
          path: 'info',
          component: GastroPayInfoComponent,
          data: {
            title: extract('Informationen'),
            breadcrumb: 'Informationen'
          }
        },
        {
          path: 'overview',
          component: GastroPayOverviewComponent,
          data: {
            title: extract('Übersicht'),
            breadcrumb: 'Übersicht'
          }
        },
        {
          path: 'orders',
          component: GastroPayOrdersComponent,
          data: {
            title: extract('Bestellungen'),
            breadcrumb: 'Bestellungen'
          }
        },
        {
          path: 'settings',
          component: GastroPaySettingsComponent,
          data: {
            title: extract('Einstellungen'),
            breadcrumb: 'Einstellungen'
          }
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class GastroPayRoutingModule {}
