import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { environment } from '@env/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CredentialsService, AuthenticationService } from '.';

@Injectable()
export class SearchService {
  constructor(
    private http: HttpClient,
    private credentialsService: CredentialsService,
    private authService: AuthenticationService
  ) {}

  search(terms: Observable<string>) {
    return terms
      .debounceTime(400)
      .distinctUntilChanged()
      .switchMap(term => this.searchEntries(term));
  }

  searchEntries(term: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    const searchData = {
      search: term,
      clientId: this.authService.activeClientId
    };
    return this.http
      .post<any>(environment.serverUrl + `client/global-search/`, searchData, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
