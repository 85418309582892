import { IAfterGuiAttachedParams } from '@ag-grid-enterprise/all-modules';
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MenucardsService } from '@app/menucards/menucards.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'ng-select-renderer',
  template: `
    <ng-select
      [items]="tags"
      [multiple]="true"
      bindLabel="name"
      placeholder="Nach Tags filtern..."
      [closeOnSelect]="false"
      [(ngModel)]="selectedTags"
      appendTo="body"
      [clearable]="false"
      #select
    >
      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div class="ng-value custom-select-tag" *ngFor="let item of items" [style.background-color]="item.color">
          <span class="ng-value-label">{{ item.name }}</span>
          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
        </div>
        <div class="ng-value custom-select-tag" style="background-color: #b1b1b1" *ngIf="items.length > 1">
          <span class="ng-value-label">+ {{ items.length - 1 }}</span>
        </div>
      </ng-template>
    </ng-select>
  `
})
export class NgSelectRendererComponent implements ICellRendererAngularComp, OnDestroy {
  params: any;
  tags: any[] = [];
  selectedTags: any = [];
  @ViewChild(NgSelectComponent, { static: false }) select: NgSelectComponent;

  constructor(private menucardsService: MenucardsService) {}

  ngOnDestroy(): void {}
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    console.log(this.select);
    setTimeout(() => {
      this.select.open();
    });
  }

  agInit(params: any): void {
    console.log(params);
    this.params = params;
    this.selectedTags = this.params.data.tags;
    this.getTags();
    // this.select.open();
  }

  getTags() {
    this.menucardsService.getTags().subscribe(tags => {
      this.tags = tags;
    });
  }

  getValue(): any {
    return this.selectedTags;
  }
}
