import { Component, OnInit } from '@angular/core';
import { ClientService } from '@app/core/client.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { SatDatepickerRangeValue } from 'saturn-datepicker';
import moment from 'moment';
moment.locale('de');

import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-opening-hours',
  templateUrl: './opening-hours.component.html',
  styleUrls: ['./opening-hours.component.scss']
})
export class OpeningHoursComponent implements OnInit {
  loading = true;

  selectHours: any[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
  selectMinutes: any[] = ['00', '15', '30', '45'];
  copiedDay = 99;

  dayToInt: any = {
    Monday: 0,
    Tuesday: 1,
    Wednesday: 2,
    Thursday: 3,
    Friday: 4,
    Saturday: 5,
    Sunday: 6
  };
  dayTranslated: any = {
    Monday: 'Montag',
    Tuesday: 'Dienstag',
    Wednesday: 'Mittwoch',
    Thursday: 'Donnerstag',
    Friday: 'Freitag',
    Saturday: 'Samstag',
    Sunday: 'Sonntag'
  };

  dropdownList: any[] = [
    { item_id: 'Monday', item_text: 'Montag' },
    { item_id: 'Tuesday', item_text: 'Dienstag' },
    { item_id: 'Wednesday', item_text: 'Mittwoch' },
    { item_id: 'Thursday', item_text: 'Donnerstag' },
    { item_id: 'Friday', item_text: 'Freitag' },
    { item_id: 'Saturday', item_text: 'Samstag' },
    { item_id: 'Sunday', item_text: 'Sonntag' }
  ];
  selectedItems: any = {};
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Alle wählen',
    unSelectAllText: 'Alle abwählen',
    itemsShowLimit: 2,
    allowSearchFilter: false
  };

  specialOpeningHours: any[] = [];
  openingHoursFreetext: string = '';
  openingHours: any[] = [
    { betriebId: 0, day: 'Monday', firstOpeningPeriod: 'yes', id: 0, tFrom: '0:00', tTo: '0:00' },
    { betriebId: 0, day: 'Tuesday', firstOpeningPeriod: 'yes', id: 0, tFrom: '0:00', tTo: '0:00' },
    { betriebId: 0, day: 'Wednesday', firstOpeningPeriod: 'yes', id: 0, tFrom: '0:00', tTo: '0:00' },
    { betriebId: 0, day: 'Thursday', firstOpeningPeriod: 'yes', id: 0, tFrom: '0:00', tTo: '0:00' },
    { betriebId: 0, day: 'Friday', firstOpeningPeriod: 'yes', id: 0, tFrom: '0:00', tTo: '0:00' },
    { betriebId: 0, day: 'Saturday', firstOpeningPeriod: 'yes', id: 0, tFrom: '0:00', tTo: '0:00' },
    { betriebId: 0, day: 'Sunday', firstOpeningPeriod: 'yes', id: 0, tFrom: '0:00', tTo: '0:00' }
  ];
  openingHours2: any[] = [
    { betriebId: 0, day: 'Monday', firstOpeningPeriod: 'no', id: 0, tFrom: '0:00', tTo: '0:00' },
    { betriebId: 0, day: 'Tuesday', firstOpeningPeriod: 'no', id: 0, tFrom: '0:00', tTo: '0:00' },
    { betriebId: 0, day: 'Wednesday', firstOpeningPeriod: 'no', id: 0, tFrom: '0:00', tTo: '0:00' },
    { betriebId: 0, day: 'Thursday', firstOpeningPeriod: 'no', id: 0, tFrom: '0:00', tTo: '0:00' },
    { betriebId: 0, day: 'Friday', firstOpeningPeriod: 'no', id: 0, tFrom: '0:00', tTo: '0:00' },
    { betriebId: 0, day: 'Saturday', firstOpeningPeriod: 'no', id: 0, tFrom: '0:00', tTo: '0:00' },
    { betriebId: 0, day: 'Sunday', firstOpeningPeriod: 'no', id: 0, tFrom: '0:00', tTo: '0:00' }
  ];
  openingSpecialHours: any[] = [];

  constructor(private clientService: ClientService, private modalService: NgbModal, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.getOpeningHours();
  }

  getOpeningHours() {
    this.clientService.getOpeningHourOfClient().subscribe((res: any) => {
      let array = res || {};

      array.openingHours.forEach((day: any) => {
        let tFromShort1 = day.tFrom.substr(0, 1);
        let tFromShort2 = day.tFrom.substr(0, 2);
        if (tFromShort1 === '0' && tFromShort2 !== '00' && tFromShort2 !== '0:') {
          day.tFrom = day.tFrom.substr(1);
        }

        let tToShort1 = day.tTo.substr(0, 1);
        let tToShort2 = day.tTo.substr(0, 2);
        if (tToShort1 === '0' && tToShort2 !== '00' && tToShort2 !== '0:') {
          day.tTo = day.tTo.substr(1);
        }

        if (day.firstOpeningPeriod == 'no') {
          this.openingHours2[this.dayToInt[day.day]] = day;
        } else {
          this.openingHours[this.dayToInt[day.day]] = day;
        }
      });

      this.specialOpeningHours = [];
      array.specialOpeningHours.forEach((day: any) => {
        day.delete = false;
        day.date = {};
        day.date.begin = new Date(day.fromDate);
        day.date.end = new Date(day.toDate);
        if (!day.to1) {
          day.to1 = '0:00';
        }
        if (!day.to2) {
          day.to2 = '0:00';
        }
        if (!day.from1) {
          day.from1 = '0:00';
        }
        if (!day.from2) {
          day.from2 = '0:00';
        }
        if (day.weekdays && day.weekdays != '') {
          let selectedI: any = {};
          selectedI[day.id] = [];
          const arrayDays = day.weekdays.split(',');

          arrayDays.forEach((selDay: any) => {
            selectedI[day.id].push({ item_id: selDay, item_text: this.dayTranslated[selDay] });
          });
          this.selectedItems[day.id] = selectedI[day.id];
        }

        this.specialOpeningHours.push(day);
      });

      this.openingHoursFreetext =
        array.openingHoursFreetext && array.openingHoursFreetext.length && array.openingHoursFreetext[0].freetext
          ? array.openingHoursFreetext[0].freetext
          : '';

      console.log(
        'getOpeningHours: openingHours,specialOpeningHours,openingHoursFreetext',
        array,
        this.openingHours,
        this.specialOpeningHours
      );
      this.loading = false;
    });
  }

  pasteData(i: number) {
    if (this.copiedDay != 99) {
      this.openingHours[i].firstOpeningPeriod = this.openingHours[this.copiedDay].firstOpeningPeriod;
      this.openingHours[i].tFrom = this.openingHours[this.copiedDay].tFrom;
      this.openingHours[i].tTo = this.openingHours[this.copiedDay].tTo;
      this.openingHours2[i].firstOpeningPeriod = this.openingHours2[this.copiedDay].firstOpeningPeriod;
      this.openingHours2[i].tFrom = this.openingHours2[this.copiedDay].tFrom;
      this.openingHours2[i].tTo = this.openingHours2[this.copiedDay].tTo;
      this.copiedDay = 99;
    } else {
      console.log('Fehler: Kein Tag zum Kopieren ausgewählt!');
    }
  }

  onDateRangesChange(event: any, day: any) {
    day.fromDate = moment(event.begin).format('YYYY-MM-DD');
    day.toDate = moment(event.end).format('YYYY-MM-DD');
    console.log('onDateRangesChange', event, day);
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  addSpecialOpeningHours() {
    this.specialOpeningHours.unshift({
      betriebId: 0,
      closed: 1,
      date: {
        begin: new Date(),
        end: new Date()
      },
      from1: '0:00',
      from2: '0:00',
      fromDate: moment().format('YYYY-MM-DD'),
      id: 0,
      reason: '',
      to1: '0:00',
      to2: '0:00',
      toDate: moment().format('YYYY-MM-DD'),
      vocation: 1,
      weekdays: ''
    });
  }

  saveOpeningHours() {
    this.loading = true;
    console.log('save', this.openingHours, this.openingHours2, this.openingHoursFreetext);
    const data = {
      openingHours: this.openingHours,
      openingHours2: this.openingHours2,
      openingHoursFreetext: this.openingHoursFreetext
    };
    this.clientService.saveOpeningHours(data).subscribe(res => {
      //console.log('clientService.saveOpeningHours res =>',res);
      this.getOpeningHours();

      this.snackBar.open('Die Öffnungszeiten wurden erfolgreich übernommen', '', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    });
  }
  saveSpecialOpeningHours() {
    this.specialOpeningHours.forEach((day: any) => {
      day.weekdays = '';
      if (this.selectedItems[day.id]) {
        if (this.selectedItems[day.id].length) {
          this.selectedItems[day.id].forEach((row: any) => {
            day.weekdays += ',' + row.item_id;
          });
          day.weekdays = day.weekdays.substring(1);
        }
      }
    });

    this.loading = true;
    console.log('save', this.specialOpeningHours);
    const data = {
      specialOpeningHours: this.specialOpeningHours
    };

    this.clientService.saveSpecialOpeningHours(data).subscribe(res => {
      //console.log('clientService.saveOpeningHours res =>',res);
      this.getOpeningHours();
      this.snackBar.open('Die Sonderöffnungszeiten wurden erfolgreich übernommen', '', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    });
  }

  /*
  addStaffMember() {
    const modalRef = this.modalService.open(AddStaffMemberComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.staffEntries = [...this.staffEntries, receivedEntry];
      const snackBarRef = this.snackBar.open('Mitarbeiter erfolgreich hinzugefügt', 'Ok', {
        duration: 3000
      });
    });
  }

  editStaffMember(staffMember: any, index: number) {
    const modalRef = this.modalService.open(AddStaffMemberComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.editStaffMember = staffMember;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.staffEntries[index] = receivedEntry;
      this.getStaff(true);
      this.staffEntries = [...this.staffEntries];
      const snackBarRef = this.snackBar.open('Mitarbeiter erfolgreich gespeichert', 'Ok', {
        duration: 3000
      });
    });
  }

  deleteStaffMember(staffMember: any) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Mitarbeiter löschen';
    modalRef.componentInstance.message = `Sind Sie sicher dass Sie den Mitarbeiter "${staffMember.name}" löschen möchten?`;

    modalRef.result.then(
      result => {
        if (result === 'ok') {
          this.clientService.deleteStaffMember(staffMember.id).subscribe(() => {
            // Delete from array
            this.staffEntries = this.staffEntries.filter((obj: any) => {
              return obj.id !== staffMember.id;
            });
            const snackBarRef = this.snackBar.open('Mitarbeiter erfolgreich gelöscht', 'Ok', {
              duration: 3000
            });
          });
        }
      },
      () => {}
    );
  }
  */
}
