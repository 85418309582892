import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CredentialsService } from './authentication/credentials.service';
import { environment } from '@env/environment';
import { AuthenticationService } from './authentication/authentication.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  constructor(
    private http: HttpClient,
    private credentialsService: CredentialsService,
    private authService: AuthenticationService
  ) {}

  getNewsArticles(): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .get<any>(`https://blog.gastro.digital/wp-json/wp/v2/posts?page=1&per_page=5`, {
        headers: headers
      })
      .pipe(
        map((articles: any) => {
          return articles;
        })
      );
  }
}
