import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { OverviewComponent } from './overview/overview.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { StatisticComponent } from './statistic/statistic.component';
import { StatisticsDetailsComponent } from './statistics-details/statistics-details.component';
import { IntegrationComponent } from './integration/integration.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'client/:clientId/feedback',
      resolve: {
        someKey: ClientResolverService
      },
      canActivate: [NgxPermissionsGuard],
      data: {
        breadcrumb: 'Gästefeedback',
        permissions: {
          only: ['admin', 'viewFeedbacks'],
          redirectTo: {
            navigationCommands: ['403'],
            navigationExtras: {
              replaceUrl: false
            }
          }
        }
      },
      children: [
        {
          path: '',
          redirectTo: 'overview',
          pathMatch: 'full'
        },
        {
          path: 'overview',
          component: OverviewComponent,
          data: {
            title: 'Übersicht',
            breadcrumb: 'Gästefeedback - Übersicht'
          }
        },
        {
          path: 'statistic',
          component: StatisticComponent,
          data: {
            title: 'Statistik',
            breadcrumb: 'Gästefeedback - Statistik'
          }
        },
        {
          path: 'integration',
          component: IntegrationComponent,
          data: {
            title: 'Integration',
            breadcrumb: 'Gästefeedback - Integration'
          }
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class FeedbackRoutingModule {}
