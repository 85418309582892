import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract, AuthenticationGuard } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { PaymentSettingsComponent } from './payment-settings/payment-settings.component';
import { VouchersComponent } from './vouchers/vouchers.component';
import { PayoutComponent } from '../orders/payout/payout.component';
import { PayoutComponent as VoucherPayoutComponent } from '../vouchers/payout/payout.component';
import { PayoutComponent as ResPayoutComponent } from './reservations/payout/payout.component';
import { PayoutComponent as TicketPayoutComponent } from '../events/payout/payout.component';
import { ClientResolverService } from '@app/core/client-resolver.service';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'client/:clientId/accounting',
      data: {
        breadcrumb: 'Buchhaltung'
      },
      resolve: {
        someKey: ClientResolverService
      },
      children: [
        {
          path: 'vouchers',
          component: VouchersComponent,
          data: {
            title: extract('Wertgutscheine'),
            breadcrumb: 'Wertgutscheine'
          }
        },
        {
          path: 'payment-settings',
          component: PaymentSettingsComponent,
          data: {
            title: extract('Zahlungseinstellungen'),
            breadcrumb: 'Zahlungseinstellungen'
          }
        },
        {
          path: 'payment-settings/:tab',
          component: PaymentSettingsComponent,
          data: {
            title: extract('Zahlungseinstellungen'),
            breadcrumb: 'Zahlungseinstellungen'
          }
        },
        {
          path: 'invoices',
          component: InvoiceListComponent,
          data: {
            title: extract('Rechnungen'),
            breadcrumb: 'Rechnungen'
          }
        },
        {
          path: 'payout/orders',
          component: PayoutComponent,
          data: {
            title: 'Auszahlungen',
            breadcrumb: 'Auszahlungen'
          }
        },
        {
          path: 'payout/vouchers',
          component: VoucherPayoutComponent,
          data: {
            title: 'Auszahlungen',
            breadcrumb: 'Auszahlungen'
          }
        },
        {
          path: 'payout/reservations',
          component: ResPayoutComponent,
          data: {
            title: extract('Auszahlungen'),
            breadcrumb: 'Auszahlungen'
          }
        },
        {
          path: 'payout/tickets',
          component: TicketPayoutComponent,
          data: {
            title: 'Auszahlungen',
            breadcrumb: 'Auszahlungen'
          }
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AccountingRoutingModule {}
