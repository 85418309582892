import { Component, OnInit } from '@angular/core';
import { GalleryService } from '../gallery.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { MatSnackBar } from '@angular/material';
import { AddAlbumComponent } from '../add-album/add-album.component';
import { AuthenticationService } from '@app/core';

@Component({
  selector: 'app-album',
  templateUrl: './album.component.html',
  styleUrls: ['./album.component.scss']
})
export class AlbumComponent implements OnInit {
  albumData: any;
  checkboxData: any;
  albumNameData: any;
  loader: boolean;
  private ngUnsubscribe: Subject<any> = new Subject();
  constructor(
    private galleryService: GalleryService,
    private modalService: NgbModal,
    private snackBar: MatSnackBar,
    private router: Router,
    private authService: AuthenticationService
  ) {
    this.checkboxData = {};
    this.albumNameData = {};
    this.loader = true;
  }

  ngOnInit() {
    this.galleryService.getAlbums().subscribe((response: any) => {
      this.loader = false;
      this.albumData = response;

      if (this.albumData && this.albumData.length) {
        for (let i = 0; i < this.albumData.length; i++) {
          const album = { ...this.albumData[i] };

          this.checkboxData[album.id] = {
            isSliderAlbum: album.isSliderAlbum ? true : false,
            isUserAlbum: album.isUserAlbum ? true : false,
            showInApp: album.showInApp ? true : false,
            showInGastroguide: album.showInGastroguide ? true : false,
            showInWebsite: album.showInWebsite ? true : false
          };

          this.albumNameData[album.id] = {
            name: album.name
          };

          let photos = [...album.albums];
          if (photos && photos.length) {
            photos = photos.filter(photo => !photo.isDeleted);
            album.albums = photos;
          }
          this.albumData[i] = { ...album };
        }
      }
    });
  }

  cancel(row: any) {
    this.albumNameData[row.id] = row.name;
    delete row['isEdit'];
  }

  create() {
    const length = this.albumData.length;
    const modalRef = this.modalService.open(AddAlbumComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.newAlbumResponse.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      // this.ngOnInit()
      this.albumData.splice(length, 0, receivedEntry);
      this.albumData = [...this.albumData];

      this.checkboxData[receivedEntry.id] = {
        isSliderAlbum: receivedEntry.isSliderAlbum ? true : false,
        isUserAlbum: receivedEntry.isUserAlbum ? true : false,
        showInApp: receivedEntry.showInApp ? true : false,
        showInGastroguide: receivedEntry.showInGastroguide ? true : false,
        showInWebsite: receivedEntry.showInWebsite ? true : false
      };

      this.albumNameData[receivedEntry.id] = {
        name: receivedEntry.name
      };

      const snackBarRef = this.snackBar.open('Album Added Successfully', 'Ok', {
        duration: 3000,
        panelClass: ['snackbar-success']
      });
      modalRef.close();
    });
  }

  set(row: any) {
    const data = Object.assign({}, row, this.checkboxData[row.id], this.albumNameData[row.id]);
    this.galleryService.updateAlbum(data).subscribe((response: any) => {
      Object.assign(row, this.checkboxData[row.id], this.albumNameData[row.id]);
      delete row['isEdit'];
      this.snackBar.open('Album Updated Successfully', '', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    });
  }

  delete(row: any) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Delete Album';
    modalRef.componentInstance.message = 'Do you want to delete Album?';
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Delete';
    modalRef.result.then(
      (result: any) => {
        if (result === 'ok') {
          this.galleryService.deleteAlbum(row.id).subscribe((response: any) => {
            this.albumData = this.albumData.filter((album: any) => album.id !== row.id);
            delete this.checkboxData[row.id];
            delete this.albumNameData[row.id];
            this.snackBar.open('Album Deleted Successfully', '', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          });
        }
      },
      () => {}
    );
  }

  upload(row: any) {
    const album = {
      name: row.name,
      albumId: row.id,
      clientId: this.authService.activeClientId
    };
    localStorage.setItem('albumInfo', JSON.stringify(album));
    this.router.navigate([`/client/${this.authService.activeClientId}/gallery/album/${row.id}/upload-photos`]);
  }

  viewAlbum(row: any) {
    const album = {
      name: row.name,
      albumId: row.id,
      clientId: this.authService.activeClientId
    };
    localStorage.setItem('albumInfo', JSON.stringify(album));
    this.router.navigate([`/client/${this.authService.activeClientId}/gallery/album/${row.id}/album-photos`]);
  }
}
