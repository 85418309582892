import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';
import { CoreModule } from '@app/core';
import {
  MatSlideToggleModule,
  MatCheckboxModule,
  MatIconModule,
  MatTabsModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatRadioModule,
  MatGridListModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatAutocompleteModule,
  MatExpansionModule
} from '@angular/material';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControlErrorComponent } from '@app/shared/control-error/control-error.component';
import { MomentModule } from 'ngx-moment';
import { AdministrationRoutingModule } from '../administration-routing.module';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { UserPermissionsComponent } from './user-permissions.component';
import { AddUserComponent } from './add-user/add-user.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    CoreModule,
    SharedModule,
    MatSlideToggleModule,
    AdministrationRoutingModule,
    MatCheckboxModule,
    MatIconModule,
    MatTabsModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatRadioModule,
    MatGridListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    MatExpansionModule
  ],
  declarations: [UserPermissionsComponent, AddUserComponent],
  entryComponents: [ControlErrorComponent, ConfirmModalComponent, AddUserComponent]
})
export class UserPermissionsModule {}
