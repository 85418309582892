import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';
import { CoreModule } from '@app/core';
import {
  MatSlideToggleModule,
  MatCheckboxModule,
  MatIconModule,
  MatTabsModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatRadioModule,
  MatGridListModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatAutocompleteModule,
  MatProgressSpinnerModule
} from '@angular/material';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControlErrorComponent } from '@app/shared/control-error/control-error.component';
import { MomentModule } from 'ngx-moment';
import { OpeningHoursRoutingModule } from '../opening-hours-routing.module';
import { OpeningHoursComponent } from './opening-hours.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { SatNativeDateModule, SatDatepickerModule } from 'saturn-datepicker';
import { DateFnsModule } from 'ngx-date-fns';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    CoreModule,
    SharedModule,
    MatSlideToggleModule,
    OpeningHoursRoutingModule,
    MatCheckboxModule,
    MatIconModule,
    MatTabsModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatRadioModule,
    MatGridListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    NgxDatatableModule,
    SatDatepickerModule,
    SatNativeDateModule,
    DateFnsModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot()
  ],
  declarations: [OpeningHoursComponent],
  exports: [OpeningHoursComponent],
  entryComponents: [ControlErrorComponent, ConfirmModalComponent]
})
export class OpeningHoursModule {}
