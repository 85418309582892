import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MomentModule } from 'ngx-moment';
import { FilterPipeModule } from '@app/shared/filter/filter.module';
import {
  MatTabsModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatProgressSpinnerModule,
  MatIconModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { DateFnsModule } from 'ngx-date-fns';
import { DeveloperRoutingModule } from './developer-routing.module';
import { DeveloperComponent } from './developer.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReservationComponent } from './reservation/reservation.component';
import { Ng5SliderModule } from 'ng5-slider';
import { Angular2CsvModule } from 'angular2-csv';
import { ApikeyComponent } from './apikey/apikey.component';

import {
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatRadioModule,
  MatGridListModule,
  MatNativeDateModule
} from '@angular/material';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ChartsModule } from 'ng2-charts';

@NgModule({
  declarations: [DeveloperComponent, ReservationComponent, ApikeyComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    CoreModule,
    SharedModule,
    DeveloperRoutingModule,
    NgScrollbarModule,
    NgxDatatableModule,
    MatProgressSpinnerModule,
    MomentModule,
    FilterPipeModule,
    MatTabsModule,
    Ng5SliderModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatRadioModule,
    MatGridListModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatDatepickerModule,
    MatIconModule,
    Angular2PromiseButtonModule.forRoot({
      spinnerTpl: '<span class="fas fa-spinner fa-spin button-loading"></span>'
    }),
    DateFnsModule.forRoot(),
    Angular2CsvModule
  ],
  entryComponents: []
})
export class DeveloperModule {}
