import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';
import { CoreModule } from '@app/core';
import {
  MatSlideToggleModule,
  MatCheckboxModule,
  MatIconModule,
  MatTabsModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatRadioModule,
  MatGridListModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatAutocompleteModule,
  MatBottomSheetModule,
  MatListModule,
  MatBadgeModule,
  MatChipsModule,
  MatSelectModule,
  MatFormFieldModule,
  MatInputModule
} from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DxSchedulerModule } from 'devextreme-angular/ui/scheduler';
import { DxTemplateModule } from 'devextreme-angular/core/template';
import { DateFnsModule } from 'ngx-date-fns';
import { ReservationsComponent, AddBottomSheetComponent } from './reservations.component';
import { Ng5SliderModule } from 'ng5-slider';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AddReservationComponent } from './add-reservation/add-reservation.component';
import { ControlErrorComponent } from '@app/shared/control-error/control-error.component';
import { MomentModule } from 'ngx-moment';
import { ReservationRoutingModule } from '../reservation-routing.module';
import { FilterPipeModule } from '@app/shared/filter/filter.module';
import { DxContextMenuModule } from 'devextreme-angular/ui/context-menu';
import { OverlayContainer, FullscreenOverlayContainer } from '@angular/cdk/overlay';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { PendingReservationsComponent } from './pending-reservations/pending-reservations.component';
import { ReservationsListModule } from '../reservations-list/reservations-list.module';
import { OccupancyPerHourComponent } from './occupancy-per-hour/occupancy-per-hour.component';
import { ReservationSettingsModule } from '../reservation-settings/reservation-settings.module';
import { AddPatternComponent } from '../reservation-settings/table-plan/add-pattern/add-pattern.component';
import { AddDecorationComponent } from '../reservation-settings/table-plan/add-decoration/add-decoration.component';
import { ReservationHistoryComponent } from './reservation-history/reservation-history.component';
import { CancelMsgBoxComponent } from '../cancel-msg-box/cancel-msg-box.component';
import { MatProgressSpinnerModule } from '@angular/material';
import { BVAddReservationComponent } from './bv-add-reservation/bv-add-reservation.component';
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    CoreModule,
    SharedModule,
    MatSlideToggleModule,
    ReservationRoutingModule,
    MatCheckboxModule,
    DragDropModule,
    MatIconModule,
    MatTabsModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatRadioModule,
    MatGridListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatSelectModule,
    MatBadgeModule,
    NgSelectModule,
    ReservationSettingsModule,
    FormsModule,
    DxSchedulerModule,
    DxTemplateModule,
    DxContextMenuModule,
    NgScrollbarModule,
    ReactiveFormsModule,
    Ng5SliderModule,
    NgxDatatableModule,
    MomentModule,
    FilterPipeModule,
    FlexLayoutModule,
    MatListModule,
    ReservationsListModule,
    MatProgressSpinnerModule,
    DateFnsModule.forRoot()
  ],
  declarations: [
    ReservationsComponent,
    AddReservationComponent,
    AddBottomSheetComponent,
    PendingReservationsComponent,
    ReservationHistoryComponent,
    CancelMsgBoxComponent,
    BVAddReservationComponent,
    OccupancyPerHourComponent,
    CancelMsgBoxComponent
  ],
  entryComponents: [
    AddReservationComponent,
    ControlErrorComponent,
    AddBottomSheetComponent,
    PendingReservationsComponent,
    AddPatternComponent,
    AddDecorationComponent,
    ReservationHistoryComponent,
    CancelMsgBoxComponent,
    BVAddReservationComponent,
    ReservationHistoryComponent
  ],
  exports: [OccupancyPerHourComponent, CancelMsgBoxComponent],
  providers: [{ provide: OverlayContainer, useClass: FullscreenOverlayContainer }]
})
export class ReservationsModule {}
