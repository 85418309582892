import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-lang-selector-common',
  templateUrl: './lang-selector-common.component.html',
  styleUrls: ['./lang-selector-common.component.scss']
})
export class LangSelectorCommonComponent implements OnInit {
  lang: boolean = false; // false for 'de', true for 'en'
  @Input() public client: any;
  constructor(private translate: TranslateService) {}

  ngOnInit() {
    const browserLang = localStorage.getItem('userbrowserLang') || 'de';
    this.lang = browserLang === 'de' ? false : true;
    this.useLang(browserLang);
    localStorage.setItem('userbrowserLang', browserLang);
  }

  changeLang(event: any) {
    const browserLang = event.checked ? 'en' : 'de';
    this.useLang(browserLang);
    localStorage.setItem('userbrowserLang', browserLang);
  }

  useLang(lang: any) {
    if (lang == 'de') {
      if (this.client.pronominalForm === 'sie') {
        this.translate.use('de-Formal');
      } else {
        this.translate.use('de-DE');
      }
    } else {
      this.translate.use(lang);
    }
  }
}
