import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GalleryService } from '../gallery.service';

@Component({
  selector: 'app-upload-photos',
  templateUrl: './upload-photos.component.html',
  styleUrls: ['./upload-photos.component.scss']
})
export class UploadPhotosComponent implements OnInit {
  albumInfo: any;
  photoFormMessage: string;
  files: File[] = [];
  formData: any;
  loader: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private galleryService: GalleryService) {
    this.activatedRoute.params.subscribe(async (parameter: any) => {
      const { albumId, clientId } = parameter;
      if (localStorage.getItem('albumInfo')) {
        const albumData = JSON.parse(localStorage.getItem('albumInfo'));
        if (albumData.albumId === parseInt(albumId) && parseInt(clientId) === albumData.clientId) {
          this.albumInfo = { ...albumData };
        } else {
          this.router.navigate([`client/${albumData.clientId}/gallery`]);
        }
      } else {
        this.router.navigate([`client/${clientId}/gallery`]);
      }
    });
  }

  ngOnInit() {}

  onFileChange(event: any) {
    this.photoFormMessage = '';
    const typeArray = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg'];

    if (!event.addedFiles) {
      event['addedFiles'] = [...event.target.files];
    }

    for (let i = 0; i < event['addedFiles'].length; i++) {
      if (typeArray.indexOf(event['addedFiles'][i].type.toLowerCase()) === -1) {
        this.photoFormMessage = `${event['addedFiles'][i].name} has invalid file Extention`;
        return;
      }
    }

    this.files.push(...event.addedFiles);

    if (this.files && this.files.length) {
      this.files.forEach((file: any) => {
        file.sizeInKB = (file.size / 1024).toFixed(2);
      });
    }

    this.formData = new FormData();
    for (var i = 0; i < this.files.length; i++) {
      this.formData.append(`file[]`, this.files[i]);
    }
  }

  onRemove(event: any) {
    this.photoFormMessage = '';
    const typeArray = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg'];
    this.files.splice(this.files.indexOf(event), 1);
    this.formData = new FormData();
    for (var i = 0; i < this.files.length; i++) {
      this.formData.append(`file[]`, this.files[i]);
    }
  }

  uploadPhotos() {
    this.loader = true;
    this.galleryService.uploadPhotos(this.formData, this.albumInfo.albumId).subscribe((response: any) => {
      this.loader = false;
      this.router.navigate([`client/${this.albumInfo.clientId}/gallery/album/${this.albumInfo.albumId}/album-photos`]);
    });
  }
}
