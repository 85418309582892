import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { MenucardsService } from '../menucards.service';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Options, LabelType } from 'ng5-slider';
import moment from 'moment';
@Component({
  selector: 'app-add-menucard',
  templateUrl: './add-menucard.component.html',
  styleUrls: ['./add-menucard.component.scss']
})
export class AddMenucardComponent implements OnInit {
  @Input() public editMenuData: any; // only if editing
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  menucardFormGroup: FormGroup;
  error: string;
  addObservable: any;
  progress: number;
  imageUrl: string | ArrayBuffer;
  fileName: string;
  deliveryTimesRange: any;
  intervalOptions: Options = {
    floor: 15,
    ceil: 240,
    step: 15,
    enforceStep: true,
    ticksArray: [15, 30, 60, 120, 180, 240],
    showTicks: true,
    showSelectionBar: true,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Floor:
          if (value === 15) {
            return '15 Minuten';
          }
          break;
        case LabelType.Ceil:
          if (value < 60) {
            return value + ' Minuten';
          } else if (value === 60) {
            return 'Eine Stunde';
          } else {
            return value / 60 + ' Stunden';
          }
        case LabelType.Low:
          if (value < 60) {
            return value + ' Minuten';
          } else if (value === 60) {
            return 'Eine Stunde';
          } else {
            return value / 60 + ' Stunden';
          }
        case LabelType.High:
          if (value < 60) {
            return value + ' Minuten';
          } else if (value === 60) {
            return 'Eine Stunde';
          } else {
            return value / 60 + ' Stunden';
          }
        default:
          return value + '';
      }
    }
  };

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private menucardService: MenucardsService,
    private snackBar: MatSnackBar,
    public activeModal: NgbActiveModal
  ) {
    this.menucardFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      description: [''],
      hasValidDate: [false],
      validFrom: [''],
      validUntil: [''],
      type: ['main'],
      isForDelivery: [true],
      isForTakeaway: [true],
      isForInhouse: [true],
      file: [''],
      deletePhoto: [false],
      isSpecial: [false],
      orderableFrom: [''],
      orderableUntil: [''],
      timeInterval: [],
      deliveryTimes: this.formBuilder.array([])
    });
  }

  get formData() {
    return <FormArray>this.menucardFormGroup.get('deliveryTimes');
  }

  ngOnInit() {
    console.log('new menu');
    /*
    this.menucardService.progressSource.subscribe((progress: number) => {
      console.log('progress', progress);
      this.progress = progress;
    });
    */

    if (this.editMenuData) {
      this.menucardFormGroup.addControl('id', new FormControl(this.editMenuData.id, Validators.required));
      this.menucardFormGroup.get('name').setValue(this.editMenuData.name);
      this.menucardFormGroup.get('description').setValue(this.editMenuData.description);
      this.menucardFormGroup.get('isForDelivery').setValue(this.editMenuData.isForDelivery);
      this.menucardFormGroup.get('isForTakeaway').setValue(this.editMenuData.isForTakeaway);
      this.menucardFormGroup.get('isForInhouse').setValue(this.editMenuData.isForInhouse);
      if (this.editMenuData.validFrom || this.editMenuData.validUntil) {
        this.menucardFormGroup.get('hasValidDate').setValue(true);
      }
      this.menucardFormGroup.get('validFrom').setValue(this.editMenuData.validFrom);
      this.menucardFormGroup.get('validUntil').setValue(this.editMenuData.validUntil);
      this.menucardFormGroup.get('type').setValue(this.editMenuData.type);
      this.menucardFormGroup.get('isSpecial').setValue(this.editMenuData.isSpecial);
      this.menucardFormGroup.get('orderableFrom').setValue(this.editMenuData.orderableFrom);
      this.menucardFormGroup.get('orderableUntil').setValue(this.editMenuData.orderableUntil);
      this.menucardFormGroup.get('timeInterval').setValue(this.editMenuData.timeInterval);
      this.changeValidDates();

      if (this.editMenuData.photo) {
        this.fileName = this.editMenuData.photo;
        this.imageUrl = 'https://cdn.gastroguide.de/' + this.editMenuData.photo;
      }

      const deliveryTimes: any = [];
      if (this.editMenuData.times) {
        this.editMenuData.times.forEach((st: any) => {
          st.day = st.day ? new Date(st.day) : 0;
          deliveryTimes.push(this.formBuilder.group(st));
        });
        this.menucardFormGroup.setControl('deliveryTimes', this.formBuilder.array(deliveryTimes));
      }
    } else {
      this.addNewTime();
    }
  }

  add() {
    if (!this.menucardFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    if (!this.menucardFormGroup.get('hasValidDate').value) {
      this.menucardFormGroup.get('validFrom').setValue('');
      this.menucardFormGroup.get('validUntil').setValue('');
    }

    const formData = this.menucardFormGroup.value;
    formData.deliveryTimes = this.menucardFormGroup.controls['deliveryTimes'].value;

    formData.validFrom = formData.validFrom ? moment(formData.validFrom).format('YYYY-MM-DD HH:mm') : null;
    formData.validUntil = formData.validUntil ? moment(formData.validUntil).format('YYYY-MM-DD HH:mm') : null;
    formData.orderableFrom = formData.orderableFrom ? moment(formData.orderableFrom).format('YYYY-MM-DD HH:mm') : null;
    formData.orderableUntil = formData.orderableUntil
      ? moment(formData.orderableUntil).format('YYYY-MM-DD HH:mm')
      : null;

    this.error = null;
    this.menucardService
      .addMenucard(formData)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          this.error = err.error.msg;
        }
      );
  }

  save() {
    if (!this.menucardFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    if (!this.menucardFormGroup.get('hasValidDate').value) {
      this.menucardFormGroup.get('validFrom').setValue('');
      this.menucardFormGroup.get('validUntil').setValue('');
    }

    const formData = this.menucardFormGroup.value;
    formData.deliveryTimes = this.menucardFormGroup.controls['deliveryTimes'].value;

    formData.validFrom = formData.validFrom ? moment(formData.validFrom).format('YYYY-MM-DD HH:mm') : null;
    formData.validUntil = formData.validUntil ? moment(formData.validUntil).format('YYYY-MM-DD HH:mm') : null;
    formData.orderableFrom = formData.orderableFrom ? moment(formData.orderableFrom).format('YYYY-MM-DD HH:mm') : null;
    formData.orderableUntil = formData.orderableUntil
      ? moment(formData.orderableUntil).format('YYYY-MM-DD HH:mm')
      : null;

    this.error = null;
    this.menucardService
      .saveMenucard(formData)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          this.error = err.error.msg;
        }
      );
  }

  onChange(file: File) {
    if (file) {
      console.log(file);
      const img = new Image();
      img.src = window.URL.createObjectURL(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);

      img.onload = event => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        if (width < 800 && height < 200) {
          this.snackBar.open('Foto muss mindestens 800px breit und 200px hoch sein', '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
          return;
        }

        const fileTypes = ['image/jpeg', 'image/gif', 'image/jpg', 'image/png'];

        if (!fileTypes.includes(file.type)) {
          this.snackBar.open('Nicht unterstütztes Dateiformat. Nur jpg, gif und png', '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
          return;
        }

        this.fileName = file.name;
        this.menucardFormGroup.get('file').setValue(file);
        this.imageUrl = reader.result;
      };
    }
  }

  removeFile() {
    this.fileName = null;
    this.menucardFormGroup.get('file').setValue('');
    this.menucardFormGroup.get('deletePhoto').setValue(true);
    this.imageUrl = null;
  }

  changeSpecial(e: any) {
    if (e.checked) {
      this.menucardFormGroup.controls['hasValidDate'].setValue(true);
      this.menucardFormGroup.controls['hasValidDate'].disable();
    } else {
      this.menucardFormGroup.controls['hasValidDate'].enable();
    }
  }

  createTimeFormGroup(): FormGroup {
    return this.formBuilder.group({
      day: [0],
      from: [''],
      to: ['']
    });
  }

  addNewTime() {
    const times = this.menucardFormGroup.get('deliveryTimes') as FormArray;
    times.push(this.createTimeFormGroup());
  }

  removeTime(index: number) {
    const times = this.menucardFormGroup.get('deliveryTimes') as FormArray;
    times.removeAt(index);
  }

  changeValidDates() {
    if (!this.menucardFormGroup.get('validFrom').value || !this.menucardFormGroup.get('validUntil').value) {
      return;
    }
    const arr = [];
    for (
      const dt = new Date(this.menucardFormGroup.get('validFrom').value);
      dt <= new Date(this.menucardFormGroup.get('validUntil').value);
      dt.setDate(dt.getDate() + 1)
    ) {
      dt.setHours(0);
      arr.push(new Date(dt));
    }
    this.deliveryTimesRange = arr;
    console.log('dd', this.deliveryTimesRange);
    // return arr;
  }
}
