import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { UtilService } from '@app/core/util.service';
import { GridsterConfig, GridsterItem, GridType, CompactType } from 'angular-gridster2';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientService } from '@app/core/client.service';

declare const _support: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  newsArticles: any = [];
  options: GridsterConfig;
  dashboard: Array<GridsterItem>;
  isDragging = false;

  static itemChange(item: any, itemComponent: any) {
    console.info('itemChanged', item, itemComponent);
  }

  static itemResize(item: any, itemComponent: any) {
    console.info('itemResized', item, itemComponent);
  }

  constructor(
    private utilService: UtilService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private clientService: ClientService,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {
    // console.log('Start Re:Amaze Push Prompt');
    // _support['ui']['push_prompt'] = {
    //   name: 'Bleibe up to date',
    //   message:
    //     'Erlaube Gastro.Digital dir Benachrichtigungen zu senden. Bleibe so up-to-date über Ausfälle, Änderungen und unseren neuen Funktionen.',
    //   delay: 24,
    //   buttons: [
    //     {
    //       text: 'Zulassen',
    //       pushprompt: true
    //     },
    //     {
    //       text: 'Später',
    //       dismiss: true
    //     }
    //   ]
    // };

    this.utilService.getNewsArticles().subscribe((newsArticles: any) => {
      if (newsArticles) {
        console.log('news', newsArticles);
        this.newsArticles = newsArticles;
      }
    });

    this.options = {
      gridType: GridType.Fit,
      compactType: CompactType.None,
      itemChangeCallback: this.itemChangeCallback.bind(this),
      minCols: 6,
      maxCols: 10,
      minRows: 6,
      maxRows: 50,
      margin: 20,
      pushItems: true,
      draggable: {
        enabled: true,
        start: this.draggableStart.bind(this),
        stop: this.draggableEnd.bind(this)
      },
      resizable: {
        enabled: true,
        start: this.draggableStart.bind(this),
        stop: this.draggableEnd.bind(this)
      }
    };

    this.clientService.getDashboard().subscribe((dashboard: any) => {
      if (dashboard && dashboard.length) {
        this.dashboard = dashboard;
      } else {
        this.dashboard = [
          { cols: 3, rows: 2, y: 0, x: 0, type: 'news', minItemCols: 3, minItemRows: 2, minItemArea: 2 }
          /*
          { cols: 1, rows: 1, y: 2, x: 0, type: 'button-reservation' },
          { cols: 1, rows: 1, y: 2, x: 1, type: 'button-orders' },
          { cols: 1, rows: 1, y: 2, x: 2, type: 'button-feedbacks' }
          */
        ];

        this.dashboard.forEach(element => {
          this.clientService.saveDashboardItem(element).subscribe(() => {});
        });
      }
    });
  }

  itemChangeCallback(itemData: any) {
    this.clientService.saveDashboardItem(itemData).subscribe(() => {});
  }

  changedOptions() {
    this.options.api.optionsChanged();
  }

  removeItem(item: any) {
    this.dashboard.splice(this.dashboard.indexOf(item), 1);
  }

  addItem() {
    this.dashboard.push();
  }

  goTo(page: string) {
    console.log('go  to');
    this.router.navigate([page], { relativeTo: this.activatedRoute });
  }

  draggableStart(e: any, ui: any) {
    ui.el.children[0].children[0].addEventListener('click', this.preventClick, true);
    this.isDragging = true;
  }

  preventClick(e: any) {
    console.log('prevent default', e);
    e.stopPropagation();
    e.preventDefault();
  }

  draggableEnd(e: any, ui: any) {
    console.log('end');
    const el = ui.el.children[0].children[0];
    setTimeout(() => {
      el.removeEventListener('click', this.preventClick, true);
    }, 100);
  }
}
