import { Component, OnInit, ViewChild } from '@angular/core';
import { ClientService } from '@app/core/client.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { FormControl } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import moment from 'moment';
import { isArray, isObject } from 'util';
import { MatPaginator } from '@angular/material/paginator';
import { PageEvent } from '@angular/material';
moment.locale('de');
@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html',
  styleUrls: ['./change-log.component.scss']
})
export class ChangeLogComponent implements OnInit {
  logs: any[];
  loading: boolean = true;
  allLogs: any[];
  tableLimit: number = 50;
  tablePages: any[] = [];
  tableOffset: number = 1;
  filterBy: string = '';
  filterId: number = 0;
  myControl = new FormControl();

  dates: any = {
    from: '',
    to: ''
  };
  pageSize: number = 50;

  lowValue: number = 0;
  highValue: number = 50;
  logsMobile: any = [];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  constructor(private clientService: ClientService, private modalService: NgbModal, private snackBar: MatSnackBar) {}

  changeTableOffset(num: number = 0) {
    this.loading = true;
    this.table.offset = num;

    setTimeout(
      function(_this: any) {
        _this.loading = false;
      },
      3000,
      this
    );
  }
  ngOnInit() {
    this.getLogs(true);
  }
  getLogs(initialStart: boolean = false) {
    this.loading = true;
    if (initialStart) {
      this.dates.from = moment()
        .subtract(9, 'months')
        .subtract(7, 'days')
        .format();
      this.dates.to = moment().format();
    }
    this.clientService.getLogs(this.dates.from, this.dates.to).subscribe(data => {
      if (data) {
        data.map((item: any, key: any) => {
          var oldData = item.oldValue ? JSON.parse(item.oldValue) : '';
          var newdata = item.newValue ? JSON.parse(item.newValue) : '';
          data[key].details = this.getChangedDetails(item, oldData, newdata);
          data[key].showMore = false;
          data[key].lessDetails =
            data[key].details && data[key].details.length > 200
              ? data[key].details.substring(0, 200) + '...'
              : data[key].details;
        });
      }
      this.logs = data;
      if (this.filterBy && this.filterBy != '') {
        this.allLogs = [];
        this.filterOrdersBy('module', this.filterBy);
      }
      this.setTablePages();
      this.loading = false;
    });
  }

  getChangedDetails(item: any, oldValue: any, newValue: any) {
    let detail = '';
    const enableValueArray = [
      'isActive',
      'remindGuestsEmail',
      'remindGuests',
      'requestCheckin',
      'paymentSystem',
      'roomChoice',
      'autoPlace',
      'isAutomatic',
      'roomChoiceMandatory',
      'noReservationAfterShiftStart',
      'selfOrdering',
      'instantPayment',
      'bringService'
    ];
    const dayArray = ['Every day', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const templateKeyArray = {
      name: 'Name',
      description: 'Description',
      info: 'Information for the guest',
      emailInfo: 'Additional note',
      minGuests: 'Min guests',
      maxGuests: 'Max guests',
      interval: 'Reservation interval (in min)',
      buffer: 'Buffer (in hour)',
      noReservationAfterShiftStart: 'Reservations are no longer possible after the start of the shift',
      stayTimeType: 'Stay time type',
      menuItemName: 'Vorbestellung',
      maxGuestsMsg: 'Max Guests Msg',
      noSlotAvailableMsg: 'Keine freien Plätze mehr'
    };

    switch (item.action) {
      case 'add':
        if (item.module == 'Reservation' && item.subModule == 'Room') {
          detail =
            'New room <span class="text-primary"> ' +
            newValue.name +
            ' </span> has been added with length ' +
            newValue.length +
            ' and width is ' +
            newValue.width +
            '. Reserved status is ' +
            (newValue.isActive == '1' ? 'enable' : 'disable') +
            '.';
          detail += newValue.alert ? ' Note is ' + newValue.alert + '.' : '';
        } else if (item.module == 'Reservation' && item.subModule == 'Table') {
          detail =
            'New table <span class="text-primary"> ' +
            newValue.name +
            ' </span> has been added  in Room ' +
            (newValue.room && newValue.room.name ? newValue.room.name : 'NA') +
            ' with ' +
            newValue.seats +
            ' seats. No. of place is ' +
            newValue.seats +
            ' and minimum occupancy ' +
            newValue.minOccupancy +
            '. Reserved status is ' +
            (newValue.isActive == '1' ? 'enable' : 'disable') +
            '.';
        } else if (item.module == 'Reservation' && item.subModule == 'Reservation times') {
          detail =
            'Added series ' +
            (newValue.shift && newValue.shift.name ? newValue.shift.name : 'NA') +
            ' ' +
            moment(newValue.startDate).format('DD.MMM HH:mm') +
            ' to ' +
            moment(newValue.endDate).format('DD.MMM HH:mm');
          detail = this.getReservationTimesChangeLog(oldValue, newValue, detail);
        } else if (item.module == 'Reservation' && item.subModule == 'Template') {
          detail =
            'New template <span class="text-primary"> ' + newValue.name + '</span> has been added. Details: <br>';
          for (const [key, value] of Object.entries(templateKeyArray)) {
            detail += newValue[key]
              ? '<span class="text-primary">' + value + '</span> => ' + newValue[key] + '<br>'
              : '';
          }
          detail = this.getReservationTemplateDetails(oldValue, newValue, detail, dayArray);
        }
        return detail;
        break;
      case 'update':
        if (item.module == 'Reservation' && item.subModule == 'Room') {
          detail = 'Room <span class="text-primary"> ' + oldValue.displayName + ' </span> has been updated. <br>';
          detail = this.getReservationSettingsChangeLog(oldValue, newValue, detail, enableValueArray);
        } else if (item.module == 'Reservation' && item.subModule == 'Table') {
          detail =
            'Table <span class="text-primary"> ' +
            oldValue.displayName +
            ' </span> of room ' +
            (oldValue.room && oldValue.room.name ? oldValue.room.name : 'NA') +
            ' has been updated. <br>';
          detail = this.getReservationSettingsChangeLog(oldValue, newValue, detail, enableValueArray);
        } else if (item.module == 'Reservation' && item.subModule == 'Reservation times') {
          detail = newValue.startDate
            ? 'Changed series ' +
              (newValue.shift && newValue.shift.name ? newValue.shift.name : 'NA') +
              ' start time from ' +
              moment(oldValue.startDate).format('DD.MMM HH:mm') +
              '  to ' +
              moment(newValue.startDate).format('DD.MMM HH:mm') +
              '<br>'
            : '';
          detail += newValue.endDate
            ? 'Changed series ' +
              (newValue.shift && newValue.shift.name ? newValue.shift.name : 'NA') +
              ' end time from  ' +
              moment(oldValue.endDate).format('DD.MMM HH:mm') +
              ' to ' +
              moment(newValue.endDate).format('DD.MMM HH:mm')
            : '';
          detail +=
            newValue.startDate || newValue.endDate
              ? ' for ' + (newValue.shift && newValue.shift.name ? newValue.shift.name : 'NA') + ' '
              : '';
          if (oldValue.startDate && newValue.startDate) {
            detail =
              'Changed series ' +
              (newValue.shift && newValue.shift.name ? newValue.shift.name : 'NA') +
              ' from ' +
              moment(oldValue.startDate).format('DD.MMM HH:mm') +
              '-' +
              moment(oldValue.endDate).format('DD.MMM HH:mm') +
              ' to ' +
              moment(newValue.startDate).format('DD.MMM HH:mm') +
              '-' +
              moment(newValue.endDate).format('DD.MMM HH:mm');
          }
          detail = detail
            ? detail
            : 'Changed series ' +
              (newValue.shift && newValue.shift.name ? newValue.shift.name : 'NA') +
              ' ' +
              moment(oldValue.displayDetails.startDate).format('DD.MMM HH:mm') +
              '-' +
              moment(oldValue.displayDetails.endDate).format('DD.MMM HH:mm') +
              ' ';
          detail = this.getReservationTimesChangeLog(oldValue, newValue, detail);
        } else if (item.module == 'Reservation' && item.subModule == 'Template') {
          detail = 'Template <span class="text-primary"> ' + oldValue.displayName + ' </span> has been updated. <br>';
          detail = this.getReservationSettingsChangeLog(oldValue, newValue, detail, enableValueArray, templateKeyArray);
          detail = this.getReservationTemplateDetails(oldValue, newValue, detail, dayArray);
        } else if (item.module == 'Reservation' && item.subModule == 'General setting') {
          detail = this.getReservationSettingsChangeLog(oldValue, newValue, detail, enableValueArray, templateKeyArray);
        }
        return detail;
        break;
      case 'delete':
        if (item.module == 'Reservation' && item.subModule == 'Room') {
          detail = item.subModule + ' <span class="text-primary"> ' + oldValue.name + ' </span> has been deleted.';
        } else if (item.module == 'Reservation' && item.subModule == 'Table') {
          detail =
            item.subModule +
            ' <span class="text-primary"> ' +
            oldValue.name +
            ' </span> has been deleted of Room ' +
            (oldValue.room && oldValue.room.name ? oldValue.room.name : 'NA') +
            '.';
        } else if (item.module == 'Reservation' && item.subModule == 'Reservation times') {
          detail =
            'Deleted series ' +
            oldValue.shift.name +
            ' ' +
            moment(oldValue.startDate).format('DD.MMM HH:mm') +
            '  to ' +
            moment(oldValue.endDate).format('DD.MMM HH:mm');
        } else if (item.module == 'Reservation' && item.subModule == 'Template') {
          detail = item.subModule + ' <span class="text-primary"> ' + oldValue.name + ' </span> has been deleted.';
        }
        return detail;
        break;
      case 'unblock':
        if (item.module == 'Reservation' && item.subModule == 'Reservation times') {
          detail =
            'Unblocked series ' +
            oldValue.shift.name +
            ' ' +
            moment(oldValue.startDate).format('DD.MMM HH:mm') +
            '  to ' +
            moment(oldValue.endDate).format('DD.MMM HH:mm');
        }
        return detail;
        break;
      default:
        break;
    }
  }
  getReservationTemplateDetails(oldValue: any, newValue: any, detail: any, dayArray: any, oldArrayData: any = null) {
    let detailRoom: string = '';
    let detailquestion: string = '';
    let detailMaxGuestsPerUnit: string = '';
    let detailStayTimes: string = '';
    let detailTemplate: string = '';
    let detailAddOns: string = '';

    let roomArray: any[] = [];
    let addOnsArray: any[] = [];

    let rooms = oldValue && oldValue.rooms ? oldValue.rooms : newValue.rooms;
    let question = oldValue && oldValue.question ? oldValue.question : newValue.question;
    let maxGuestsPerUnit =
      oldValue && oldValue.maxGuestsPerUnit ? oldValue.maxGuestsPerUnit : newValue.maxGuestsPerUnit;
    let stayTimes = oldValue && oldValue.stayTimes ? oldValue.stayTimes : newValue.stayTimes;
    let addOns = oldValue && oldValue.addOns ? oldValue.addOns : newValue.addOns;

    if (rooms) {
      rooms.map((item: any, key: any) => {
        if (item) {
          roomArray[key] = item.name;
        }
      });
      detailRoom = rooms ? '<span class="text-primary">Rooms</span> => ' + roomArray.join() + '<br>' : '';
    }

    if (question) {
      detailquestion += '<span class="text-primary">Question </span> => ';
      question.map((item: any) => {
        if (item.questionDetail) {
          detailquestion +=
            item.questionDetail.question + ' (' + (item.required == '1' ? 'required' : 'optional') + ')' + '<br>';
        }
      });
    }

    if (maxGuestsPerUnit) {
      maxGuestsPerUnit.map((item: any) => {
        if (item) {
          detailMaxGuestsPerUnit +=
            '<span class="text-primary"> Max. People per unit </span>: ' +
            '<br> - ' +
            dayArray[item.day] +
            ' ' +
            (item.time ? item.time + ' min ' : '') +
            (item.guestCount ? item.guestCount + ' person ' : '') +
            '<br>';
        }
      });
    }

    if (stayTimes) {
      stayTimes.map((item: any) => {
        if (item) {
          if (item.guestCountFrom && item.guestCountTo) {
            detailStayTimes +=
              '<span class="text-primary"> Length of stay ' +
              item.guestCountFrom +
              '-' +
              item.guestCountTo +
              ' people </span> => ' +
              item.minStayTime +
              ' to ' +
              item.maxStayTime +
              ' min';
          } else {
            detailStayTimes +=
              '<span class="text-primary"> Length of stay from ' +
              item.guestCountFrom +
              ' people </span> => ' +
              item.minStayTime +
              ' to ' +
              item.maxStayTime +
              ' min';
          }
          detailStayTimes += item.defaultStayTime
            ? ' (Default value: ' + item.defaultStayTime + ' hour after) <br>'
            : '<br>';
        }
      });
    }

    if (addOns && addOns.length > 0) {
      addOns.map((item: any, key: any) => {
        if (item) {
          addOnsArray[key] = item.title;
        }
      });
      detailAddOns = addOns ? '<span class="text-primary">Add Ons</span> => ' + addOnsArray.join() + '<br>' : '';
    }

    if (oldArrayData) {
      if (oldArrayData.oldDetailRoom !== detailRoom) {
        detailRoom = (oldArrayData.oldDetailRoom ? oldArrayData.oldDetailRoom + 'to<br>' : '') + detailRoom;
      } else {
        detailRoom = '';
      }
      if (oldArrayData.oldDetailquestion !== detailquestion) {
        detailquestion =
          (oldArrayData.oldDetailquestion ? oldArrayData.oldDetailquestion + 'to<br>' : '') + detailquestion;
      } else {
        detailquestion = '';
      }
      if (oldArrayData.oldDetailMaxGuestsPerUnit !== detailMaxGuestsPerUnit) {
        detailMaxGuestsPerUnit =
          (oldArrayData.oldDetailMaxGuestsPerUnit ? oldArrayData.oldDetailMaxGuestsPerUnit + 'to<br>' : '') +
          detailMaxGuestsPerUnit;
      } else {
        detailMaxGuestsPerUnit = '';
      }
      if (oldArrayData.oldDetailStayTimes !== detailStayTimes) {
        detailStayTimes =
          (oldArrayData.oldDetailStayTimes ? oldArrayData.oldDetailStayTimes + ' to<br>' : '') + detailStayTimes;
      } else {
        detailStayTimes = '';
      }
      if (oldArrayData.oldAddOns !== detailAddOns) {
        detailAddOns = (oldArrayData.oldAddOns ? oldArrayData.oldAddOns + 'to<br>' : '') + detailAddOns;
      } else {
        detailAddOns = '';
      }
    }

    detailTemplate = detail + detailRoom + detailquestion + detailMaxGuestsPerUnit + detailStayTimes + detailAddOns;
    if (oldValue && newValue) {
      let oldArrayData = {
        oldDetailRoom: detailRoom,
        oldDetailquestion: detailquestion,
        oldDetailMaxGuestsPerUnit: detailMaxGuestsPerUnit,
        oldDetailStayTimes: detailStayTimes,
        oldAddOns: detailAddOns
      };
      oldValue = null;
      detailTemplate = this.getReservationTemplateDetails(oldValue, newValue, detail, dayArray, oldArrayData);
    }
    return detailTemplate;
  }
  getReservationSettingsChangeLog(
    oldValue: any,
    newValue: any,
    detail: any,
    enableValueArray: any,
    KeyArray: any = {}
  ) {
    for (const [key] of Object.entries(newValue)) {
      if (!(oldValue[key] && (isArray(oldValue[key]) || isObject(oldValue[key])))) {
        let displayKey = KeyArray[key]
          ? KeyArray[key]
          : key
              .replace(/([A-Z])/g, ' $1')
              .charAt(0)
              .toUpperCase() + key.replace(/([A-Z])/g, ' $1').slice(1);
        if (enableValueArray.includes(key)) {
          oldValue[key] = oldValue[key] == '1' ? 'enable' : 'disable';
          newValue[key] = newValue[key] == '1' ? 'enable' : 'disable';
        }
        detail +=
          '<span class="text-primary">' +
          displayKey +
          ' </span> => ' +
          (oldValue[key] !== null ? oldValue[key] : '-') +
          ' to ' +
          (newValue[key] !== null ? newValue[key] : '-') +
          '<br>';
      }
    }
    return detail;
  }

  getReservationTimesChangeLog(oldValue: any, newValue: any, detail: any) {
    let valueDetails = oldValue ? oldValue : newValue;
    if (valueDetails.recurrenceException) {
      let recurrenceException = valueDetails.recurrenceException.split(',').map((item: any) => {
        return moment(item).format('DD.MMM HH:mm');
      });
      detail += '<br> Blocked date ' + recurrenceException.join(', ');
    }
    if (valueDetails.recurrenceRule) {
      let recurrenceRuleArray = valueDetails.recurrenceRule.split(';');
      let recurrenceRule: any[] = [];
      recurrenceRuleArray.map((item: any) => {
        let itemData = item.split('=');
        recurrenceRule[itemData[0]] = itemData[1];
      });
      detail += '(';
      detail += recurrenceRule['FREQ'] ? recurrenceRule['FREQ'] : '';
      detail += recurrenceRule['BYDAY'] ? ':' + recurrenceRule['BYDAY'] : '';
      detail += recurrenceRule['UNTIL'] ? ' till ' + moment(recurrenceRule['UNTIL']).format('DD.MMM HH:mm') : '';
      detail += ')';
    }
    if (oldValue && newValue) {
      detail += oldValue.recurrenceRule || oldValue.recurrenceException ? ' to ' : '';
      oldValue = null;
      detail = this.getReservationTimesChangeLog(oldValue, newValue, detail);
    }
    return detail;
  }

  filterDates(filterBy: string, event: any) {
    this.loading = true;
    const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });
    const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(event.value._d);

    let filterDate = `${year}-${month}-${day} 00:00:00`;
    if (filterBy == 'to') {
      filterDate = `${year}-${month}-${day} 23:59:59`;
    }
    console.log('filterDates', filterDate);

    if (this.allLogs.length) {
      this.logs = [...this.allLogs];
      this.allLogs = [];
    }
    if (filterDate) {
      this.filterOrdersBy(filterBy, filterDate);
    }
  }

  setTablePages() {
    this.tablePages = [];
    for (let index = 1; index <= this.logs.length / this.tableLimit + 1; index++) {
      this.tablePages.push(index);
    }
    const paginateEvent = {
      previousPageIndex: 0,
      pageSize: this.pageSize,
      pageIndex: 0,
      length: this.logs.length
    };
    this.getPaginatorData(paginateEvent);
    this.loading = false;
  }

  filterOrdersBy(filterBy: string = '', filterVal: string = '') {
    switch (filterBy) {
      case 'from':
        this.dates.from = filterVal;

        this.allLogs = [...this.logs];
        this.logs = this.allLogs.filter(function(log: any) {
          if (
            (log.createdAt >= this.dates.from || this.dates.from == '') &&
            (log.createdAt <= this.dates.to || this.dates.to == '')
          ) {
            return log;
          }
        }, this);
        this.logs = [...this.logs];
        this.setTablePages();
        break;

      case 'to':
        this.dates.to = filterVal;
        this.allLogs = [...this.logs];
        this.logs = this.allLogs.filter(function(log: any) {
          if (
            (log.createdAt >= this.dates.from || this.dates.from == '') &&
            (log.createdAt <= this.dates.to || this.dates.to == '')
          ) {
            return log;
          }
        }, this);
        this.logs = [...this.logs];
        this.setTablePages();
        break;

      case 'module':
        filterVal = `${filterVal}`;
        this.filterBy = filterVal;
        if (this.allLogs && this.allLogs.length) {
          this.logs = this.allLogs;
        }
        this.allLogs = [...this.logs];
        this.logs = this.allLogs.filter(function(log: any) {
          if (log.module) {
            if (
              log.module.toLowerCase().includes(filterVal.toLowerCase()) ||
              log.subModule.toLowerCase().includes(filterVal.toLowerCase())
            ) {
              return log;
            }
          }
        }, this);
        this.logs = [...this.logs];
        this.setTablePages();
        this.table.offset = 0;
        break;

      default:
        this.filterBy = '';
        this.logs = [...this.allLogs];
        this.setTablePages();
        this.allLogs = [];
        break;
    }
  }

  setDates(type: string) {
    if (type == 'today') {
      this.dates.from = moment().format();
      this.dates.to = moment().format();
    }
    if (type == 'yesterday') {
      this.dates.from = moment()
        .subtract(1, 'days')
        .format();
      this.dates.to = moment()
        .subtract(1, 'days')
        .format();
    }
    if (type == 'thisWeek') {
      const date = moment();
      this.dates.from = date.startOf('week');
      this.dates.to = moment(this.dates.from).endOf('week');
    }
    if (type == 'lastSeven') {
      this.dates.from = moment()
        .subtract(6, 'days')
        .format();
      this.dates.to = moment().format();
    }
    if (type == 'lastWeek') {
      const date = moment().subtract(1, 'week');
      this.dates.from = date.startOf('week');
      this.dates.to = moment(this.dates.from).endOf('week');
    }
    if (type == 'lastMonth') {
      const date = moment().subtract(1, 'month');
      this.dates.from = date.startOf('month');
      this.dates.to = moment(this.dates.from).endOf('month');
    }
    if (type == 'thisMonth') {
      const date = moment();
      this.dates.from = date.startOf('month');
      this.dates.to = moment(this.dates.from).endOf('month');
    }
    this.getLogs(false);
  }
  // used to build an array of orders for mobile relevant at any given time
  public getPaginatorData(event: PageEvent): PageEvent {
    this.pageSize = event.pageSize;
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    this.logsMobile = this.logs.slice(this.lowValue, this.highValue);
    return event;
  }

  toggleExpandRow(row: any) {
    this.table.rowDetail.toggleExpandRow(row);
  }
}
