import { Component, OnInit, ViewChild } from '@angular/core';
import { GastroPayService } from '@app/gastro-pay/gastro-pay.service';
import { Subscription, Subject } from 'rxjs';
import { SocketService } from '@app/socket.service';
import { MatSnackBar } from '@angular/material';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { filter } from 'rxjs/operators';

import moment from 'moment';
moment.locale('de');

@Component({
  selector: 'app-developer-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit {
  buttonHtml: string;
  modalHtml: string;
  clientId: number;

  constructor(
    private gastropayService: GastroPayService,
    private socketService: SocketService,
    private snackBar: MatSnackBar,
    private modalService: NgbModal,
    private clientResolver: ClientResolverService
  ) {}

  ngOnInit() {
    this.clientId = this.clientResolver.client.id;
    this.buttonHtml = `&lt;script id="gg-reservation" data-id="${this.clientId}"&gt; (function(d, s) {
  var ggel, rjs = d.getElementsByTagName(s)[0];
  ggel = d.createElement(s);
  ggel.src = "//www.gastroguide.de/js/app/widgets/res_button_modal.min.js";
  ggel.async = true; rjs.parentNode.insertBefore(ggel, rjs); }(document, "script") );
&lt;/script&gt;`;

    this.modalHtml = `&lt;script id="gg-reservation" data-id="${this.clientId}"&gt; (function(d, s) {
  var ggel, rjs = d.getElementsByTagName(s)[0];
  ggel = d.createElement(s);
  ggel.src = "//www.gastroguide.de/js/app/widgets/res_button_modal.min.js";
  ggel.async = true; rjs.parentNode.insertBefore(ggel, rjs); }(document, "script") );
&lt;/script&gt;`;
  }
}
