import { Component, OnInit } from '@angular/core';
import { MatSnackBar, PageEvent } from '@angular/material';
import { ClientService } from '@app/core/client.service';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { Page } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-news-letter-subscribers',
  templateUrl: './news-letter-subscribers.component.html',
  styleUrls: ['./news-letter-subscribers.component.scss']
})
export class NewsLetterSubscribersComponent implements OnInit {
  csvOptions: any = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: false,
    headers: ['Email'],
    showTitle: false,
    title: '',
    useBom: false,
    removeNewLines: true,
    keys: ['email']
  };
  newLetters: any;
  getAllNewsletter: any;
  currentPage = 1;
  pageSize = 10;
  totalElements: number = 0;
  loading: boolean = false;
  now: any = new Date();

  constructor(private clientService: ClientService, private modalService: NgbModal, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.getNewsLetterByClientId();
    this.getCSVData();
  }

  getNewsLetterByClientId() {
    this.loading = true;
    this.clientService.getNewsLetterSubscribers(this.currentPage, this.pageSize).subscribe(
      newsLetter => {
        this.newLetters = newsLetter;
        this.totalElements = newsLetter.total;
        this.loading = false;
      },
      error => {
        console.log(error);
        this.loading = false;
      }
    );
  }

  getCSVData() {
    this.loading = true;
    let currentPage = 0;
    let pageSize = 0;
    this.clientService.getNewsLetterSubscribers(currentPage, pageSize).subscribe(
      csvData => {
        this.getAllNewsletter = csvData.users;
        this.loading = false;
      },
      error => {
        console.log(error);
        this.loading = false;
      }
    );
  }

  deleteMenu(row: any) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Newsletter löschen';
    modalRef.componentInstance.message = `Sind Sie sicher, dass Sie den Newsletter löschen möchten? Diese Aktion ist unwiderruflich.`;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      (result: any) => {
        this.loading = true;
        if (result === 'ok') {
          this.clientService.removeNewsLetterSubscribers(row.email).subscribe(
            result => {
              this.loading = false;
              this.snackBar.open('Newsletter erfolgreich gelöscht', '', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
              this.getNewsLetterByClientId();
            },
            error => {
              this.loading = false;
              this.snackBar.open('Newsletter nicht gelöscht, ging manchmal schief', '', {
                duration: 2000,
                panelClass: ['snackbar-error']
              });
              console.log(error);
            }
          );
        }
      },
      () => {}
    );
  }

  onPage(event: any) {
    this.currentPage = event.page;
    this.getNewsLetterByClientId();
  }

  onPageSizeChange(event: any) {
    this.pageSize = event.target.value;
    this.getNewsLetterByClientId();
  }
}
