import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { EventsService } from '../events.service';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.scss']
})
export class AddEventComponent implements OnInit {
  @Input() public editEventData: any; // only if editing
  @Output() passNewEvent: EventEmitter<any> = new EventEmitter();
  @Output() updateEvent: EventEmitter<any> = new EventEmitter();
  eventFormGroup: FormGroup;
  error: string;
  addObservable: any;
  defaultTime: string;
  progress: number;
  imageUrl: string | ArrayBuffer;
  fileName: string;
  times: any;
  tags: any;
  locationArray: any;
  isList: boolean;
  timer: number;
  timeoutVal: number;
  message: string;
  isGoogleResp: any;
  startDate: any;
  loader: boolean;
  formInvalid: string;
  photoSizeError: string;
  photoFormatError: string;
  currZoom: number = 15;

  private ngUnsubscribe: Subject<any> = new Subject();
  latitude: any;
  longitude: any;

  constructor(
    private formBuilder: FormBuilder,
    private eventsService: EventsService,
    private snackBar: MatSnackBar,
    public activeModal: NgbActiveModal,
    public translate: TranslateService
  ) {
    this.eventFormGroup = this.formBuilder.group(
      {
        name: ['', Validators.required],
        description: [''],
        venue: ['', Validators.required],
        file: [''],
        tag: [''],
        deletePhoto: [false],
        // startsAtDay: ['', Validators.required],
        startsAtDay: [''],
        endsAtDay: [''],
        startsAtHour: [''],
        endsAtHour: ['']
      },
      { validator: this.dateLessThan('startsAtDay', 'endsAtDay') }
    );
    this.startDate = moment().toDate();
    this.loadTranslation();
    this.translate.onLangChange.subscribe((event: any) => {
      this.loadTranslation();
    });
  }

  get formData() {
    return <FormArray>this.eventFormGroup.get('deliveryTimes');
  }

  ngOnInit() {
    /*
    this.menucardService.progressSource.subscribe((progress: number) => {
      console.log('progress', progress);
      this.progress = progress;
    });
    */
    this.times = [];
    this.tags = [];
    this.locationArray = [];
    this.isList = false;
    this.defaultTime = moment().format('HH:mm');
    this.getTimes();
    this.timer = this.timeoutVal = 500;
    this.message = '';
    this.isGoogleResp = null;
    this.loader = false;
    if (this.editEventData) {
      // console.log('Event => ', this.editEventData);
      //   this.eventFormGroup.addControl('id', new FormControl(this.editEventData.id, Validators.required));
      this.eventFormGroup.get('name').setValue(this.editEventData.name);
      this.eventFormGroup.get('description').setValue(this.editEventData.description);
      this.eventFormGroup.get('venue').setValue(this.editEventData.venue);
      if (this.editEventData.photoFile) {
        this.fileName = this.editEventData.photoFile;
        this.imageUrl = `http://cloud.gastroguide.de/${this.editEventData.photoFile}`;
      }
    }
    this.getLocation();
  }

  dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      const f = group.controls[from];
      const t = group.controls[to];
      if (t && t.value && moment(f.value) > moment(t.value)) {
        return {
          dates: 'Das End-Datum darf nicht vor dem Start-Datum liegen!'
        };
      }
      return {};
    };
  }
  loadTranslation() {
    this.formInvalid = this.translate.instant('Events&Tickets.Common.FormInvalid');
    this.photoSizeError = this.translate.instant('Events&Tickets.NewEvent.PhotoSizeError');
    this.photoFormatError = this.translate.instant('Events&Tickets.NewEvent.PhotoFormatError');
  }

  add() {
    if (!this.eventFormGroup.valid) {
      this.snackBar.open(this.formInvalid, '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    const formData = this.eventFormGroup.value;
    if (this.eventFormGroup.value.startsAtDay) {
      this.eventFormGroup.value.startsAtDay = moment(this.eventFormGroup.value.startsAtDay).format('YYYY-MM-DD');
    }
    if (this.eventFormGroup.value.endsAtDay) {
      this.eventFormGroup.value.endsAtDay = moment(this.eventFormGroup.value.endsAtDay).format('YYYY-MM-DD');
    }
    // formData.deliveryTimes = this.eventFormGroup.controls['deliveryTimes'].value;

    // formData.validFrom = formData.validFrom ? moment(formData.validFrom).format('YYYY-MM-DD HH:mm') : null;
    // formData.validUntil = formData.validUntil ? moment(formData.validUntil).format('YYYY-MM-DD HH:mm') : null;
    // formData.orderableFrom = formData.orderableFrom ? moment(formData.orderableFrom).format('YYYY-MM-DD HH:mm') : null;
    // formData.orderableUntil = formData.orderableUntil
    //   ? moment(formData.orderableUntil).format('YYYY-MM-DD HH:mm')
    //   : null;

    formData['tags'] = this.tags && this.tags.length ? this.tags.toString() : null;

    Object.assign(formData, {
      latitude: this.isGoogleResp.latitude,
      longitude: this.isGoogleResp.longitude
    });

    if (this.isGoogleResp.isGoogleResp) {
      this.eventsService.setLocation(this.isGoogleResp).subscribe();
    }

    this.loader = true;

    this.error = null;
    this.addObservable = this.eventsService
      .addEvent(formData)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.loader = false;
          this.passNewEvent.emit(response);
        },
        err => {
          this.loader = false;
          this.error = err.error.msg;
        }
      );
  }

  onChange(file: File) {
    if (file) {
      console.log(file);
      const img = new Image();
      img.src = window.URL.createObjectURL(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);

      img.onload = event => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        if (width < 800 && height < 200) {
          this.snackBar.open(this.photoSizeError, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
          return;
        }

        const fileTypes = ['image/jpeg', 'image/gif', 'image/jpg', 'image/png'];

        if (!fileTypes.includes(file.type)) {
          this.snackBar.open(this.photoFormatError, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
          return;
        }

        this.fileName = file.name;
        this.eventFormGroup.get('file').setValue(file);
        this.imageUrl = reader.result;
      };
    }
  }

  removeFile() {
    this.fileName = null;
    this.eventFormGroup.get('file').setValue('');
    this.eventFormGroup.get('deletePhoto').setValue(true);
    this.imageUrl = null;
  }

  getTimes() {
    let tStart = 0;
    const interval = 15;
    for (let i = 0; tStart < 24 * 60; i++) {
      const hh = Math.floor(tStart / 60); // getting hours of day in 0-24 format
      const mm = tStart % 60; // getting minutes of the hour in 0-55 format

      const timeObj = {
        time: ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2),
        isClosed: true
      };
      this.times.push(timeObj);

      // console.log('get times');
      const hhNext = Math.floor((tStart + interval) / 60); // getting hours of day in 0-24 format
      const mmNext = (tStart + interval) % 60; // getting minutes of the hour in 0-55 format
      const dateObjNext = new Date();
      dateObjNext.setHours(hhNext);
      dateObjNext.setMinutes(mmNext);

      const dateObj = new Date();
      dateObj.setHours(hh);
      dateObj.setMinutes(mm);

      const defaultTimeDateObj = new Date();
      const defaultTimeSplit = this.defaultTime.split(':');
      defaultTimeDateObj.setHours(+defaultTimeSplit[0]);
      defaultTimeDateObj.setMinutes(+defaultTimeSplit[1]);

      if (dateObj < defaultTimeDateObj && dateObjNext > defaultTimeDateObj) {
        const defaultTimeObj = {
          time: this.defaultTime,
          isClosed: false
        };
        this.times.push(defaultTimeObj);
      }

      tStart = tStart + interval;
    }
    // console.log('times => ', this.times);
  }

  addTag() {
    const tag = this.eventFormGroup.value.tag;
    this.eventFormGroup.controls['tag'].reset();
    if (tag && this.tags.indexOf(tag) < 0) {
      this.tags.push(tag);
    }
    console.log(this.tags);
  }

  deleteTag(index: number) {
    this.tags = this.tags.filter((tag: any, i: number) => i !== index);
  }

  checkForTyping() {
    window.clearTimeout(this.timer);
    this.isList = false;
    this.isGoogleResp = null;
  }

  fetchLocation() {
    window.clearTimeout(this.timer);
    const text = this.eventFormGroup.value.venue;
    if (text) {
      this.timer = window.setTimeout(() => {
        this.eventsService.getLocations(text).subscribe(locations => {
          if (locations && locations.length) {
            this.isList = true;
            this.locationArray = locations;
          } else {
            this.isList = true;
            this.locationArray = [];
            this.message = 'No Data Found';
            this.eventsService.getGoogleLocationData(text).subscribe(response => {
              const { results } = response;
              const data = {
                address: results[0].formatted_address,
                latitude: results[0].geometry.location.lat,
                longitude: results[0].geometry.location.lng,
                isGoogleResp: true
              };
              this.locationArray.push(data);
            });
            // this.locationArray = { address: 'No Data Found' };
          }
        });
        // this.isList = true;
        // this.locationArray = [{
        //   address: "Rabale, Navi Mumbai, Maharashtra, India",
        //   id: 1190085,
        //   latitude: 19.14633179,
        //   longitude: 73.00805664
        // }]
      }, this.timeoutVal);
    } else {
      this.isList = false;
    }
  }

  selectLocation(itemObject: any) {
    this.latitude = itemObject.latitude;
    this.longitude = itemObject.longitude;
    this.isGoogleResp = itemObject;
    this.eventFormGroup.get('venue').setValue(itemObject.address);
    this.isList = false;
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          if (position) {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
          }
        },
        (error: any) => console.log(error)
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }
  mapClicked(event: MouseEvent) {
    this.latitude = event['coords']['lat'];
    this.longitude = event['coords']['lng'];
    this.isGoogleResp = {
      latitude: this.latitude,
      longitude: this.longitude
    };
    this.getCurrentLocation(this.latitude, this.longitude);
  }

  getCurrentLocation(lat: any, lng: any) {
    this.eventsService.getlocationlatlng(lat, lng).subscribe(response => {
      const { results } = response;
      this.eventFormGroup.get('venue').setValue(results[0].formatted_address);
    });
  }
}
