import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { GastroPayService } from '@app/gastro-pay/gastro-pay.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-developer-apikey',
  templateUrl: './apikey.component.html',
  styleUrls: ['./apikey.component.scss']
})
export class ApikeyComponent implements OnInit {
  clientApiKey: any = null;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private modalService: NgbModal,
    private gastropayService: GastroPayService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.getClientApiKey();
  }

  getClientApiKey() {
    this.gastropayService
      .getRestApiKey()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((res: any) => {
        this.clientApiKey = res || null;
        console.log(31, res);
      });
  }
  generateNewKey() {
    this.gastropayService
      .getRestApiKey(true)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((res: any) => {
        this.clientApiKey = res || null;
        console.log(31, res);
      });
  }
}
