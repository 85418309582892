import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { Shell } from '@app/shell/shell.service';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { ChangePasswordComponent } from './change-password.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'client/:clientId/changepassword',
      resolve: {
        someKey: ClientResolverService
      },
      component: ChangePasswordComponent
    }
  ])
];

@NgModule({
  declarations: [ChangePasswordComponent],
  imports: [BrowserModule, ReactiveFormsModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChangePasswordModule {}
