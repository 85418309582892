import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { GalleryService } from '../gallery.service';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-album-photos',
  templateUrl: './album-photos.component.html',
  styleUrls: ['./album-photos.component.scss']
})
export class AlbumPhotosComponent implements OnInit {
  albumInfo: any;
  albums: any;
  albumName: string;
  albumDescTitleModel: any = {};
  loader: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private galleryService: GalleryService,
    private snackBar: MatSnackBar,
    private modalService: NgbModal
  ) {
    this.activatedRoute.params.subscribe(async (parameter: any) => {
      const { albumId, clientId } = parameter;
      if (localStorage.getItem('albumInfo')) {
        const albumData = JSON.parse(localStorage.getItem('albumInfo'));
        if (albumData.albumId === parseInt(albumId) && parseInt(clientId) === albumData.clientId) {
          this.albumInfo = { ...albumData };
          this.loader = true;
        } else {
          this.router.navigate([`client/${albumData.clientId}/gallery`]);
        }
      } else {
        this.router.navigate([`client/${clientId}/gallery`]);
      }
    });
  }

  ngOnInit() {
    if (this.albumInfo) {
      this.galleryService.getAlbumById(this.albumInfo.albumId).subscribe((response: any) => {
        this.albumName = response.name;
        this.albums = response.albums;

        this.albums = this.albums.filter((album: any) => album.isDeleted === 0);

        if (this.albums && this.albums.length) {
          for (let i = 0; i < this.albums.length; i++) {
            const album = { ...this.albums[i] };
            this.albumDescTitleModel[album.id] = {
              description: album.description,
              title: album.title
            };
          }
        }

        this.loader = false;
      });
    }
  }

  cancel(row: any) {
    delete row['isEdit'];
    this.albumDescTitleModel[row.id] = {
      description: row.description,
      title: row.title
    };
  }

  set(row: any) {
    const data = {
      title: this.albumDescTitleModel[row.id].title,
      description: this.albumDescTitleModel[row.id].description
    };

    this.galleryService.updateAlbumPhoto(data, this.albumInfo.albumId, row.id).subscribe((response: any) => {
      delete row['isEdit'];
      Object.assign(row, data);
      const snackBarRef = this.snackBar.open('Photo Details Udated Successfully', 'Ok', {
        duration: 3000,
        panelClass: ['snackbar-success']
      });
    });
  }

  remove(row: any) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Delete Photo';
    modalRef.componentInstance.message = 'Do you want to delete Photo?';
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Delete';
    modalRef.result.then(
      (result: any) => {
        if (result === 'ok') {
          this.galleryService.deleteAlbumPhoto(this.albumInfo.albumId, row.id).subscribe((response: any) => {
            this.albums = this.albums.filter((album: any) => album.id !== row.id);
            delete this.albumDescTitleModel[row.id];
            this.snackBar.open('Photo Deleted Successfully', '', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          });
        }
      },
      () => {}
    );
  }
}
