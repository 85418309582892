import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract, AuthenticationGuard } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { ClientStaffComponent } from './client-staff/client-staff.component';
import { UserPermissionsComponent } from './user-permissions/user-permissions.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { ImprintComponent } from './imprint/imprint.component';
import { ChangeLogComponent } from './change-log/change-log.component';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';
import { NewsLetterSubscribersComponent } from './news-letter-subscribers/news-letter-subscribers.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'client/:clientId/administration',
      data: {
        breadcrumb: 'Administration'
      },
      children: [
        {
          path: '',
          redirectTo: 'staff',
          pathMatch: 'full'
        },
        {
          path: 'staff',
          component: ClientStaffComponent,
          data: {
            title: extract('Mitarbeiter'),
            breadcrumb: 'Mitarbeiter'
          }
        },
        {
          path: 'change-log',
          component: ChangeLogComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: extract('Change log'),
            breadcrumb: 'Change log',
            permissions: {
              only: ['admin'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'general-setting',
          component: GeneralSettingsComponent,
          data: {
            title: extract('Allgemein'),
            breadcrumb: 'Allgemein'
          }
        },
        {
          path: 'users',
          component: UserPermissionsComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: extract('Benutzer verwalten'),
            breadcrumb: 'Benutzer verwalten',
            permissions: {
              only: ['admin', 'manageModerators'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'imprint',
          component: ImprintComponent,
          data: {
            title: extract('Impressum'),
            breadcrumb: 'Impressum'
          }
        },
        {
          path: 'news-letter',
          component: NewsLetterSubscribersComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: extract('News-letter'),
            breadcrumb: 'Newsletter-Empfänger',
            permissions: {
              only: ['admin', 'manageStaff'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AdministrationRoutingModule {}
