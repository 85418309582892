import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';
import { CoreModule } from '@app/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MomentModule } from 'ngx-moment';
import { FilterPipeModule } from '@app/shared/filter/filter.module';
import {
  MatTabsModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatIconModule,
  MatSlideToggleModule,
  MatRadioModule,
  MatSidenavModule,
  MatListModule,
  MatExpansionModule,
  MatTooltipModule,
  MatProgressSpinnerModule,
  MatProgressBarModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  SatDatepickerModule,
  SatNativeDateModule
} from 'saturn-datepicker';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddMenucardComponent } from './add-menucard/add-menucard.component';
import { ManageItemsComponent } from './manage-items/manage-items.component';
import { MenucardsRoutingModule } from './menucards-routing.module';
import { MenucardsComponent } from './menucards.component';
import { ManageComponent } from './manage/manage.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MenucardItemComponent } from './manage/menucard-item/menucard-item.component';
import { AddCategoryComponent } from './add-category/add-category.component';
import { AddChoiceComponent } from './add-choice/add-choice.component';
import { SettingsComponent } from './settings/settings.component';
import { AddPricecategoryComponent } from './add-pricecategory/add-pricecategory.component';
import { AddSizeComponent } from './add-size/add-size.component';
import { CsvImportComponent } from './csv-import/csv-import.component';
import { AgGridModule } from 'ag-grid-angular';
import { ToggleRendererComponent } from './manage-items/renderers/toggle-renderer.component';
import { PhotoRendererComponent } from './manage-items/renderers/photo-renderer.component';
import { CheckboxRendererComponent } from './manage-items/renderers/checkbox-renderer.component';
import { ItemLayerComponent } from './item-layer/item-layer.component';
import { NoEditorComponent } from './manage-items/renderers/no-editor.component';
import { NgSelectRendererComponent } from './manage-items/renderers/ng-select-renderer.component';
import { TagsRendererComponent } from './manage-items/renderers/tags-renderer.component';
import { DateFnsModule } from 'ngx-date-fns';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { Ng5SliderModule } from 'ng5-slider';
export const maskOptions: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    CoreModule,
    SharedModule,
    MenucardsRoutingModule,
    NgScrollbarModule,
    MomentModule,
    FilterPipeModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatRadioModule,
    AgGridModule.withComponents([]),
    MatSidenavModule,
    DragDropModule,
    MatListModule,
    MatIconModule,
    MatExpansionModule,
    MatTabsModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    DateFnsModule.forRoot(),
    NgxMaskModule.forRoot(maskOptions),
    Ng5SliderModule,
    Angular2PromiseButtonModule.forRoot({
      spinnerTpl: '<span class="fas fa-spinner fa-spin button-loading"></span>'
    })
  ],
  declarations: [
    MenucardsComponent,
    AddMenucardComponent,
    ManageItemsComponent,
    ManageComponent,
    MenucardItemComponent,
    AddCategoryComponent,
    AddChoiceComponent,
    SettingsComponent,
    AddPricecategoryComponent,
    AddSizeComponent,
    CsvImportComponent,
    ToggleRendererComponent,
    PhotoRendererComponent,
    CheckboxRendererComponent,
    NoEditorComponent,
    ItemLayerComponent,
    NgSelectRendererComponent,
    TagsRendererComponent
  ],
  entryComponents: [
    AddMenucardComponent,
    AddCategoryComponent,
    AddChoiceComponent,
    AddPricecategoryComponent,
    AddSizeComponent,
    CsvImportComponent,
    ToggleRendererComponent,
    PhotoRendererComponent,
    CheckboxRendererComponent,
    NoEditorComponent,
    ItemLayerComponent,
    NgSelectRendererComponent,
    TagsRendererComponent
  ],
  exports: [
    MenucardItemComponent,
    ToggleRendererComponent,
    PhotoRendererComponent,
    CheckboxRendererComponent,
    NoEditorComponent,
    ItemLayerComponent,
    NgSelectRendererComponent,
    TagsRendererComponent
  ]
})
export class MenucardsModule {}
