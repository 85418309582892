import { Component, OnInit } from '@angular/core';
import { GastroPayService } from '../gastro-pay.service';
import { MatSnackBar } from '@angular/material';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gastro-pay-orders',
  templateUrl: './gastro-pay-orders.component.html',
  styleUrls: ['./gastro-pay-orders.component.scss']
})
export class GastroPayOrdersComponent implements OnInit {
  openOrders: any;
  allOrders: any;
  unpaidOrders: any;
  archive: any;

  constructor(
    private gastropayService: GastroPayService,
    private snackBar: MatSnackBar,
    private clientResolver: ClientResolverService,
    private router: Router
  ) {
    if (!this.clientResolver.client.gastropay) {
      this.router.navigate(['/client/2/gastropay']);
    }
  }

  ngOnInit() {
    this.getOrders();
  }

  getOrders(refresh: boolean = false) {
    this.gastropayService.getOrders().subscribe(res => {
      this.openOrders = [];
      this.allOrders = [];
      this.unpaidOrders = [];
      this.archive = [];
      res.rows.forEach((order: any) => {
        // Status:
        // 0 -> Offen -> Unbezahlt
        // 1 -> Abgeschlossen -> Komplett fertig
        // 2 & 3 -> In bearbeitung -> Bezahlt
        if (order.status === 0) {
          this.unpaidOrders.push(order);
        }
        if (order.status === 1) {
          this.archive.push(order);
        }
        if (order.status === 2 || order.status === 3) {
          this.openOrders.push(order);
        }
        // order.rows = this.groupArray(order.rows, 5);
      });
    });
  }

  markAsDone(row: any) {
    const orderItems: Array<any> = [];
    row.items.forEach((item: any) => {
      if (item.checked) {
        item.deliveryStatus = 2;
        orderItems.push(item);
        item.checked = false;
      }
    });

    this.gastropayService.setOrderItemsStatus(row.id, orderItems, 2).subscribe((resp: any) => {
      this.snackBar.open('Status der Artikel erfolgreich geändert', '', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    });
  }

  markAsFetched(row: any) {
    const orderItems: Array<any> = [];
    row.items.forEach((item: any) => {
      if (item.checked) {
        item.deliveryStatus = 3;
        orderItems.push(item);
        item.checked = false;
      }
    });

    this.gastropayService.setOrderItemsStatus(row.id, orderItems, 3).subscribe((resp: any) => {
      // row.items = resp.orderItems;
      this.snackBar.open('Status der Artikel erfolgreich geändert', '', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    });
  }

  groupArray<T>(data: any, n: number): Array<T[]> {
    const group = new Array<T[]>();
    for (let i = 0, j = 0; i < data.length; i++) {
      if (i >= n && i % n === 0) {
        j++;
      }
      group[j] = group[j] || [];
      group[j].push(data[i]);
    }
    return group;
  }
}
