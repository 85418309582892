import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { ClientService } from '@app/core/client.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@app/core/authentication/authentication.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  @Input() public editUser: any; // only if editing
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  userFormGroup: FormGroup;
  roles: any;
  permissions: any;
  permissionsFormGroup: FormGroup;
  originalUser: any;
  disableBtn: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    public activeModal: NgbActiveModal,
    private authService: AuthenticationService
  ) {
    this.userFormGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      firstName: [''],
      lastName: [''],
      role: ['', Validators.required]
    });

    this.permissionsFormGroup = this.formBuilder.group({});

    this.authService.getUserData().subscribe((user: any) => {
      this.originalUser = user || null;
      console.log(132, this.originalUser);
    });
  }

  ngOnInit() {
    this.disableBtn = false;
    if (this.editUser) {
      this.userFormGroup.addControl('id', new FormControl(this.editUser.user.id, Validators.required));
      this.userFormGroup.get('email').setValue(this.editUser.user.email);
      this.userFormGroup.get('email').disable();
      this.userFormGroup.get('firstName').setValue(this.editUser.user.firstName);
      this.userFormGroup.get('lastName').setValue(this.editUser.user.lastName);
      this.userFormGroup.get('role').setValue(this.editUser.roleId);
    }

    this.getPermissions();
  }

  getRoles() {
    this.clientService.getRoles().subscribe((roles: any) => {
      this.roles = roles;

      if (this.editUser && this.editUser.roleId) {
        const roleData = this.roles.find((role: any) => {
          return role.id === this.editUser.roleId;
        });
        this.changeRole(roleData);
        // First uncheck all checkboxes
        this.permissions.forEach((permGroup: any) => {
          permGroup.permissions.forEach((perm: any) => {
            if (!perm || !Object.keys(perm).length) {
              return;
            }
            this.permissionsFormGroup.controls[perm.permission].setValue(false);
          });
        });
        // Get permissions of this moderator
        this.clientService.getPermissionsOfUser(this.editUser.user.id).subscribe((permissions: any) => {
          permissions.forEach((permission: any) => {
            this.permissionsFormGroup.controls[permission].setValue(true);
          });
        });
      }
    });
  }

  getPermissions() {
    this.clientService.getPermissions().subscribe((permissions: any) => {
      this.permissions = permissions;
      this.getRoles();

      permissions.forEach((group: any) => {
        group.permissions.forEach((permission: any) => {
          this.permissionsFormGroup.addControl(permission.permission, new FormControl(''));
        });
      });
    });
  }

  changeRole(role: any) {
    // First uncheck all checkboxes
    this.permissions.forEach((permGroup: any) => {
      permGroup.permissions.forEach((perm: any) => {
        this.permissionsFormGroup.controls[perm.permission].setValue(false);
      });
    });
    if (!role) {
      return;
    }
    // Now set value according to role
    role.permissions.forEach((permission: any) => {
      this.permissionsFormGroup.controls[permission.permission].setValue(true);
    });
  }

  save() {
    if (!this.userFormGroup.valid) {
      console.log('nope');
      return;
    }
    if (this.originalUser.email.toLowerCase() == this.userFormGroup.get('email').value.toLowerCase()) {
      alert('Sie können nicht Ihre eigene E-Mail eintragen!');
      return;
    }
    const userData = this.userFormGroup.getRawValue();
    userData.permissions = Object.keys(this.permissionsFormGroup.value).map(key => ({
      permission: key,
      value: this.permissionsFormGroup.value[key]
    }));

    if (this.editUser && this.editUser.user.email == userData.email) {
      this.disableBtn = true;
      this.clientService.saveModerator(userData).subscribe((response: any) => {
        this.disableBtn = false;
        this.passEntry.emit(response);
      });
    } else {
      this.clientService.validateEmail(this.userFormGroup.get('email').value).subscribe((validation: any) => {
        console.log(20, validation.result);
        if (validation) {
          if (validation.result.suggestion) {
            let verdict = 'Ungültige E-Mail!';
            if (validation.result.verdict != 'Invalid') {
              verdict = 'E-Mail Adresse prüfen!';
            }
            const suggestedEmail = validation.result.local + '@' + validation.result.suggestion;
            if (confirm(verdict + '\nMeinten Sie vlt. stattdessen ' + suggestedEmail + '?')) {
              this.userFormGroup.get('email').setValue(suggestedEmail);
              this.save();
            }
            return;
          } else {
            if (validation.result.verdict != 'Invalid') {
              if (validation.result.verdict == 'Valid') {
                this.disableBtn = true;
                this.clientService.saveModerator(userData).subscribe((response: any) => {
                  this.disableBtn = false;
                  this.passEntry.emit(response);
                });
              } else {
                if (validation.result.verdict == 'Risky') {
                  if (validation.result.score > 0.3) {
                    if (
                      confirm(
                        'Unser System hält es für unwahrscheinlich, dass Sie Nachrichten von uns an die angegebene E-Mail Adresse erhalten werden. \nWollen Sie diese E-Mail Adresse wirklich verwenden?'
                      )
                    ) {
                      this.disableBtn = true;
                      this.clientService.saveModerator(userData).subscribe((response: any) => {
                        this.disableBtn = false;
                        this.passEntry.emit(response);
                      });
                    }
                  } else {
                    this.disableBtn = true;
                    this.clientService.saveModerator(userData).subscribe((response: any) => {
                      this.disableBtn = false;
                      this.passEntry.emit(response);
                    });
                  }
                }
              }
            } else {
              let err = '';
              if (!validation.result.checks.domain.has_mx_or_a_record) {
                err = '\nFehler: Die Domain (' + validation.result.host + ') ist unbekannt.';
              }
              if (!validation.result.checks.additional.has_known_bounces) {
                err = '\nFehler: Die E-Mail Adresse ist nicht erreichbar.';
              }
              if (!validation.result.checks.domain.has_valid_address_syntax) {
                err = '\nFehler: Ungültiges E-Mail Format.';
              }
              alert('Die angegebene E-Mail Adresse ist ungültig!' + err);
              return;
            }
          }
        }
      });
    }
  }
}
