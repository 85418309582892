import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { I18nService, untilDestroyed } from '@app/core';
import { MatIconRegistry } from '@angular/material/icon';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { SiteVisitTrackingService } from './site-visit-tracking.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private permissionsService: NgxPermissionsService,
    private userrolesService: NgxRolesService,
    private siteVisitTrackingService: SiteVisitTrackingService
  ) {
    this.matIconRegistry.addSvgIcon(
      'stats',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/stats.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'transactions',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/transactions.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'users',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/users.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'user',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/user.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'employee',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/employee.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'orders',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/orders.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'Visa',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/visa.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'MasterCard',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/mastercard.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'American Express',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/amex.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'edit',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/edit.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'delete',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/delete.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'sepa',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/sepa.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'save',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/save.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'company',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/company.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'plus',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/plus.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'minus',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/minus.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'warning',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/warning.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'move',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/move.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'menu',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/menu.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'settings',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/settings-2.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'print',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/print-1.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'subdirectory-arrow-right',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/subdirectory-arrow-right.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'help',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/help.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'vegetarian',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/vegetarian.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'vegan',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/vegan.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fire',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/fire.svg')
    );

    // Google analytics
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     (<any>window).ga('set', 'page', event.urlAfterRedirects);
    //     (<any>window).ga('send', 'pageview');
    //   }
    // });
  }

  ngOnInit() {
    this.handleOAuthRedirect();
    const withoutTokenRoute = ['register', 'forgot-password', 'reset-password', 'user/reservation'];
    window.addEventListener('storage', event => {
      if (event.storageArea == localStorage) {
        let token = localStorage.getItem('credentials');
        if (token == undefined && !withoutTokenRoute.some(route => this.router.url.includes(route))) {
          // Perform logout
          //Navigate to login/home
          this.router.navigate(['/login']);
        }
      }
    });
    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
        untilDestroyed(this)
      )
      .subscribe(event => {
        const pageTitle = event['title'];
        if (pageTitle) {
          const title = this.translateService.instant(pageTitle);
          if (typeof title === 'object') {
            this.titleService.setTitle(title.Title);
          } else {
            this.titleService.setTitle(pageTitle);
          }
        }
      });
  }

  handleOAuthRedirect() {
    const hash = window.location.hash;
    if (hash && (hash.startsWith('#access_token=') || hash.startsWith('#state='))) {
      const params = new URLSearchParams(hash.slice(1));
      const accessToken = params.get('access_token');
      const expiresIn = params.get('expires_in');
      const clientId = params.get('state');

      if (accessToken && expiresIn) {
        // Calculate expiry time in milliseconds and store it
        const expiresInMs = parseInt(expiresIn, 10) * 1000;
        const expiryTime = Date.now() + expiresInMs;

        // Store the access token and expiry time in local storage
        localStorage.setItem('googleAccessToken', accessToken);
        localStorage.setItem('googleAccessTokenExpiry', expiryTime.toString());

        console.log('Access token:', accessToken);
        console.log('Expiry time:', new Date(expiryTime));

        const newUrl = `${window.location.origin}/client/${clientId}/feedback/statistic?tab=1&google=true`;
        window.location.replace(newUrl);
      } else {
        console.error('Access token or expiry time not found.');
        // this.snackBar.open('There is some technical issue. Please contact support', '', {
        //   duration: 2000,
        //   panelClass: ['snackbar-error']
        // });
      }
    }
  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }
}
