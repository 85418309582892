import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule, MatChipsModule } from '@angular/material';

@NgModule({
  imports: [MatSidenavModule, MatToolbarModule, MatIconModule, MatListModule, MatRadioModule, MatChipsModule],
  exports: [MatSidenavModule, MatToolbarModule, MatIconModule, MatListModule, MatRadioModule, MatChipsModule]
})
export class MaterialModule {}
