import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '@env/environment';
import { I18nService, AuthenticationService, untilDestroyed } from '@app/core';
import { Subject } from 'rxjs';
import 'rxjs/add/operator/takeUntil';
import { NgxPermissionsService } from 'ngx-permissions';
import { InitPermissionsService } from '@app/core/init-permissions.service';
var CryptoJS = require('crypto-js');

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  // version: string = environment.version;
  error: string | undefined;
  loginForm!: FormGroup;
  isLoading = false;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private i18nService: I18nService,
    private authenticationService: AuthenticationService,
    private permissionService: InitPermissionsService,
    private rs: NgxPermissionsService,
    private translate: TranslateService
  ) {
    this.createForm();
  }

  ngOnInit() {}

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  login() {
    this.isLoading = true;
    const login$ = this.authenticationService.login(this.loginForm.value);
    login$
      .pipe(
        finalize(() => {
          this.loginForm.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this)
      )
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        credentials => {
          const snapshot = this.route.snapshot.queryParams.redirect;
          let clientId = null;
          if (snapshot) {
            const match = snapshot.match(/^\/client\/(\d+)/);
            if (match) {
              clientId = match[1];
            }
          }
          let { email, id } = JSON.parse(localStorage.getItem('credentials'));
          // let key = 'qBkTSzSFwLTD-lmoh8DV4b1SwBeu_kUSbdvCD_sv'; // test
          let key = 'TZCUeUbiUDqdTTrdeVlqxtZ6ieSfVLxmPtKYzNH4'; // live

          // '1ca12d7c0629194a9f9d0dbbc957709dd3aed385925b077e726813f0b452de6a38256abd1116138d21754cfb33964b6b1aaa375b74d3580fcda916898f553c92';
          id = `${id}`;

          let signedString = CryptoJS.HmacSHA256(id, key);
          let resultSign = CryptoJS.enc.Hex.stringify(signedString);
          this.authenticationService.getUserData().subscribe(
            (user: any) => {
              console.log(85, 'testing', user, credentials, email, id, resultSign);

              (<any>window).Intercom('boot', {
                app_id: window.location.href.includes('kunden.gastro.digital') ? 'u57ft9ay' : 'u57ft9ay',
                email: email,
                user_id: id,
                user_hash: resultSign,
                name: user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : 'Besucher',

                company: {
                  id: id + 10000000,
                  name: credentials.username || 'Firma'
                }
              });
            },
            error => {
              console.error(91, error);
            }
          );
          this.permissionService.load(clientId).then(async permissions => {
            await this.rs.addPermission(permissions);
            this.router.navigate([snapshot || '/select'], { replaceUrl: true });
          });
        },
        error => {
          if (error && error.status != 0) {
            this.error = this.translate.instant('Login.Error');
          } else {
            this.error = 'Something went wrong! Please contact administrator.';
          }
        }
      );
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  resetPassword() {
    this.router.navigate(['/login/reset-password'], { replaceUrl: true });
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      remember: true
    });
  }
}
