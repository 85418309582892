import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { EventListComponent } from './event-list/event-list.component';
import { AllDatesComponent } from './all-dates/all-dates.component';
import { EditEventComponent } from './edit-event/edit-event.component';
import { OrdersComponent } from './orders/orders.component';
import { TicketSettingsComponent } from './ticket-settings/ticket-settings.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'client/:clientId/events',
      data: {
        breadcrumb: 'Event & Tickets',
        transPath: 'ShellMenu.GuestMarketing.EventsAndTickets.Title'
      },
      resolve: {
        someKey: ClientResolverService
      },
      children: [
        {
          path: 'all',
          component: EventListComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Alle Events',
            breadcrumb: 'Alle Events',
            transPath: 'ShellMenu.GuestMarketing.EventsAndTickets.AllEvents',
            permissions: {
              only: ['admin', 'manageEvents'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'edit/eventId/:id',
          component: EditEventComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Edit Events',
            breadcrumb: 'Edit Events',
            permissions: {
              only: ['admin', 'manageEvents'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'dates/all',
          component: AllDatesComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Alle Termine',
            breadcrumb: 'Alle Termine',
            transPath: 'ShellMenu.GuestMarketing.EventsAndTickets.AllAppointments',
            permissions: {
              only: ['admin', 'manageEvents'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'orders',
          component: OrdersComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Orders',
            breadcrumb: 'Orders',
            transPath: 'ShellMenu.GuestMarketing.EventsAndTickets.Orders',
            permissions: {
              only: ['admin', 'manageEvents'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'settings',
          component: TicketSettingsComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Settings',
            breadcrumb: 'Settings',
            transPath: 'ShellMenu.GuestMarketing.EventsAndTickets.Settings',
            permissions: {
              only: ['admin', 'manageEvents'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventsRoutingModule {}
