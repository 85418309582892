import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { GastroAppStatisticsComponent } from './gastro-app-statistics/gastro-app-statistics.component';
import { GastroAppUpdateLogoComponent } from './gastro-app-update-logo/gastro-app-update-logo.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'client/:clientId/gastro-app',
      data: {
        breadcrumb: 'Eigene App',
        transPath: 'ShellMenu.GuestMarketing.OwnApp.Title'
      },
      resolve: {
        someKey: ClientResolverService
      },
      children: [
        {
          path: 'statistics',
          component: GastroAppStatisticsComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Statistiken',
            breadcrumb: 'Statistiken',
            transPath: 'ShellMenu.GuestMarketing.OwnApp.Statistics',
            permissions: {
              only: ['admin'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'restaurant-info',
          component: GastroAppUpdateLogoComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Logo ändern',
            breadcrumb: 'Logo ändern',
            transPath: 'ShellMenu.GuestMarketing.OwnApp.RestaurantInfo',
            permissions: {
              only: ['admin'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GastroAppRoutingModule {}
