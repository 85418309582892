import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GalleryService } from '../gallery.service';

@Component({
  selector: 'app-add-album',
  templateUrl: './add-album.component.html',
  styleUrls: ['./add-album.component.scss']
})
export class AddAlbumComponent implements OnInit {
  @Output() newAlbumResponse: EventEmitter<any> = new EventEmitter();
  name: string;
  isChecked: boolean = false;
  constructor(public activeModal: NgbActiveModal, private galleryService: GalleryService) {}

  ngOnInit() {}

  createAlbum() {
    if (this.name.trim()) {
      const data = {
        name: this.name,
        showInApp: this.isChecked
      };
      this.galleryService.createAlbum(data).subscribe((response: any) => {
        this.newAlbumResponse.emit(response);
      });
    }
  }
}
