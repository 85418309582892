import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { AuthenticationService, CredentialsService } from '@app/core';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private credentialsService: CredentialsService
  ) {}

  uploadDocument(data: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .post<any>(environment.serverUrl + `client/${this.authService.activeClientId}/uploadDocument`, data, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getDocuments() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `client/${this.authService.activeClientId}/getDocuments`, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  removeDocument(docId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `client/${this.authService.activeClientId}/removeDocument/${docId}`, {
        headers: headers
      })
      .pipe(
        map((event: any) => {
          return event;
        })
      );
  }

  updateDocName(docId: number, name: string) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .put<any>(
        environment.serverUrl + `client/${this.authService.activeClientId}/updateDocument/${docId}`,
        { name },
        {
          headers: headers,
          reportProgress: true
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getShortURLs() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `client/${this.authService.activeClientId}/getShortURLs`, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  addShortURL(data: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    if (!data.clientId) {
      data.clientId = this.authService.activeClientId;
    }
    return this.http
      .post<any>(environment.serverUrl + `client/add-short-url`, data, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  editShortURL(data: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    if (!data.clientId) {
      data.clientId = this.authService.activeClientId;
    }
    return this.http
      .put<any>(environment.serverUrl + `client/edit-short-url`, data, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteShortURL(id: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `client/delete-short-url/` + id, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
