import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RegisterService } from './register.service';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@app/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InitPermissionsService } from '@app/core/init-permissions.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ReCaptchaV3Service } from 'ng-recaptcha';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  message: boolean;
  timer: number;
  // isList: boolean;
  locationArray: any;
  timeoutVal: number;
  locationId: number;
  countryId: number;
  stateId: number;
  countries: any;
  disableRegisterBtn: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private registerService: RegisterService,
    private snackBar: MatSnackBar,
    public translate: TranslateService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private permissionService: InitPermissionsService,
    private rs: NgxPermissionsService // private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]],
      password: ['', Validators.required],
      zipCode: ['', Validators.required],
      companyName: ['', Validators.required],
      telefon: ['', Validators.required],
      country: ['', Validators.required],
      street: ['', Validators.required],
      name: ['', Validators.required],
      place: ['', Validators.required],
      // username: ['', Validators.required],
      termsAndCond: [false],
      cameFrom: [''],
      recaptcha: [null, Validators.required]
    });
  }

  ngOnInit() {
    this.getBannedIPs();
    this.message = false;
    this.locationArray = [];
    this.timer = this.timeoutVal = 500;
    this.registerService.getGeoCountries().subscribe(countries => {
      this.countries = countries;
      this.countries.push({ id: 4, name: 'anderes' });
    });
  }

  getBannedIPs() {
    this.registerService.getBannedIPs().subscribe(data => {
      if (data && data.length > 0) {
        const bannedIPs = data.map((item: any) => item.ip);
        this.registerService.getIPAddress().subscribe(IPdata => {
          if (IPdata && IPdata.ip && bannedIPs.includes(IPdata.ip)) {
            this.disableRegisterBtn = true;
          }
        });
      }
    });
  }

  register() {
    // this.recaptchaV3Service.execute('submitForm').subscribe(
    //   (token: string) => {
    //     console.debug(`Token [${token}] generated`);
    //   },
    //   (error: any) => {
    //     console.error('An error occurred:', error);
    //     // You can add additional error handling logic here
    //   }
    // );

    let isValid = true;
    this.message = false;
    if (!this.registerForm.valid) {
      for (let key in this.registerForm.controls) {
        if (!this.registerForm.controls[key].valid) {
          isValid = false;
          this.registerForm.controls[key].markAsTouched({ onlySelf: true });
        }
      }
    }
    if (!this.registerForm.controls['termsAndCond'].value) {
      this.message = true;
      isValid = false;
    }

    if (isValid) {
      const data = { ...this.registerForm.value };
      data['countryId'] = this.countryId ? this.countryId : data.country ? data.country : null;
      data['locationId'] = this.locationId ? this.locationId : null;
      data['stateId'] = this.stateId ? this.stateId : null;

      this.registerService.register(data).subscribe(
        (response: any) => {
          this.snackBar.open('Erfolgreich registriert', '', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.authenticationService
            .login({ email: data.email, password: data.password, remember: true })
            .subscribe(response => {
              const snapshot = this.route.snapshot.queryParams.redirect;
              let clientId = null;
              if (snapshot) {
                const match = snapshot.match(/^\/client\/(\d+)/);
                if (match) {
                  clientId = match[1];
                }
              }
              this.permissionService.load(clientId).then(async permissions => {
                await this.rs.addPermission(permissions);
                this.router.navigate([snapshot || '/select'], { replaceUrl: true });
              });
            });
        },
        (err: any) => {
          this.snackBar.open(err.error.msg, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      );
    }
  }

  checkForTyping() {
    window.clearTimeout(this.timer);
    // this.isList = false;
    // this.isGoogleResp = null;
  }

  fetchLocation() {
    window.clearTimeout(this.timer);
    const zips = this.registerForm.value.zipCode;
    console.log('Zips => ', zips);
    if (zips && this.registerForm.value.country === 1) {
      this.timer = window.setTimeout(() => {
        this.registerService.getGeoLocations({ zips }).subscribe(locations => {
          if (locations && locations.length) {
            // this.isList = true;
            let locationArray = locations;
            const newArray: any = [];

            locationArray = locationArray.filter((each: any) => {
              const locationArray = each.zips.split(',');
              if (locationArray.length > 1) {
                newArray.push(each);
              } else if (each.zips.startsWith(zips)) {
                return each;
              }
            });

            if (newArray && newArray.length) {
              for (let j = 0; j < newArray.length; j++) {
                const newZips = newArray[j].zips.split(',');
                for (let z = 0; z < newZips.length; z++) {
                  if (newZips[z].startsWith(zips)) {
                    const element = { ...newArray[j] };
                    element['zips'] = newZips[z];
                    locationArray.push(element);
                  }
                }
              }
            }
            this.locationArray = locationArray;
          } else {
            // this.isList = false;
            this.locationArray = [];
          }
        });
      }, this.timeoutVal);
    } else {
      // this.isList = false;
    }
  }

  selectZipCode(zipCode: any) {
    this.registerForm.get('zipCode').setValue(zipCode.zips);
    this.registerForm.get('place').setValue(zipCode.name);
    this.locationId = zipCode.id;
    this.countryId = zipCode.countryId;
    this.stateId = zipCode.stateId;
    // this.isList = false;
  }

  InputControl(event: any) {
    if (this.registerForm.value.country === 1) {
      setTimeout(() => {
        let isValueTrue = this.locationArray.filter(
          (myAlias: any) => myAlias.zips === this.registerForm.value['zipCode']
        );
        if (isValueTrue.length === 0) {
          this.registerForm.get('zipCode').setValue(null);
          this.registerForm.get('place').setValue(null);
        }
      }, 300);
    }
  }
  selectCountriesChange() {
    this.registerForm.get('zipCode').setValue(null);
    this.registerForm.get('place').setValue(null);
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }
}
