import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModalConfig, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '@env/environment';
import { CoreModule, I18nService, AuthenticationService } from '@app/core';
import { SharedModule } from '@app/shared';
import { HomeModule } from './home/home.module';
import { ShellModule } from './shell/shell.module';
import { LoginModule } from './login/login.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SelectClientModule } from './select-client/select-client.module';
import { ReservationSettingsModule } from './reservation/reservation-settings/reservation-settings.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './core/material.module';
import { NavService } from './nav.service';
import { CurrencyPipe, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import * as deLocale from 'date-fns/locale/de';
import { DateFnsModule, DateFnsConfigurationService } from 'ngx-date-fns';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MomentUtcDateAdapter } from './shared/moment-utc-date-adapter';
import { ReservationsModule } from './reservation/reservations/reservations.module';
import { ReservationOverviewModule } from './reservation/reservation-overview/reservation-overview.module';
import { ReservationsStatisticsModule } from './reservation/reservations-statistics/reservations-statistics.module';
import { ClientStaffModule } from './administration/client-staff/client-staff.module';
import { ReservationUserPageModule } from './reservation/reservation-user-page/reservation-user-page.module';
import { ReservationIntegrationModule } from './reservation/reservation-integration/reservation-integration.module';
import { ErrorPagesModule } from './error-pages/error-pages.module';
import { ReservationAlertsModule } from './reservation/alerts/reservation-alerts.module';
import { GastroPayModule } from './gastro-pay/gastro-pay.module';
import { LazyElementsModule } from '@angular-extensions/elements';
import { VouchersPoolModule } from './vouchers/vouchers-pool/vouchers-pool.module';
import { VouchersLogModule } from './vouchers/vouchers-log/vouchers-log.module';
import { VouchersListModule } from './vouchers/vouchers-list/vouchers-list.module';
import { VouchersOrdersModule } from './vouchers/vouchers-orders/vouchers-orders.module';
import { VouchersSettingsModule } from './vouchers/vouchers-settings/vouchers-settings.module';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { OpeningHoursModule } from './administration/opening-hours/opening-hours.module';
import { EventModule } from './events/event.module';
import { GastroAppModule } from './gastro-app/gastro-app.module';
import { MyEntryModule } from './my-entry/my-entry.module';
import { ReviewsModule } from './reviews/reviews.module';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { ChangeLogModule } from './administration/change-log/change-log.module';
import { SMSServiceConfirmationModule } from './reservation/sms-service-confirmation/sms-service-confirmation.module';
import { ReservationDemoModule } from './reservation/reservation-demo/reservation-demo.module';
import { SiteVisitTrackingService } from './site-visit-tracking.service';

const socketConfig: SocketIoConfig = {
  url: environment.serverUrl,
  options: {
    reconnect: true,
    reconnectionAttempts: Infinity,
    autoConnect: true,
    reconnection: true,
    transports: ['websocket']
  }
};
const deConfig = new DateFnsConfigurationService();
deConfig.setLocale(deLocale);

import { FONT_PICKER_CONFIG } from 'ngx-font-picker';
import { FontPickerConfigInterface } from 'ngx-font-picker';
import { GuestModule } from './reservation/guest/guest.module';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { CheckInsModule } from './check-ins/check-ins.module';
import { OrdersModule } from './orders/orders.module';
import { DeveloperModule } from './developer/developer.module';
import { HotelReservationModule } from './reservation/hotel-reservation/hotel-reservation.module';
import { MenucardsModule } from './menucards/menucards.module';
import { ReservationImportModule } from './reservation/reservation-import/reservation-import.module';
import { AccountingModule } from './accounting/accounting.module';
import { FeedbackModule } from './feedback/feedback.module';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { MyGuestsModule } from './reservation/my-guests/my-guests.module';
import { UserPermissionsModule } from './administration/user-permissions/user-permissions.module';
import { InviteModule } from './invite/invite.module';
import { InitPermissionsService } from './core/init-permissions.service';
import { ExportModule } from './reservation/export/export.module';
import { ImprintModule } from './administration/imprint/imprint.module';
import { GalleryModule } from './gallery/gallery.module';
// import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
// import { SentryErrorHandler } from 'sentry-error-handler';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ChangePasswordModule } from './change-password/change-password-routing.module';
import { NewCompanyModule } from './new-company/new-company-routing.module';
import { SelectDraftCompanyRoutingModule } from './select-draft-company/select-draft-company-routing.module';
import { DocumentsModule } from './documents/documents.module';
import { ReservationLogsModule } from './reservation/reservation-logs/reservation-logs.module';
import { ReservationTableSettingsModule } from './reservation/reservation-table-settings/reservation-table-settings.module';
import { VoucherStatisticsModule } from './vouchers/voucher-statistics/voucher-statistics.module';
import { GeneralSettingModule } from './administration/general-settings/general-settings.module';
import { TicketSettingsModule } from './events/ticket-settings/ticket-settings.module';
import { VouchersApiModule } from './vouchers/vouchers-api/vouchers-api.module';
import { ReservationListByDateModule } from './reservation/reservation-list-by-date/reservations-list-by-date.module';
import { NewsLetterSubscribersModule } from './administration/news-letter-subscribers/news-letter-subscribers.module';
import { PhoneAssistantModule } from './reservation/phone-assistant/phone-assistant.module';

const DEFAULT_FONT_PICKER_CONFIG: FontPickerConfigInterface = {
  apiKey: 'AIzaSyAyAtE7GNzI6Y6Vep7WrPCQ05VADtP8ztM'
};

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  imports: [
    BrowserModule,
    SocketIoModule.forRoot(socketConfig),
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
    NgbModule,
    MaterialModule,
    CoreModule,
    SharedModule,
    ShellModule,
    HomeModule,
    LoginModule,
    ChangePasswordModule,
    NewCompanyModule,
    SelectDraftCompanyRoutingModule,
    BrowserAnimationsModule,
    SelectClientModule,
    ReservationSettingsModule,
    PhoneAssistantModule,
    ReservationsModule,
    ReservationListByDateModule,
    GuestModule,
    ReservationOverviewModule,
    ReservationsStatisticsModule,
    ReservationAlertsModule,
    ClientStaffModule,
    VouchersApiModule,
    ChangeLogModule,
    ReservationUserPageModule,
    ReservationIntegrationModule,
    GastroPayModule,
    ErrorPagesModule,
    LazyElementsModule,
    VouchersPoolModule,
    VouchersLogModule,
    VouchersListModule,
    VouchersApiModule,
    VoucherStatisticsModule,
    MenucardsModule,
    VouchersSettingsModule,
    VouchersApiModule,
    EventModule,
    GastroAppModule,
    MyEntryModule,
    ReviewsModule,
    DocumentsModule,
    CheckInsModule,
    OrdersModule,
    DeveloperModule,
    ReservationImportModule,
    ExportModule,
    HotelReservationModule,
    MyGuestsModule,
    OpeningHoursModule,
    ImprintModule,
    AccountingModule,
    FeedbackModule,
    UserPermissionsModule,
    InviteModule,
    GalleryModule,
    VouchersOrdersModule,
    ReservationTableSettingsModule,
    SMSServiceConfirmationModule,
    TicketSettingsModule,
    ReservationDemoModule,
    VouchersApiModule,
    NewsLetterSubscribersModule,
    Angular2PromiseButtonModule.forRoot({
      spinnerTpl: '<span class="fas fa-spinner fa-spin button-loading"></span>'
    }),
    DeviceDetectorModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ReservationLogsModule,
    GeneralSettingModule
  ],
  declarations: [AppComponent],
  providers: [
    NavService,
    InitPermissionsService,
    CurrencyPipe,
    SiteVisitTrackingService,
    /*
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    },
    */
    /*
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    */
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: DateFnsConfigurationService, useValue: deConfig },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: DateAdapter, useClass: MomentUtcDateAdapter },
    { provide: FONT_PICKER_CONFIG, useValue: DEFAULT_FONT_PICKER_CONFIG },
    {
      provide: APP_INITIALIZER,
      useFactory: loadPermissions,
      deps: [InitPermissionsService, NgxPermissionsService /* Sentry.TraceService */],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  exports: [NgxPermissionsModule, TranslateModule]
})
export class AppModule {
  constructor(config: NgbModalConfig) {
    registerLocaleData(localeDe);
    config.backdrop = 'static';
    config.keyboard = false;
  }
}

export function loadPermissions(permissionService: InitPermissionsService, rs: NgxPermissionsService) {
  return () => {
    return permissionService.load().then(permissions => {
      console.log('perms', permissions);
      return rs.addPermission(permissions);
    });
  };
}
