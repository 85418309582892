// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
// import env from './.env';

export const environment = {
  production: true,
  // serverUrl: 'https://ec2.gastroguide.de/', // Testsystem
  serverUrl: 'https://api.gastrodigital.net:2017/',
  mainServerUrl: 'https://api.gastroguide.de/',
  secondaryServerUrl: 'https://api2.gastroguide.de/',
  ticketServerUrl: 'https://tickets.gastroguide.de',
  defaultLanguage: 'de-DE',
  supportedLanguages: ['de-DE', 'de-Formal', 'en', 'de', 'es', 'nl', 'fr'],
  gastroPayUrl: 'https://gastropay.azurewebsites.de/api/v1.0/',
  versionCheckURL: '/dist/version.json',
  frontendUrl: 'https://kunden.gastrodigital.net',
  onlineResURL: 'https://reserve.gastrodigital.net',
  googleTranslationUrl: 'https://translation.googleapis.com/language/translate/v2?key=',
  googleTranslationApiKey: 'AIzaSyCVGSVFC4w5IbDNwDP3Zgq1PswBH8JHiiY',
  baseUrl: 'https://kunden.gastro.digital/',
  baseUrlHetzner: 'https://kunden.gastrodigital.net/'
};
