import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { HandleStateForPipeService } from '../handle-state-for-pipe.service';

@Pipe({
  name: 'googleTranslate',
  pure: true // Ensure the pipe is pure
})
export class GoogleTranslatePipe implements PipeTransform {
  sourceLangNew: string;
  currentState: boolean;
  callGoogleTranslateAPI: any = false;
  defaultLang: any;
  private apiCount: number = 0;
  constructor(private http: HttpClient, private stateService: HandleStateForPipeService) {}

  transform(value: string, targetLanguage: string): Observable<any> {
    this.callGoogleTranslateAPI = localStorage.getItem('callGoogleTranslateAPI') === 'true' ? true : false;
    this.defaultLang = localStorage.getItem('defaultLang') ? localStorage.getItem('defaultLang') : 'de';
    const apiKey = environment.googleTranslationApiKey;
    const url = `${environment.googleTranslationUrl}${apiKey}`;

    this.stateService.state$.subscribe(state => {
      this.currentState = state;
    });

    if (this.currentState === true) {
      let body = {
        q: '',
        target: '',
        source: this.defaultLang
      };
      if (targetLanguage !== body.source && environment.production === true && this.callGoogleTranslateAPI) {
        console.log('translate', value);
        if (value) {
          const headers = new HttpHeaders({
            'Content-Type': 'application/json'
          });

          body.q = value;
          body.target = targetLanguage;

          return this.http
            .post<any>(url, body, { headers })
            .pipe(
              map(response => {
                this.apiCount++;
                // BehaviorSubject to get total count of API call
                this.stateService.getApiCount(this.apiCount);
                return response.data.translations[0].translatedText;
              })
            );
        } else {
          return of(value);
        }
      } else {
        return of(value);
      }
    } else {
      return of(value);
    }
  }
}
