import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HandleStateForPipeService {
  // Initialize the BehaviorSubject with an initial value (false in this case)
  private stateSubject = new BehaviorSubject<boolean>(false);
  private apiCountSubject = new BehaviorSubject<number>(0);

  // Expose the BehaviorSubject as an observable for components to subscribe to
  state$ = this.stateSubject.asObservable();
  apiCount$ = this.apiCountSubject.asObservable();

  constructor() {}

  // Method to update the state
  updateState(newState: boolean): void {
    this.stateSubject.next(newState);
  }

  getApiCount(apiCount: any) {
    this.apiCountSubject.next(apiCount);
  }
}
