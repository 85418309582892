import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { EventsService } from '../events.service';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import moment from 'moment';
import { MatSnackBar } from '@angular/material';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { AddEventDatesComponent } from '../add-event-dates/add-event-dates.component';
import { AuthenticationService, CredentialsService } from '@app/core';
import { ClientResolverService } from '@app/core/client-resolver.service';

@Component({
  selector: 'app-all-dates',
  templateUrl: './all-dates.component.html',
  styleUrls: ['./all-dates.component.scss']
})
export class AllDatesComponent implements OnInit {
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild('myTable', { static: false }) Mobiletable: any;
  @ViewChild('myTable2', { static: false }) Mobiletable2: any;
  rows: any[] = [];
  expanded: any = {};
  editEventData: any;
  activeDates: any;
  archiveDates: any;
  startDate: string;
  endDate: string;
  isActiveEventDetails: boolean;
  isArchiveEventDetails: boolean;
  activeDateForm: FormGroup;
  archiveDateForm: FormGroup;
  somethingWentWrong: string;
  deleteEventModelButtonText: string;
  invalidDateCombination: string;
  updateEventDateSuccessMessage: string;
  deleteEventDatesModelTitle: string;
  deleteEventDateModelMessage: string;
  deleteEventDateSuccessMessage: string;
  activeClient: any;
  activeDateLoader: boolean = true;
  archiveDateLoader: boolean = true;

  private ngUnsubscribe: Subject<any> = new Subject();
  constructor(
    private eventsService: EventsService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private snackBar: MatSnackBar,
    public translate: TranslateService,
    private router: Router,
    private authService: AuthenticationService,
    private clientResolver: ClientResolverService
  ) {
    this.activeDateForm = this.formBuilder.group({
      activeSearchName: [''],
      activeStartDate: [''],
      activeEndDate: ['']
    });
    this.archiveDateForm = this.formBuilder.group({
      archiveSearchName: [''],
      archiveStartDate: [''],
      archiveEndDate: ['']
    });
    this.loadTranslation();
    this.translate.onLangChange.subscribe((event: any) => {
      this.loadTranslation();
    });
    this.activeClient = this.clientResolver.client;
  }

  toggleExpandRow(row: any) {
    this.Mobiletable.rowDetail.toggleExpandRow(row);
  }
  toggleExpandRow2(row: any) {
    this.Mobiletable2.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event: any) {}

  ngOnInit() {
    this.activeDates = [];
    this.archiveDates = [];
    this.isActiveEventDetails = false;
    this.isArchiveEventDetails = false;
    this.startDate = moment().format('YYYY-MM-DD');
    this.endDate = moment().format('YYYY-MM-DD');

    this.getAllActiveDates({ startDate: this.startDate, endDate: '', name: '' });
    this.getAllArchiveDates({ startDate: '', endDate: this.endDate, name: '' });
  }

  loadTranslation() {
    this.somethingWentWrong = this.translate.instant('Common.SomethingWentWrong');
    this.invalidDateCombination = this.translate.instant('Events&Tickets.AllDates.InvalidDateCombination');
    this.updateEventDateSuccessMessage = this.translate.instant(
      'Events&Tickets.AllDates.UpdateEventDateSuccessMessages'
    );
    this.deleteEventDatesModelTitle = this.translate.instant('Events&Tickets.AllDates.DeleteEventDatesModelTitle');
    this.deleteEventDateModelMessage = this.translate.instant('Events&Tickets.AllDates.DeleteEventDateModelMessage');
    this.deleteEventDateSuccessMessage = this.translate.instant(
      'Events&Tickets.AllDates.DeleteEventDateSuccessMessage'
    );
    this.deleteEventModelButtonText = this.translate.instant('Common.Delete');
  }

  getAllActiveDates(params: any) {
    this.activeDateLoader = true;
    this.eventsService.getAllDates('active', params).subscribe(
      (dates: any) => {
        dates.map((item: any) => {
          if (item && item.ticketDates) {
            item.ticketDates.map((item2: any) => {
              item2.sold = 0;
              item2.redeemed = 0;
              item2 &&
                item2.allTickets.map((item3: any) => {
                  item2.sold += 1;
                  if (item3.redeemedAt) {
                    item2.redeemed += 1;
                  }
                });
            });
          }
        });
        this.activeDates = dates;
        this.activeDateLoader = false;
      },
      err => {
        this.activeDateLoader = false;
        this.snackBar.open(this.somethingWentWrong, '', {
          duration: 2000,
          panelClass: ['snackbar-error']
        });
      }
    );
  }

  getAllArchiveDates(params: any) {
    this.archiveDateLoader = true;
    this.eventsService.getAllDates('archive', params).subscribe(
      (dates: any) => {
        this.archiveDates = dates;
        dates.map((item: any) => {
          if (item && item.ticketDates) {
            item.ticketDates.map((item2: any) => {
              item2.sold = 0;
              item2.redeemed = 0;
              item2 &&
                item2.allTickets.map((item3: any) => {
                  item2.sold += 1;
                  if (item3.redeemedAt) {
                    item2.redeemed += 1;
                  }
                });
            });
          }
        });
        this.archiveDateLoader = false;
      },
      err => {
        this.archiveDateLoader = false;
        this.snackBar.open(this.somethingWentWrong, '', {
          duration: 2000,
          panelClass: ['snackbar-error']
        });
      }
    );
  }

  activeFilter() {
    const formData = {
      startDate: this.activeDateForm.value.activeStartDate
        ? moment(this.activeDateForm.value.activeStartDate).format('YYYY-MM-DD')
        : this.startDate,
      endDate: this.activeDateForm.value.activeEndDate
        ? moment(this.activeDateForm.value.activeEndDate).format('YYYY-MM-DD')
        : '',
      name: this.activeDateForm.value.activeSearchName
    };

    if (formData.startDate && formData.endDate && !moment(formData.endDate).isSameOrAfter(formData.startDate)) {
      return this.snackBar.open(this.invalidDateCombination, '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
    }

    this.getAllActiveDates(formData);
  }

  archiveFilter() {
    const formData = {
      startDate: this.archiveDateForm.value.archiveStartDate
        ? moment(this.archiveDateForm.value.archiveStartDate).format('YYYY-MM-DD')
        : '',
      endDate: this.archiveDateForm.value.archiveEndDate
        ? moment(this.archiveDateForm.value.archiveEndDate).format('YYYY-MM-DD')
        : this.endDate,
      name: this.archiveDateForm.value.archiveSearchName
    };

    if (formData.startDate && formData.endDate && !moment(formData.endDate).isSameOrAfter(formData.startDate)) {
      return this.snackBar.open(this.invalidDateCombination, '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
    }

    this.getAllArchiveDates(formData);
  }

  editEvent(event: any, type: string) {
    this.router.navigate([`client/${this.authService.activeClientId}/events/edit/eventId/${event.id}`]);
  }

  updateDate(dateInfo: any, event: any) {
    const newEvent = { ...event };
    newEvent['dateInfo'] = dateInfo;
    const modalRef = this.modalService.open(AddEventDatesComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.eventData = { ...newEvent };
    modalRef.componentInstance.passNewEventDate.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      const snackBarRef = this.snackBar.open(this.updateEventDateSuccessMessage, 'Ok', {
        duration: 3000,
        panelClass: ['snackbar-success']
      });

      this.getAllActiveDates({ startDate: this.startDate, endDate: '', name: '' });
      this.getAllArchiveDates({ startDate: '', endDate: this.endDate, name: '' });

      modalRef.close();
    });
  }

  cancelEvent(type: string) {
    this.getAllActiveDates({ startDate: this.startDate, endDate: '', name: '' });
    this.getAllArchiveDates({ startDate: '', endDate: this.endDate, name: '' });
    switch (type) {
      case 'active':
        this.isActiveEventDetails = false;
        break;
      case 'archive':
        this.isArchiveEventDetails = false;
        break;
    }
  }

  removeEventDate(dateId: number, eventId: number, type: string) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = this.deleteEventDatesModelTitle;
    modalRef.componentInstance.message = this.deleteEventDateModelMessage;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = this.deleteEventModelButtonText;
    modalRef.result.then(
      (result: any) => {
        if (result === 'ok') {
          this.eventsService.removeEventDate(dateId, eventId).subscribe(
            (data: any) => {
              this.snackBar.open(this.deleteEventDateSuccessMessage, '', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
              switch (type) {
                case 'active':
                  this.activeDates = this.activeDates.filter((event: any) => event.id !== dateId);
                  break;
                case 'archive':
                  this.archiveDates = this.archiveDates.filter((event: any) => event.id !== dateId);
                  break;
              }
            },
            (err: any) => {
              this.snackBar.open(this.somethingWentWrong, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
              });
            }
          );
        }
      },
      () => {}
    );
  }

  preview(eventId: number, dateId: number) {
    window.open(`${environment.ticketServerUrl}/event/${eventId}/date/${dateId}`, '_blank');
  }
  downloadPdf(data: any) {
    data.clientName = this.activeClient.name;
    this.eventsService.downloadEventTicketDetailsPdf(data, 'pdf').subscribe(
      (res: any) => {
        const blobdata = new Blob([new Uint8Array(res.data)], { type: 'application/pdf' });
        // const fileURL = URL.createObjectURL(blobdata);
        // window.open(fileURL, '_blank');
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blobdata);
        link.download = 'all-tickets';
        document.body.append(link);
        link.click();
        link.remove();
        this.snackBar.open('PDF is generated successfully.', '', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      },
      err => {
        const errMsg = err && err.error.msg ? err.error.msg : this.somethingWentWrong;
        this.snackBar.open(errMsg, '', {
          duration: 2000,
          panelClass: ['snackbar-error']
        });
      }
    );
  }

  downloadCSV(data: any) {
    data.clientName = this.activeClient.name;
    this.eventsService.downloadEventTicketDetailsPdf(data, 'csv').subscribe(
      (res: any) => {
        // Convert JSON to CSV
        const csvContent = this.convertToCSV(res);
        // Create a Blob object with the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv' });

        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = 'all-tickets';

        // Trigger the download link programmatically
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        this.snackBar.open('CSV is generated successfully.', '', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      },
      err => {
        const errMsg = err && err.error.msg ? err.error.msg : this.somethingWentWrong;
        this.snackBar.open(errMsg, '', {
          duration: 2000,
          panelClass: ['snackbar-error']
        });
      }
    );
  }

  convertToCSV(jsonData: any) {
    let ticketData = jsonData.ticketData;
    // Define the CSV column headers
    const headers = Object.keys(ticketData[0]);
    // let headersCap = headers.map(item => item.replace(/^\w/, char => char.toUpperCase()));
    // Convert JSON to CSV content
    const csvContent = [
      jsonData.eventName,
      jsonData.clientName,
      jsonData.date,
      '',
      headers.join(','),
      ...ticketData.map((obj: any) => headers.map(key => obj[key]).join(',')),
      `Total, , ,  ${jsonData.totalPrice}`
    ].join('\n');
    return csvContent;
  }
}
