import { Component, OnInit } from '@angular/core';
import { AddUserComponent } from './add-user/add-user.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material';
import { ClientService } from '@app/core/client.service';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-user-permissions',
  templateUrl: './user-permissions.component.html',
  styleUrls: ['./user-permissions.component.scss']
})
export class UserPermissionsComponent implements OnInit {
  users: any = [];

  constructor(private modalService: NgbModal, private snackBar: MatSnackBar, private clientService: ClientService) {}

  ngOnInit() {
    this.getUsers();
  }

  addUser() {
    const modalRef = this.modalService.open(AddUserComponent, { size: 'lg' });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      const snackBarRef = this.snackBar.open('Benutzer erfolgreich hinzugefügt', 'Ok', {
        duration: 3000
      });
      this.getUsers();
      modalRef.close();
    });
  }

  editUser(user: any) {
    const modalRef = this.modalService.open(AddUserComponent, { size: 'lg' });
    modalRef.componentInstance.editUser = { ...user };
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      const snackBarRef = this.snackBar.open('Benutzer erfolgreich gespeichert', 'Ok', {
        duration: 3000
      });
      this.getUsers();
      modalRef.close();
    });
  }

  getUsers() {
    this.clientService.getModerators().subscribe((users: any) => {
      this.users = users;
    });
  }

  deleteUser(user: any) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Ausgewählten Benutzer löschen';
    modalRef.componentInstance.message = `Wollen Sie den Benutzer ${user.user.email} wirklich löschen?`;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      result => {
        if (result === 'ok') {
          this.clientService.deleteModerator(user.user.id).subscribe((res: any) => {
            if (res) {
              const snackBarRef = this.snackBar.open('Benutzer erfolgreich gelöscht', 'Ok', {
                duration: 3000
              });
              this.getUsers();
            }
          });
        }
      },
      () => {}
    );
  }
}
