import { Component, OnInit } from '@angular/core';
import { ClientService } from '@app/core/client.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material';
import moment from 'moment';
moment.locale('de');

@Component({
  selector: 'app-settings-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent implements OnInit {
  loading: boolean = true;
  imprint: any = {};

  form: any = {
    targetObject: 'imprint',
    fields: [],
    settings: {
      // type: select, textarea, checkbox, input: (text, email) usw., label, minlength, maxlength, min, max, mandatory, info, checkboxText, checkboxValue: 1, groupClass, labelClass, inputClass
      // col- // sm: 6, md: 6, lg: 4, xl: 3
      // type: textarea // rows: 3
      betriebId: {
        type: 'hidden',
        label: 'ID',
        minlength: 1,
        maxlength: 11,
        mandatory: true
      },
      imprName: {
        type: 'text',
        label: 'Name / Firma',
        minlength: 3,
        maxlength: 64,
        mandatory: true
      },
      imprAddress: {
        type: 'text',
        label: 'Strasse',
        maxlength: 64,
        mandatory: true
      },
      imprPlzOrt: {
        type: 'text',
        label: 'PLZ + Ort',
        maxlength: 64,
        mandatory: true
      },
      /*
      imprGmbH: {
        groupClass: 'form-check',
        type: 'checkbox', // groupClass: 'form-check',
        label: 'GmbH?',
        mandatory: false,
        checkboxText: 'ja / nein',
        checkboxValue: '1'
      },
      */
      imprGmbH: {
        groupClass: 'form-select',
        type: 'select', // groupClass: 'form-check',
        label: 'GmbH',
        mandatory: false,
        options: [
          {
            value: 1,
            text: 'Ja',
            default: true
          },
          {
            value: 0,
            text: 'Nein',
            default: false
          }
        ]
      },
      imprGeschaeftsfuehrung: {
        type: 'text',
        label: 'Geschäftsführer',
        maxlength: 128,
        mandatory: true
      },
      imprTelefon: {
        type: 'text',
        label: 'Telefon',
        maxlength: 32,
        mandatory: false
      },
      imprEmail: {
        type: 'text',
        label: 'E-Mail',
        maxlength: 64,
        mandatory: false
      },
      imprRegistergericht: {
        type: 'text',
        label: 'Registergericht',
        maxlength: 128,
        mandatory: false
      },
      imprRegisternummer: {
        type: 'text',
        label: 'Registernummer',
        maxlength: 24,
        mandatory: false
      },
      imprSteuernummer: {
        type: 'text',
        label: 'Steuernummer',
        maxlength: 16,
        mandatory: false
      },
      imprUstId: {
        type: 'text',
        label: 'Umsatzsteuer-ID',
        maxlength: 16,
        mandatory: false
      },
      imprAufsichtsbehoerde: {
        type: 'text',
        label: 'Aufsichtsbehörde',
        maxlength: 128,
        mandatory: false
      },
      freeText: {
        sm: 12,
        md: 6,
        lg: 6,
        xl: 6,
        type: 'textarea',
        rows: 5,
        label: 'Freitext',
        maxlength: 512,
        mandatory: false
      },
      mediaSource: {
        sm: 12,
        md: 6,
        lg: 6,
        xl: 6,
        type: 'textarea',
        rows: 5,
        label: 'Quellangaben für Fotos/Bilder',
        maxlength: 1024,
        mandatory: false
      },
      websitePlaningAndImplementation: {
        sm: 12,
        md: 6,
        lg: 6,
        xl: 6,
        type: 'textarea',
        rows: 5,
        label: 'Design & Programmierung',
        maxlength: 1024,
        mandatory: false
      },
      liabilityDisclaimer: {
        sm: 12,
        md: 6,
        lg: 6,
        xl: 6,
        type: 'textarea',
        rows: 5,
        label: 'Haftungsausschluss',
        mandatory: false
      }
    }
  };

  constructor(private clientService: ClientService, private modalService: NgbModal, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.setFormFields(this.form);
    this.getImprint();
  }

  setFormFields(form: any) {
    Object.keys(form.settings).forEach(key => {
      form.fields.push(key);
    });
  }

  getImprint() {
    this.clientService.getImprintOfClient().subscribe((res: any) => {
      if (res.imprGmbH == '0') {
        // res.imprGmbH = false;
        this.onChange({ target: { value: '0' } }, 'imprGmbH');
      }
      this.imprint = res || {};
      this.loading = false;
    });
  }

  validateForm(form: any) {
    let isValid = true;
    form.fields.forEach((field: any) => {
      const input = this[form.targetObject][field];
      if (form.settings[field].mandatory) {
        if (!input || input == '') {
          isValid = false;
        }
      }
      if (form.settings[field].minlength > 0) {
        if (!input || input.length < form.settings[field].minlength) {
          isValid = false;
        }
      }
      if (form.settings[field].maxlength > 0) {
        if (input) {
          if (input.length > form.settings[field].maxlength) {
            isValid = false;
          }
        }
      }
    });

    if (!isValid) {
      const snackBarRef = this.snackBar.open('Bitte füllen Sie alle Pflichtfelder aus!', 'Ok', {
        duration: 3000
      });
    }
    return isValid;
  }
  save(imprint: any) {
    if (this.validateForm(this.form)) {
      this.clientService.saveImprintOfClient(imprint).subscribe((res: any) => {
        if (res.imprGmbH == '0') {
          // res.imprGmbH = false;
          this.onChange({ target: { value: '0' } }, 'imprGmbH');
        }
        this.imprint = res || {};
        const snackBarRef = this.snackBar.open('Änderungen wurden erfolgreich übernommen!', 'Ok', {
          duration: 3000
        });
      });
    }
  }

  onChange(event: any, field: any) {
    if (field === 'imprGmbH') {
      if (event.target.value === '1') {
        this.form.settings.imprGeschaeftsfuehrung.label = 'Geschäftsführer';
      }
      if (event.target.value === '0') {
        this.form.settings.imprGeschaeftsfuehrung.label = 'Inhaber';
      }
    }
    console.log('onChange', event, field);
  }
}
