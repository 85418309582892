import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { DeveloperComponent } from './developer.component';
import { ReservationComponent } from './reservation/reservation.component';
import { ApikeyComponent } from './apikey/apikey.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { ReservationIntegrationComponent } from '@app/reservation/reservation-integration/reservation-integration.component';
const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'client/:clientId/developer',
      data: {
        breadcrumb: 'Developer'
      },
      resolve: {
        someKey: ClientResolverService
      },
      children: [
        {
          path: '',
          redirectTo: 'api/key',
          pathMatch: 'full'
        },
        {
          path: 'api',
          children: [
            {
              path: 'key',
              component: ApikeyComponent,
              canActivate: [NgxPermissionsGuard],
              data: {
                title: 'API-Key',
                breadcrumb: 'API-Key',
                permissions: {
                  only: ['admin', 'viewAPIKey'],
                  redirectTo: {
                    navigationCommands: ['403'],
                    navigationExtras: {
                      replaceUrl: false
                    }
                  }
                }
              }
            }
          ]
        },
        {
          path: 'reservation',
          component: ReservationIntegrationComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Reservierung',
            breadcrumb: 'Reservierung Integration',
            permissions: {
              only: ['admin', 'viewWidgets'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class DeveloperRoutingModule {}
