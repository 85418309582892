import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpEvent } from '@angular/common/http';
import { CredentialsService, AuthenticationService } from '@app/core';
import { environment } from '@env/environment';
import { last, map, tap } from 'rxjs/operators';
import { of, Observable, BehaviorSubject } from 'rxjs';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class MenucardsService {
  public progressSource = new BehaviorSubject<number>(0);

  constructor(
    private http: HttpClient,
    private credentialsService: CredentialsService,
    private authService: AuthenticationService
  ) {}

  getClientMenus() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `menucard/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((menucards: any) => {
          return menucards;
        })
      );
  }

  addMenucard(menucardData: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    if (!menucardData.clientId) {
      menucardData.clientId = this.authService.activeClientId;
    }

    const formData = this.objectToFormData(menucardData);

    return this.http
      .put<any>(environment.serverUrl + `menucard/`, formData, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  saveMenucard(menucardData: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    if (!menucardData.clientId) {
      menucardData.clientId = this.authService.activeClientId;
    }

    const formData = this.objectToFormData(menucardData);

    return this.http
      .post<any>(environment.serverUrl + `menucard/`, formData, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteMenucard(menuId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `menucard/${menuId}`, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getClientChoices() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `menucard/choices/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((choices: any) => {
          return choices;
        })
      );
  }

  getClientSizes() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `menucard/sizes/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((sizes: any) => {
          return sizes;
        })
      );
  }

  getMenuCategories(menuId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `menucard/categories/` + menuId, {
        headers: headers
      })
      .pipe(
        map((categories: any) => {
          return categories;
        })
      );
  }

  addChoice(choiceData: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    if (!choiceData.clientId) {
      choiceData.clientId = this.authService.activeClientId;
    }
    return this.http
      .put<any>(environment.serverUrl + `menucard/choices/`, choiceData, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  saveChoice(choiceData: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(environment.serverUrl + `menucard/choices/`, choiceData, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteChoice(choiceId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `menucard/choices/${choiceId}`, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  addCategory(menuId: number, categoryData: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    categoryData.menuId = menuId;
    if (!categoryData.clientId) {
      categoryData.clientId = this.authService.activeClientId;
    }

    const formData: any = new FormData();
    // tslint:disable-next-line:forin
    for (const key in categoryData) {
      formData.append(key, categoryData[key]);
    }

    return this.http
      .put<any>(environment.serverUrl + `menucard/categories/`, formData, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  saveCategory(categoryData: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });

    if (!categoryData.clientId) {
      categoryData.clientId = this.authService.activeClientId;
    }

    const formData: any = new FormData();
    // tslint:disable-next-line:forin
    for (const key in categoryData) {
      formData.append(key, categoryData[key]);
    }

    return this.http
      .post<any>(environment.serverUrl + `menucard/categories/`, formData, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteCategory(categoryId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `menucard/categories/${categoryId}`, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  addCategoryItem(menuId: number, categoryId: number, itemId: number, sortingOrder: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    const categoryItemData = {
      menuId,
      categoryId,
      itemId,
      sortingOrder,
      clientId: this.authService.activeClientId
    };
    return this.http
      .put<any>(environment.serverUrl + `menucard/categories/item/`, categoryItemData, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteCategoryItem(itemId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `menucard/categories/item/${itemId}`, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  addChoiceItem(choiceId: number, itemId: number, sortingOrder: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    const choiceItemData = {
      choiceId,
      itemId,
      sortingOrder,
      clientId: this.authService.activeClientId
    };
    return this.http
      .put<any>(environment.serverUrl + `menucard/choices/item/`, choiceItemData, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteChoiceItem(choiceId: number, itemId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `menucard/choices/item/${choiceId}/${itemId}`, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteItemChoice(itemId: number, choiceId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `menucard/item/choices/${itemId}/${choiceId}`, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteItemSize(itemId: number, sizeId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `menucard/item/sizes/${itemId}/${sizeId}`, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  addItemChoice(itemId: number, choiceId: number, sortingOrder: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    const itemChoiceData = {
      itemId,
      choiceId,
      sortingOrder,
      clientId: this.authService.activeClientId
    };
    return this.http
      .put<any>(environment.serverUrl + `menucard/item/choices/`, itemChoiceData, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  addItemSize(itemId: number, sizeId: number, sortingOrder: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    const itemSizeData = {
      itemId,
      sizeId,
      sortingOrder,
      clientId: this.authService.activeClientId
    };
    return this.http
      .put<any>(environment.serverUrl + `menucard/item/sizes/`, itemSizeData, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getClientItems() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `menucard/items/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((items: any) => {
          return items;
        })
      );
  }

  /*
  objectToFormData(item: any, form: any = null, namespace: any = null) {
    const formData: any = form || new FormData();
    let formKey;
    // tslint:disable-next-line:forin
    for (const key in item) {
      if (item.hasOwnProperty(key)) {
        if (namespace) {
          formKey = namespace + '[' + key + ']';
        } else {
          formKey = key;
        }

        // if the property is an object, but not a File,
        // use recursivity.
        if (typeof item[key] === 'object' && !(item[key] instanceof File)) {
          this.objectToFormData(item[key], formData, key);
        } else {
          // if it's a string or a File object
          let itemKey = item[key];
          if (typeof itemKey === 'boolean' && itemKey === true) {
            itemKey = 1;
          }
          if (typeof itemKey === 'boolean' && itemKey === false) {
            itemKey = 0;
          }
          if (itemKey !== undefined) {
            formData.append(formKey, itemKey);
          }
        }
      }
    }
    return formData;
  }
  */

  objectToFormData(model: any, form: FormData = null, namespace: string = ''): FormData {
    const formData = form || new FormData();
    let formKey: string;

    for (const propertyName in model) {
      if (!model.hasOwnProperty(propertyName)) {
        continue;
      }
      formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
      if (model[propertyName] instanceof Date) {
        formData.append(formKey, model[propertyName].toISOString());
      } else if (model[propertyName] instanceof Array) {
        model[propertyName].forEach((element: any, index: number) => {
          const tempFormKey = `${formKey}[${index}]`;
          this.objectToFormData(element, formData, tempFormKey);
        });
      } else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File)) {
        this.objectToFormData(model[propertyName], formData, formKey);
      } else {
        let itemKey = model[propertyName];
        if (typeof itemKey === 'boolean' && itemKey === true) {
          itemKey = 1;
        }
        if (typeof itemKey === 'boolean' && itemKey === false) {
          itemKey = 0;
        }
        if (itemKey !== undefined) {
          formData.append(formKey, itemKey);
        }
      }
    }
    return formData;
  }

  saveItem(item: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    if (!item.clientId) {
      item.clientId = this.authService.activeClientId;
    }

    const formData = this.objectToFormData(item);

    return this.http
      .post<any>(environment.serverUrl + `menucard/item/`, formData, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  saveItemFromImport(item: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    if (!item.clientId) {
      item.clientId = this.authService.activeClientId;
    }
    if (!item.type) {
      item.type = 'product';
    }

    return this.http
      .post<any>(environment.serverUrl + `menucard/item/`, item, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteItem(itemId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `menucard/item/${itemId}`, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteItems(itemIdsArray: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(environment.serverUrl + `menucard/delete-items/`, itemIdsArray, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  addSize(data: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    data.clientId = this.authService.activeClientId;
    return this.http
      .put<any>(environment.serverUrl + `menucard/sizes/`, data, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  saveSize(data: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(environment.serverUrl + `menucard/sizes/`, data, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteSize(sizeId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `menucard/sizes/${sizeId}`, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  addPricecategory(data: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    const priceCategoryData = {
      name: data.name,
      clientId: this.authService.activeClientId
    };
    return this.http
      .put<any>(environment.serverUrl + `menucard/prices/category/`, priceCategoryData, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  savePricecategory(data: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    const priceCategoryData = {
      id: data.id,
      name: data.name
    };
    return this.http
      .post<any>(environment.serverUrl + `menucard/prices/category/`, priceCategoryData, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deletePricecategory(priceCategoryId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `menucard/prices/category/${priceCategoryId}`, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getPriceCategories() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `menucard/prices/categories/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((cats: any) => {
          return cats;
        })
      );
  }

  uploadItemPhoto(formData: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    formData.append('clientId', this.authService.activeClientId);
    return this.http
      .post<any>(environment.serverUrl + `menucard/item/photo`, formData, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  reorderMenucards(itemsArray: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `menucard/reorder`,
        { clientId: this.authService.activeClientId, items: itemsArray },
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  reorderMenucardItems(itemsArray: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `menucard/item/reorder`,
        { clientId: this.authService.activeClientId, items: itemsArray },
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  reorderCategoryItems(menuId: number, categoryId: number, itemsArray: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `menucard/categories/item/reorder`,
        { menuId, categoryId, items: itemsArray },
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  reorderItemSize(sizesArray: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(environment.serverUrl + `menucard/item/sizes/reorder`, sizesArray, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  reorderItemChoice(itemId: number, choicesArray: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `menucard/item/choices/reorder`,
        { itemId, choices: choicesArray },
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  reorderCategories(categoriesArray: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(environment.serverUrl + `menucard/categories/reorder`, categoriesArray, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  reorderChoiceItems(choiceId: number, itemsArray: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `menucard/choices/item/reorder`,
        { choiceId, items: itemsArray },
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  addTag(color: string, name: string) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    const data = {
      color,
      name,
      clientId: this.authService.activeClientId
    };
    return this.http
      .post<any>(environment.serverUrl + `menucard/tags/`, data, {
        headers: headers
      })
      .pipe(
        map((tag: any) => {
          return tag;
        })
      );
  }

  getTags() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `menucard/tags/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((tags: any) => {
          return tags;
        })
      );
  }

  deleteTag(tagId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `menucard/tags/${tagId}`, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
