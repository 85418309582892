import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { CredentialsService } from './credentials.service';
import { NgxRolesService, NgxPermissionsService } from 'ngx-permissions';
import { AuthenticationService } from './authentication.service';
import { InitPermissionsService } from '../init-permissions.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private credentialsService: CredentialsService,
    private rolesService: NgxRolesService,
    private authService: AuthenticationService,
    private permissionsService: NgxPermissionsService,
    private initPermissionService: InitPermissionsService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    console.log('can activate');
    if (this.credentialsService.isAuthenticated()) {
      // this.getPermissions();
      return true;
    }

    this.router.navigate(['/login'], { queryParams: { redirect: state.url }, replaceUrl: true });
    return false;
  }
}
