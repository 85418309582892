import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { ContactDetailsComponent } from './contact-details/contact-details.component';
import { GenerallyComponent } from './generally/generally.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'client/:clientId/my-entry',
      data: {
        breadcrumb: 'Mein Eintrag'
      },
      resolve: {
        someKey: ClientResolverService
      },
      children: [
        {
          path: 'allgemein',
          component: GenerallyComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Allgemein',
            breadcrumb: 'Allgemein',
            permissions: {
              only: ['admin', 'myEntry'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        },
        {
          path: 'kontaktdaten',
          component: ContactDetailsComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Kontaktdaten',
            breadcrumb: 'Kontaktdaten',
            permissions: {
              only: ['admin', 'myEntry'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyEntryRoutingModule {}
