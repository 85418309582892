import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@app/shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatStepperModule,
  MatDatepickerModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatCheckboxModule,
  MatTooltipModule,
  MatButtonModule,
  MatRadioModule,
  MatSlideToggleModule
} from '@angular/material';
import { Ng5SliderModule } from 'ng5-slider';
import { DateFnsModule } from 'ngx-date-fns';
import { ControlErrorComponent } from '@app/shared/control-error/control-error.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ReservationComponent } from '../../../../reservation/src/app/reservation/reservation.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { NgxPayPalModule } from 'ngx-paypal';
import { InfoTextModule } from '../../../../reservation/src/app/reservation/info-text/info-text.module';
import { GoogleTranslateModule } from '../../../../reservation/src/app/reservation/google-translate/google-translate.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatButtonModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    Ng5SliderModule,
    MatCheckboxModule,
    NgSelectModule,
    FlexLayoutModule,
    AngularSvgIconModule,
    NgxPayPalModule,
    InfoTextModule,
    GoogleTranslateModule,
    MatSlideToggleModule,
    Angular2PromiseButtonModule.forRoot({
      spinnerTpl: '<span class="fas fa-spinner fa-spin button-loading"></span>'
    }),
    DeviceDetectorModule.forRoot(),
    RouterModule.forRoot([{ path: '', component: ReservationComponent }]),
    DateFnsModule.forRoot()
  ],
  declarations: [ReservationComponent],
  entryComponents: [ControlErrorComponent],
  exports: [ReservationComponent]
})
export class ReservationWidgetWrapperModule {}
