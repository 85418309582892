import { Component, OnInit } from '@angular/core';
import { ClientService } from '@app/core/client.service';
import { saveAs } from 'file-saver';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { id } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-check-ins',
  templateUrl: './check-ins.component.html',
  styleUrls: ['./check-ins.component.scss']
})
export class CheckInsComponent implements OnInit {
  checkins: any;
  date: any;
  client: any;
  activeClient: any;

  constructor(private clientService: ClientService, private clientResolver: ClientResolverService) {
    this.activeClient = this.clientResolver.client;
  }

  ngOnInit() {
    this.getCheckins();
  }

  getCheckins() {
    let date = '';
    if (this.date) {
      date = this.date.format('YYYY-MM-DD');
    }
    this.clientService.getCheckIns(date).subscribe(checkins => {
      this.checkins = checkins;
    });
  }

  updateCheckIns() {
    this.getCheckins();
  }

  downloadCSV() {
    let data = this.checkins;
    data = data.map((entry: any) => {
      if (entry.reservation) {
        entry.createdAt = entry.reservation.reservedFor;
      }
      return entry;
    });
    data.forEach((v: any) => {
      delete v.id;
      delete v.reservationId;
      delete v.reservation;
      delete v.clientId;
      delete v.updatedAt;
    });
    const replacer = (key: string, value: any) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    const csv = data.map((row: any) => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const blob = new Blob([csvArray], { type: 'text/csv' });
    saveAs(blob, 'checkins.csv');
  }
}
