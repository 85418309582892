import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { VouchersService } from '../vouchers.service';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import 'rxjs/add/operator/takeUntil';
import { MatSnackBar } from '@angular/material';
import { Subscription, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { PageEvent } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';

import { ChangeEmailAddressComponent } from './change-email-address/change-email-address.component';
import { ChangeVoucherPostCodes } from './change-voucher-post-codes/change-voucher-post-codes.component';

import moment from 'moment';
moment.locale('de');

@Component({
  selector: 'app-vouchers-orders',
  templateUrl: './vouchers-orders.component.html',
  styleUrls: ['./vouchers-orders.component.scss']
})
export class VouchersOrdersComponent implements OnInit, OnDestroy {
  orders: any = [];
  ordersMobile: any = [];
  allOrdersCopy: any = [];

  loading: boolean = true;

  tableLimit: number = 50;
  tablePages: any[] = [];
  tableOffset: number = 1;
  filterBy: string = '';
  filterId: number = 0;
  filterByValue: string = '';
  myControl = new FormControl();

  dates: any = {
    from: '',
    to: ''
  };

  private _ordersSub: Subscription;
  private ngUnsubscribe: Subject<any> = new Subject();
  lowValue: number = 0;
  highValue: number = 50;
  pageSize: number = 50;
  isPostalVouchersClicked: boolean = false;
  filterStats: any = {
    orders: {
      count: 0,
      total: 0
    },
    cancelations: {
      count: 0,
      total: 0
    }
  };
  finalOrders: any = [];

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    private voucherService: VouchersService,
    private modalService: NgbModal,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute
  ) {}

  changeTableOffset(num: number = 0) {
    this.loading = true;
    this.table.offset = num;

    setTimeout(
      function(_this: any) {
        _this.loading = false;
      },
      3000,
      this
    );
  }

  ngOnInit() {
    this.getTransactions(true);
  }

  ngOnDestroy() {}

  getTransactions(initialStart: boolean = false) {
    console.log('getTransactions', initialStart, this.dates);
    this.loading = true;
    if (initialStart) {
      this.dates.from = moment()
        .subtract(9, 'months')
        .subtract(7, 'days')
        .format();
      this.dates.to = moment().format();
    }
    this.voucherService.getVoucherOrders(this.dates.from, this.dates.to, this.filterByValue).subscribe(data => {
      const result = data;
      let finalResult: any[] = [];
      let i = 0;
      result.map((item: any, key: any) => {
        let isPresent = false;
        if (finalResult) {
          isPresent = finalResult.some(function(el: any) {
            return el.id === item.id;
          });
        }
        if (!isPresent) {
          const templates: any[] = [];
          let j = 0;
          let totalTemplate = 0;
          data.map(function(data: any, key1: any) {
            if (data.vouchers && data.vouchers.orderId == item.id) {
              if (data.vouchers.templateFileName) {
                totalTemplate++;
              }
              templates[j] = data.vouchers;
              j++;
            }
          });
          if (result[key] !== undefined && templates) {
            result[key].templates = templates;
            result[key].totalTemplate = totalTemplate;
            finalResult[i] = result[key];
            i++;
          }
        }
      });
      this.orders = finalResult;
      if (this.filterBy && this.filterBy != '') {
        this.allOrdersCopy = [];
        this.filterOrdersBy('client', this.filterBy);
      }
      this.setTablePages();
      this.loading = false;
      console.log('getVoucherOrders', this.orders);
    });
  }

  getPostalVouchers(filterByPost: string) {
    this.isPostalVouchersClicked = !this.isPostalVouchersClicked;
    if (this.isPostalVouchersClicked) {
      this.filterByValue = filterByPost;
      this.getTransactions(true);
    } else {
      this.filterByValue = '';
      this.getTransactions(true);
    }
  }

  setShippingDate(order: any) {
    const modalRef = this.modalService.open(ChangeVoucherPostCodes, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.order = order;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      let voucherData: any[] = [];
      receivedEntry.map(function(data: any, key: any) {
        voucherData.push({ ...data, oldId: key });
      });
      // let allOrders = this.orders;
      this.voucherService.setShippingDate(order.id, voucherData).subscribe(
        (result: any) => {
          this.getTransactions(false);
          // this.orders.map(function(data: any, key: any) {
          //   if (data.id == order.id) {
          //     allOrders[key]['shippedDate'] = result.shippedDate;
          //     return;
          //   }
          // });
          // this.orders = allOrders;
          this.snackBar.open('Versanddatum erfolgreich eingestellt.', '', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        },
        err => {
          console.error(55, err);
          this.snackBar.open('Fehler beim Einstellen des Versanddatums.', '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      );
    });
  }

  filterDates(filterBy: string, event: any) {
    this.loading = true;
    const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });
    const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(event.value._d);

    let filterDate = `${year}-${month}-${day} 00:00:00`;
    if (filterBy == 'to') {
      filterDate = `${year}-${month}-${day} 23:59:59`;
    }
    console.log('filterDates', filterDate);

    if (this.allOrdersCopy.length) {
      this.orders = [...this.allOrdersCopy];
      this.allOrdersCopy = [];
    }
    if (filterDate) {
      this.filterOrdersBy(filterBy, filterDate);
    }
  }

  filterVoucherData(result: any) {
    let finalResult: any[] = [];
    let i = 0;
    this.filterStats.orders.total = 0;
    this.filterStats.orders.count = 0;
    this.filterStats.cancelations.total = 0;
    this.filterStats.cancelations.count = 0;
    result.map((item: any, key: any) => {
      let isPresent = false;
      if (finalResult) {
        isPresent = finalResult.some(function(el: any) {
          return el.id === item.id;
        });
      }
      if (!isPresent) {
        const templates: any[] = [];
        let j = 0;
        let totalTemplate = 0;
        result.map(function(data: any, key1: any) {
          if (data.vouchers && data.vouchers.orderId == item.id) {
            if (data.vouchers.templateFileName) {
              totalTemplate++;
            }
            templates[j] = data.vouchers;
            j++;
          }
        });
        if (result[key] !== undefined && templates) {
          result[key].templates = templates;
          result[key].totalTemplate = totalTemplate;
          finalResult[i] = result[key];
          i++;
        }
      }
    });

    for (let item of finalResult) {
      if (item.status === 2) {
        // this.filterStats.cancelations.total += item.total / item.quantity;
        this.filterStats.cancelations.total += parseFloat(item.total);
        this.filterStats.cancelations.count += 1;
      } else if (item.status === 0) {
        // this.filterStats.orders.total += item.total / item.quantity;
        this.filterStats.orders.total += parseFloat(item.total);
        this.filterStats.orders.count += 1;
      }
    }
    this.finalOrders = finalResult;
  }

  setTablePages() {
    this.filterVoucherData(this.orders);
    this.tablePages = [];
    for (let index = 1; index <= this.orders.length / this.tableLimit + 1; index++) {
      this.tablePages.push(index);
    }
    const paginateEvent = {
      previousPageIndex: 0,
      pageSize: this.pageSize,
      pageIndex: 0,
      length: this.orders.length
    };
    this.getPaginatorData(paginateEvent);
    this.loading = false;
  }

  filterOrdersBy(filterBy: string = '', filterVal: string = '') {
    switch (filterBy) {
      case 'from':
        this.dates.from = filterVal;

        this.allOrdersCopy = [...this.orders];
        this.orders = this.allOrdersCopy.filter(function(order: any) {
          if (
            (order.createdAt >= this.dates.from || this.dates.from == '') &&
            (order.createdAt <= this.dates.to || this.dates.to == '')
          ) {
            return order;
          }
        }, this);
        this.orders = [...this.orders];
        this.setTablePages();
        //this.prepareChartData();
        break;

      case 'to':
        this.dates.to = filterVal;
        this.allOrdersCopy = [...this.orders];
        this.orders = this.allOrdersCopy.filter(function(order: any) {
          if (
            (order.createdAt >= this.dates.from || this.dates.from == '') &&
            (order.createdAt <= this.dates.to || this.dates.to == '')
          ) {
            return order;
          }
        }, this);
        this.orders = [...this.orders];
        this.setTablePages();
        //this.prepareChartData();
        break;

      case 'client':
        filterVal = `${filterVal}`;
        this.filterBy = filterVal;
        if (this.allOrdersCopy.length) {
          this.orders = this.allOrdersCopy;
        }
        this.allOrdersCopy = [...this.orders];
        this.orders = this.allOrdersCopy.filter(function(order: any) {
          if (order.client) {
            if (order.client.name.toLowerCase().includes(filterVal.toLowerCase())) {
              return order;
            }
          }
          if (
            order.name.toLowerCase().includes(filterVal.toLowerCase()) ||
            order.email.toLowerCase().includes(filterVal.toLowerCase()) ||
            order.betriebId == filterVal
          ) {
            return order;
          }
        }, this);
        this.orders = [...this.orders];
        this.setTablePages();
        this.table.offset = 0;
        break;

      default:
        this.filterBy = '';
        this.orders = [...this.allOrdersCopy];
        this.setTablePages();
        this.allOrdersCopy = [];
        break;
    }
  }

  setDates(type: string) {
    if (type == 'today') {
      this.dates.from = moment().format();
      this.dates.to = moment().format();
    }
    if (type == 'yesterday') {
      this.dates.from = moment()
        .subtract(1, 'days')
        .format();
      this.dates.to = moment()
        .subtract(1, 'days')
        .format();
    }
    if (type == 'thisWeek') {
      const date = moment();
      this.dates.from = date.startOf('week');
      this.dates.to = moment(this.dates.from).endOf('week');
    }
    if (type == 'lastSeven') {
      this.dates.from = moment()
        .subtract(6, 'days')
        .format();
      this.dates.to = moment().format();
    }
    if (type == 'lastWeek') {
      const date = moment().subtract(1, 'week');
      this.dates.from = date.startOf('week');
      this.dates.to = moment(this.dates.from).endOf('week');
    }
    if (type == 'lastMonth') {
      const date = moment().subtract(1, 'month');
      this.dates.from = date.startOf('month');
      this.dates.to = moment(this.dates.from).endOf('month');
    }
    if (type == 'thisMonth') {
      const date = moment();
      this.dates.from = date.startOf('month');
      this.dates.to = moment(this.dates.from).endOf('month');
    }
    this.getTransactions(false);
  }

  returnPrice(price: any) {
    if (typeof price == 'string') {
      return parseFloat(price.replace(',', '.'));
    } else {
      return price || 0;
    }
  }
  // used to build an array of orders for mobile relevant at any given time
  public getPaginatorData(event: PageEvent): PageEvent {
    this.pageSize = event.pageSize;
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    this.ordersMobile = this.orders.slice(this.lowValue, this.highValue);
    return event;
  }
  sendVoucherPdf(order: any) {
    const modalRef = this.modalService.open(ChangeEmailAddressComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.email = order.email;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      order.email = receivedEntry.email;
      this.voucherService.sendVoucherPdf(order).subscribe(
        (result: any) => {
          this.snackBar.open('Gutschein wurde erfolgreich gesendet an ' + order.email + '!', '', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        },
        (err: any) => {
          console.error(55, err);
          this.snackBar.open('Fehler beim Senden von E-Mails.', '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      );
    });
  }

  showAddress(event: any, order: any) {
    event.target.parentElement.parentElement.blur();
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Details';
    modalRef.componentInstance.message =
      '<div>' +
      (order.email || '') +
      '<br>' +
      (order.name || '') +
      '<br>' +
      (order.street || '') +
      '<br>' +
      (order.zipCode || '') +
      '<br>' +
      (order.city || '') +
      '</div>';
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      (result: any) => {
        if (result === 'ok') {
        }
      },
      () => {}
    );
  }

  cancelVoucher(voucher: any) {
    if (confirm('Bitte bestätigen:\nMöchten Sie den Gutschein wirklich stornieren?')) {
      this.voucherService.cancelVoucherOrder(voucher).subscribe((res: any) => {
        console.log('storno', res);
        const index = this.orders.findIndex((x: any) => x.id === voucher.id);
        if (this.orders[index]) {
          this.orders[index].status = 2;
        }
        this.orders = [...this.orders];
        this.ordersMobile = [...this.orders];
      });
    }
  }
}
