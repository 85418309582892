import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AuthenticationService } from '@app/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  isLoading: boolean;
  error: string;
  hash: string;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      passwordRepeat: ['', Validators.required, PasswordValidation.MatchPassword]
    });

    this.hash = this.route.snapshot.params.hash;

    if (!this.hash) {
      this.router.navigate(['/404']);
    }
  }

  ngOnInit() {}

  resetPassword() {
    if (!this.resetPasswordForm.valid) {
      return false;
    }
    const data = this.resetPasswordForm.value;
    data.hash = this.hash;
    this.authService.resetPassword(data).subscribe(
      (result: any) => {
        this.snackBar.open('Passwort wurde erfolgreich geändert', '', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
        this.router.navigate(['/login']);
      },
      error => {
        this.error = error.error.msg;
      }
    );
  }
}

export class PasswordValidation {
  static MatchPassword(AC: FormControl) {
    return new Promise(resolve => {
      const password = AC.parent.controls['password'].value; // to get value in input tag
      const confirmPassword = AC.value; // to get value in input tag
      if (password === confirmPassword) {
        return resolve(null); // All ok, passwords match!!!
      } else {
        return resolve({ not_match: true });
      }
    });
  }
}
