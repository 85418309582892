import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { DocumentsService } from '../documents.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-short-url',
  templateUrl: './add-short-url.component.html',
  styleUrls: ['./add-short-url.component.scss']
})
export class AddShortURLComponent implements OnInit, OnDestroy {
  @Input() public editShortURL: any; // only if editing
  @Input() public clientData: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  shortURLFormGroup: FormGroup;
  error: string;
  addObservable: any;
  editObservable: any;
  deleteObservable: any;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    public activeModal: NgbActiveModal,
    private documentsService: DocumentsService,
    private snackBar: MatSnackBar,
    public formBuilder: FormBuilder,
    private translateService: TranslateService
  ) {
    this.translateService.onLangChange.subscribe((event: any) => {});
  }

  ngOnInit() {
    this.shortURLFormGroup = this.formBuilder.group({
      url: ['', Validators.required],
      description: ['']
    });

    if (this.editShortURL) {
      this.shortURLFormGroup.addControl('id', new FormControl(this.editShortURL.id, Validators.required));
      this.shortURLFormGroup.get('url').setValue(this.editShortURL.url);
      this.shortURLFormGroup
        .get('description')
        .setValue(this.editShortURL.description ? this.editShortURL.description : '');
    }
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  add() {
    if (!this.shortURLFormGroup.valid) {
      this.snackBar.open(this.translateService.instant('Common.FormInvalid'), '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    this.error = null;
    this.addObservable = this.documentsService
      .addShortURL(this.shortURLFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        (err: any) => {
          this.error = err.error.msg;
        }
      );
  }

  save() {
    if (!this.shortURLFormGroup.valid) {
      this.snackBar.open(this.translateService.instant('Common.FormInvalid'), '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    this.error = null;
    this.editObservable = this.documentsService
      .editShortURL(this.shortURLFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        (err: any) => {
          console.log('err', err);
          this.error = err.error.msg;
        }
      );
  }
}
