import { Component, OnInit, OnDestroy } from '@angular/core';
import { EventsService } from '../events.service';
import { SatDatepickerRangeValue } from 'saturn-datepicker';
import moment from 'moment';
import { Subject } from 'rxjs';
import 'rxjs/add/operator/takeUntil';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ticket-settings',
  templateUrl: './ticket-settings.component.html',
  styleUrls: ['./ticket-settings.component.scss']
})
export class TicketSettingsComponent implements OnInit, OnDestroy {
  notifyByEmailOption: boolean = false;
  notifyByEmail: string = '';
  emailTextError: string = '';
  settings: any = [];
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private eventService: EventsService, private snackBar: MatSnackBar, public translate: TranslateService) {}

  ngOnInit() {
    this.getTicketSettings();
  }

  ngOnDestroy() {}

  changedSettings(isActivate: boolean) {
    this.saveSettings('changeStatus', isActivate);
  }

  getTicketSettings() {
    this.eventService
      .getTicketSettings()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((res: any) => {
        this.settings = res;
        this.notifyByEmailOption = res.notifyByEmail ? true : false;
        this.notifyByEmail = res.notifyByEmail ? res.notifyByEmail : '';
      });
  }
  saveSettings(onChange?: string, isActivate?: boolean) {
    this.emailTextError = '';
    if (this.notifyByEmailOption && (!this.notifyByEmail || this.notifyByEmail.trim() == '')) {
      this.emailTextError = 'Bitte einen Email für die tickets eintragen.';
    }
    //  else if (this.notifyByEmailOption && this.notifyByEmail) {
    //   if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.notifyByEmail)) {
    //     this.emailTextError = 'Ungültige E-Mail Adresse';
    //   }
    // }
    if (this.emailTextError) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
      return false;
    }
    if (onChange) {
      this.settings.isActive = isActivate ? 1 : 0;
      this.settings.updateField = 'isActive';
    } else {
      this.settings.notifyByEmail = this.notifyByEmail;
      this.settings.updateField = 'notifyByEmail';
    }

    this.eventService.saveTicketSettings(this.settings).subscribe(res => {
      this.snackBar.open(this.translate.instant('Events&Tickets.Common.saveTickets'), '', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    });
  }

  changeNotifyEmailOption(value: any) {
    this.emailTextError = '';
    if (!value) {
      this.notifyByEmail = '';
    }
  }
}
