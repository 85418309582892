import { Component, OnInit } from '@angular/core';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { GastroPayService } from '../gastro-pay.service';
import { Router } from '@angular/router';
import subMonths from 'date-fns/sub_months';
import { DxChartModule } from 'devextreme-angular/ui/chart';

@Component({
  selector: 'app-gastro-pay-overview',
  templateUrl: './gastro-pay-overview.component.html',
  styleUrls: ['./gastro-pay-overview.component.scss']
})
export class GastroPayOverviewComponent implements OnInit {
  report: any;
  date: any;
  weekChartData: any;

  constructor(
    private gastropayService: GastroPayService,
    private clientResolver: ClientResolverService,
    private router: Router
  ) {
    if (!this.clientResolver.client.gastropay) {
      this.router.navigate(['/client/' + this.clientResolver.client.id + '/gastropay']);
    }
  }

  ngOnInit() {
    this.getReport(new Date('2018-01-01'), new Date('2018-10-08'));
    this.getWeekChart(new Date('2018-01-01'), new Date('2018-10-08'));
  }

  getReport(from: Date, to: Date) {
    this.gastropayService.getReport(from, to).subscribe(report => {
      this.report = report;
    });
  }

  getWeekChart(from: Date, to: Date) {
    this.gastropayService.getChartData(from, to, 'month').subscribe(data => {
      this.weekChartData = data;
    });
  }

  customizeChartTooltip(arg: any) {
    return {
      text: arg.value + ' €'
    };
  }
}
