import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { NewCompanyService } from './new-company.service';
import moment from 'moment';
import { InitPermissionsService } from '@app/core/init-permissions.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from '@app/events/events.service';

@Component({
  selector: 'app-new-company',
  templateUrl: './new-company.component.html',
  styleUrls: ['./new-company.component.scss']
})
export class NewCompanyComponent implements OnInit {
  @ViewChild('stepper', { static: true }) stepper: MatStepper;

  // Form Groups
  masterDataForm: FormGroup;
  restaurantForm: FormGroup;
  dayTimeManagementForm: FormGroup;
  photoUploadForm: FormGroup;
  connectionMangementForm: FormGroup;

  timer: number;
  timeoutVal: number;
  locationArray: any;
  streetArray: any;
  locationId: number;
  countryId: number;
  districtId: number;
  stateId: number;
  latitude: number;
  longitude: number;
  districtArray: any;
  startDate: any;
  restaurantTypeArray: any;
  offerTypeArray: any;
  dayTimeArray: any;
  copiedValue: any;
  errorIndexStep: any;
  note: string;
  draftId: number;
  loadedData: any;
  countries: any;

  totalSize: number;
  totalSizeInKB: any;

  files: File[] = [];
  masterDataMessage: string;
  restaurantFormMessage: string;
  photoFormMessage: string;
  dayTimeFormMessage: any;

  cateringTypeArray: any;
  offerArrayList: any;
  formData: any;
  imageUrl: string | ArrayBuffer;
  fileName: string;
  logo: any;
  isLogoDeleted: boolean;
  loader: boolean;
  isSubmit: boolean;
  currZoom: number = 15;
  isEmptyAddresss: boolean = true;
  isSelectStreet: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private newCompanyService: NewCompanyService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private initPermissionService: InitPermissionsService,
    private permissionsService: NgxPermissionsService,
    public translate: TranslateService,
    public eventsService: EventsService
  ) {
    this.totalSize = 0;
    this.masterDataForm = this.formBuilder.group({
      name: ['', Validators.required],
      telefon: ['', Validators.required],
      street: ['', Validators.required],
      email: ['', Validators.email],
      zipCode: ['', Validators.required],
      place: [''],
      website: [''],
      district: [''],
      openingDate: [''],
      countryId: ['', Validators.required]
    });

    this.restaurantForm = this.formBuilder.group({
      cateringType: [''],
      offerType: ['']
    });

    this.note = '';

    this.dayTimeArray = [
      {
        day: 'Montag',
        firstStartTime: '',
        firstEndTime: '',
        secondStartTime: '',
        secondEndTime: '',
        isDisable: false,
        copied: false
      },
      {
        day: 'Dienstag',
        firstStartTime: '',
        firstEndTime: '',
        secondStartTime: '',
        secondEndTime: '',
        isDisable: false,
        copied: false
      },
      {
        day: 'Mittwoch',
        firstStartTime: '',
        firstEndTime: '',
        secondStartTime: '',
        secondEndTime: '',
        isDisable: false,
        copied: false
      },
      {
        day: 'Donnerstag',
        firstStartTime: '',
        firstEndTime: '',
        secondStartTime: '',
        secondEndTime: '',
        isDisable: false,
        copied: false
      },
      {
        day: 'Freitag',
        firstStartTime: '',
        firstEndTime: '',
        secondStartTime: '',
        secondEndTime: '',
        isDisable: false,
        copied: false
      },
      {
        day: 'Samstag',
        firstStartTime: '',
        firstEndTime: '',
        secondStartTime: '',
        secondEndTime: '',
        isDisable: false,
        copied: false
      },
      {
        day: 'Sonntag',
        firstStartTime: '',
        firstEndTime: '',
        secondStartTime: '',
        secondEndTime: '',
        isDisable: false,
        copied: false
      }
    ];

    this.photoUploadForm = this.formBuilder.group({
      file: ['']
    });

    this.newCompanyService.getRestaurentType({ type: 'Typ' }).subscribe(types => {
      if (types && types.length) {
        this.restaurantTypeArray = types;
      }
    });

    this.newCompanyService.getRestaurentType({ type: 'Angebot' }).subscribe(types => {
      if (types && types.length) {
        this.offerTypeArray = types;
      }
    });

    if (this.activatedRoute.snapshot.queryParams.id) {
      this.loader = true;
      this.isSubmit = false;
      this.newCompanyService.loadDraftData(this.activatedRoute.snapshot.queryParams.id).subscribe((response: any) => {
        let data = response;
        data['masterData'] = JSON.parse(data['masterData']);
        this.loadedData = data;
        this.ngOnInit();
      });
    }
  }

  ngOnInit() {
    this.locationArray = [];
    this.districtArray = [];
    this.streetArray = [];
    this.cateringTypeArray = [];
    this.offerArrayList = [];
    this.timer = this.timeoutVal = 500;
    this.startDate = moment().format('YYYY-MM-DD');
    this.isLogoDeleted = false;
    this.newCompanyService.getGeoCountries().subscribe(countries => {
      this.countries = countries;
      this.countries.push({ id: 4, name: 'anderes' });
    });
    this.dayTimeFormMessage = {
      message: '',
      index: '',
      position: ''
    };

    this.copiedValue = null;
    if (this.loadedData) {
      // this.isLogoDeleted = false;
      let index = 1;
      this.draftId = this.loadedData.id;
      const { masterData } = this.loadedData;
      for (let key in this.masterDataForm.controls) {
        this.masterDataForm.get(key).setValue(masterData[key]);
      }
      this.locationId = masterData.locationId;
      this.fetchDistrict(this.locationId);
      this.countryId = masterData.countryId;
      this.districtId = masterData.stadtteilId;
      this.stateId = masterData.stateId;
      this.latitude = masterData.latitude;
      this.longitude = masterData.longitude;

      if (this.loadedData.logoBuffer) {
        const data = new Uint8Array(this.loadedData.logoBuffer['data']);
        this.imageUrl = 'data:image/png;base64,' + this.bufferToBase64(data);
        this.fileName = masterData.logoFile;
      }

      if (this.loadedData.restaurentTypeData) {
        let { restaurentTypeData } = this.loadedData;
        restaurentTypeData = JSON.parse(restaurentTypeData);

        this.cateringTypeArray = restaurentTypeData['cateringTypeArray'];
        this.offerArrayList = restaurentTypeData['offerArrayList'];

        this.restaurantForm.get('cateringType').setValue(this.cateringTypeArray);
        this.restaurantForm.get('offerType').setValue(this.offerArrayList);

        index = 2;
      }

      if (this.loadedData.dayTimeData) {
        let { dayTimeData } = this.loadedData;
        dayTimeData = JSON.parse(dayTimeData);
        this.dayTimeArray = dayTimeData.dayTimeArray;
        this.note = dayTimeData.note;
        index = 3;
      }

      setTimeout(() => {
        while (index) {
          this.stepper.next();
          index--;
          if (!index) this.loader = false;
        }
      }, 0);
    }
    this.getLocation();
  }

  bufferToBase64(buf: any) {
    var binstr = Array.prototype.map
      .call(buf, (ch: any) => {
        return String.fromCharCode(ch);
      })
      .join('');
    return btoa(binstr);
  }

  validateMasterData() {
    let isValid = true;
    this.masterDataMessage = '';
    if (!this.isEmptyAddresss || !this.isSelectStreet) {
      isValid = false;
      // if (!this.isSelectStreet) {
      //   this.masterDataMessage = 'Kindly Select Street';
      // }
      this.errorIndexStep = this.errorIndexStep !== '' && this.errorIndexStep > 1 ? 1 : this.errorIndexStep;
    }
    if (!this.masterDataForm.valid) {
      this.errorIndexStep = 0;
      for (let key in this.masterDataForm.controls) {
        if (!this.masterDataForm.controls[key].valid) {
          isValid = false;
          this.masterDataForm.controls[key].markAsTouched({ onlySelf: true });
        }
      }
    }
    return isValid;
  }

  validateRestaurantData() {
    let isValid = true;
    this.restaurantFormMessage = '';
    if (this.cateringTypeArray.length === 0) {
      this.restaurantFormMessage = 'Minimum One catering type must be selected';
      isValid = false;
      this.errorIndexStep = this.errorIndexStep !== '' && this.errorIndexStep > 1 ? 1 : this.errorIndexStep;
    }

    if (this.offerArrayList.length === 0) {
      this.restaurantFormMessage = !this.restaurantFormMessage
        ? 'Minimum One Offer type must be selected'
        : this.restaurantFormMessage;
      this.errorIndexStep = this.errorIndexStep !== '' && this.errorIndexStep > 1 ? 1 : this.errorIndexStep;
      isValid = false;
    }

    return isValid;
  }

  validateDayTimeArray() {
    this.dayTimeFormMessage = {
      message: '',
      index: '',
      position: ''
    };
    let isValid = true;

    for (let m = 0; m < this.dayTimeArray.length; m++) {
      const object = this.dayTimeArray[m];
      if (!object.isDisable) {
        let firstStartTime = object['firstStartTime'] ? object['firstStartTime'] : null;
        let firstEndTime = object['firstEndTime'] ? object['firstEndTime'] : null;
        let secondStartTime = object['secondStartTime'] ? object['secondStartTime'] : null;
        let secondEndTime = object['secondEndTime'] ? object['secondEndTime'] : null;

        firstStartTime = firstStartTime ? firstStartTime.split(':') : null;
        firstStartTime =
          firstStartTime && firstStartTime.length ? parseInt(firstStartTime[0] + firstStartTime[1]) : null;

        firstEndTime = firstEndTime ? firstEndTime.split(':') : null;
        firstEndTime = firstEndTime && firstEndTime.length ? parseInt(firstEndTime[0] + firstEndTime[1]) : null;

        secondStartTime = secondStartTime ? secondStartTime.split(':') : null;
        secondStartTime =
          secondStartTime && secondStartTime.length ? parseInt(secondStartTime[0] + secondStartTime[1]) : null;

        secondEndTime = secondEndTime ? secondEndTime.split(':') : null;
        secondEndTime = secondEndTime && secondEndTime.length ? parseInt(secondEndTime[0] + secondEndTime[1]) : null;

        // if (firstEndTime && firstEndTime < firstStartTime) {
        //   this.dayTimeFormMessage['message'] = 'End time of first half should be greter than start time';
        //   this.dayTimeFormMessage['index'] = m;
        //   this.dayTimeFormMessage['position'] = 2;
        //   isValid = false;
        //   break;
        // }

        if (secondStartTime && secondStartTime < firstEndTime) {
          this.dayTimeFormMessage['message'] = 'Start time of Second half should be greter than end time of first half';
          this.dayTimeFormMessage['index'] = m;
          this.dayTimeFormMessage['position'] = 3;
          isValid = false;
          break;
        }

        // if (secondEndTime && secondEndTime < secondStartTime) {
        //   this.dayTimeFormMessage['message'] =
        //     'End time of Second half should be greter than start time of second half';
        //   this.dayTimeFormMessage['index'] = m;
        //   this.dayTimeFormMessage['position'] = 4;
        //   isValid = false;
        //   break;
        // }
      }
    }
    return isValid;
  }

  /* common function */
  checkForTyping() {
    window.clearTimeout(this.timer);
  }

  /* Master data Function Starts */

  fetchLocation() {
    window.clearTimeout(this.timer);
    const zips = this.masterDataForm.value.zipCode;
    if (zips) {
      this.timer = window.setTimeout(() => {
        this.newCompanyService.getGeoLocations({ zips }).subscribe(locations => {
          if (locations && locations.length) {
            let locationArray = locations;
            const newArray: any = [];

            locationArray = locationArray.filter((each: any) => {
              const locationArray = each.zips.split(',');
              if (locationArray.length > 1) {
                newArray.push(each);
              } else if (each.zips.startsWith(zips)) {
                return each;
              }
            });

            if (newArray && newArray.length) {
              for (let j = 0; j < newArray.length; j++) {
                const newZips = newArray[j].zips.split(',');
                for (let z = 0; z < newZips.length; z++) {
                  if (newZips[z].startsWith(zips)) {
                    const element = { ...newArray[j] };
                    element['zips'] = newZips[z];
                    locationArray.push(element);
                  }
                }
              }
            }
            this.locationArray = locationArray;
          } else {
            this.locationArray = [];
          }
        });
      }, this.timeoutVal);
    }
  }

  selectZipCode(zipCode: any) {
    this.masterDataForm.get('zipCode').setValue(zipCode.zips);
    this.masterDataForm.get('place').setValue(zipCode.name);
    this.masterDataForm.get('countryId').setValue(zipCode.countryId);
    this.locationId = zipCode.id;
    this.countryId = zipCode.countryId;
    this.stateId = zipCode.stateId;
    this.fetchStreetData();
    this.fetchDistrict(this.locationId);
  }

  fetchDistrict(locationId: number) {
    if (locationId) {
      this.districtArray = [];
      this.newCompanyService.getDistrict({ locationId }).subscribe(districts => {
        if (districts && districts.length) {
          this.districtArray = districts;
        }
      });
    }
  }

  changeDistrict(distirct: any) {
    if (distirct) {
      const distirctObjectArray = this.districtArray.filter((each: any) => each.name === distirct);
      if (distirctObjectArray && distirctObjectArray.length) {
        this.districtId = distirctObjectArray[0].id;
      }
    }
  }
  /* Master data Function Starts */

  /* Catering Data Functions Start */
  selectedCatType() {
    this.cateringTypeArray = this.restaurantForm.value[`cateringType`];
  }

  selectedOfferType() {
    this.offerArrayList = this.restaurantForm.value[`offerType`];
  }

  submitDraftData(currentIndex: number) {
    const { id } = JSON.parse(localStorage.getItem('credentials'));
    const data = {
      userId: id,
      draftId: this.draftId || null
    };

    data['masterData'] = JSON.stringify({
      ...this.masterDataForm.value,
      locationId: this.locationId,
      countryId: this.countryId,
      stadtteilId: this.districtId,
      stateId: this.stateId,
      latitude: this.latitude,
      longitude: this.longitude
    });

    if (this.logo) {
      data['logo'] = this.logo;
    }
    data['isLogoDeleted'] = this.isLogoDeleted;

    switch (currentIndex) {
      case 1:
        data['restaurentTypeData'] = JSON.stringify({
          cateringTypeArray: this.cateringTypeArray,
          offerArrayList: this.offerArrayList
        });
        break;
      case 2:
        data['restaurentTypeData'] = JSON.stringify({
          cateringTypeArray: this.cateringTypeArray,
          offerArrayList: this.offerArrayList
        });
        data['dayTimeData'] = JSON.stringify({
          dayTimeArray: this.dayTimeArray,
          note: this.note
        });
        break;
      case 3:
        data['restaurentTypeData'] = JSON.stringify({
          cateringTypeArray: this.cateringTypeArray,
          offerArrayList: this.offerArrayList
        });
        data['dayTimeData'] = JSON.stringify({
          dayTimeArray: this.dayTimeArray,
          note: this.note
        });

        const fileArray = [];

        if (this.files && this.files.length) {
          for (let i = 0; i < this.files.length; i++) {
            fileArray.push({
              name: this.files[i].name,
              size: this.files[i].size,
              type: this.files[i].type,
              sizeInKB: this.files[i]['sizeInKB']
            });
          }
        }

        data['photoData'] = JSON.stringify({
          files: fileArray
        });
        break;
    }
    this.newCompanyService.saveData(data).subscribe(
      (response: any) => {
        this.draftId = !this.draftId ? response.id : this.draftId;
      },
      (err: any) => {
        console.log('Error => ', err);
      }
    );
  }

  moveForward(stepper: MatStepper) {
    const currentIndex = stepper.selectedIndex;
    let isValid = false;
    switch (currentIndex) {
      case 0:
        isValid = this.validateMasterData();
        break;
      case 1:
        isValid = this.validateMasterData() && this.validateRestaurantData();
        break;
      case 2:
      case 3:
        isValid = this.validateMasterData() && this.validateRestaurantData() && this.validateDayTimeArray();
        break;
    }
    if (isValid) {
      this.submitDraftData(currentIndex);
      stepper.next();
    }
  }

  moveBackward(stepper: MatStepper) {
    stepper.previous();
  }

  copyData(index: number) {
    if (!this.dayTimeArray[index].isDisable) {
      this.dayTimeArray[index]['copied'] = true;
      this.copiedValue = {
        firstStartTime: this.dayTimeArray[index]['firstStartTime'],
        firstEndTime: this.dayTimeArray[index]['firstEndTime'],
        secondStartTime: this.dayTimeArray[index]['secondStartTime'],
        secondEndTime: this.dayTimeArray[index]['secondEndTime']
      };
      this.dayTimeArray.forEach((dayTime: any, i: number) => {
        if (i !== index) {
          dayTime['copied'] = false;
        }
      });
    }
  }

  pasteTo(target: any) {
    if (this.copiedValue && Object.keys(this.copiedValue).length > 0) {
      if (target === 'all') {
        this.dayTimeArray.forEach((dayTime: any) => {
          if (!dayTime['copied'] && !dayTime['isDisable']) {
            Object.assign(dayTime, this.copiedValue);
          }
        });
      } else {
        const dayTimeObject = this.dayTimeArray[target];
        Object.assign(dayTimeObject, this.copiedValue);
      }
    }
  }

  logoChange(file: File) {
    if (file) {
      const img = new Image();
      img.src = window.URL.createObjectURL(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);

      img.onload = event => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        if (width < 200 && height < 200) {
          this.masterDataMessage = 'Foto muss mindestens 200px breit und 200px hoch sein';
          return;
        }

        const fileTypes = ['image/jpeg', 'image/gif', 'image/jpg', 'image/png'];

        if (!fileTypes.includes(file.type)) {
          this.masterDataMessage = 'Nicht unterstütztes Dateiformat. Nur jpg, gif und png';
          return;
        }

        this.fileName = file.name;
        this.logo = file;
        this.imageUrl = reader.result;
      };
    }
  }

  removeLogo() {
    this.fileName = null;
    this.logo = null;
    this.imageUrl = null;
    this.isLogoDeleted = true;
  }

  onFileChange(event: any) {
    this.photoFormMessage = '';
    this.totalSize = 0;
    const typeArray = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg'];
    console.log('Event => ', event);
    if (!event.addedFiles) {
      event['addedFiles'] = [...event.target.files];
    }

    for (let i = 0; i < event['addedFiles'].length; i++) {
      if (typeArray.indexOf(event['addedFiles'][i].type.toLowerCase()) === -1) {
        this.photoFormMessage = `${event['addedFiles'][i].name} has invalid file Extention`;
        return;
      }
    }

    this.files.push(...event.addedFiles);

    if (this.files && this.files.length) {
      this.files.forEach((file: any) => {
        file.sizeInKB = (file.size / 1024).toFixed(2);
        this.totalSize += file.size;
      });
    }

    this.totalSizeInKB = (this.totalSize / 1024).toFixed(2);

    if (this.totalSize > 10 * 1024 * 1024) {
      this.photoFormMessage = `Total Size is more than 10MB`;
      return;
    }

    this.formData = new FormData();
    for (var i = 0; i < this.files.length; i++) {
      this.formData.append(`file[]`, this.files[i]);
    }
  }

  onRemove(event: any) {
    this.totalSize = 0;
    this.photoFormMessage = '';
    const typeArray = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg'];

    this.files.splice(this.files.indexOf(event), 1);
    if (this.files && this.files.length) {
      this.files.forEach((file: any) => {
        this.totalSize += file.size;
      });
    }

    this.totalSizeInKB = (this.totalSize / 1024).toFixed(2);

    for (let i = 0; i < this.files.length; i++) {
      if (typeArray.indexOf(this.files[i].type.toLowerCase()) === -1) {
        this.photoFormMessage = `${this.files[i].name} has invalid file Extention`;
        return;
      }
    }
  }

  submitForm() {
    this.loader = true;
    this.isSubmit = true;
    this.newCompanyService.submitForm(this.formData, this.draftId).subscribe((response: any) => {
      this.loader = false;
      this.router.navigate(['/client', response.betriebId, 'dashboard']);
      this.getPermissions(response.betriebId);
    });
  }

  getPermissions(clientId: number) {
    this.initPermissionService
      .load(clientId)
      .then((permissions: any) => this.permissionsService.addPermission(permissions));
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          if (position) {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
          }
        },
        (error: any) => console.log(error)
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }

  mapClicked(event: MouseEvent) {
    this.latitude = event['coords']['lat'];
    this.longitude = event['coords']['lng'];
  }
  fetchStreet() {
    this.isSelectStreet = false;
    window.clearTimeout(this.timer);
    const text = this.masterDataForm.value.street;
    if (text && text.length > 2) {
      this.timer = window.setTimeout(() => {
        this.newCompanyService.getStreetLocation({ text }).subscribe(streets => {
          this.streetArray = [];
          if (streets && streets.length > 0) {
            this.isEmptyAddresss = true;
            streets.filter((street: any) => {
              const data = {
                address: street.address,
                latitude: street.latitude,
                longitude: street.longitude,
                isGoogleResp: false
              };
              this.streetArray.push(data);
            });
          } else {
            this.eventsService.getGoogleLocationData(text).subscribe(response => {
              this.streetArray = [];
              const { results } = response;
              console.log(results);
              results.filter((street: any) => {
                this.isEmptyAddresss = true;
                const data = {
                  address: street.formatted_address,
                  latitude: street.geometry.location.lat,
                  longitude: street.geometry.location.lng,
                  isGoogleResp: true
                };
                this.streetArray.push(data);
              });
              if (this.streetArray.length == 0) {
                this.isEmptyAddresss = false;
                this.isSelectStreet = false;
              }
            });
          }
        });
      }, this.timeoutVal);
    }
  }
  fetchStreetData() {
    window.clearTimeout(this.timer);
    const text = this.masterDataForm.value.street;
    this.isSelectStreet = text ? true : false;
    if (text && text.length > 2) {
      this.timer = window.setTimeout(() => {
        // Call Google API
        let address = ``;
        address += this.masterDataForm.value.street ? `${this.masterDataForm.value.street}, ` : '';
        address += this.masterDataForm.value.zipCode;
        address += this.masterDataForm.value.place ? ` ${this.masterDataForm.value.place}, Germany` : ', Germany';

        this.eventsService.getGoogleLocationData(address).subscribe(response => {
          if (response) {
            const { results } = response;
            this.latitude = results[0].geometry.location.lat;
            this.longitude = results[0].geometry.location.lng;
          }
        });
      }, this.timeoutVal);
    }
  }
  selectStreet(street: MouseEvent) {
    this.isSelectStreet = true;
    this.masterDataMessage = '';
    this.masterDataForm.get('street').setValue(street['address']);
    this.latitude = street['latitude'];
    this.longitude = street['longitude'];
    // this.eventsService.getGoogleLocationData(street['address']).subscribe(response => {
    //   const { results } = response;
    //     this.latitude= results[0].geometry.location.lat;
    //     this.longitude= results[0].geometry.location.lng;
    // });
  }

  selectCountriesChange() {
    if (this.masterDataForm.value.countryId) {
      this.countryId = this.masterDataForm.value.countryId;
    }
  }
}
