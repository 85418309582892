import { IAfterGuiAttachedParams } from '@ag-grid-enterprise/all-modules';
import { Component, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MenucardsService } from '@app/menucards/menucards.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'photo-renderer',
  template: `
    <input type="file" style="display:none" accept="image/*" (change)="uploadPhoto(file.files)" #file />
    <button
      class="btn btn-light btn-sm"
      (click)="file.click()"
      [disabled]="!params.data.id"
      *ngIf="params && !params.data.photo && !isUploading"
    >
      Upload
    </button>
    <mat-spinner *ngIf="isUploading" diameter="20"></mat-spinner>
    <img
      [src]="params.data.photo"
      *ngIf="params && params.data.photo && !isUploading"
      style="width:30px; height: 30px; border-radius:30px;object-fit: contain;"
      (click)="file.click()"
    />
  `
})
export class PhotoRendererComponent implements ICellRendererAngularComp, OnDestroy {
  params: any;
  isUploading: any;

  constructor(private snackBar: MatSnackBar, private menucardService: MenucardsService) {}

  ngOnDestroy(): void {}
  refresh(params: any): boolean {
    if (params.data.photo && !params.data.photo.includes('https://cdn.gastroguide.de/')) {
      params.data.photo = 'https://cdn.gastroguide.de/' + params.data.photo;
    }
    this.params = params;
    return true;
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}

  agInit(params: any): void {
    this.params = params;
  }

  uploadPhoto(files: any) {
    this.isUploading = true;
    const formData = new FormData();
    formData.append('itemId', this.params.data.id.toString());
    formData.append('file', files[0], files[0].name);
    this.menucardService.uploadItemPhoto(formData).subscribe(
      result => {
        this.isUploading = false;
        this.params.data.photo = result.photo;
      },
      err => {
        this.isUploading = true;
        this.snackBar.open('Foto konnte nicht hochgeladen werden', '', {
          duration: 2000,
          panelClass: ['snackbar-error']
        });
      }
    );
  }
}
