import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ClientResolverService } from '@app/core/client-resolver.service';

declare function initFeedbackWidget(containerId: string, clientId: string): void;
@Component({
  selector: 'app-feedback-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntegrationComponent implements AfterViewInit {
  activeClient: any = {
    id: null
  };
  expandedPanel: string;

  constructor(public clientResolver: ClientResolverService) {
    this.activeClient = this.clientResolver.client;
  }

  ngAfterViewInit(): void {
    this.loadFeedbackContainerScript();
  }

  expandPanel(panel: string) {
    this.expandedPanel = panel;
  }

  loadFeedbackContainerScript() {
    const script = document.createElement('script');
    script.src = 'https://widgets.gastro.digital/assets/js/feedback.js';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      initFeedbackWidget('gd-feedback-container', this.activeClient.id);
    };
    document.body.appendChild(script);
  }
}
