import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { GastroPayService } from '@app/gastro-pay/gastro-pay.service';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SetDeliveryTimeComponent } from '../set-delivery-time/set-delivery-time.component';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  @Input() public data: any = [];
  @Input() public finished: boolean = false;
  @Input() public isInProgress: boolean;
  @Output() change = new EventEmitter();

  constructor(
    private modalService: NgbModal,
    private gastropayService: GastroPayService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {}

  changeStatus(status: string) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Status ändern';
    if (status === 'canceled') {
      modalRef.componentInstance.message = `Sind Sie sicher dass Sie die Bestellung stornieren möchten?`;
    } else if (status === 'confirmed') {
      modalRef.componentInstance.message = `Sind Sie sicher dass Sie die Bestellung abschließen möchten?`;
    }
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      (result: any) => {
        if (result === 'ok') {
          this.gastropayService.changeOrderStatus(this.data.id, status).subscribe(
            (res: any) => {
              this.data.status = res.status;
              this.change.emit(this.data);

              this.snackBar.open('Status wurde erfolgreich geändert', '', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            },
            err => {
              this.snackBar.open(err.error.msg, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
              });
            }
          );
        }
      },
      () => {}
    );
  }

  setDeliveryTime() {
    if (this.data.type === 'inhouse') {
      this.gastropayService.setDeliveryTime(this.data.id, 0).subscribe(
        () => {
          this.snackBar.open('Bestellung "In Arbeit" verschoben', '', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        },
        err => {
          this.snackBar.open(err.error.msg, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      );
      return;
    }
    const modalRef = this.modalService.open(SetDeliveryTimeComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.order = { ...this.data };
    modalRef.result.then(
      (res: any) => {
        console.log('res', res);
        if (res) {
          this.data = res;
          this.change.emit(this.data);
        }
      },
      () => {}
    );
  }

  printBon(orderId: number) {
    this.gastropayService.printBon(orderId).subscribe(() => {
      this.snackBar.open('Druckauftrag versendet', '', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    });
  }
}
