import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { GastroPayService } from '@app/gastro-pay/gastro-pay.service';
import { Subscription, Subject } from 'rxjs';
import { SocketService } from '@app/socket.service';
import { MatSnackBar } from '@angular/material';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientResolverService } from '@app/core/client-resolver.service';

import { differenceInMinutes, subDays, addDays, isSameDay } from 'date-fns';
import moment from 'moment';
moment.locale('de');

@Component({
  selector: 'app-developer',
  templateUrl: './developer.component.html',
  styleUrls: ['./developer.component.scss']
})
export class DeveloperComponent implements OnInit, OnDestroy, AfterViewInit {
  pendingOrders: any;
  finishedOrders: any;
  inprogressOrders: any;
  currentDate = new Date();
  newOrderAudio = new Audio();
  settings: any;
  stats: any = {
    orders: {
      total: 0,
      yesterday: 0,
      today: 0
    },
    balance: {
      total: 0
    },
    sum: {
      total: 0,
      yesterday: 0,
      today: 0
    }
  };

  // Layout
  readonly LAYOUT = {
    XXS: 450,
    XS: 768,
    SM: 992,
    MD: 1200,
    LG: 1600,
    XL: 1920,
    XXL: 2560
  };
  layout: number = this.LAYOUT.MD;

  private _ordersSub: Subscription;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private gastropayService: GastroPayService,
    private socketService: SocketService,
    private snackBar: MatSnackBar,
    private modalService: NgbModal,
    private clientResolver: ClientResolverService
  ) {}

  ngOnInit() {
    this.getSettings();

    if (this.clientResolver.client.gastropaySettings) {
      this.newOrderAudio.src =
        '/assets/sound/' + this.clientResolver.client.gastropaySettings.notificationSound + '.wav';
      this.newOrderAudio.load();
      this.newOrderAudio.loop = this.clientResolver.client.gastropaySettings.loopNotificationSound;
    }
  }

  ngAfterViewInit() {
    // Get socket messages
    this._ordersSub = this.socketService.newOrder.takeUntil(this.ngUnsubscribe).subscribe((res: any) => {
      if (this.newOrderAudio) {
        try {
          this.newOrderAudio.play();
        } catch (e) {}
      }
      const snackBar = this.snackBar.open('Neue Bestellung eingetroffen', 'Klingeln stoppen', {
        panelClass: ['snackbar-info']
      });
      snackBar.afterDismissed().subscribe(() => {
        if (this.newOrderAudio) {
          this.newOrderAudio.pause();
        }
      });
      this.getOrders();
      this.getStats();
    });
  }

  ngOnDestroy() {
    if (this.newOrderAudio) {
      try {
        this.newOrderAudio.pause();
      } catch (e) {}
    }
    this.ngUnsubscribe.complete();
  }

  getStats() {
    this.gastropayService
      .getShortStats()
      .takeUntil(this.ngUnsubscribe)
      .subscribe(stats => {
        this.stats = stats;
      });
  }

  getOrders(refresh: boolean = false, dateString: string = '') {
    this.pendingOrders = null;
    this.inprogressOrders = null;
    this.finishedOrders = null;

    if (!dateString || dateString === '') {
      dateString = moment().format('YYYY-MM-DD');
    }

    this.gastropayService
      .getOwnOrders(false, dateString)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(res => {
        this.pendingOrders = [];
        this.finishedOrders = [];
        this.inprogressOrders = [];
        res.forEach((order: any) => {
          if (order.status === 'pending' && !order.deliveryTime && order.deliveryTime !== 0) {
            this.pendingOrders.push(order);
          }
          if (order.status === 'pending' && (order.deliveryTime || order.deliveryTime === 0)) {
            this.inprogressOrders.push(order);
          }
          if (order.status === 'confirmed' || order.status === 'canceled') {
            this.finishedOrders.push(order);
          }
        });
      });
  }

  isToday() {
    return isSameDay(new Date(), this.currentDate);
  }
  prevDay() {
    this.currentDate = subDays(this.currentDate, 1);
    this.changeDate();
  }

  nextDay() {
    this.currentDate = addDays(this.currentDate, 1);
    this.changeDate();
  }

  today() {
    this.currentDate = new Date();
    this.changeDate();
  }

  changeDate() {
    const dateString = moment(this.currentDate).format('YYYY-MM-DD');
    this.getOrders(false, dateString);
  }

  getSettings() {
    this.gastropayService
      .getSettings()
      .takeUntil(this.ngUnsubscribe)
      .subscribe(settings => {
        this.settings = settings;
        this.getOrders();
        this.getStats();
      });
  }

  orderChange(order: any) {
    // Find in arrays
    const pendingIndex = this.pendingOrders.findIndex((po: any) => po.id === order.id);
    if (pendingIndex !== -1) {
      if (order.status === 'pending' && !order.deliveryTime && order.deliveryTime !== 0) {
        this.pendingOrders[pendingIndex] = order;
      }
      if (order.status === 'pending' && (order.deliveryTime || order.deliveryTime === 0)) {
        this.pendingOrders.splice(pendingIndex, 1);
        this.inprogressOrders.push(order);
      }
      if (order.status === 'confirmed' || order.status === 'canceled') {
        this.pendingOrders.splice(pendingIndex, 1);
        this.finishedOrders.push(order);
      }
    }

    // Search in inprogress Array
    const inprogressIndex = this.inprogressOrders.findIndex((ip: any) => ip.id === order.id);
    if (inprogressIndex !== -1) {
      if (order.status === 'pending' && !order.deliveryTime && order.deliveryTime !== 0) {
        this.inprogressOrders.splice(inprogressIndex, 1);
        this.pendingOrders.push(order);
      }
      if (order.status === 'pending' && (order.deliveryTime || order.deliveryTime === 0)) {
        this.inprogressOrders[inprogressIndex] = order;
      }
      if (order.status === 'confirmed' || order.status === 'canceled') {
        this.inprogressOrders.splice(inprogressIndex, 1);
        this.finishedOrders.push(order);
      }
    }

    // this.getOrders();
  }
}
