import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'editor-cell',
  template: `
    <div #container tabindex="0" (keydown)="onKeyDown($event)"></div>
  `,
  styles: []
})
export class NoEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  @ViewChild('container', { read: ViewContainerRef, static: false }) public container: any;
  private params: any;

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.container.element.nativeElement.focus();
    });
  }

  agInit(params: any): void {
    this.params = params;
    this.params.api.stopEditing();
  }

  getValue(): any {}

  isPopup(): any {}

  onKeyDown(event: any): void {
    console.log('key', event);
    const key = event.which || event.keyCode;
    if (key === 13) {
      this.params.api.stopEditing();
    }
  }
}
