import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { EventsService } from '../events.service';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-move-ticket-dates',
  templateUrl: './move-ticket-dates.component.html',
  styleUrls: ['./move-ticket-dates.component.scss']
})
export class MoveTicketDatesComponent implements OnInit {
  @Input() ticketInfo: any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  moveTicketDateFormGroup: FormGroup;
  eventData: any = [];
  error: any;
  formInvalid: string;
  associatedTickets: any = [];
  private ngUnsubscribe: Subject<any> = new Subject();
  constructor(
    private formBuilder: FormBuilder,
    private eventsService: EventsService,
    private snackBar: MatSnackBar,
    public activeModal: NgbActiveModal,
    public translate: TranslateService
  ) {
    this.moveTicketDateFormGroup = this.formBuilder.group({
      oldDateId: [],
      dateId: ['', Validators.required],
      movingTickets: []
    });
    this.loadTranslation();
    this.translate.onLangChange.subscribe((event: any) => {
      this.loadTranslation();
    });
  }

  async ngOnInit() {
    const eventId = this.ticketInfo.tickets[0].eventId;
    let events1: any = await Promise.all([this.eventsService.getSingleEvent(eventId, 'active').toPromise()]);
    let events2 = await Promise.all([this.eventsService.getAllDateTickets('active', eventId).toPromise()]);
    let commonEvents = [];
    // if (this.dateInfo && this.dateInfo.ticketDates) {
    //   this.dateInfo.ticketDates.map((item: any) => {
    //     this.associatedTickets.push({
    //       id: item.id,
    //       name: item.tickets.name,
    //       isChecked: false
    //     });
    //   });
    // }
    this.eventData = events1 && events1.length > 0 ? events1[0] : [];

    if (events1 && events1[0] && events1[0].eventDates && events2 && events2[0]) {
      events1 = events1[0].eventDates;
      events2 = events2[0];
      commonEvents = events1.filter((event1: any) => events2.some(event2 => event2.id === event1.id));
    }

    this.eventData.eventDates = commonEvents;

    let movingTickets: any = [];
    let oldDateId;

    if (this.ticketInfo && this.ticketInfo.tickets) {
      this.ticketInfo.tickets.map((item: any) => {
        oldDateId = item.dateId;
        movingTickets.push(item.id);
      });
    }
    this.moveTicketDateFormGroup.get('movingTickets').setValue(movingTickets);
    this.moveTicketDateFormGroup.get('oldDateId').setValue(oldDateId);
  }

  private loadTranslation() {
    this.formInvalid = this.translate.instant('Events&Tickets.Common.FormInvalid');
  }

  save() {
    // let selectedTickets: any = [];
    // selectedTickets = this.associatedTickets.filter((opt: any) => opt.isChecked).map((opt: any) => opt.id);
    // this.moveTicketDateFormGroup.get('selectedTickets').setValue(selectedTickets);
    // if (!this.moveTicketDateFormGroup.value.selectedTickets) {
    //   this.snackBar.open('Please select at least one ticket', '', {
    //     duration: 2000,
    //     panelClass: ['snackbar-error']
    //   });
    //   return;
    // }

    if (!this.moveTicketDateFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    console.log('data', this.moveTicketDateFormGroup.value);

    this.eventsService
      .moveTicketDate(this.moveTicketDateFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          this.error = err.error.message;
        }
      );
  }
}
