import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { EventsService } from '../events.service';
import { MatSnackBar } from '@angular/material';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-edit-option',
  templateUrl: './add-edit-option.component.html',
  styleUrls: ['./add-edit-option.component.scss']
})
export class AddEditOptionComponent implements OnInit {
  @Input() public optionInfo: any;
  @Output() cancelOption: EventEmitter<any> = new EventEmitter();
  @Output() addOptionResp: EventEmitter<any> = new EventEmitter();
  @Output() updateOptionResp: EventEmitter<any> = new EventEmitter();
  optionFormGroup: FormGroup;
  error: string;
  isEditOption: boolean;
  private ngUnsubscribe: Subject<any> = new Subject();
  constructor(
    private formBuilder: FormBuilder,
    private eventsService: EventsService,
    private snackBar: MatSnackBar,
    public activeModal: NgbActiveModal
  ) {
    this.optionFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      description: [''],
      price: [0]
    });
  }

  ngOnInit() {
    this.isEditOption = this.optionInfo.isEditOption;
    if (this.isEditOption) {
      const { optionData } = this.optionInfo;
      this.optionFormGroup.get('name').setValue(optionData.name);
      this.optionFormGroup.get('description').setValue(optionData.description);
      this.optionFormGroup.get('price').setValue(optionData.price);
    }
  }

  add() {
    if (!this.optionFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    const optionData = this.optionFormGroup.value;
    // ticketData['eventId'] = this.ticketInfo.eventId

    if (this.optionInfo.isTicket) {
      this.eventsService
        .addOption(optionData, this.optionInfo.ticketInfo)
        .takeUntil(this.ngUnsubscribe)
        .subscribe(
          (response: any) => {
            this.addOptionResp.emit(response);
          },
          err => {
            this.error = err.error.msg;
          }
        );
    } else {
      this.addOptionResp.emit(optionData);
    }
  }

  cancel() {
    this.cancelOption.emit();
  }

  save() {
    if (!this.optionFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    const optionData = this.optionFormGroup.value;

    this.eventsService
      .updateOption(optionData, this.optionInfo.optionData.id, this.optionInfo.ticketInfo)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.snackBar.open('Option erfolgreich aktualisiert', '', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.updateOptionResp.emit(response);
        },
        err => {
          this.error = err.error.msg;
          this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      );
  }
}
