import { Component, OnInit, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { GastroPayService } from '@app/gastro-pay/gastro-pay.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import addMinutes from 'date-fns/add_minutes';
import differenceInMinutes from 'date-fns/difference_in_minutes';

@Component({
  selector: 'app-set-delivery-time',
  templateUrl: './set-delivery-time.component.html',
  styleUrls: ['./set-delivery-time.component.scss']
})
export class SetDeliveryTimeComponent implements OnInit {
  @Input() public order: any;
  customValueForm: FormGroup;
  setDeliveryTimeObservable: any;

  constructor(
    public activeModal: NgbActiveModal,
    private snackBar: MatSnackBar,
    private gastropayService: GastroPayService,
    private formBuilder: FormBuilder
  ) {
    this.customValueForm = this.formBuilder.group({
      deliveryTime: ['', Validators.required],
      type: ['minutes', Validators.required]
    });
  }

  ngOnInit() {}

  setDeliveryTime(deliveryTime: number) {
    let calcTime = 0;
    if (deliveryTime > 0) {
      calcTime = differenceInMinutes(
        addMinutes(new Date(), deliveryTime),
        this.order.desiredTime ? this.order.desiredTime : this.order.createdAt
      );
    }

    this.setDeliveryTimeObservable = this.gastropayService.setDeliveryTime(this.order.id, calcTime).subscribe(
      res => {
        this.order.deliveryTime = res.deliveryTime;
        this.order.deliveryTimestamp = res.deliveryTimestamp;
        this.activeModal.close(this.order);
        this.snackBar.open('Lieferzeit wurde erfolgreich eingestellt', '', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      },
      err => {
        this.snackBar.open(err.error.msg, '', {
          duration: 2000,
          panelClass: ['snackbar-error']
        });
      }
    );
  }

  setCustomDeliveryTime() {
    if (!this.customValueForm.valid) {
      return;
    }

    let deliveryTime = this.customValueForm.value.deliveryTime;
    if (this.customValueForm.value.type === 'hours') {
      deliveryTime = deliveryTime * 60;
    }

    this.setDeliveryTime(deliveryTime);
  }
}
