import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  constructor(private http: HttpClient) {}

  register(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http
      .post<any>(environment.serverUrl + `public/member-register`, data, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getGeoLocations(params: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http
      .get<any>(environment.serverUrl + `public/getLocationsForMember`, {
        headers: headers,
        params
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getGeoCountries() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http
      .get<any>(environment.serverUrl + `public/getCountries`, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getBannedIPs() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http
      .get<any>(environment.serverUrl + `public/bannedIPs`, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getIPAddress() {
    return this.http.get<any>(`https://jsonip.com/`).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }
}
