import { Component, OnInit, ViewChild } from '@angular/core';
import { Papa } from 'ngx-papaparse';
import * as XLSX from 'xlsx';
import { ReservationService } from '@app/reservation/reservation.service';

import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material';
import { Subscription, Subject } from 'rxjs';

import { ActivatedRoute } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';

import moment from 'moment';
import { MenucardsService } from '../menucards.service';
moment.locale('de');

@Component({
  selector: 'app-menu-csv-import',
  templateUrl: './csv-import.component.html',
  styleUrls: ['./csv-import.component.scss']
})
export class CsvImportComponent implements OnInit {
  csvData: any;
  csvHeader: any;
  charFind: any[] = ['Ã„', 'Ãœ', 'Ã–', 'Ã¼', 'Ã¶', 'Ã¤', 'ÃŸ'];
  charReplace: any[] = ['Ä', 'Ü', 'Ö', 'ü', 'ö', 'ä', 'ß'];
  mappingArray: any = { 0: '4', 1: '5', 2: '10', 3: '6', 4: '', 5: '8', 6: '', 7: '', 8: '12', 9: '12' };
  csvTemplate: string = 'Manuell';
  defaultValues: any = { 0: '', 1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '' };
  placeholderValues: any = {
    0: '',
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: ''
  };
  //'confirmed','canceled','noShow','arrived','placed','pending','finished','waiting'

  linesToImport: number = 0;
  linesImported: number = 0;

  msg: string = '';
  msgClass: string = '';

  reservationData = {
    name: 0, // Gericht
    description: 0, // Beschreibung
    price: 0,
    photo: 0, // Foto URL
    type: 0, // Produkt oder Extra
    additives: 0, // Zusatzstoffe
    allergens: 0, // Allergene
    deposit: 0, // Pfand
    sortingOrder: 0 // Sortierung
  };
  textConstants: any = {
    name: 'Gericht/Name',
    description: 'Beschreibung',
    price: 'Preis in €',
    photo: 'Foto-URL',
    type: 'Produkttyp',
    additives: 'Zusatzstoffe (kommagetrennt)',
    allergens: 'Allergene (kommagetrennt)',
    deposit: 'Pfand in €',
    sortingOrder: 'Sortierung'
  };
  textConstantsArray: any[] = [];
  statusRewrite: string = '';
  statusListValue: any[] = ['', 'product', 'side', 'ingredient', 'dip', 'extra', 'drink', 'menu'];
  statusListName: any[] = ['Art (optional)', 'Gericht', 'Beilage', 'Zutat', 'Dip', 'Extra', 'Getränk', 'Menü'];
  statusListSelected: 0;

  private ngUnsubscribe: Subject<any> = new Subject();
  @ViewChild('fileImportInput', { static: false }) fileImportInput: any;

  constructor(private papa: Papa, public menucardsService: MenucardsService) {
    this.textConstantsArray = Object.keys(this.reservationData);
  }

  fileChangeListener(event: any): void {
    this.msgClass = '';
    this.msg = '';
    let files = event.srcElement.files;

    if (files[0].name.endsWith('.csv')) {
      let input = event.target;
      let reader = new FileReader();

      reader.readAsText(input.files[0], 'utf8');
      reader.onload = data => {
        const csvString: any = reader.result || '';
        this.papa.parse(csvString, {
          complete: result => {
            this.csvData = result.data || [];
            this.csvHeader = this.csvData.splice(0, 1);
            console.log('Parsed: ', this.csvHeader, this.csvData);
          }
        });
      };
    } else {
      if (files[0].name.endsWith('.xlsx')) {
        let input = event.target;

        const target: DataTransfer = <DataTransfer>input;

        if (target.files.length !== 1) throw new Error('Cannot use multiple files');
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
          /* read workbook */
          const bstr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

          /* grab first sheet */
          const wsname: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];

          /* save data */
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          this.csvData = data || [];
          this.csvHeader = this.csvData.splice(0, 1);
          console.log('Parsed: ', this.csvHeader, this.csvData);
        };
        reader.readAsBinaryString(target.files[0]);
        /*
        const CSV_TYPE ='text/csv;charset=utf-8';
        const CSV_EXTENSION = '.csv';
        // const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const csvOutput: string = XLSX.utils.sheet_to_csv(worksheet);
        */
      } else {
        this.msgClass = 'alert alert-info';
        this.msg = 'Bitte importieren Sie eine gültige .csv/.xlsx Datei';
        this.fileImportInput.nativeElement.value = '';
      }
    }
  }

  encode_utf8(s: string) {
    return unescape(encodeURIComponent(s));
  }

  decode_utf8(s: string) {
    return decodeURIComponent(escape(s));
  }

  replaceArray(replaceString: string, find: any, replace: any) {
    if (typeof replaceString == 'string') {
      let regex;
      for (let i = 0; i < find.length; i++) {
        regex = new RegExp(find[i], 'g');
        replaceString = replaceString.substr(0, 60).replace(regex, replace[i]);
      }
    }
    return replaceString;
  }

  setCsvTemplate(template: string = 'Manuell') {
    this.csvTemplate = template;

    switch (template) {
      case 'Manuell':
        this.mappingArray = { 0: '', 1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '' };
        break;
      case 'Resmio':
        this.mappingArray = { 0: '10', 1: '2', 2: '1', 3: '', 4: '3', 5: '4', 6: '5', 7: '12', 8: '14', 9: '' };
        break;
      case 'Opentable':
        this.mappingArray = { 0: '1', 1: '5', 2: '0', 3: '', 4: '2', 5: '', 6: '3', 7: '4', 8: '6', 9: '' };
        break;
      case 'Quandoo':
        this.mappingArray = { 0: '12', 1: '8', 2: '9', 3: '10', 4: '3', 5: '5', 6: '6', 7: '18', 8: '11', 9: '14' };
        break;
      case 'Bookatable':
        this.mappingArray = { 0: '3', 1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '' };
        break;
      default:
        this.mappingArray = { 0: '', 1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '' };
        this.csvTemplate = 'Manuell';
        break;
    }
  }
  startCsvImport() {
    this.msg = '';
    this.msgClass = '';

    this.linesToImport = 0;
    this.linesImported = 0;

    this.csvData.forEach((row: any) => {
      if (row.length > 1) {
        this.linesToImport++;
      }
    });

    if (this.linesToImport <= 1000) {
      //const row = this.csvData[1];
      const delay = 150;
      let index = 0;
      let interval = setInterval(
        function(_this: any) {
          let row = _this.csvData[index];

          if (row.length <= 1) {
            index++;
            row = _this.csvData[index];
          }
          if (row.length > 1) {
            //_this.linesToImport++;
            /*
            name: 'Gericht/Name',
            description: 'Beschreibung',
            price: 'Preis in €',
            photo: 'Foto-URL',
            type: 'Produkttyp',
            additives: 'Zusatzstoffe (kommagetrennt)',
            allergens: 'Allergene (kommagetrennt)',
            deposit: 'Pfand in €',
            sortingOrder: 'Sortierung'
            */
            let resData = {
              name: row[_this.mappingArray[0]] || null,
              description: row[_this.mappingArray[1]] || null,
              newPrice: row[_this.mappingArray[2]] || null,
              photo: row[_this.mappingArray[3]] || null,
              type: row[_this.mappingArray[4]] || null,
              additives: row[_this.mappingArray[5]] || null,
              allergens: row[_this.mappingArray[6]] || null,
              deposit: row[_this.mappingArray[7]] || null,
              sortingOrder: row[_this.mappingArray[8]] || null
            };

            switch (_this.csvTemplate) {
              // case 'Resmio':
              // resData.status = resData.status.replace('Gebucht', 'confirmed').replace('cancelled', 'canceled');
              // break;
              default:
                break;
            }

            /*
            if (resData.status == '' || !resData.status) {
              resData.status = 'pending';
            }
            if (_this.statusRewrite && _this.statusRewrite != '') {
              resData.status = _this.statusRewrite;
            }
            if (_this.defaultValues[3] && _this.defaultValues[3] != '') {
              resData.stayTime = parseInt(_this.defaultValues[3].split(':')[0]);
            }
            if (_this.defaultValues[7] && _this.defaultValues[7] != '') {
              resData.notes = _this.defaultValues[7];
            }
            */

            console.log('resData', resData);
            _this.processImport(resData);
          }
          if (++index === _this.csvData.length) {
            clearInterval(interval);
          }
        },
        delay,
        this
      );
    } else {
      this.msg =
        'Vorgang abgebrochen!<br />Sie versuchen mehr als 1000 Zeilen auf einmal zu importieren. Bitte versuchen Sie es mit einer kürzeren CSV-Datei/Liste erneut.';
      this.msgClass = 'alert alert-danger';
    }
  }

  parseTime(s: string) {
    var c = s.split(':');
    return parseInt(c[0]) * 60 + parseInt(c[1]);
  }

  processImport(item: any) {
    this.menucardsService
      .saveItemFromImport(item)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((res: any) => {
        this.linesImported++;
        console.log('saveItem res:', res, 'item:', item);
        //this.passEntry.emit(reservation);;
      });
  }

  resetImport() {
    this.linesToImport = 0;
    this.linesImported = 0;
    this.csvData = '';
    this.csvTemplate = 'Manuell';
  }

  ngOnInit() {}
}
