import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { AuthenticationService, CredentialsService } from '@app/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private credentialsService: CredentialsService
  ) {}

  getEvents(type: string) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `events/list/` + this.authService.activeClientId + `/${type}`, {
        headers: headers
      })
      .pipe(
        map((events: any) => {
          return events;
        })
      );
  }

  getSingleEvent(eventId: number, type: string) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `events/${this.authService.activeClientId}/${type}/${eventId}`, {
        headers: headers
      })
      .pipe(
        map((event: any) => {
          return event;
        })
      );
  }

  removeEvent(eventId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `events/${eventId}/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((event: any) => {
          return event;
        })
      );
  }

  addEvent(eventData: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    if (!eventData.clientId) {
      eventData.clientId = this.authService.activeClientId;
    }

    const formData = this.objectToFormData(eventData);

    return this.http
      .post<any>(environment.serverUrl + `events/${eventData.clientId}/add`, formData, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  addEventDates(eventData: any, eventId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    if (!eventData.clientId) {
      eventData.clientId = this.authService.activeClientId;
    }

    // const formData = this.objectToFormData(eventData);

    return this.http
      .post<any>(environment.serverUrl + `events/${eventId}/dates/${eventData.clientId}/add`, eventData, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updateEventDates(dateData: any, eventId: number, dateId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    dateData['eventId'] = eventId;
    // const formData = this.objectToFormData(eventData);

    return this.http
      .put<any>(environment.serverUrl + `events/${this.authService.activeClientId}/date/${dateId}`, dateData, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  saveEvent(eventData: any, id: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    if (!eventData.clientId) {
      eventData.clientId = this.authService.activeClientId;
    }

    const formData = this.objectToFormData(eventData);

    return this.http
      .post<any>(environment.serverUrl + `events/${id}/${eventData.clientId}`, formData, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  removeEventDate(eventDateId: number, eventId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `events/${eventId}/date/${eventDateId}/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((event: any) => {
          return event;
        })
      );
  }

  objectToFormData(model: any, form: FormData = null, namespace: string = ''): FormData {
    const formData = form || new FormData();
    let formKey: string;
    console.log('Model =>', model);
    console.log('Form =>', form);
    for (const propertyName in model) {
      if (!model.hasOwnProperty(propertyName)) {
        continue;
      }
      formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
      if (model[propertyName] instanceof Date) {
        formData.append(formKey, model[propertyName].toISOString());
      } else if (model[propertyName] instanceof Array) {
        model[propertyName].forEach((element: any, index: number) => {
          const tempFormKey = `${formKey}[${index}]`;
          this.objectToFormData(element, formData, tempFormKey);
        });
      } else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File)) {
        this.objectToFormData(model[propertyName], formData, formKey);
      } else {
        let itemKey = model[propertyName];
        if (typeof itemKey === 'boolean' && itemKey === true) {
          itemKey = 1;
        }
        if (typeof itemKey === 'boolean' && itemKey === false) {
          itemKey = 0;
        }
        if (itemKey !== undefined) {
          formData.append(formKey, itemKey);
        }
      }
    }
    return formData;
  }

  // getAllDatesAndEvents(params: any) {
  //   const cred = this.credentialsService.credentials;
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     Authorization: 'Bearer ' + cred.token
  //   });
  //   return this.http
  //     .get<any>(environment.serverUrl + `events/${this.authService.activeClientId}/dates/all`, {
  //       headers: headers, params:params
  //     })
  //     .pipe(
  //       map((settings: any) => {
  //         return settings;
  //       })
  //     );
  // }

  getAllDates(type: string, params: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `events/` + this.authService.activeClientId + `/dates/${type}`, {
        headers: headers,
        params: params
      })
      .pipe(
        map((dates: any) => {
          return dates;
        })
      );
  }

  getAllTickets(eventId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `tickets/` + this.authService.activeClientId + `/${eventId}/list`, {
        headers: headers
      })
      .pipe(
        map((tickets: any) => {
          return tickets;
        })
      );
  }

  addTicket(ticketData: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .post<any>(environment.serverUrl + `tickets/${this.authService.activeClientId}/add`, ticketData, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updateTicket(ticketData: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .put<any>(environment.serverUrl + `tickets/${this.authService.activeClientId}/${ticketData.id}`, ticketData, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  removeTicket(ticketData: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(
        environment.serverUrl + `tickets/${ticketData.id}/${this.authService.activeClientId}/${ticketData.eventId}/`,
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getAllSubCategory(ticketData: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(
        environment.serverUrl +
          `tickets/${ticketData.id}/${this.authService.activeClientId}/${ticketData.eventId}/listSubCat`,
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  addSubCategory(categoryData: any, ticketInfo: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    categoryData['eventId'] = ticketInfo['eventId'];
    categoryData['ticketId'] = ticketInfo['id'];

    return this.http
      .post<any>(environment.serverUrl + `tickets/${this.authService.activeClientId}/addSubCat`, categoryData, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updateSubCategory(subCategoryData: any, id: number, ticketInfo: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    subCategoryData['eventId'] = ticketInfo['eventId'];
    subCategoryData['ticketId'] = ticketInfo['id'];

    return this.http
      .put<any>(environment.serverUrl + `tickets/${this.authService.activeClientId}/subCat/${id}`, subCategoryData, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  removeSubCategory(id: number, ticketData: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(
        environment.serverUrl +
          `tickets/${ticketData.id}/${this.authService.activeClientId}/${ticketData.eventId}/subCat/${id}`,
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getAllOptions(ticketData: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(
        environment.serverUrl +
          `tickets/${ticketData.id}/${this.authService.activeClientId}/${ticketData.eventId}/optionList`,
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  addOption(optionData: any, ticketInfo: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    optionData['eventId'] = ticketInfo['eventId'];
    optionData['ticketId'] = ticketInfo['id'];

    return this.http
      .post<any>(environment.serverUrl + `tickets/${this.authService.activeClientId}/addOption`, optionData, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updateOption(optionData: any, id: number, ticketInfo: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    optionData['eventId'] = ticketInfo['eventId'];
    optionData['ticketId'] = ticketInfo['id'];

    return this.http
      .put<any>(environment.serverUrl + `tickets/${this.authService.activeClientId}/option/${id}`, optionData, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  removeOption(id: number, ticketData: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(
        environment.serverUrl +
          `tickets/${ticketData.id}/${this.authService.activeClientId}/${ticketData.eventId}/option/${id}`,
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  addDateTicket(data: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    // const formData = this.objectToFormData(eventData);

    return this.http
      .post<any>(environment.serverUrl + `tickets/${this.authService.activeClientId}/addTicketToDate`, data, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updateDateTicket(data: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    // const formData = this.objectToFormData(eventData);

    return this.http
      .put<any>(
        environment.serverUrl + `tickets/${this.authService.activeClientId}/updateTicketDate/${data.id}`,
        data,
        {
          headers: headers,
          reportProgress: true
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getAllDateTickets(type: string, eventId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(
        environment.serverUrl + `tickets/${this.authService.activeClientId}/${type}/${eventId}/getAllTicketsAllDates`,
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  removeTicketForDate(ticketData: any, dateData: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(
        environment.serverUrl +
          `tickets/${this.authService.activeClientId}/${dateData.eventId}/removeTicketDate/${ticketData.id}`,
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getPreviewData(ticketId: number, dateId: number, eventId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      responseType: 'Buffer',
      Authorization: 'Bearer ' + cred.token
    });
    // const options = { responseType: 'blob'  };
    return this.http
      .get<any>(
        environment.serverUrl +
          `tickets/${this.authService.activeClientId}/ticketId/${ticketId}/eventId/${eventId}/dateId/${dateId}`,
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getLocations(text: string) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `events/location`, {
        headers: headers,
        params: { text }
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  setLocation(data: string) {
    console.log('Data => ', data);
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(environment.serverUrl + `events/location`, data, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getGoogleLocationData(text: any) {
    console.log('Google Call');
    // https://maps.googleapis.com/maps/api/geocode/json?address=lal&key=AIzaSyBvKqFb3-0ryGntQlxwSZWoO9miYqZWRkg
    return this.http
      .get<any>(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${text}&key=AIzaSyBvKqFb3-0ryGntQlxwSZWoO9miYqZWRkg`
      )
      .pipe(
        map((response: any) => {
          console.log('Response => ', response);
          return response;
        })
      );
  }

  getlocationlatlng(lat: any, lng: any) {
    return this.http
      .get<any>(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng= ${lat} , ${lng} &sensor=true&key=AIzaSyBvKqFb3-0ryGntQlxwSZWoO9miYqZWRkg`
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getTicketOrders(parFrom: any = null, parTo: any = null, amountFilter: any = '', parLimit: number = 500) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    const params: any = {
      limit: parLimit,
      from: parFrom,
      to: parTo,
      clientId: this.authService.activeClientId,
      amountFilter: amountFilter
    };

    return this.http
      .get<any>(environment.serverUrl + `admin/ticketOrders`, { headers, params })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  downloadEventTicketDetailsPdf(ticketData: any, format = 'pdf') {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .post<any>(
        environment.serverUrl + `tickets/${this.authService.activeClientId}/downlad-pdf-event-ticket-details`,
        {
          ticketData,
          format
        },
        {
          headers: headers,
          reportProgress: true
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  sendTicketPdf(order: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .post<any>(
        environment.serverUrl + `admin/sendTicketPdf`,
        {
          order: order
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  getTicketTransactions(payoutId: any) {
    let clientId = this.authService.activeClientId;

    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `tickets/payout/transactions/`,
        {
          clientId: this.authService.activeClientId,
          payoutId: payoutId
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((stats: any) => {
          return stats;
        })
      );
  }

  requestPayout(path: string = 'tickets'): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + path + `/payout/`,
        { clientId: this.authService.activeClientId },
        {
          headers: headers
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  cancelOrder(orderId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `tickets/status`,
        {
          orderId: orderId,
          status: 'canceled'
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((order: any) => {
          return order;
        })
      );
  }
  printTicketPayout(source: string, path: string = 'tickets'): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + path + `/printTicketPayout/`,
        {
          html: source
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getTicketSettings() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `tickets/settings/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((stats: any) => {
          return stats;
        })
      );
  }

  saveTicketSettings(settings: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(environment.serverUrl + `tickets/ticket-settings/update/`, settings, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return of(response);
        })
      );
  }

  prepareTicketPdf(ticketCode: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .post<any>(
        environment.serverUrl + `tickets/prepare-ticket-pdf`,
        {
          ticketCode: ticketCode
        },
        {
          headers: headers,
          reportProgress: true
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
  archiveEvent(eventId: number, type: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .put<any>(
        environment.serverUrl + `events/update-event/${eventId}`,
        {
          type: type
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((event: any) => {
          return event;
        })
      );
  }

  moveTicketDate(data: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .post<any>(environment.serverUrl + `tickets/${this.authService.activeClientId}/moveTicketDate/`, data, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  saveEventSettings(eventId: number, data: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .put<any>(environment.serverUrl + `events/save-event-settings/${eventId}`, data, {
        headers: headers
      })
      .pipe(
        map((event: any) => {
          return event;
        })
      );
  }
}
