import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { DocumentsService } from '../documents.service';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss']
})
export class UploadDocumentComponent implements OnInit {
  @Output() addDocumentResponse: EventEmitter<any> = new EventEmitter();
  @ViewChild('fileInput', { static: false }) fileInput: any;
  private ngUnsubscribe: Subject<any> = new Subject();
  documentName: string;
  fileName: string;
  fileData: any;
  isClicked: boolean;
  formData: any;

  constructor(public activeModal: NgbActiveModal, private documentService: DocumentsService) {
    this.isClicked = false;
  }

  ngOnInit() {}

  onChange(file: File) {
    if (file) {
      this.fileData = file;
      this.fileName = this.fileData.name;
      this.fileData.sizeInKB = (this.fileData.size / 1024).toFixed(2);
    }
  }

  removeFile() {
    this.fileInput.nativeElement.value = '';
    this.fileData = null;
    this.fileName = null;
  }

  uploadDoc() {
    this.isClicked = true;
    if (!this.documentName || !this.fileData) {
      return;
    } else {
      this.formData = new FormData();
      this.formData.append(`file`, this.fileData);
      this.formData.append(`name`, this.documentName);
      this.documentService
        .uploadDocument(this.formData)
        .takeUntil(this.ngUnsubscribe)
        .subscribe((response: any) => {
          this.addDocumentResponse.emit(response);
        });
    }
  }
}
