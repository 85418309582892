import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyEntryRoutingModule } from './my-entry-routing.module';
import { SharedModule } from '@app/shared';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatIconModule,
  MatDatepickerModule,
  MatTabsModule,
  MatSnackBarModule,
  MatSlideToggleModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatExpansionModule,
  MatAutocompleteModule
} from '@angular/material';
import { GenerallyComponent } from './generally/generally.component';
import { ContactDetailsComponent } from './contact-details/contact-details.component';

@NgModule({
  declarations: [GenerallyComponent, ContactDetailsComponent],
  imports: [
    CommonModule,
    MyEntryRoutingModule,
    MatIconModule,
    MatDatepickerModule,
    MatTabsModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatAutocompleteModule,
    SharedModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class MyEntryModule {}
