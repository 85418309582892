import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthenticationService, CredentialsService } from '@app/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private credentialsService: CredentialsService
  ) {}

  getAlbums() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `client/${this.authService.activeClientId}/get-albums`, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getAlbumById(id: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `client/${this.authService.activeClientId}/get-album/${id}`, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updateAlbum(albumData: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .put<any>(environment.serverUrl + `client/${this.authService.activeClientId}/album/${albumData.id}`, albumData, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteAlbum(id: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .delete<any>(environment.serverUrl + `client/${this.authService.activeClientId}/album/${id}`, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  createAlbum(albumData: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .post<any>(environment.serverUrl + `client/${this.authService.activeClientId}/album/new`, albumData, {
        headers: headers,
        reportProgress: true
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  uploadPhotos(data: any, albumId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .post<any>(
        environment.serverUrl + `client/${this.authService.activeClientId}/album/${albumId}/upload-photos`,
        data,
        {
          reportProgress: true
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updateAlbumPhoto(albumData: any, albumId: number, photoId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .put<any>(
        environment.serverUrl + `client/${this.authService.activeClientId}/album/${albumId}/photo/${photoId}`,
        albumData,
        {
          headers: headers,
          reportProgress: true
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteAlbumPhoto(albumId: number, photoId: number) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .delete<any>(
        environment.serverUrl + `client/${this.authService.activeClientId}/album/${albumId}/photo/${photoId}`,
        {
          headers: headers,
          reportProgress: true
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
