import { Component, OnInit } from '@angular/core';
import { ClientService } from '@app/core/client.service';

@Component({
  selector: 'app-gastro-pay-info',
  templateUrl: './gastro-pay-info.component.html',
  styleUrls: ['./gastro-pay-info.component.scss']
})
export class GastroPayInfoComponent implements OnInit {
  signupObservable: any;

  constructor(private clientService: ClientService) {}

  ngOnInit() {}

  requestMoreInfo() {
    this.signupObservable = this.clientService.signupGastroPay().subscribe(() => {});
  }
}
