import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { MenucardsService } from '../menucards.service';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent implements OnInit {
  @Input() public editCategoryData: any; // only if editing
  @Input() public menuId: number;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  categoryFormGroup: FormGroup;
  error: string;
  addObservable: any;
  editObservable: any;
  deleteObservable: any;
  progress: number;
  imageUrl: string | ArrayBuffer;
  fileName: string;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    public activeModal: NgbActiveModal,
    private snackBar: MatSnackBar,
    public formBuilder: FormBuilder,
    private menucardService: MenucardsService,
    private modalService: NgbModal
  ) {
    this.categoryFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      description: [''],
      file: [''],
      deletePhoto: [false]
    });
  }

  ngOnInit() {
    if (this.editCategoryData) {
      this.categoryFormGroup.addControl('id', new FormControl(this.editCategoryData.id, Validators.required));
      this.categoryFormGroup.get('name').setValue(this.editCategoryData.name);
      this.categoryFormGroup.get('description').setValue(this.editCategoryData.description);

      if (this.editCategoryData.photo) {
        this.fileName = this.editCategoryData.photo;
        this.imageUrl = 'https://cdn.gastroguide.de/' + this.editCategoryData.photo;
      }
    }
  }

  add() {
    if (!this.categoryFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    this.error = null;
    this.addObservable = this.menucardService
      .addCategory(this.menuId, this.categoryFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          this.error = err.error.msg;
        }
      );
  }

  save() {
    if (!this.categoryFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    this.error = null;
    this.editObservable = this.menucardService
      .saveCategory(this.categoryFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.passEntry.emit(response);
        },
        err => {
          this.error = err.error.msg;
        }
      );
  }

  delete() {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Kategorie löschen';
    modalRef.componentInstance.message = `Sind Sie sicher dass Sie die Kategorie löschen möchten? Diese Aktion ist unwiederruflich.`;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      (result: any) => {
        if (result === 'ok') {
          this.menucardService.deleteCategory(this.editCategoryData.id).subscribe(
            () => {
              this.passEntry.emit('delete');
              this.activeModal.dismiss();
              this.snackBar.open('Kategorie wurde erfolgreich gelöscht', '', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            },
            (err: any) => {
              this.snackBar.open(err.error.msg, '', {
                duration: 2000,
                panelClass: ['snackbar-error']
              });
            }
          );
        }
      },
      () => {}
    );
  }

  onChange(file: File) {
    if (file) {
      console.log(file);
      const img = new Image();
      img.src = window.URL.createObjectURL(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);

      img.onload = event => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        if (width < 800 && height < 200) {
          this.snackBar.open('Foto muss mindestens 800px breit und 200px hoch sein', '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
          return;
        }

        const fileTypes = ['image/jpeg', 'image/gif', 'image/jpg', 'image/png'];

        if (!fileTypes.includes(file.type)) {
          this.snackBar.open('Nicht unterstütztes Dateiformat. Nur jpg, gif und png', '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
          return;
        }

        this.imageUrl = reader.result;
        this.fileName = file.name;
        this.categoryFormGroup.get('file').setValue(file);
      };
    }
  }

  removeFile() {
    this.fileName = null;
    this.categoryFormGroup.get('file').setValue('');
    this.categoryFormGroup.get('deletePhoto').setValue(true);
    this.imageUrl = null;
  }
}
