import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'specialCharacters' })
export class SpecialCharactersPipe implements PipeTransform {
  transform(value: string): string {
    const div = document.createElement('div');
    div.innerHTML = value;
    return div.textContent || div.innerText || '';
  }
}
