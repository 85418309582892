import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Subscription, Subject } from 'rxjs';
import { VouchersService } from '@app/vouchers/vouchers.service';
import { MatSnackBar } from '@angular/material';
import { ClientResolverService } from '@app/core/client-resolver.service';
import moment from 'moment';
moment.locale('de');

@Component({
  selector: 'app-vouchers',
  templateUrl: './vouchers.component.html',
  styleUrls: ['./vouchers.component.scss']
})
export class VouchersComponent implements OnInit {
  csvOptions: any = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: false,
    headers: ['ID', 'Datum', 'Uhrzeit', 'Besteller', 'Betrag', 'Gebühr', 'Zahlart', 'Typ', 'Status', 'Kontostand'],
    showTitle: false,
    title: '',
    useBom: false,
    removeNewLines: true,
    keys: [
      'id',
      'dateFormatted',
      'timeFormatted',
      'person',
      'subtotal',
      'commissionFee',
      'paymentMethodText',
      'typeText',
      'statusText',
      'balance'
    ]
  };

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  transactions: any[] = [];
  filterFrom: string = '';
  filterTo: string = '';
  now: any = new Date();
  currentDate = new Date();
  tableLimit: number = 1;
  filterStats: any = {
    orders: {
      count: 0,
      total: 0,
      cash: 0,
      gastropay: 0,
      online: 0,
      commission: 0
    }
  };
  stats: any = {
    orders: {
      total: 0,
      yesterday: 0,
      today: 0
    },
    balance: {
      total: 0
    },
    sum: {
      total: 0,
      yesterday: 0,
      today: 0
    }
  };
  dates: any = {
    from: '',
    to: ''
  };
  pushedPayouts: any[] = [];
  pushedOrders: any[] = [];

  private _ordersSub: Subscription;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private voucherService: VouchersService,
    private snackBar: MatSnackBar,
    private clientResolver: ClientResolverService
  ) {}

  ngOnInit() {
    this.getVouchers();
  }

  getVouchers(initialStart: boolean = false) {
    this.voucherService
      .getBalanceTransactions(this.filterFrom, this.filterTo)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(res => {
        this.transactions = res;
      });
  }

  arrayToCSV(objArray: any = []) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map(value => `"${value}"`)
        .join(',')}` + '\r\n';

    return array.reduce((str: any, next: any) => {
      str +=
        `${Object.values(next)
          .map(value => `"${value}"`)
          .join(',')}` + '\r\n';
      return str;
    }, str);
  }

  filterDates(filterBy: string, event: any) {
    const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });
    const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(event.value._d);

    let filterDate = `${year}-${month}-${day} 00:00:00`;
    if (filterBy == 'to') {
      filterDate = `${year}-${month}-${day} 23:59:59`;
    }
  }

  getFilterStats() {
    this.filterStats.orders.count = 0;
    this.filterStats.orders.total = 0;
    this.filterStats.orders.commission = 0;
    this.filterStats.orders.cash = 0;
    this.filterStats.orders.gastropay = 0;
    this.filterStats.orders.online = 0;

    this.transactions.forEach((order: any) => {});
    //console.log('getFilterStats',this.allOrders);
  }

  setDates(type: string) {
    if (type == 'today') {
      this.filterDates('from', { value: { _d: new Date() } });
      this.filterDates('to', { value: { _d: new Date() } });
    }
    if (type == 'yesterday') {
      let yesterday: Date = new Date();
      yesterday.setDate(yesterday.getDate() - 1);

      this.filterDates('from', { value: { _d: yesterday } });
      this.filterDates('to', { value: { _d: yesterday } });
    }
    if (type == 'thisWeek') {
      const date = new Date();
      this.filterDates('from', { value: { _d: this.getMonday(date) } });

      const date2 = new Date();
      this.filterDates('to', { value: { _d: this.getSunday(date2) } });
    }
    if (type == 'lastSeven') {
      let date = new Date();
      date.setDate(date.getDate() - 6);
      this.filterDates('from', { value: { _d: date } });
      date.setDate(date.getDate() + 6);
      this.filterDates('to', { value: { _d: date } });
    }
    if (type == 'lastWeek') {
      let date = new Date();
      date.setDate(date.getDate() - 7);
      this.filterDates('from', { value: { _d: this.getMonday(date) } });
      this.filterDates('to', { value: { _d: this.getSunday(date) } });
    }
    if (type == 'lastMonth') {
      let date = new Date();
      let startDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      let endDate = new Date(date.getFullYear(), date.getMonth(), 1);
      endDate.setDate(endDate.getDate() - 1);

      this.filterDates('from', { value: { _d: startDate } });
      this.filterDates('to', { value: { _d: endDate } });
    }
    if (type == 'thisMonth') {
      let date = new Date();
      let startDate = new Date(date.getFullYear(), date.getMonth(), 1);
      let endDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      endDate.setDate(endDate.getDate() - 1);

      this.filterDates('from', { value: { _d: startDate } });
      this.filterDates('to', { value: { _d: endDate } });
    }
  }
  getMonday(d: any) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }
  getSunday(d: any) {
    d = new Date(d);
    const s = moment(d).day(7);
    return s;
  }

  returnPrice(price: any) {
    if (typeof price == 'string') {
      return parseFloat(price.replace(',', '.'));
    } else {
      return price || 0;
    }
  }
}
