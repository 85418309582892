import { Component, OnInit } from '@angular/core';
import { ClientService } from '@app/core/client.service';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent implements OnInit {
  addressGuest: string = 'NA';
  selectOptions: any[] = [
    {
      value: 'du',
      label: 'Du'
    },
    {
      value: 'sie',
      label: 'Sie'
    }
  ];
  loading: boolean = false;

  private ngUnsubscribe: Subject<any> = new Subject();
  constructor(private clientService: ClientService, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.getGreetingText();
  }

  getGreetingText() {
    this.loading = true;
    this.clientService
      .getClientGreetingText()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((response: any) => {
        if (response.status) {
          this.loading = false;
          this.addressGuest = response.settings.pronominalForm;
        } else {
          this.loading = false;
          this.addressGuest = 'NA';
          // this.snackBar.open('Something went wrong', '', {
          //   duration: 2000,
          //   panelClass: ['snackbar-error']
          // });
        }
      });
  }
  prepareChartData(event: Event) {
    this.loading = true;
    const value = event['value'];
    const checkUsername = (obj: any) => obj.value === value;
    if (this.selectOptions.some(checkUsername)) {
      this.clientService
        .saveClientGreetingText(value)
        .takeUntil(this.ngUnsubscribe)
        .subscribe((response: any) => {
          if (response.status) {
            this.loading = false;
            this.addressGuest = response.settings.pronominalForm;
            this.snackBar.open('Successfully Updated', '', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          } else {
            this.snackBar.open('Something went wrong', '', {
              duration: 2000,
              panelClass: ['snackbar-error']
            });
          }
        });
    }
  }
}
