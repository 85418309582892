import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { EventsService } from '../events.service';
import { MatSnackBar } from '@angular/material';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-edit-subcategory',
  templateUrl: './add-edit-subcategory.component.html',
  styleUrls: ['./add-edit-subcategory.component.scss']
})
export class AddEditSubcategoryComponent implements OnInit {
  @Input() public categoryInfo: any;
  @Output() cancelSubCat: EventEmitter<any> = new EventEmitter();
  @Output() addCategoryResp: EventEmitter<any> = new EventEmitter();
  @Output() updateCategoryResp: EventEmitter<any> = new EventEmitter();
  subCategoryFormGroup: FormGroup;
  error: string;
  isEditCategory: boolean;
  private ngUnsubscribe: Subject<any> = new Subject();
  constructor(
    private formBuilder: FormBuilder,
    private eventsService: EventsService,
    private snackBar: MatSnackBar,
    public activeModal: NgbActiveModal
  ) {
    this.subCategoryFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      description: [''],
      price: [0]
    });
  }

  ngOnInit() {
    this.isEditCategory = this.categoryInfo.isEditCategory;
    if (this.isEditCategory) {
      const { subCategoryData } = this.categoryInfo;
      this.subCategoryFormGroup.get('name').setValue(subCategoryData.name);
      this.subCategoryFormGroup.get('description').setValue(subCategoryData.description);
      this.subCategoryFormGroup.get('price').setValue(subCategoryData.price);
    }
  }

  add() {
    if (!this.subCategoryFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    const categoryData = this.subCategoryFormGroup.value;
    // ticketData['eventId'] = this.ticketInfo.eventId

    if (this.categoryInfo.isTicket) {
      this.eventsService
        .addSubCategory(categoryData, this.categoryInfo.ticketInfo)
        .takeUntil(this.ngUnsubscribe)
        .subscribe(
          (response: any) => {
            this.addCategoryResp.emit(response);
          },
          err => {
            this.error = err.error.msg;
          }
        );
    } else {
      this.addCategoryResp.emit(categoryData);
    }
  }

  cancel() {
    this.cancelSubCat.emit();
  }

  save() {
    if (!this.subCategoryFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    const subCategoryData = this.subCategoryFormGroup.value;

    this.eventsService
      .updateSubCategory(subCategoryData, this.categoryInfo.subCategoryData.id, this.categoryInfo.ticketInfo)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (response: any) => {
          this.snackBar.open('Unterkategorie erfolgreich aktualisiert', '', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.updateCategoryResp.emit(response);
        },
        err => {
          this.error = err.error.msg;
          this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      );
  }
}
