import { IAfterGuiAttachedParams } from '@ag-grid-enterprise/all-modules';
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { GastroPayService } from '@app/gastro-pay/gastro-pay.service';
import { MenucardsService } from '@app/menucards/menucards.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'ng-select-renderer',
  template: `
    <ng-select
      [items]="items"
      [multiple]="false"
      bindLabel="name"
      placeholder="Ort auswählen..."
      [closeOnSelect]="true"
      [(ngModel)]="selectedItem"
      appendTo="body"
      [clearable]="true"
      [disabled]="isDisabled"
      #select
    >
    </ng-select>
  `
})
export class NgSelectRendererComponent implements ICellRendererAngularComp, OnDestroy {
  params: any;
  isDisabled: boolean;
  items: any[] = [];
  selectedItem: any = [];
  @ViewChild(NgSelectComponent, { static: false }) select: NgSelectComponent;

  constructor(private gastropayService: GastroPayService) {}

  ngOnDestroy(): void {}
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    console.log(this.select);
    setTimeout(() => {
      this.select.open();
    });
  }

  agInit(params: any): void {
    console.log('init', params.data.zipCode.length);
    this.params = params;
    this.selectedItem = this.params.data.location;
    if (this.params.data.zipCode.length < 5) {
      console.log('DISABLED');
      this.isDisabled = true;
    }
    this.getItems();
  }

  getItems() {
    this.gastropayService.getZipcodeLocations(this.params.data.zipCode).subscribe(locations => {
      console.log(60, locations);
      this.items = locations;
    });
  }

  getValue(): any {
    return this.selectedItem;
  }
}
