import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MenucardsService } from '../menucards.service';
import { MatSnackBar, MatSidenav } from '@angular/material';
import { NavService } from '@app/nav.service';
import { CheckboxRendererComponent } from './renderers/checkbox-renderer.component';
import { LicenseManager } from 'ag-grid-enterprise';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { ItemLayerComponent } from '../item-layer/item-layer.component';
import { Subject } from 'rxjs';
import { NoEditorComponent } from './renderers/no-editor.component';
import { ToggleRendererComponent } from './renderers/toggle-renderer.component';
import { PhotoRendererComponent } from './renderers/photo-renderer.component';
import { CurrencyPipe } from '@angular/common';
import { NgSelectRendererComponent } from './renderers/ng-select-renderer.component';
import { TagsRendererComponent } from './renderers/tags-renderer.component';
import { ClientSideRowModelModule } from '@ag-grid-enterprise/all-modules';
declare var AG_GRID_LOCALE_DE: any;

@Component({
  selector: 'app-manage-items',
  templateUrl: './manage-items.component.html',
  styleUrls: ['./manage-items.component.scss']
})
export class ManageItemsComponent implements OnInit {
  activeClient: any;
  @ViewChild('contentBoxEl', { static: false }) contentBoxEl: ElementRef;
  itemIndex = 0;
  priceCategories: any = [];
  items: any;
  frameworkComponents = {
    toggleRenderer: ToggleRendererComponent,
    checkboxRenderer: CheckboxRendererComponent,
    photoRenderer: PhotoRendererComponent,
    noEditor: NoEditorComponent,
    ngSelectEditor: NgSelectRendererComponent,
    tagsRenderer: TagsRendererComponent
  };
  modules: any[] = [ClientSideRowModelModule, RangeSelectionModule];
  gridLocale = AG_GRID_LOCALE_DE;
  gridOptions: any = {};
  sideBar: any;
  uploadQueue: any = [];
  rangeSelectionMode = false;

  columnDefs = [
    {
      rowDrag: true,
      rowDragText: (params: any) => {
        return (params.rowNode.data.productId ? params.rowNode.data.productId + '. ' : '') + params.rowNode.data.name;
      },
      field: 'isOrderable',
      headerName: 'Bestellbar',
      cellRenderer: 'toggleRenderer',
      width: 100,
      headerTooltip: 'Soll der Artikel bestellbar sein?',
      lockPosition: true,
      floatingFilter: true,
      editable: true,
      cellEditor: 'noEditor',
      sortable: false
    },
    {
      field: 'isVisible',
      headerName: 'Sichtbar',
      cellRenderer: 'toggleRenderer',
      width: 80,
      headerTooltip: 'Soll der Artikel für Gäste sichtbar sein?',
      editable: true,
      cellEditor: 'noEditor',
      sortable: false,
      hide: true
    },
    {
      field: 'productId',
      headerName: 'ArtikelID',
      width: 100,
      editable: true,
      hide: true
    },
    {
      field: 'cashregisterId',
      headerName: 'KassenID',
      width: 100,
      editable: true,
      hide: true
    },
    {
      field: 'photo',
      headerName: 'Foto',
      width: 80,
      editable: true,
      cellRenderer: 'photoRenderer',
      cellStyle: { textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' },
      cellEditor: 'noEditor'
    },
    {
      field: 'name',
      headerName: 'Name',
      editable: true,
      minWidth: 160,
      filter: 'agTextColumnFilter',
      floatingFilter: true
    },
    {
      field: 'description',
      headerName: 'Beschreibung',
      minWidth: 180,
      editable: true,
      cellEditor: 'agLargeTextCellEditor',
      filter: 'agTextColumnFilter',
      floatingFilter: true
    },
    {
      field: 'unitValue',
      headerName: 'Menge',
      width: 80,
      editable: true,
      cellStyle: { textAlign: 'right' }
    },
    {
      field: 'unit',
      headerName: 'Einheit',
      editable: true,
      sortable: false,
      width: 90,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['', 'Stk', 'l', 'ml', 'g', 'kg']
      },
      filter: 'agSetColumnFilter',
      floatingFilter: true,
      filterParams: {
        suppressMiniFilter: true
      }
    },
    {
      field: 'type',
      headerName: 'Typ',
      cellEditor: 'agSelectCellEditor',
      editable: true,
      width: 90,
      cellEditorParams: {
        values: ['Produkt', 'Getränk', 'Beilage', 'Zutat', 'Dip', 'Extra', 'Menü']
      },
      sortable: false,
      filter: 'agSetColumnFilter',
      floatingFilter: true,
      filterParams: {
        suppressMiniFilter: true
      }
    },
    {
      field: 'isVegetarian',
      headerName: 'Vegetarisch',
      cellRenderer: 'checkboxRenderer',
      width: 80,
      editable: true,
      cellStyle: { textAlign: 'center' },
      cellEditor: 'noEditor'
    },
    {
      field: 'isVegan',
      headerName: 'Vegan',
      cellRenderer: 'checkboxRenderer',
      width: 80,
      editable: true,
      cellStyle: { textAlign: 'center' },
      cellEditor: 'noEditor'
    },
    {
      field: 'allergens',
      headerName: 'Allergene',
      width: 100,
      editable: true
    },
    {
      field: 'additives',
      headerName: 'Zusatzstoffe',
      width: 100,
      editable: true
    },
    {
      field: 'tags',
      headerName: 'Tags',
      width: 180,
      editable: true,
      cellRenderer: 'tagsRenderer',
      cellEditor: 'ngSelectEditor'
    },
    {
      field: 'deposit',
      headerName: 'Pfand',
      editable: true,
      width: 100,
      hide: true,
      valueFormatter: (params: any) => this.currencyFormatter(params.data.deposit)
    }
  ];

  defaultColDef = {
    lockPosition: true,
    suppressMenu: true,
    enablePivot: false,
    sortable: true,
    comparator: this.columnSorting
  };
  rowSelection = 'multiple';
  private gridApi: any;
  private gridColumnApi: any;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private menucardService: MenucardsService,
    private snackBar: MatSnackBar,
    private nav: NavService,
    private clientResolver: ClientResolverService,
    private modalService: NgbModal,
    private currencyPipe: CurrencyPipe
  ) {
    this.activeClient = this.clientResolver.client;
    LicenseManager.setLicenseKey('your-license-key'); // We need this line to trigger enterprise mode

    this.sideBar = {
      position: 'left',
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true
          }
        }
      ]
    };
  }

  ngOnInit() {
    this.nav.closeNav();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // this.gridApi.setFillHandleDirection('y');

    this.gridOptions.getRowNodeId = function(data: any) {
      return data.index;
    };

    this.menucardService.getPriceCategories().subscribe((cats: any) => {
      this.priceCategories = cats;
      cats.forEach((cat: any) => {
        const catColumn: any = {
          headerName: cat.name,
          headerTooltip: cat.name,
          field: 'price.' + cat.id,
          editable: true,
          width: 100,
          cellRenderer: (cellParams: any) => {
            return this.currencyFormatter(
              cellParams.data.price && cellParams.data.price.hasOwnProperty(cat.id)
                ? cellParams.data.price[cat.id]
                : null
            );
          }
          // valueFormatter: (p: any) => this.currencyFormatter(p.data.deposit, '€')
        };
        this.columnDefs.push(catColumn);
        this.gridApi.setColumnDefs(this.columnDefs);
      });

      this.getItems();
    });
  }

  getItems() {
    this.menucardService.getClientItems().subscribe(items => {
      this.items = items.map((i: any) => {
        i.index = this.itemIndex;
        this.itemIndex += 1;
        return i;
      });

      for (let index = 0; index < 20; index++) {
        this.addEmptyRow();
      }
    });
  }

  addEmptyRow() {
    const priceObj = {};
    for (let i = 0; i < this.priceCategories.length; i++) {
      priceObj[this.priceCategories[i].id] = null;
    }
    const defaultObj: any = {
      additives: null,
      allergens: null,
      cashregisterId: null,
      clientId: this.activeClient.id,
      deposit: 0,
      description: '',
      id: null,
      isVisible: 1,
      isOrderable: 1,
      isVegan: 0,
      isVegetarian: 0,
      name: '',
      photo: null,
      price: priceObj,
      prices: [],
      productId: null,
      type: 'Produkt',
      unit: '',
      unitValue: null,
      index: this.itemIndex
    };
    this.items.push(defaultObj);
    this.itemIndex += 1;
    const res = this.gridApi.applyTransaction({
      add: [defaultObj]
    });
  }

  currencyFormatter(value: string) {
    if (!value || value === '0.00' || value === '0,00') {
      return '-';
    }
    if (value === '-1' || value === '-1.00') {
      return 'n.A';
    }
    value = value.toString().replace(',', '.');
    value = parseFloat(value).toFixed(2);
    value = this.currencyPipe.transform(value, 'EUR');
    return value;
  }

  onCellValueChanged(e: any) {
    const itemData = { ...e.data };
    console.log('update', itemData);

    if (!itemData.id || this.uploadQueue.length) {
      // If no name is provided, do not submit because name is needed
      if (!itemData.name) {
        return;
      }
      // If id is null (new item), remove the id property from the object
      delete itemData.id;

      // If its coming from a paste or a range selection fill, work with a queue because if not multiple items will be created
      // because agGrid runs this even for each cell
      if (this.rangeSelectionMode || e.source === 'paste') {
        // Add to upload queue
        const queueObject = {
          index: e.rowIndex,
          data: itemData
        };
        const findIndex = this.uploadQueue.findIndex((uq: any) => uq.index === e.rowIndex);
        if (findIndex > -1) {
          this.uploadQueue[findIndex] = queueObject;
        } else {
          this.uploadQueue.push(queueObject);
        }
        return;
      }
    }
    this.menucardService.saveItem(e.data).subscribe((itemRes: any) => {
      // If is a new row (there was no id), add new empty row
      if (!itemData.id) {
        this.addEmptyRow();
      }
      const index = this.items.findIndex((i: any) => i === e.data);
      if (index > -1) {
        this.items[index].id = itemRes.id;
      }
      itemRes.index = itemData.index;
      itemRes.price = itemData.price;
      this.gridApi.applyTransaction({ update: [itemRes] });
      this.gridApi.refreshCells({ rowNodes: [itemRes] });
    });
  }

  onRangeSelectionChanged(e: any) {
    // If range selection started set rangeSelectionMode mode on
    if (e.started && !e.finished) {
      this.rangeSelectionMode = true;
    }

    // If range selection ended, start uploading articles
    if (!e.started && e.finished && this.uploadQueue.length) {
      // Upload rows from queue
      this.processQueue();
      this.rangeSelectionMode = false;
    }
  }

  onPasteEnd(e: any) {
    console.log(e);
    // Upload queue now
    this.processQueue();
  }

  processQueue() {
    this.uploadQueue.forEach((row: any) => {
      this.menucardService.saveItem(row.data).subscribe((itemRes: any) => {
        // Remove from Queue
        const queueIndex = this.uploadQueue.findIndex((up: any) => (this.uploadQueue.index = row.index));
        this.uploadQueue.splice(queueIndex, 1);

        // Apply grid transaction to update row ids
        this.items[row.index].id = itemRes.id;

        // Get row node
        const rowNode = this.gridApi.getDisplayedRowAtIndex(row.index);
        rowNode.data.id = itemRes.id;
        this.gridApi.applyTransaction({ update: [rowNode.data] });
        this.addEmptyRow();
      });
    });
  }

  getContextMenuItems = (params: any) => {
    console.log(params);
    const result = [
      {
        icon: '<i class="fas fa-info"></i>',
        name: 'Artikelinfos',
        action: () => {
          if (!params || !params.node) {
            this.snackBar.open('Unbekannter Fehler', '', {
              duration: 2000,
              panelClass: ['snackbar-error']
            });
            return;
          }
          this.openItem(params.node.data);
        }
      },
      {
        icon: '<i class="fas fa-ban"></i>',
        name: 'Foto entfernen',
        disabled: !params.node.data.photo,
        action: () => {
          this.removePhoto(params.node.data);
        }
      },
      'separator',
      'export',
      'copy',
      'separator',
      {
        icon: '<i class="far fa-trash-alt"></i>',
        name: 'Ausgewählte Artikel löschen',
        action: () => {
          this.deleteItems(this.gridApi.getCellRanges()[0]);
        },
        cssClasses: ['text-danger']
      }
    ];
    return result;
    // tslint:disable-next-line:semicolon
  };

  openItem(data: any) {
    const modalRef = this.modalService.open(ItemLayerComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.product = { ...data };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      // Find menu in array to update it
      const index = this.items.findIndex((m: any) => m.id === receivedEntry.id);
      this.items[index] = receivedEntry;

      receivedEntry.prices = data.prices;
      // receivedEntry.price = data.price;
      receivedEntry.index = data.index;

      this.gridApi.applyTransaction({ update: [receivedEntry] });

      const snackBarRef = this.snackBar.open('Artikel erfolgreich gespeichert', 'Ok', {
        duration: 3000
      });
      modalRef.close();
    });
  }

  removePhoto(data: any) {
    data.photo = '';
    this.gridApi.applyTransaction({ update: [data] });
    this.menucardService.saveItem(data).subscribe((itemRes: any) => {});
  }

  deleteItems(ranges: any) {
    const start =
      ranges.startRow.rowIndex <= ranges.endRow.rowIndex ? ranges.startRow.rowIndex : ranges.endRow.rowIndex;
    const end = ranges.endRow.rowIndex >= ranges.startRow.rowIndex ? ranges.endRow.rowIndex : ranges.startRow.rowIndex;

    const rowsToDelete: any = [];
    for (let index = start; index <= end; index++) {
      const row = this.gridApi.getDisplayedRowAtIndex(index);
      if (row && row.data) {
        rowsToDelete.push(row.data);
      }
    }

    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Artikel löschen';
    if (end - start > 0) {
      modalRef.componentInstance.message = `Sind Sie sicher dass Sie <b>${rowsToDelete.length} Artikel</b> löschen möchten? Diese Aktion ist unwiederruflich.`;
    } else {
      modalRef.componentInstance.message = `Sind Sie sicher dass Sie den Artikel <b>${rowsToDelete[0].name}</b> löschen möchten? Diese Aktion ist unwiederruflich.`;
    }
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      (result: any) => {
        if (result === 'ok') {
          // Remove from grid
          this.gridApi.applyTransaction({ remove: rowsToDelete });

          rowsToDelete.forEach((row: any) => {
            // Find in items array and remove from there as well
            const itemIndex = this.items.findIndex((item: any) => (item.id = row.id));
            if (itemIndex > -1) {
              this.items.splice(itemIndex, 1);
            }

            this.menucardService.deleteItem(row.id).subscribe(
              () => {
                this.snackBar.open('Artikel wurde erfolgreich gelöscht', '', {
                  duration: 2000,
                  panelClass: ['snackbar-success']
                });
              },
              (err: any) => {
                this.snackBar.open(err && err.error ? err.error.msg : 'Unbekannter Fehler', '', {
                  duration: 2000,
                  panelClass: ['snackbar-error']
                });
              }
            );
          });
        }
      },
      () => {}
    );
  }

  deleteItem(itemData: any) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Artikel löschen';
    modalRef.componentInstance.message = `Sind Sie sicher dass Sie den Artikel <b>${itemData.name}</b> löschen möchten? Diese Aktion ist unwiederruflich.`;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      (result: any) => {
        if (result === 'ok') {
          this.menucardService.deleteItem(itemData.id).subscribe(
            () => {
              // Remove from grid
              this.gridApi.applyTransaction({ remove: [itemData] });

              this.snackBar.open('Artikel wurde erfolgreich gelöscht', '', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            },
            (err: any) => {
              this.snackBar.open(err && err.error ? err.error.msg : 'Unbekannter Fehler', '', {
                duration: 2000,
                panelClass: ['snackbar-error']
              });
            }
          );
        }
      },
      () => {}
    );
  }

  onRowDragEnd(e: any) {
    const rowData: any = [];
    this.gridApi.forEachNode((node: any) => {
      if (node.data.id) {
        rowData.push(node.data);
      }
    });
    console.log(rowData);
    this.menucardService.reorderMenucardItems(rowData).subscribe((res: any) => {});
  }

  columnSorting(a: any, b: any, nodeA: any, nodeB: any) {
    if (nodeA && nodeA.data && !nodeA.data.id) {
      // If no id is present, ignore the row because its empty
      return 0;
    }
    return a > b ? 1 : -1;
  }
}
