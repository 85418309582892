import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { GastroPayService } from '../gastro-pay.service';
import { Subject } from 'rxjs';
import { MatSnackBar, MatTabGroup } from '@angular/material';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { ClientResolverService } from '@app/core/client-resolver.service';
import moment from 'moment';
import { Options, LabelType } from 'ng5-slider';
import { ClientSideRowModelModule, RangeSelectionModule } from '@ag-grid-enterprise/all-modules';
import { LicenseManager } from 'ag-grid-enterprise';
import { NgSelectRendererComponent } from './renderers/ng-select-renderer.component';
import { CurrencyPipe } from '@angular/common';
import { NgSelectDistrictRendererComponent } from './renderers/ng-select-district-renderer.component';
import { AddSpecialTimesComponent } from './add-special-times/add-special-times.component';
declare var AG_GRID_LOCALE_DE: any;

@Component({
  selector: 'app-gastro-pay-settings',
  templateUrl: './gastro-pay-settings.component.html',
  styleUrls: ['./gastro-pay-settings.component.scss']
})
export class GastroPaySettingsComponent implements OnInit, OnDestroy {
  generalSettings: FormGroup;
  analyticsForm: FormGroup;
  orderSystemVersion = 1;
  deliveryAreaForm: FormGroup;
  deliveryAreas: Array<any> = [];
  ordershopLink: string;
  inhouseshopLink: string;
  activeClient: any;
  timesForm: FormGroup;
  timeOptionsForm: FormGroup;
  timesArray: any = [];
  itemIndex = 0;
  frameworkComponents = {
    ngSelectEditor: NgSelectRendererComponent,
    ngSelectEditorDistricts: NgSelectDistrictRendererComponent
  };
  modules: any[] = [ClientSideRowModelModule, RangeSelectionModule];
  gridLocale = AG_GRID_LOCALE_DE;
  gridOptions: any = {};
  sideBar: any;
  uploadQueue: any = [];
  rangeSelectionMode = false;
  limitFormGroup: FormGroup;
  maxOrderLimit: FormArray;
  limitArray: any;
  times: any = [];
  defaultTime: string;
  specialGastroPayTimes: any = [];
  tableLimit: number = 10;
  tablePages: any[] = [];
  tableOffset: number = 1;
  columnDefs = [
    {
      field: 'zipCode',
      headerName: 'Postleitzahl',
      width: 200,
      editable: true,
      sort: 'asc'
    },
    {
      field: 'location',
      headerName: 'Ort',
      width: 200,
      editable: true,
      cellEditor: 'ngSelectEditor',
      cellRenderer: (data: any) => {
        console.log('render', data);
        return data.value ? data.value.name : '';
      }
    },
    {
      field: 'district',
      headerName: 'Stadtteil',
      width: 200,
      editable: true,
      cellEditor: 'ngSelectEditorDistricts',
      cellRenderer: (data: any) => {
        console.log('render', data);
        return data.value ? data.value.name : '';
      }
    },
    {
      field: 'minOrderValue',
      headerName: 'Mindestbestellwert',
      width: 200,
      editable: true,
      valueFormatter: (params: any) => this.currencyFormatter(params.data.minOrderValue)
    },
    {
      field: 'deliveryFreeFrom',
      headerName: 'Lieferkostenfrei ab',
      width: 200,
      editable: true,
      valueFormatter: (params: any) => this.currencyFormatter(params.data.deliveryFreeFrom)
    },
    {
      field: 'deliveryFee',
      headerName: 'Liefergebühren',
      width: 200,
      editable: true,
      valueFormatter: (params: any) => this.currencyFormatter(params.data.deliveryFee)
    }
  ];

  defaultColDef = {
    lockPosition: true,
    suppressMenu: true,
    enablePivot: false,
    sortable: true,
    comparator: this.columnSorting
  };
  rowSelection = 'multiple';
  deliverytimeDays: any = [
    {
      label: 'Montag',
      value: 0
    },
    {
      label: 'Dienstag',
      value: 1
    },
    {
      label: 'Mittwoch',
      value: 2
    },
    {
      label: 'Donnerstag',
      value: 3
    },
    {
      label: 'Freitag',
      value: 4
    },
    {
      label: 'Samstag',
      value: 5
    },
    {
      label: 'Sonntag',
      value: 6
    }
  ];
  dayLimitTemplate: any = [
    {
      label: 'Jeden Tag',
      value: 0
    },
    {
      label: 'Montag',
      value: 1
    },
    {
      label: 'Dienstag',
      value: 2
    },
    {
      label: 'Mittwoch',
      value: 3
    },
    {
      label: 'Donnerstag',
      value: 4
    },
    {
      label: 'Freitag',
      value: 5
    },
    {
      label: 'Samstag',
      value: 6
    },
    {
      label: 'Sonntag',
      value: 7
    }
  ];
  LimitDayTemplateValue: any = [
    'Jeden Tag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
    'Sonntag'
  ];

  printServiceTimesOptions: Options = {
    floor: 1,
    ceil: 5,
    step: 1,
    showTicks: true,
    showSelectionBar: true,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Floor:
          if (value === 0) {
            return 'Deaktiviert';
          } else if (value === 1) {
            return value + 'x';
          } else {
            return value + 'x';
          }
        case LabelType.Ceil:
          if (value === 0) {
            return 'Deaktiviert';
          } else if (value === 1) {
            return value + 'x';
          } else {
            return value + 'x';
          }
        case LabelType.Low:
          if (value === 0) {
            return 'Deaktiviert';
          } else if (value === 1) {
            return value + 'x';
          } else {
            return value + 'x';
          }
        case LabelType.High:
          if (value === 0) {
            return 'Deaktiviert';
          } else if (value === 1) {
            return value + 'x';
          } else {
            return value + 'x';
          }
        default:
          return value + '';
      }
    }
  };
  leadTimeOptions: Options = {
    floor: 0,
    ceil: 30,
    step: 1,
    showTicks: true,
    showSelectionBar: true,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Floor:
          if (value === 0) {
            return 'Nur am selben Tag';
          } else if (value === 1) {
            return value + ' Tag';
          } else {
            return value + ' Tage';
          }
        case LabelType.Ceil:
          if (value === 0) {
            return 'Nur am selben Tag';
          } else if (value === 1) {
            return value + ' Tag';
          } else {
            return value + ' Tage';
          }
        case LabelType.Low:
          if (value === 0) {
            return 'Nur am selben Tag';
          } else if (value === 1) {
            return value + ' Tag';
          } else {
            return value + ' Tage';
          }
        case LabelType.High:
          if (value === 0) {
            return 'Nur am selben Tag';
          } else if (value === 1) {
            return value + ' Tag';
          } else {
            return value + ' Tage';
          }
        default:
          return value + '';
      }
    }
  };
  intervalOptions: Options = {
    floor: 15,
    ceil: 240,
    step: 15,
    enforceStep: true,
    ticksArray: [15, 30, 60, 120, 180, 240],
    showTicks: true,
    showSelectionBar: true,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Floor:
          if (value === 15) {
            return '15 Minuten';
          }
          break;
        case LabelType.Ceil:
          if (value < 60) {
            return value + ' Minuten';
          } else if (value === 60) {
            return 'Eine Stunde';
          } else {
            return value / 60 + ' Stunden';
          }
        case LabelType.Low:
          if (value < 60) {
            return value + ' Minuten';
          } else if (value === 60) {
            return 'Eine Stunde';
          } else {
            return value / 60 + ' Stunden';
          }
        case LabelType.High:
          if (value < 60) {
            return value + ' Minuten';
          } else if (value === 60) {
            return 'Eine Stunde';
          } else {
            return value / 60 + ' Stunden';
          }
        default:
          return value + '';
      }
    }
  };
  @ViewChild('timesTabs', { static: false }) timesTabs!: MatTabGroup;
  private ngUnsubscribe: Subject<any> = new Subject();
  private gridApi: any;
  private gridColumnApi: any;
  transactionFeeCash: number = 0;

  constructor(
    private gastropayService: GastroPayService,
    private snackBar: MatSnackBar,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private clientResolver: ClientResolverService,
    private currencyPipe: CurrencyPipe
  ) {
    LicenseManager.setLicenseKey(
      'CompanyName=mes.mo GmbH,LicensedApplication=GastroDigital,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-011541,ExpiryDate=2_November_2021_[v2]_MTYzNTgxMTIwMDAwMA==70d96546e88e1bf7b42625520a2c639c'
    );
    this.generalSettings = this.formBuilder.group({
      isActive: [0],
      delivery: [1],
      deliveryType: [],
      takeaway: [1],
      inhouse: [1],
      cashEnabled: [1],
      vouchersEnabled: [1],
      printServiceActive: [0],
      printItemPrices: [0],
      notifyByEmailOption: [0],
      printServiceTimes: [1],
      onlinePaymentEnabled: [1],
      alert: [''],
      inhouseAlert: [''],
      email: ['', [Validators.email]],
      notificationSound: ['bell'],
      loopNotificationSound: [0],
      giveTakeawayDiscount: [false],
      takeawayDiscount: [],
      enableTip: [],
      inhouseTableNo: [1],
      inhouseName: [1],
      notifyByEmail: ['', [Validators.email]],
      inhousePickupActive: [0]
    });
    this.analyticsForm = this.formBuilder.group({
      googleAnalyticsId: '',
      facebookAnalyticsId: ''
    });
    this.deliveryAreaForm = this.formBuilder.group({
      zipCode: [
        '',
        [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(1), Validators.maxLength(5)]
      ],
      minOrderValue: ['', Validators.required],
      deliveryFee: ['', Validators.required],
      deliveryFreeFrom: []
    });
    this.timeOptionsForm = this.formBuilder.group({
      enablePreorder: [1],
      minDeliveryTime: [30, Validators.required],
      bufferTime: [30, Validators.required],
      timeInterval: [15, Validators.required],
      leadTime: [0],
      usePeriods: [1],
      showTimeSection: [0]
    });
    this.timesForm = this.formBuilder.group({
      days: this.formBuilder.array([this.createDaysForm()])
    });
    this.deliverytimeDays.forEach((day: any) => {
      const days = this.timesForm.get('days') as FormArray;
      days.push(this.createDaysForm());
    });
    this.limitFormGroup = this.formBuilder.group({
      maxOrderLimit: this.formBuilder.array([])
    });
    this.activeClient = this.clientResolver.client;
  }

  ngOnInit() {
    this.getTimes();
    this.getSettings();
    this.getSpecialGastroTimes();
  }
  getTimes() {
    let times = [];
    this.defaultTime = moment().format('HH:mm');
    let tStart = 0;
    const interval = 15;
    for (let i = 0; tStart < 24 * 60; i++) {
      const hh = Math.floor(tStart / 60); // getting hours of day in 0-24 format
      const mm = tStart % 60; // getting minutes of the hour in 0-55 format

      const timeObj = ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2);
      times.push(timeObj);

      const hhNext = Math.floor((tStart + interval) / 60); // getting hours of day in 0-24 format
      const mmNext = (tStart + interval) % 60; // getting minutes of the hour in 0-55 format
      const dateObjNext = new Date();
      dateObjNext.setHours(hhNext);
      dateObjNext.setMinutes(mmNext);

      const dateObj = new Date();
      dateObj.setHours(hh);
      dateObj.setMinutes(mm);

      const defaultTimeDateObj = new Date();
      const defaultTimeSplit = this.defaultTime.split(':');
      defaultTimeDateObj.setHours(+defaultTimeSplit[0]);
      defaultTimeDateObj.setMinutes(+defaultTimeSplit[1]);
      if (dateObj < defaultTimeDateObj && dateObjNext > defaultTimeDateObj) {
        const defaultTimeObj = this.defaultTime;
        times.push(defaultTimeObj);
      }

      tStart = tStart + interval;
    }
    times.map((i: any) => {
      if (((i >= '09:00' && i <= '22:00') || i == '00:00') && (i.split(':')[1] == '30' || i.split(':')[1] == '00')) {
        this.times.push({ label: i == '00:00' ? 'Ganzer Tag' : i, value: i });
      }
    });
  }
  saveLimit() {
    if (!this.limitFormGroup.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    this.gastropayService
      .saveOrderTimeLimit(this.limitFormGroup.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        res => {
          if (res) {
            this.limitArray = res;
            this.snackBar.open('Änderungen erfolgreich gespeichert.', '', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          } else {
            this.snackBar.open('Something went wrong!', '', {
              duration: 2000,
              panelClass: ['snackbar-error']
            });
          }
        },
        err => {
          this.snackBar.open('Something went wrong!' + err.error.msg, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      );
  }
  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  get formData() {
    return <FormArray>this.limitFormGroup.get('maxOrderLimit');
  }
  addNewUnit() {
    this.maxOrderLimit = this.limitFormGroup.get('maxOrderLimit') as FormArray;
    this.maxOrderLimit.push(this.createUnit());
  }

  removeUnit(index: number) {
    this.maxOrderLimit = this.limitFormGroup.get('maxOrderLimit') as FormArray;
    this.maxOrderLimit.removeAt(index);
  }
  createUnit(): FormGroup {
    return this.formBuilder.group({
      day: [0, Validators.required],
      time: ['', Validators.required],
      limit: ['', Validators.required]
    });
  }
  getSettings() {
    this.gastropayService
      .getSettings()
      .takeUntil(this.ngUnsubscribe)
      .subscribe(data => {
        if (data) {
          const res = data;
          this.transactionFeeCash = res.transactionFeeCash;
          const maxOrderLimitUnits: any = [];
          this.limitArray = res.maxOrderLimit;
          if (res.maxOrderLimit) {
            res.maxOrderLimit.forEach((st: any) => {
              maxOrderLimitUnits.push(this.formBuilder.group(st));
            });
            this.limitFormGroup.setControl('maxOrderLimit', this.formBuilder.array(maxOrderLimitUnits));
          }
          this.orderSystemVersion = res.orderSystemVersion;
          this.generalSettings.controls.isActive.setValue(res.isActive);

          this.generalSettings.controls.delivery.setValue(res.delivery);
          this.generalSettings.controls.deliveryType.setValue(res.deliveryType);
          this.generalSettings.controls.takeaway.setValue(res.takeaway);
          this.generalSettings.controls.inhousePickupActive.setValue(res.inhousePickupActive);
          this.generalSettings.controls.inhouse.setValue(res.inhouse);
          this.generalSettings.controls.cashEnabled.setValue(res.cashEnabled);
          this.generalSettings.controls.enableTip.setValue(res.enableTip);
          this.generalSettings.controls.vouchersEnabled.setValue(res.vouchersEnabled);
          this.generalSettings.controls.printServiceActive.setValue(res.printServiceActive);
          this.generalSettings.controls.printItemPrices.setValue(res.printItemPrices);
          if (res.notifyByEmail) {
            this.generalSettings.controls.notifyByEmailOption.setValue(1);
          }
          this.generalSettings.controls.notifyByEmail.setValue(res.notifyByEmail);
          this.generalSettings.controls.printServiceTimes.setValue(res.printServiceTimes);
          this.generalSettings.controls.onlinePaymentEnabled.setValue(res.onlinePaymentEnabled);
          this.generalSettings.controls.alert.setValue(res.alert);
          this.generalSettings.controls.inhouseAlert.setValue(res.inhouseAlert);
          this.generalSettings.controls.notificationSound.setValue(res.notificationSound);
          this.generalSettings.controls.loopNotificationSound.setValue(res.loopNotificationSound);
          this.generalSettings.controls.email.setValue(res.email);
          this.generalSettings.controls.inhouseTableNo.setValue(res.inhouseTableNo);
          this.generalSettings.controls.inhouseName.setValue(res.inhouseName);

          if (res.takeawayDiscount > 0) {
            this.generalSettings.controls.giveTakeawayDiscount.setValue(true);
            this.generalSettings.controls.takeawayDiscount.setValue(res.takeawayDiscount);
          }
          this.analyticsForm.controls.googleAnalyticsId.setValue(res.googleAnalyticsId);
          this.analyticsForm.controls.facebookAnalyticsId.setValue(res.facebookAnalyticsId);

          this.timeOptionsForm.controls.bufferTime.setValue(res.bufferTime);
          this.timeOptionsForm.controls.minDeliveryTime.setValue(res.minDeliveryTime);
          this.timeOptionsForm.controls.leadTime.setValue(res.leadTime);
          this.timeOptionsForm.controls.timeInterval.setValue(res.timeInterval);
          this.timeOptionsForm.controls.usePeriods.setValue(res.usePeriods);
          this.timeOptionsForm.controls.enablePreorder.setValue(res.enablePreorder);
          this.timeOptionsForm.controls.showTimeSection.setValue(res.showTimeSection);
          if (this.activeClient) {
            let clientName = this.activeClient.name.replace('.', '');
            clientName = clientName.replace('/', '');
            clientName = clientName.replace('ä', 'ae');
            clientName = clientName.replace('ö', 'oe');
            clientName = clientName.replace('ü', 'ue');

            if (this.orderSystemVersion === 1) {
              this.ordershopLink =
                'https://bestellung.gastroguide.de/' + encodeURI(clientName) + '/' + this.activeClient.id;
              this.inhouseshopLink =
                'https://inhouse.gastroguide.de/' + encodeURI(clientName) + '/' + this.activeClient.id;
            } else {
              this.ordershopLink = 'https://order.gastroguide.de/' + encodeURI(clientName) + '/' + this.activeClient.id;
              this.inhouseshopLink = null;
            }
          }
        }
      });
  }

  save() {
    if (!this.generalSettings.valid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    this.gastropayService
      .saveSettings(this.generalSettings.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        res => {
          if (res) {
            this.snackBar.open('Einstellungen gespeichert.', '', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          } else {
            this.snackBar.open('Einstellungen konnten nicht gespeichert werden:' + res.msg, '', {
              duration: 2000,
              panelClass: ['snackbar-error']
            });
          }
        },
        err => {
          this.snackBar.open('Einstellungen konnten nicht gespeichert werden:' + err.error.msg, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      );
  }
  saveAnalytics() {
    this.gastropayService
      .saveAnalytics(this.analyticsForm.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        res => {
          if (res) {
            this.snackBar.open('Einstellungen gespeichert.', '', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          } else {
            this.snackBar.open('Einstellungen konnten nicht gespeichert werden:' + res.msg, '', {
              duration: 2000,
              panelClass: ['snackbar-error']
            });
          }
        },
        err => {
          this.snackBar.open('Einstellungen konnten nicht gespeichert werden:' + err.error.msg, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      );
  }
  saveTimeOptions() {
    this.gastropayService
      .saveSettings(this.timeOptionsForm.value)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        res => {
          if (res) {
            this.snackBar.open('Liefer-/Abholzeit Einstellungen gespeichert.', '', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          } else {
            this.snackBar.open('Liefer-/Abholzeit Einstellungen konnten nicht gespeichert werden:' + res.msg, '', {
              duration: 2000,
              panelClass: ['snackbar-error']
            });
          }
        },
        err => {
          this.snackBar.open('Liefer-/Abholzeit Einstellungen konnten nicht gespeichert werden:' + err.error.msg, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      );
  }

  getDeliveryAreas() {
    this.gastropayService
      .getDeliveryAreas()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((res: any) => {
        this.deliveryAreas = res.map((i: any) => {
          i.index = this.itemIndex;
          this.itemIndex += 1;
          return i;
        });
        console.log(450, this.deliveryAreas);
        for (let index = 0; index < 5; index++) {
          this.addEmptyRow();
        }
      });
  }

  selectedTabChange(e: any) {
    if (e.index === 2) {
      // this.timesTabs.realignInkBar();
    }
  }

  addDeliveryArea() {
    if (!this.deliveryAreaForm.valid) {
      return;
    }
    this.gastropayService.addDeliveryArea(this.deliveryAreaForm.value).subscribe(
      () => {
        this.deliveryAreaForm = this.formBuilder.group({
          zipCode: [
            '',
            [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(1), Validators.maxLength(5)]
          ],
          minOrderValue: ['', Validators.required],
          deliveryFee: ['', Validators.required],
          deliveryFreeFrom: []
        });
        this.getDeliveryAreas();
        this.snackBar.open('Liefergebiet hinzugefügt', '', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      },
      (err: any) => {
        this.snackBar.open('Liefergebiet konnte nicht hinzugefügt werden: ' + err.error.msg, '', {
          duration: 2000,
          panelClass: ['snackbar-error']
        });
      }
    );
  }

  deleteDeliveryArea(areaId: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Liefergebiet löschen';
    modalRef.componentInstance.message = `Sind Sie sicher dass Sie das Liefergebiet unwiederruflich löschen möchten?`;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      result => {
        if (result === 'ok') {
          this.gastropayService
            .deleteDeliveryArea(areaId)
            .takeUntil(this.ngUnsubscribe)
            .subscribe(
              () => {
                // Delete from array
                this.deliveryAreas = this.deliveryAreas.filter((obj: any) => {
                  return obj.id !== areaId;
                });
                const snackBarRef = this.snackBar.open('Liefergebiet erfolgreich gelöscht', 'Ok', {
                  duration: 2000,
                  panelClass: ['snackbar-success']
                });
              },
              (err: any) => {
                this.snackBar.open('Liefergebiet konnte nicht gelöscht werden: ' + err.error.msg, '', {
                  duration: 2000,
                  panelClass: ['snackbar-error']
                });
              }
            );
        }
      },
      () => {}
    );
  }

  editDeliveryArea(area: any) {
    console.log('editDeliveryArea', area, this.deliveryAreaForm);
    this.deliveryAreaForm.controls.zipCode.setValue(area.zipCode);
    this.deliveryAreaForm.controls.minOrderValue.setValue(area.minOrderValue);
    this.deliveryAreaForm.controls.deliveryFee.setValue(area.deliveryFee);
    this.deliveryAreaForm.controls.deliveryFreeFrom.setValue(area.deliveryFreeFrom);
  }

  previewSound(sound: string) {
    const audio = new Audio();
    audio.src = '/assets/sound/' + sound + '.wav';
    audio.load();
    audio.play();
  }

  createDaysForm(): FormGroup {
    return this.formBuilder.group({
      from: [''],
      to: [''],
      delivery: [true],
      takeaway: [true]
    });
  }

  addTime(day: any) {
    const index = day.value;
    console.log('add', this.timesForm.controls.days);
    const timeData = this.timesForm.controls.days.value[index];
    timeData.day = moment()
      .day(day.label)
      .locale('en')
      .format('dddd');
    if (!timeData.from) {
      this.snackBar.open('"Von" Uhrzeit fehlt', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    if (!timeData.to) {
      this.snackBar.open('"Bis" Uhrzeit fehlt', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    const fromReplaced = timeData.from.replace(':', '');
    const toReplaced = timeData.to.replace(':', '');
    if (fromReplaced > toReplaced) {
      this.snackBar.open('"Bis" Uhrzeit darf nicht vor der "Von" Uhrzeit sein', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    if (fromReplaced === toReplaced) {
      this.snackBar.open('"Von" Uhrzeit darf nicht mit "Bis" Uhrzeit übereinstimmen', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    this.gastropayService
      .addTime(timeData)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        res => {
          if (res) {
            // Reset form
            this.timesForm = this.formBuilder.group({
              days: this.formBuilder.array([this.createDaysForm()])
            });
            this.deliverytimeDays.forEach(() => {
              const days = this.timesForm.get('days') as FormArray;
              days.push(this.createDaysForm());
            });
            this.snackBar.open('Uhrzeit wurde erfolgreich angelegt', '', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          }
        },
        (err: any) => {
          this.snackBar.open('Uhrzeit konnte nicht angelegt werden: ' + err.error.msg, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      );
  }

  deleteTime(timeId: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Uhrzeit löschen';
    modalRef.componentInstance.message = `Sind Sie sicher dass Sie die Uhrzeit unwiederruflich löschen möchten?`;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      result => {
        if (result === 'ok') {
          this.gastropayService
            .deleteTime(timeId)
            .takeUntil(this.ngUnsubscribe)
            .subscribe(
              () => {
                const snackBarRef = this.snackBar.open('Uhrzeit erfolgreich gelöscht', 'Ok', {
                  duration: 2000,
                  panelClass: ['snackbar-success']
                });
              },
              (err: any) => {
                this.snackBar.open('Uhrzeit konnte nicht gelöscht werden: ' + err.error.msg, '', {
                  duration: 2000,
                  panelClass: ['snackbar-error']
                });
              }
            );
        }
      },
      () => {}
    );
  }

  changeDiscountOption(e: any) {
    if (!e.checked) {
      this.generalSettings.controls.takeawayDiscount.setValue(0);
    }
    this.save();
  }

  getZipCodeLocations(zipcode: number) {
    console.log(661, zipcode);
    return this.gastropayService.getZipcodeLocations(zipcode).subscribe((locations: any) => {
      return locations;
    });
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridOptions.getRowNodeId = function(data: any) {
      return data.index;
    };
    this.getDeliveryAreas();
  }

  onCellValueChanged(e: any) {
    const itemData = { ...e.data };
    console.log('update', itemData);

    if (!itemData.id) {
      // If no zipCode is provided, do not submit because zipCode is needed
      if (!itemData.zipCode) {
        return;
      }
      // If id is null (new item), remove the id property from the object
      delete itemData.id;

      // If its coming from a paste or a range selection fill, work with a queue because if not multiple items will be created
      // because agGrid runs this even for each cell
      if (this.rangeSelectionMode || e.source === 'paste') {
        // Add to upload queue
        const queueObject = {
          index: e.rowIndex,
          data: itemData
        };
        const findIndex = this.uploadQueue.findIndex((uq: any) => uq.index === e.rowIndex);
        if (findIndex > -1) {
          this.uploadQueue[findIndex] = queueObject;
        } else {
          this.uploadQueue.push(queueObject);
        }
        return;
      }
    }
    this.gastropayService.saveDeliveryArea(e.data).subscribe((itemRes: any) => {
      // If is a new row (there was no id), add new empty row
      if (!itemData.id) {
        this.addEmptyRow();
      }
      const index = this.deliveryAreas.findIndex((i: any) => i === e.data);
      if (index > -1) {
        this.deliveryAreas[index].id = itemRes.id;
      }
      itemRes.index = itemData.index;
      this.gridApi.applyTransaction({ update: [itemRes] });
      this.gridApi.refreshCells({ rowNodes: [itemRes] });
    });
  }

  onRangeSelectionChanged(e: any) {
    // If range selection started set rangeSelectionMode mode on
    if (e.started && !e.finished) {
      this.rangeSelectionMode = true;
    }

    // If range selection ended, start uploading articles
    if (!e.started && e.finished && this.uploadQueue.length) {
      // Upload rows from queue
      this.processQueue();
      this.rangeSelectionMode = false;
    }
  }

  onPasteEnd(e: any) {
    console.log(e);
    // Upload queue now
    this.processQueue();
  }

  processQueue() {
    this.uploadQueue.forEach((row: any) => {
      this.gastropayService.saveDeliveryArea(row.data).subscribe((itemRes: any) => {
        // Remove from Queue
        const queueIndex = this.uploadQueue.findIndex((up: any) => (this.uploadQueue.index = row.index));
        this.uploadQueue.splice(queueIndex, 1);

        // Apply grid transaction to update row ids
        this.deliveryAreas[row.index].id = itemRes.id;

        // Get row node
        const rowNode = this.gridApi.getDisplayedRowAtIndex(row.index);
        rowNode.data.id = itemRes.id;
        this.gridApi.applyTransaction({ update: [rowNode.data] });
        this.addEmptyRow();
      });
    });
  }

  addEmptyRow() {
    const defaultObj: any = {
      zipCode: null,
      location: null,
      district: null,
      minOrderValue: null,
      deliveryFee: null,
      deliveryFreeFrom: null,
      index: this.itemIndex
    };

    this.deliveryAreas.push(defaultObj);
    this.itemIndex += 1;

    const res = this.gridApi.applyTransaction({
      add: [defaultObj]
    });
  }

  currencyFormatter(value: string) {
    if (!value || value === '0.00' || value === '0,00') {
      return '-';
    }
    if (value === '-1' || value === '-1.00') {
      return 'n.A';
    }
    value = value.toString().replace(',', '.');
    value = parseFloat(value).toFixed(2);
    value = this.currencyPipe.transform(value, 'EUR');
    return value;
  }

  columnSorting(a: any, b: any, nodeA: any, nodeB: any) {
    if (nodeA && nodeA.data && !nodeA.data.id) {
      // If no id is present, ignore the row because its empty
      return 0;
    }
    return a > b ? 1 : -1;
  }

  getContextMenuItems = (params: any) => {
    const result = [
      'copy',
      {
        icon: '<i class="far fa-trash-alt"></i>',
        name: 'Ausgewählte Zeile löschen',
        action: () => {
          this.deleteItems(this.gridApi.getCellRanges()[0]);
        },
        cssClasses: ['text-danger']
      }
    ];
    return result;
    // tslint:disable-next-line:semicolon
  };

  deleteItems(ranges: any) {
    const start =
      ranges.startRow.rowIndex <= ranges.endRow.rowIndex ? ranges.startRow.rowIndex : ranges.endRow.rowIndex;
    const end = ranges.endRow.rowIndex >= ranges.startRow.rowIndex ? ranges.endRow.rowIndex : ranges.startRow.rowIndex;

    const rowsToDelete: any = [];
    for (let index = start; index <= end; index++) {
      const row = this.gridApi.getDisplayedRowAtIndex(index);
      if (row && row.data) {
        rowsToDelete.push(row.data);
      }
    }

    // Remove from grid
    this.gridApi.applyTransaction({ remove: rowsToDelete });

    rowsToDelete.forEach((row: any) => {
      // Find in items array and remove from there as well
      const itemIndex = this.deliveryAreas.findIndex((item: any) => (item.id = row.id));
      if (itemIndex > -1) {
        this.deliveryAreas.splice(itemIndex, 1);
      }

      this.gastropayService.deleteDeliveryArea(row.id).subscribe(
        () => {
          this.snackBar.open('Liefergebiet wurde erfolgreich gelöscht', '', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        },
        (err: any) => {
          this.snackBar.open(err && err.error ? err.error.msg : 'Unbekannter Fehler', '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      );
    });
  }
  changeNotifyEmailOption(value: any) {
    if (!value) {
      this.generalSettings.controls.notifyByEmail.setValue(null);
      this.save();
    }
  }
  /* New Gastro pay special times */
  addSpecialGastroTimes() {
    const modalRef = this.modalService.open(AddSpecialTimesComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.dayLimitTemplate = this.dayLimitTemplate;
    modalRef.componentInstance.limitArray = this.limitArray;
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      this.gastropayService.addSpecialGastroTimes(receivedEntry).then((response: any) => {
        this.specialGastroPayTimes.push(response);
        this.specialGastroPayTimes = [...this.specialGastroPayTimes];
        const snackBarRef = this.snackBar.open('Änderungen erfolgreich gespeichert.', 'Ok', {
          duration: 3000,
          panelClass: ['snackbar-success']
        });
        modalRef.close();
      });
    });
  }

  editSpecialGastroTimes(data: any) {
    const modalRef = this.modalService.open(AddSpecialTimesComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.dayLimitTemplate = this.dayLimitTemplate;
    modalRef.componentInstance.limitArray = this.limitArray;
    modalRef.componentInstance.editData = { ...data };
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      this.gastropayService.editSpecialGastroTimes(receivedEntry).then((response: any) => {
        this.specialGastroPayTimes = this.specialGastroPayTimes.map((g: any) => {
          if (receivedEntry.id === g.id) {
            return receivedEntry;
          }
          return g;
        });
        this.specialGastroPayTimes = [...this.specialGastroPayTimes];
        const snackBarRef = this.snackBar.open('Änderungen erfolgreich gespeichert.', 'Ok', {
          duration: 3000,
          panelClass: ['snackbar-success']
        });
        modalRef.close();
      });
    });
  }

  getSpecialGastroTimes() {
    this.gastropayService.getSpecialGastroTimes().subscribe(data => {
      this.specialGastroPayTimes = data;
    });
  }

  deleteSpecialGastroTimes(timeId: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Uhrzeit löschen';
    modalRef.componentInstance.message = `Sind Sie sicher, dass Sie die Uhrzeit unwiderruflich löschen möchten?`;
    modalRef.componentInstance.showInfo = false;
    modalRef.componentInstance.buttonText = 'Ja';
    modalRef.result.then(
      result => {
        if (result === 'ok') {
          this.gastropayService
            .deleteSpecialGastroTimes(timeId)
            .takeUntil(this.ngUnsubscribe)
            .subscribe(
              () => {
                this.specialGastroPayTimes.splice(
                  this.specialGastroPayTimes.findIndex((i: any) => {
                    return i.id === timeId;
                  }),
                  1
                );
                this.specialGastroPayTimes = [...this.specialGastroPayTimes];
                const snackBarRef = this.snackBar.open('Uhrzeit erfolgreich gelöscht', 'Ok', {
                  duration: 2000,
                  panelClass: ['snackbar-success']
                });
              },
              (err: any) => {
                this.snackBar.open('Uhrzeit konnte nicht gelöscht werden: ' + err.error.msg, '', {
                  duration: 2000,
                  panelClass: ['snackbar-error']
                });
              }
            );
        }
      },
      () => {}
    );
  }
}
