import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ClientService } from '@app/core/client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {
  inviteForm!: FormGroup;
  hash: string;
  invalidHashError: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.inviteForm = this.formBuilder.group({
      password: ['', Validators.required],
      passwordRepeat: ['', Validators.required, PasswordValidation.MatchPassword]
    });
    this.hash = this.route.snapshot.params.hash;

    if (!this.hash) {
      this.router.navigate(['/404']);
    }

    this.clientService.checkInviteHash(this.hash).subscribe((isValid: boolean) => {
      if (!isValid) {
        this.invalidHashError = true;
      }
    });
  }

  ngOnInit() {}

  send() {
    if (!this.inviteForm.valid) {
      return;
    }
    const inviteData = this.inviteForm.value;
    inviteData.hash = this.hash;
    this.clientService.acceptModInvite(inviteData).subscribe(
      (response: any) => {
        this.snackBar.open('Ihr Account ist eingerichtet. Sie können sich jetzt mit Ihren Daten einloggen.', '', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
        this.router.navigate(['/login']);
      },
      err => {
        this.snackBar.open(err.error.msg, '', {
          duration: 2000,
          panelClass: ['snackbar-error']
        });
      }
    );
  }
}

export class PasswordValidation {
  static MatchPassword(AC: FormControl) {
    return new Promise(resolve => {
      const password = AC.parent.controls['password'].value; // to get value in input tag
      const confirmPassword = AC.value; // to get value in input tag
      if (password === confirmPassword) {
        return resolve(null); // All ok, passwords match!!!
      } else {
        return resolve({ not_match: true });
      }
    });
  }
}
