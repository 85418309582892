import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { extract } from '@app/core';
import { CheckInsComponent } from './check-ins.component';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { NgxPermissionsGuard } from 'ngx-permissions';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'client/:clientId/check-ins',
      // component: CheckInsComponent,
      resolve: {
        someKey: ClientResolverService
      },
      data: {
        title: extract('Covid19 Datenerfassung'),
        breadcrumb: 'Covid19 Datenerfassung'
      },
      children: [
        {
          path: '',
          redirectTo: 'list',
          pathMatch: 'full'
        },
        {
          path: 'list',
          component: CheckInsComponent,
          canActivate: [NgxPermissionsGuard],
          data: {
            title: 'Covid19 Datenerfassung',
            breadcrumb: 'Liste',
            permissions: {
              only: ['admin', 'manageCheckins'],
              redirectTo: {
                navigationCommands: ['403'],
                navigationExtras: {
                  replaceUrl: false
                }
              }
            }
          }
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class CheckInsRoutingModule {}
