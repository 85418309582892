import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { AlbumComponent } from './album/album.component';
import { SetPreviewImageComponent } from './set-preview-image/set-preview-image.component';
import { UploadPhotosComponent } from './upload-photos/upload-photos.component';
import { AlbumPhotosComponent } from './album-photos/album-photos.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'client/:clientId/gallery',
      data: {
        breadcrumb: 'Gallery'
      },
      resolve: {
        someKey: ClientResolverService
      },
      children: [
        {
          path: '',
          redirectTo: 'album',
          pathMatch: 'full'
        },
        {
          path: 'album',
          component: AlbumComponent,
          data: {
            breadcrumb: 'Album'
          }
        },
        {
          path: 'preview-image',
          component: SetPreviewImageComponent,
          data: {
            breadcrumb: 'Preview-Image'
          }
        },
        {
          path: 'album/:albumId/upload-photos',
          component: UploadPhotosComponent,
          data: {
            breadcrumb: 'Upload Photos'
          }
        },
        {
          path: 'album/:albumId/album-photos',
          component: AlbumPhotosComponent,
          data: {
            breadcrumb: 'Album Photos'
          }
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GalleryRoutingModule {}
