import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';
import { CoreModule } from '@app/core';
import { DateFnsModule } from 'ngx-date-fns';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MomentModule } from 'ngx-moment';
import { FilterPipeModule } from '@app/shared/filter/filter.module';
import {
  MatTabsModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatIconModule,
  MatSlideToggleModule,
  MatRadioModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GastroPayInfoComponent } from './info/gastro-pay-info.component';
import { GastroPayOrdersComponent } from './orders/gastro-pay-orders.component';
import { GastroPayRoutingModule } from './gastro-pay-routing.module';
import { GastroPayOverviewComponent } from './gastro-pay-overview/gastro-pay-overview.component';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  SatDatepickerModule,
  SatNativeDateModule
} from 'saturn-datepicker';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { DxChartModule } from 'devextreme-angular/ui/chart';
import { GastroPaySettingsComponent } from './gastro-pay-settings/gastro-pay-settings.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng5SliderModule } from 'ng5-slider';
import { AgGridModule } from 'ag-grid-angular';
import { NgSelectRendererComponent } from './gastro-pay-settings/renderers/ng-select-renderer.component';
import { NgSelectDistrictRendererComponent } from './gastro-pay-settings/renderers/ng-select-district-renderer.component';
import { DeliveryTimesComponent } from './gastro-pay-settings/delivery-times/delivery-times.component';
import { DeleteButtonRendererComponent } from './gastro-pay-settings/renderers/delete-button-renderer.component';
import { AddSpecialTimesComponent } from './gastro-pay-settings/add-special-times/add-special-times.component';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    CoreModule,
    SharedModule,
    GastroPayRoutingModule,
    NgScrollbarModule,
    NgxDatatableModule,
    MomentModule,
    FilterPipeModule,
    MatTabsModule,
    MatCheckboxModule,
    MatIconModule,
    FormsModule,
    NgSelectModule,
    Ng5SliderModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatDatepickerModule,
    MatRadioModule,
    DxChartModule,
    AgGridModule.withComponents([]),
    NgxMaskModule.forRoot(options),
    Angular2PromiseButtonModule.forRoot({
      spinnerTpl: '<span class="fas fa-spinner fa-spin button-loading"></span>'
    }),
    DateFnsModule.forRoot()
  ],
  declarations: [
    GastroPayOrdersComponent,
    GastroPayInfoComponent,
    GastroPayOverviewComponent,
    GastroPaySettingsComponent,
    NgSelectRendererComponent,
    NgSelectDistrictRendererComponent,
    DeleteButtonRendererComponent,
    DeliveryTimesComponent,
    AddSpecialTimesComponent
  ],
  entryComponents: [
    NgSelectRendererComponent,
    NgSelectDistrictRendererComponent,
    DeleteButtonRendererComponent,
    DeliveryTimesComponent,
    AddSpecialTimesComponent
  ]
})
export class GastroPayModule {}
