import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkinsLabel'
})
export class CheckinsLabelPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return Array.prototype.map
      .call(value, (item: any) => {
        return item.firstName + ' ' + item.lastName;
      })
      .join(',');
  }
}
