import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CredentialsService } from './authentication/credentials.service';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InitPermissionsService {
  constructor(private credentialsService: CredentialsService, private http: HttpClient) {}

  load(clientId: number = null): Promise<any> {
    if (!clientId) {
      const match = window.location.pathname.match(/^\/client\/(\d+)/);
      if (match && match.length > 1) {
        clientId = +window.location.pathname.match(/^\/client\/(\d+)/)[1];
      }
    }

    if (!clientId) {
      return new Promise((resolve, reject) => {
        resolve();
      });
    }

    const cred = this.credentialsService.credentials;

    if (!cred) {
      // Credentials not found
      return new Promise((resolve, reject) => {
        resolve();
      });
      return;
    }

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });

    if (this.credentialsService.isAdmin()) {
      return new Promise((resolve, reject) => {
        resolve('admin');
      });
    }

    return this.http
      .get<any>(environment.serverUrl + `private/moderators/check/${clientId}/${cred.id}`, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          if (response) {
            return this.http
              .get<any>(environment.serverUrl + `private/moderators/permissions/${clientId}/${cred.id}`, {
                headers: headers
              })
              .toPromise();
          } else {
            return new Promise((resolve, reject) => {
              resolve('admin');
            });
          }
        })
      )
      .toPromise();
  }
}
