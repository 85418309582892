import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ReservationService } from '../reservation.service';
import 'rxjs/add/operator/takeUntil';
import { Subscription, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';

import { DatatableComponent } from '@swimlane/ngx-datatable';
import { PageEvent } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { formatNumber } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import moment from 'moment';
import { updateGuestDataComponent } from './update-guest-data/update-guest-data.component';
moment.locale('de');

@Component({
  selector: 'app-reservation-logs',
  templateUrl: './reservation-logs.component.html',
  styleUrls: ['./reservation-logs.component.scss']
})
export class ReservationLogsComponent implements OnInit, OnDestroy {
  logs: any = [];
  ordersMobile: any = [];
  allOrdersCopy: any = [];

  recurrencelogs: any = [];
  recMobileLogs: any = [];

  loading: boolean = true;

  tableLimit: number = 50;
  tablePages: any[] = [];
  tableOffset: number = 1;
  filterBy: string = '';
  filterId: number = 0;
  myControl = new FormControl();

  dates: any = {
    from: '',
    to: ''
  };

  recurrenceDate: any = {
    from: '',
    to: ''
  };
  private _ordersSub: Subscription;
  private ngUnsubscribe: Subject<any> = new Subject();
  lowValue: number = 0;
  highValue: number = 50;
  pageSize: number = 50;

  lowRecValue: number = 0;
  highRecValue: number = 50;
  pageRecSize: number = 50;

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  tableRecPages: any[];

  recurrenceTab: any;
  showReservationList: boolean = false;

  constructor(
    private reservationService: ReservationService,
    public translate: TranslateService,
    private modalService: NgbModal,
    private snackBar: MatSnackBar
  ) {
    this.loadTranslation();
    this.translate.onLangChange.subscribe((event: any) => {
      this.loadTranslation();
    });
  }

  loadTranslation() {
    this.recurrenceTab = this.translate.instant('RecurrenceTab.Recurrancelogs');
    this.recurrencelogs.map((entry: any) => {
      if (entry.repeatOption == 'weekly') {
        entry.weekDays = this.transformDay(entry.repeatOn);
        return entry;
      } else if (entry.repeatOption == 'monthly_weekday') {
        entry.weekDays = this.transformDay(entry.repeatOn);
        return entry;
      }
    });
  }

  changeTableOffset(num: number = 0) {
    this.loading = true;
    this.table.offset = num;

    setTimeout(
      function(_this: any) {
        _this.loading = false;
      },
      3000,
      this
    );
  }

  ngOnInit() {
    this.getTransactions(true);
    this.getRecurrences(true);
  }

  ngOnDestroy() {}

  getTransactions(initialStart: boolean = false) {
    this.loading = true;
    if (initialStart) {
      this.dates.from = moment()
        .subtract(7, 'days')
        .format();
      this.dates.to = moment().format();
    }

    this.reservationService.getReservationLogs(this.dates.from, this.dates.to).subscribe(data => {
      this.logs = data;
      if (this.filterBy && this.filterBy != '') {
        this.allOrdersCopy = [];
        this.filterOrdersBy('client', this.filterBy);
      }
      this.setTablePages();
      this.loading = false;
      this.logs.map((entry: any) => {
        entry.actionDetails = this.transformLogText(entry.action, entry.oldValue, entry.newValue, entry.details);
        entry.status = this.translateStatus(entry.reservation.status);
        return entry;
      });
    });
  }
  getRecurrences(initialStart: boolean = false) {
    this.loading = true;
    if (initialStart) {
      this.recurrenceDate.from = moment()
        .subtract(7, 'days')
        .format();
      this.recurrenceDate.to = moment().format();
    }
    this.reservationService.getRecurrenceLogs(this.recurrenceDate.from, this.recurrenceDate.to).subscribe(data => {
      this.recurrencelogs = data;
      if (this.filterBy && this.filterBy != '') {
        this.allOrdersCopy = [];
        this.filterOrdersBy('client', this.filterBy);
      }
      this.setRecTablePages();
      this.loading = false;
      this.recurrencelogs.map((entry: any) => {
        if (entry.repeatOption == 'weekly') {
          entry.weekDays = this.transformDay(entry.repeatOn);
          return entry;
        } else if (entry.repeatOption == 'monthly_weekday') {
          entry.weekDays = this.transformDay(entry.repeatOn);
          return entry;
        }
      });
    });
  }

  transformLogText(action: string, oldValue: string, newValue: string, detail: string) {
    switch (action) {
      case 'newReservation':
        if (!detail || detail === 'manual') {
          return 'via Res.buch';
        } else {
          return 'via Online';
        }
        break;
      case 'guestCanceled':
        return 'vom Gast storniert';
        break;

      default:
        break;
    }
  }
  translateStatus(status: string) {
    switch (status) {
      case 'confirmed':
        return 'Bestätigt';
        break;
      case 'canceled':
        return 'Storniert';
        break;
      case 'noShow':
        return 'No-Show';
        break;
      case 'arrived':
        return 'Angekommen';
        break;
      case 'placed':
        return 'Platziert';
        break;
      case 'pending':
        return 'Ausstehend';
        break;
      case 'finished':
        return 'Fertig';
        break;
      case 'blocked':
        return 'Blocked';
        break;
      default:
        break;
    }
  }

  transformDay(days: string) {
    let names: string[] = [];
    const dayArray = days.split(',');
    dayArray.forEach(day => {
      switch (day) {
        case 'Su':
          names.push(this.translate.instant('RecurrenceTab.common.Sunday'));
          break;
        case 'Mo':
          names.push(this.translate.instant('RecurrenceTab.common.Monday'));
          break;
        case 'Tu':
          names.push(this.translate.instant('RecurrenceTab.common.Tuesday'));
          break;
        case 'We':
          names.push(this.translate.instant('RecurrenceTab.common.Wednesday'));
          break;
        case 'Th':
          names.push(this.translate.instant('RecurrenceTab.common.Thursday'));
          break;
        case 'Fr':
          names.push(this.translate.instant('RecurrenceTab.common.Friday'));
          break;
        case 'Sa':
          names.push(this.translate.instant('RecurrenceTab.common.Saturday'));
          break;
        default:
          break;
      }
    });
    return names.join(', ');
  }

  filterDates(filterBy: string, event: any) {
    this.loading = true;
    const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });
    const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(event.value._d);

    let filterDate = `${year}-${month}-${day} 00:00:00`;
    if (filterBy == 'to') {
      filterDate = `${year}-${month}-${day} 23:59:59`;
    }

    if (this.allOrdersCopy.length) {
      this.logs = [...this.allOrdersCopy];
      this.allOrdersCopy = [];
    }
    if (filterDate) {
      this.filterOrdersBy(filterBy, filterDate);
    }
  }

  setTablePages() {
    this.tablePages = [];
    for (let index = 1; index <= this.logs.length / this.tableLimit + 1; index++) {
      this.tablePages.push(index);
    }
    const paginateEvent = {
      previousPageIndex: 0,
      pageSize: this.pageSize,
      pageIndex: 0,
      length: this.logs.length
    };
    this.getPaginatorData(paginateEvent);
    this.loading = false;
  }

  setRecTablePages() {
    this.tableRecPages = [];
    for (let index = 1; index <= this.recurrencelogs.length / this.tableLimit + 1; index++) {
      this.tableRecPages.push(index);
    }
    const paginateEvent = {
      previousPageIndex: 0,
      pageSize: this.pageRecSize,
      pageIndex: 0,
      length: this.recurrencelogs.length
    };
    this.getRecPaginatorData(paginateEvent);
    this.loading = false;
  }

  filterOrdersBy(filterBy: string = '', filterVal: string = '') {
    switch (filterBy) {
      case 'from':
        this.dates.from = filterVal;

        this.allOrdersCopy = [...this.logs];
        this.logs = this.allOrdersCopy.filter(function(order: any) {
          if (
            (order.createdAt >= this.dates.from || this.dates.from == '') &&
            (order.createdAt <= this.dates.to || this.dates.to == '')
          ) {
            return order;
          }
        }, this);
        this.logs = [...this.logs];
        this.setTablePages();
        //this.prepareChartData();
        break;

      case 'to':
        this.dates.to = filterVal;
        this.allOrdersCopy = [...this.logs];
        this.logs = this.allOrdersCopy.filter(function(order: any) {
          if (
            (order.createdAt >= this.dates.from || this.dates.from == '') &&
            (order.createdAt <= this.dates.to || this.dates.to == '')
          ) {
            return order;
          }
        }, this);
        this.logs = [...this.logs];
        this.setTablePages();
        //this.prepareChartData();
        break;

      // case 'client':
      //   filterVal = `${filterVal}`;
      //   this.filterBy = filterVal;
      //   if (this.allOrdersCopy.length) {
      //     this.logs = this.allOrdersCopy;
      //   }
      //   this.allOrdersCopy = [...this.logs];
      //   this.logs = this.allOrdersCopy.filter(function (order: any) {
      //     if (order.client) {
      //       if (order.client.name.toLowerCase().includes(filterVal.toLowerCase())) {
      //         return order;
      //       }
      //     }
      //     if (
      //       order.name.toLowerCase().includes(filterVal.toLowerCase()) ||
      //       order.email.toLowerCase().includes(filterVal.toLowerCase()) ||
      //       order.betriebId == filterVal
      //     ) {
      //       return order;
      //     }
      //   }, this);
      //   this.logs = [...this.logs];
      //   this.setTablePages();
      //   this.table.offset = 0;
      //   break;

      default:
        this.filterBy = '';
        this.logs = [...this.allOrdersCopy];
        this.setTablePages();
        this.allOrdersCopy = [];
        break;
    }
  }

  setDates(type: string, isLogs: boolean) {
    if (isLogs) {
      if (type == 'today') {
        this.dates.from = moment().format();
        this.dates.to = moment().format();
      }
      if (type == 'yesterday') {
        this.dates.from = moment()
          .subtract(1, 'days')
          .format();
        this.dates.to = moment()
          .subtract(1, 'days')
          .format();
      }
      if (type == 'thisWeek') {
        const date = moment();
        this.dates.from = date.startOf('week');
        this.dates.to = moment(this.dates.from).endOf('week');
      }
      if (type == 'lastSeven') {
        this.dates.from = moment()
          .subtract(6, 'days')
          .format();
        this.dates.to = moment().format();
      }
      if (type == 'lastWeek') {
        const date = moment().subtract(1, 'week');
        this.dates.from = date.startOf('week');
        this.dates.to = moment(this.dates.from).endOf('week');
      }
      if (type == 'lastMonth') {
        const date = moment().subtract(1, 'month');
        this.dates.from = date.startOf('month');
        this.dates.to = moment(this.dates.from).endOf('month');
      }
      if (type == 'thisMonth') {
        const date = moment();
        this.dates.from = date.startOf('month');
        this.dates.to = moment(this.dates.from).endOf('month');
      }
      this.getTransactions(false);
    } else {
      if (type == 'today') {
        this.recurrenceDate.from = moment().format();
        this.recurrenceDate.to = moment().format();
      }
      if (type == 'yesterday') {
        this.recurrenceDate.from = moment()
          .subtract(1, 'days')
          .format();
        this.recurrenceDate.to = moment()
          .subtract(1, 'days')
          .format();
      }
      if (type == 'thisWeek') {
        const date = moment();
        this.recurrenceDate.from = date.startOf('week');
        this.recurrenceDate.to = moment(this.recurrenceDate.from).endOf('week');
      }
      if (type == 'lastSeven') {
        this.recurrenceDate.from = moment()
          .subtract(6, 'days')
          .format();
        this.recurrenceDate.to = moment().format();
      }
      if (type == 'lastWeek') {
        const date = moment().subtract(1, 'week');
        this.recurrenceDate.from = date.startOf('week');
        this.recurrenceDate.to = moment(this.recurrenceDate.from).endOf('week');
      }
      if (type == 'lastMonth') {
        const date = moment().subtract(1, 'month');
        this.recurrenceDate.from = date.startOf('month');
        this.recurrenceDate.to = moment(this.recurrenceDate.from).endOf('month');
      }
      if (type == 'thisMonth') {
        const date = moment();
        this.recurrenceDate.from = date.startOf('month');
        this.recurrenceDate.to = moment(this.recurrenceDate.from).endOf('month');
      }
      this.getRecurrences(false);
    }
  }

  returnPrice(price: any) {
    if (typeof price == 'string') {
      return parseFloat(price.replace(',', '.'));
    } else {
      return price || 0;
    }
  }
  // used to build an array of logs for mobile relevant at any given time
  public getPaginatorData(event: PageEvent): PageEvent {
    this.pageSize = event.pageSize;
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    this.ordersMobile = this.logs.slice(this.lowValue, this.highValue);
    return event;
  }

  public getRecPaginatorData(event: PageEvent): PageEvent {
    this.pageRecSize = event.pageSize;
    this.lowRecValue = event.pageIndex * event.pageSize;
    this.highRecValue = this.lowRecValue + event.pageSize;
    this.recMobileLogs = this.recurrencelogs.slice(this.lowRecValue, this.highRecValue);
    return event;
  }

  getRowClass = (row: any) => {
    return {
      'red-class': row.action === 'guestCanceled'
    };
  };

  updateGuestData(data: any) {
    const modalRef = this.modalService.open(updateGuestDataComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.guestData = data;
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      const snackBarRef = this.snackBar.open(this.translate.instant('Common.Successful'), 'Ok', {
        duration: 3000,
        panelClass: ['snackbar-success']
      });
      this.getRecurrences(false);
      modalRef.close();
    });
  }

  tabClick(tab: any) {
    console.log(tab);
    if (tab.index == 2) {
      this.showReservationList = true;
    } else {
      this.showReservationList = false;
    }
  }
}
