import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatList, MatSnackBar, MatExpansionPanelHeader } from '@angular/material';
import { CdkDragStart, CdkDragMove, CdkDragDrop, moveItemInArray, copyArrayItem } from '@angular/cdk/drag-drop';
import { MenucardsService } from '../menucards.service';
import { AddCategoryComponent } from '../add-category/add-category.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AddChoiceComponent } from '../add-choice/add-choice.component';
import { AuthenticationService } from '@app/core';
import { NavService } from '@app/nav.service';
import { ItemLayerComponent } from '../item-layer/item-layer.component';

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss']
})
export class ManageComponent implements OnInit, OnDestroy {
  @ViewChild(MatList, { read: ElementRef, static: false }) child: ElementRef;
  menuId: number;
  _currentIndex: any;
  _currentField: any;
  categories: any;
  choices: any;
  menucardArticles: any;
  categoryItems: any[] = [];
  choiceItems: any[] = [];
  sizes: any[] = [];
  activeClient: any;
  isTouchDevice: boolean;
  tags: any = [];
  articlesOrder = 'custom';
  private _menucardArticlesCopy: any = [];

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private menucardService: MenucardsService,
    private modalService: NgbModal,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private nav: NavService
  ) {
    if ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
      this.isTouchDevice = true;
    } else {
      this.isTouchDevice = false;
    }
  }

  ngOnInit() {
    this.nav.closeNav();
    this.authService
      .getActiveClient()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((clientData: any) => {
        this.activeClient = clientData;
        // (<any>window).ga(
        //   'set',
        //   'title',
        //   'Speisekarte Artikelliste - ' + this.activeClient.name + ' ' + this.activeClient.location
        // );
        // this.socketService.joinClient(this.activeClient.id);
      });

    this.menuId = +this.route.snapshot.paramMap.get('menuId');
    this.getCategories();
    this.getChoices();
    this.getItems();
    this.getSizes();
    this.getTags();
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.nav.openNav();
  }

  getCategories() {
    this.menucardService.getMenuCategories(this.menuId).subscribe((categories: any) => {
      this.categories = categories;
    });
  }

  getChoices() {
    this.menucardService.getClientChoices().subscribe((choices: any) => {
      this.choices = choices;
    });
  }

  getSizes() {
    this.menucardService.getClientSizes().subscribe((sizes: any) => {
      this.sizes = sizes;
    });
  }

  addCategory() {
    const modalRef = this.modalService.open(AddCategoryComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.menuId = this.menuId;
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      receivedEntry.items = [];
      this.categories.push(receivedEntry);
      const snackBarRef = this.snackBar.open('Kategorie erfolgreich hinzugefügt', 'Ok', {
        duration: 3000
      });
      modalRef.close();
    });
  }

  editCategory(category: any) {
    const modalRef = this.modalService.open(AddCategoryComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.editCategoryData = category;
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      // Find category
      const index = this.categories.findIndex((c: any) => c.id === category.id);

      if (receivedEntry === 'delete') {
        // Was deleted, so find and remove Array
        this.categories.splice(index, 1);
        return;
      }

      // Update choice
      receivedEntry.items = category.items;
      this.categories[index] = receivedEntry;
      const snackBarRef = this.snackBar.open('Kategorie erfolgreich gespeichert', 'Ok', {
        duration: 3000
      });
      modalRef.close();
    });
  }

  addChoice() {
    const modalRef = this.modalService.open(AddChoiceComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      receivedEntry.items = [];
      this.choices.push(receivedEntry);
      const snackBarRef = this.snackBar.open('Auswahlfenster erfolgreich hinzugefügt', 'Ok', {
        duration: 3000
      });
      modalRef.close();
    });
  }

  editChoice(choice: any) {
    const modalRef = this.modalService.open(AddChoiceComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.editChoiceData = choice;
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      // Find choice
      const index = this.choices.findIndex((c: any) => c.id === choice.id);

      if (receivedEntry === 'delete') {
        // Was deleted, so find and remove Array
        this.choices.splice(index, 1);
        return;
      }

      // Update choice
      receivedEntry.items = choice.items;
      this.choices[index] = receivedEntry;

      const snackBarRef = this.snackBar.open('Auswahlfenster erfolgreich gespeichert', 'Ok', {
        duration: 3000
      });
      modalRef.close();
    });
  }

  dragStart(event: CdkDragStart) {
    this._currentIndex = this.menucardArticles.indexOf(event.source.data); // Get index of dragged type
    this._currentField = this.child.nativeElement.children[this._currentIndex]; // Store HTML field
  }

  moved(event: CdkDragMove) {
    // Check if stored HTML field is as same as current field
    if (this.child.nativeElement.children[this._currentIndex] !== this._currentField) {
      // Replace current field, basically replaces placeholder with old HTML content
      this.child.nativeElement.replaceChild(this._currentField, this.child.nativeElement.children[this._currentIndex]);
    }
  }

  getItemChoicesList(): any[] {
    let items: any = [];
    if (!this.categories || !this.categories.length) {
      return items;
    }
    this.categories.map((cat: any) => {
      const subItems = cat.items.map((x: any) => `cat-${cat.id}-item-${x.id}`);
      items = items.concat(subItems);
    });
    return items;
  }

  getItemSizesList(): any[] {
    let items: any = [];
    if (!this.categories || !this.categories.length) {
      return items;
    }
    this.categories.map((cat: any) => {
      const subItems = cat.items.map((x: any) => `cat-${cat.id}-size-${x.id}`);
      items = items.concat(subItems);
    });
    return items;
  }

  getCategoriesConnectedList() {
    return this.categories.map((x: any) => `cat-${x.id}`);
  }

  getConnectedList(): any[] {
    let catConcat = [];
    let choicesConcat = [];
    if (this.categories && this.categories.length) {
      const categoriesList = this.categories.map((x: any) => `cat-${x.id}`);
      const categoriesHeaderList = this.categories.map((x: any) => `cat-header-${x.id}`);
      catConcat = categoriesList.concat(categoriesHeaderList);
    }

    if (this.choices && this.choices.length) {
      let choicesList = this.choices.map((x: any, index: number) => `choice-${index}`);
      let choicesHeaderList = this.choices.map((x: any) => `choice-${x.id}`);
      if (!choicesList) {
        choicesList = [];
        choicesHeaderList = [];
      }
      choicesConcat = choicesList.concat(choicesHeaderList);
    }

    return catConcat.concat(choicesConcat);
  }

  itemDropped(event: CdkDragDrop<any[]>) {
    let categoryId = 0;
    if (event.container.element.nativeElement.id.includes('mat-expansion-panel-header')) {
      categoryId = +event.container.id.replace('cat-header-', '');
    } else {
      categoryId = +event.container.id.replace('cat-', '');
    }

    const categoryIndex = this.categories.findIndex((x: any) => x.id === categoryId);

    if (event.previousContainer === event.container) {
      moveItemInArray(this.categories[categoryIndex].items, event.previousIndex, event.currentIndex);

      // Save new orders in DB
      this.menucardService
        .reorderCategoryItems(this.menuId, this.categories[categoryIndex].id, this.categories[categoryIndex].items)
        .subscribe(() => {});
    } else {
      copyArrayItem(
        event.previousContainer.data,
        this.categories[categoryIndex].items,
        event.previousIndex,
        event.currentIndex
      );

      this.menucardService
        .addCategoryItem(this.menuId, this.categories[categoryIndex].id, event.item.data.id, event.currentIndex)
        .subscribe((res: any) => {
          console.log('res', res);
          // find and replace in original array
          const index = this.categories[categoryIndex].items.findIndex((i: any) => i.id === res.itemId);
          const newObj = this.categories[categoryIndex].items[index];
          newObj.categoryId = res.categoryId;
          newObj.itemId = res.id;
          this.categories[categoryIndex].items[index] = newObj;
          // Save new orders in DB
          this.menucardService
            .reorderCategoryItems(this.menuId, this.categories[categoryIndex].id, this.categories[categoryIndex].items)
            .subscribe(() => {});
        });

      this.snackBar.open(
        event.item.data.name + ' wurde in die Kategorie "' + this.categories[categoryIndex].name + '" kopiert',
        '',
        {
          duration: 2000,
          panelClass: ['snackbar-success']
        }
      );
    }
  }

  itemChoiceDropped(event: CdkDragDrop<any[]>) {
    let choiceIndex = 0;
    if (event.container.element.nativeElement.id.includes('mat-expansion-panel-header')) {
      choiceIndex = +event.container.id.replace('choice-header-', '');
    } else {
      choiceIndex = +event.container.id.replace('choice-', '');
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(this.choices[choiceIndex].items, event.previousIndex, event.currentIndex);

      // Save new orders in DB
      this.menucardService
        .reorderChoiceItems(this.choices[choiceIndex].id, this.choices[choiceIndex].items)
        .subscribe(() => {});
    } else {
      // Check if size exists already
      const sizeFound = this.choices[choiceIndex].items.find((s: any) => s.id === event.item.data.id);
      if (sizeFound) {
        this.snackBar.open('Dieser Artikel existiert bereits in diesem Auswahlfenster', '', {
          duration: 2000,
          panelClass: ['snackbar-error']
        });
        return;
      }

      copyArrayItem(
        event.previousContainer.data,
        this.choices[choiceIndex].items,
        event.previousIndex,
        event.currentIndex
      );
      this.menucardService
        .addChoiceItem(this.choices[choiceIndex].id, event.item.data.id, event.currentIndex)
        .subscribe((res: any) => {
          // Save new orders in DB
          this.menucardService
            .reorderChoiceItems(this.choices[choiceIndex].id, this.choices[choiceIndex].items)
            .subscribe(() => {});
        });
    }
  }

  dropCategory(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);

    // Save new orders in DB
    this.menucardService.reorderCategories(this.categories).subscribe((res: any) => {});
  }

  dropChoice(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.choices, event.previousIndex, event.currentIndex);
  }

  addCategoryItem(itemData: any, categoryIndex: number, index: number) {
    if (!this.categories[categoryIndex].items) {
      this.categories[categoryIndex].items = [];
    }
    this.categories[categoryIndex].items.splice(index, 0, itemData);
    // this.categoryItems.splice(index, 0, fieldType);
    this.menucardService
      .addCategoryItem(this.menuId, this.categories[categoryIndex].id, itemData.id, index)
      .subscribe((res: any) => {
        console.log(res);
      });
  }

  addChoiceItem(itemData: any, choiceIndex: number, index: number) {
    if (!this.choices[choiceIndex].items) {
      this.choices[choiceIndex].items = [];
    }
    this.choices[choiceIndex].items.splice(index, 0, itemData);

    this.menucardService.addChoiceItem(this.choices[choiceIndex].id, itemData.id, index).subscribe((res: any) => {
      console.log(res);
    });
  }

  getItems() {
    this.menucardService.getClientItems().subscribe(items => {
      items = items.map((i: any) => {
        i.sizes = [];
        i.choices = [];
        return i;
      });
      // items.sort((a: any, b: any) => (a.id < b.id ? 1 : -1));
      this.menucardArticles = items;
      this._menucardArticlesCopy = items;
    });
  }

  categoryDropEntered(e: any, category: any) {
    category.disablePlaceholder = true;
  }

  deleteCategoryItem(returnData: any) {
    this.menucardService.deleteCategoryItem(returnData.itemId).subscribe(() => {
      const index = this.categories.findIndex((item: any) => item.id === returnData.categoryId);
      this.categories[index].items = this.categories[index].items.filter((obj: any) => {
        return obj.id !== returnData.id;
      });
    });
  }

  deleteChoiceItem(choice: any, returnData: any) {
    this.menucardService.deleteChoiceItem(choice.id, returnData.id).subscribe(() => {
      const index = this.choices.findIndex((item: any) => item.id === choice.id);
      this.choices[index].items = this.choices[index].items.filter((obj: any) => {
        return obj.id !== returnData.id;
      });
    });
  }

  filterInput(e: any) {
    const searchValue = e.target.value;
    this.menucardArticles = [...this._menucardArticlesCopy];
    this.menucardArticles = this.menucardArticles.filter((article: any) =>
      article.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  filterType(searchValue: string) {
    this.menucardArticles = [...this._menucardArticlesCopy];
    if (searchValue) {
      this.menucardArticles = this.menucardArticles.filter((article: any) => article.type === searchValue);
    }
  }

  filterTags(searchTags: any) {
    this.menucardArticles = [...this._menucardArticlesCopy];
    if (searchTags && searchTags.length) {
      this.menucardArticles = this.menucardArticles.filter((article: any) =>
        article.tags.some((at: any) => searchTags.findIndex((st: any) => st.id === at.id) >= 0)
      );
    }
  }

  changeOrder(order: string) {
    switch (order) {
      case 'asc':
        this.menucardArticles.sort((a: any, b: any) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        break;
      case 'desc':
        this.menucardArticles.sort((a: any, b: any) => {
          if (b.name < a.name) {
            return -1;
          }
          if (b.name > a.name) {
            return 1;
          }
          return 0;
        });
        break;
      case 'custom':
        this.menucardArticles.sort((a: any, b: any) => {
          return a.sortingOrder - b.sortingOrder;
        });
        break;
    }
  }

  addArticle() {
    const modalRef = this.modalService.open(ItemLayerComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      receivedEntry.sizes = [];
      receivedEntry.choices = [];
      receivedEntry.price = {};
      for (let index = 0; index < receivedEntry.prices.length; index++) {
        const element = receivedEntry.prices[index];
        receivedEntry.price[element.priceCategory] = element.price;
      }
      this.menucardArticles.push(receivedEntry);
      // this._menucardArticlesCopy.push(receivedEntry);
      // this.menucardArticles.sort((a: any, b: any) => (a.id < b.id ? 1 : -1));
      // this._menucardArticlesCopy.sort((a: any, b: any) => (a.id < b.id ? 1 : -1));

      const snackBarRef = this.snackBar.open('Artikel erfolgreich gespeichert', 'Ok', {
        duration: 3000
      });
      modalRef.close();
    });
  }

  expandCategory(cat: any) {
    cat.isExpanded = true;
    console.log('is expanded');
  }

  collapseCategory(cat: any) {
    cat.isExpanded = false;
    console.log('is collapsed');
  }

  toggleExpandItems(cat: any) {
    cat.expanded = !cat.expanded;
  }

  getTags() {
    this.menucardService.getTags().subscribe(tags => {
      this.tags = tags;
    });
  }
}
