import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { BrowserModule } from '@angular/platform-browser';
import { ImageCropperModule } from './set-preview-image/lib/image-cropper.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GalleryRoutingModule } from './gallery-routing.module';
import { SetPreviewImageComponent } from './set-preview-image/set-preview-image.component';
import { AlbumComponent } from './album/album.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AddAlbumComponent } from './add-album/add-album.component';
import { UploadPhotosComponent } from './upload-photos/upload-photos.component';
import { MatProgressBarModule } from '@angular/material';
import { AlbumPhotosComponent } from './album-photos/album-photos.component';
@NgModule({
  declarations: [
    SetPreviewImageComponent,
    AlbumComponent,
    AddAlbumComponent,
    UploadPhotosComponent,
    AlbumPhotosComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    ImageCropperModule,
    GalleryRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    NgxDatatableModule,
    MatProgressBarModule
  ],
  entryComponents: [AddAlbumComponent]
})
export class GalleryModule {}
