import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { GastroPayService } from '@app/gastro-pay/gastro-pay.service';
import { CheckboxRendererComponent } from '@app/menucards/manage-items/renderers/checkbox-renderer.component';
import { Subject } from 'rxjs';
declare var AG_GRID_LOCALE_DE: any;

@Component({
  selector: 'app-delivery-times-settings',
  templateUrl: './delivery-times.component.html',
  styleUrls: ['./delivery-times.component.scss']
})
export class DeliveryTimesComponent implements OnInit {
  deliveryTimes: Array<any>;
  itemIndex = 0;
  gridLocale = AG_GRID_LOCALE_DE;
  gridOptions: any = {};
  sideBar: any;
  uploadQueue: any = [];
  rangeSelectionMode = false;
  timesArray: any = [];
  frameworkComponents = {
    checkboxRenderer: CheckboxRendererComponent
  };
  dayMappings = {
    Monday: 'Montag',
    Tuesday: 'Dienstag',
    Wednesday: 'Mittwoch',
    Thursday: 'Donnerstag',
    Friday: 'Freitag',
    Saturday: 'Samstag',
    Sunday: 'Sonntag'
  };
  columnDefs = [
    {
      field: 'day',
      headerName: 'Tag',
      cellEditor: 'agSelectCellEditor',
      width: 200,
      editable: true,
      sortable: false,
      cellEditorParams: {
        values: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
      },
      valueFormatter: (params: any) => {
        return this.lookupValue(this.dayMappings, params.value);
      },
      valueParser: (params: any) => {
        return this.lookupKey(this.dayMappings, params.newValue);
      }
    },
    {
      field: 'from',
      headerName: 'Von',
      width: 130,
      editable: true,
      sortable: false,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: this.timesArray
      },
      valueFormatter: (params: any) => this.timeFormatter(params.data ? params.data.from : params.value)
    },
    {
      field: 'to',
      headerName: 'Bis',
      width: 130,
      editable: true,
      sortable: false,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: this.timesArray
      },
      valueFormatter: (params: any) => this.timeFormatter(params.data ? params.data.to : params.value)
    },
    {
      field: 'takeaway',
      headerName: 'Abholung?',
      cellRenderer: 'checkboxRenderer',
      width: 150,
      editable: true,
      sortable: false,
      cellStyle: { textAlign: 'center' },
      cellEditor: 'noEditor'
    },
    {
      field: 'delivery',
      headerName: 'Lieferung?',
      cellRenderer: 'checkboxRenderer',
      width: 150,
      editable: true,
      sortable: false,
      cellStyle: { textAlign: 'center' },
      cellEditor: 'noEditor'
    }
  ];

  defaultColDef = {
    lockPosition: true,
    suppressMenu: true,
    enablePivot: false,
    sortable: true,
    comparator: this.columnSorting
  };
  rowSelection = 'multiple';
  private ngUnsubscribe: Subject<any> = new Subject();
  private gridApi: any;
  private gridColumnApi: any;

  constructor(private gastropayService: GastroPayService, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.getTimesArray();
    this.gastropayService
      .getTimes()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((res: any) => {
        this.deliveryTimes = res.map((i: any) => {
          i.index = this.itemIndex;
          this.itemIndex += 1;
          return i;
        });

        const sorter = {
          monday: 1,
          tuesday: 2,
          wednesday: 3,
          thursday: 4,
          friday: 5,
          saturday: 6,
          sunday: 7
        };

        this.deliveryTimes.sort(function sortByDay(a: any, b: any) {
          const day1 = a.day.toLowerCase();
          const day2 = b.day.toLowerCase();
          const time1 = a.from.toLowerCase();
          const time2 = b.from.toLowerCase();
          return sorter[day1] - sorter[day2] || time1.replace(':', '') - time2.replace(':', '');
        });

        for (let index = 0; index < 5; index++) {
          this.addEmptyRow();
        }
      });
  }

  lookupValue(mappings: any, key: string) {
    return mappings[key];
  }
  lookupKey(mappings: any, name: string) {
    const keys = Object.keys(mappings);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (mappings[key] === name) {
        return key;
      }
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridOptions.getRowNodeId = function(data: any) {
      return data.index;
    };
  }

  onCellValueChanged(e: any) {
    const itemData = { ...e.data };
    console.log('update', itemData);

    if (!itemData.id) {
      // If no day is provided, do not submit because day is needed
      if (!itemData.day) {
        return;
      }
      // If id is null (new item), remove the id property from the object
      delete itemData.id;

      // If its coming from a paste or a range selection fill, work with a queue because if not multiple items will be created
      // because agGrid runs this even for each cell
      if (this.rangeSelectionMode || e.source === 'paste') {
        // Add to upload queue
        const queueObject = {
          index: e.rowIndex,
          data: itemData
        };
        const findIndex = this.uploadQueue.findIndex((uq: any) => uq.index === e.rowIndex);
        if (findIndex > -1) {
          this.uploadQueue[findIndex] = queueObject;
        } else {
          this.uploadQueue.push(queueObject);
        }
        return;
      }
    }
    this.gastropayService.saveTime(e.data).subscribe((itemRes: any) => {
      // If is a new row (there was no id), add new empty row
      if (!itemData.id) {
        this.addEmptyRow();
      }
      const index = this.deliveryTimes.findIndex((i: any) => i === e.data);
      if (index > -1) {
        this.deliveryTimes[index].id = itemRes.id;
      }
      itemRes.index = itemData.index;
      this.gridApi.applyTransaction({ update: [itemRes] });
      this.gridApi.refreshCells({ rowNodes: [itemRes] });
    });
  }

  onRangeSelectionChanged(e: any) {
    // If range selection started set rangeSelectionMode mode on
    if (e.started && !e.finished) {
      this.rangeSelectionMode = true;
    }

    // If range selection ended, start uploading articles
    if (!e.started && e.finished && this.uploadQueue.length) {
      // Upload rows from queue
      this.processQueue();
      this.rangeSelectionMode = false;
    }
  }

  onPasteEnd(e: any) {
    console.log(e);
    // Upload queue now
    this.processQueue();
  }

  processQueue() {
    this.uploadQueue.forEach((row: any) => {
      this.gastropayService.saveTime(row.data).subscribe((itemRes: any) => {
        // Remove from Queue
        const queueIndex = this.uploadQueue.findIndex((up: any) => (this.uploadQueue.index = row.index));
        this.uploadQueue.splice(queueIndex, 1);

        // Apply grid transaction to update row ids
        this.deliveryTimes[row.index].id = itemRes.id;

        // Get row node
        const rowNode = this.gridApi.getDisplayedRowAtIndex(row.index);
        rowNode.data.id = itemRes.id;
        this.gridApi.applyTransaction({ update: [rowNode.data] });
        this.addEmptyRow();
      });
    });
  }

  getTimesArray() {
    let tStart = 0;
    const interval = 15;
    for (let i = 0; tStart <= 24 * 60; i++) {
      const hh = Math.floor(tStart / 60); // getting hours of day in 0-24 format
      const mm = tStart % 60; // getting minutes of the hour in 0-55 format
      const timeObj = {
        time: ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2),
        value: hh
      };
      this.timesArray[i] = timeObj.time;
      tStart = tStart + interval;
    }
  }

  addEmptyRow() {
    const defaultObj: any = {
      day: null,
      from: null,
      to: null,
      takeaway: true,
      delivery: true,
      index: this.itemIndex
    };
    this.deliveryTimes.push(defaultObj);
    this.itemIndex += 1;
    const res = this.gridApi.applyTransaction({
      add: [defaultObj]
    });
  }

  columnSorting(a: any, b: any, nodeA: any, nodeB: any) {
    if (nodeA && nodeA.data && !nodeA.data.id) {
      // If no id is present, ignore the row because its empty
      return 0;
    }
    return a > b ? 1 : -1;
  }

  timeFormatter(value: string) {
    console.log(value);
    if (value) {
      value = value + ' Uhr';
    }
    return value;
  }

  getContextMenuItems = (params: any) => {
    console.log(params);
    const result = [
      'copy',
      {
        icon: '<i class="far fa-trash-alt"></i>',
        name: 'Ausgewählte Zeiten löschen',
        action: () => {
          this.deleteItems(this.gridApi.getCellRanges()[0]);
        },
        cssClasses: ['text-danger']
      }
    ];
    return result;
    // tslint:disable-next-line:semicolon
  };

  deleteItems(ranges: any) {
    const start =
      ranges.startRow.rowIndex <= ranges.endRow.rowIndex ? ranges.startRow.rowIndex : ranges.endRow.rowIndex;
    const end = ranges.endRow.rowIndex >= ranges.startRow.rowIndex ? ranges.endRow.rowIndex : ranges.startRow.rowIndex;

    const rowsToDelete: any = [];
    for (let index = start; index <= end; index++) {
      const row = this.gridApi.getDisplayedRowAtIndex(index);
      if (row && row.data) {
        rowsToDelete.push(row.data);
      }
    }

    // Remove from grid
    this.gridApi.applyTransaction({ remove: rowsToDelete });

    rowsToDelete.forEach((row: any) => {
      // Find in items array and remove from there as well
      const itemIndex = this.deliveryTimes.findIndex((item: any) => (item.id = row.id));
      if (itemIndex > -1) {
        this.deliveryTimes.splice(itemIndex, 1);
      }

      this.gastropayService.deleteTime(row.id).subscribe(
        () => {
          this.snackBar.open('Zeit wurde erfolgreich gelöscht', '', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        },
        (err: any) => {
          this.snackBar.open(err && err.error ? err.error.msg : 'Unbekannter Fehler', '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      );
    });
  }
}
