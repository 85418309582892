import { Component, OnInit } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { ClientService } from '@app/core/client.service';
import { MatSnackBar } from '@angular/material';
import { CredentialsService } from '@app/core';

import moment from 'moment';
moment.locale('de');

@Component({
  selector: 'app-feedback-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  loading: boolean = true;
  public showFeedbacks: number = 20;
  public showMoreFeedbacks: number = 10;
  public feedbacks: any[] = [];
  public selectedFeedback: number = 0;
  public selectedFeedbackAnswer: number = -1;
  private ngUnsubscribe: Subject<any> = new Subject();
  public clientName: string = '';
  canDeleteFeedback = false;
  dates: any = {
    from: '',
    to: ''
  };
  todaySelect: boolean = false;
  yesterdaySelect: boolean = false;
  lastSevenSelect: boolean = false;
  thisWeekSelect: boolean = false;
  lastWeekSelect: boolean = false;
  thisMonthSelect: boolean = false;
  lastMonthSelect: boolean = false;

  constructor(
    private clientService: ClientService,
    private snackBar: MatSnackBar,
    private credentialsService: CredentialsService
  ) {}

  ngOnInit() {
    this.clientService
      .getPermissionsOfUser(this.credentialsService.getCredentials().id)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((permisions: string[]) => {
        if (this.credentialsService.isAdmin()) {
          this.canDeleteFeedback = true;
        }
      });
    this.getFeedbacks(true);
  }

  getFeedbacks(initialStart: boolean = false) {
    // .getFeedbacks(this.dates.from, this.dates.to)
    this.loading = true;
    if (initialStart) {
      this.dates.from = moment()
        .subtract(30, 'days')
        .format();
      this.dates.to = moment().format();
    }
    this.clientService
      .getFeedbacks(this.dates.from, this.dates.to)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(res => {
        this.loading = false;
        this.feedbacks = res || [];
        this.feedbacks.forEach(feedback => {
          feedback.postedAt = new Date(feedback.postedAt.replace(/\s/, 'T') + 'Z');
          if (feedback.reservation) {
            feedback.reservation.reservedFor = new Date(feedback.reservation.reservedFor.replace(/\s/, 'T') + 'Z');
          }
        });

        if (this.clientName == '' && this.feedbacks.length) {
          this.clientService
            .getClient(this.feedbacks[0].betriebId)
            .takeUntil(this.ngUnsubscribe)
            .subscribe(res => {
              if (res) {
                this.clientName = res.name;
              }
            });
        }
        this.loading = false;
      });
  }
  saveFeedback(feedback: any) {
    this.loading = true;
    feedback.clientName = this.clientName;
    this.clientService.saveFeedback(feedback).subscribe((res: any) => {
      this.loading = false;
      this.snackBar.open('Das Feedback wurden erfolgreich aktualisiert', '', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    });
  }
  deleteFeedback(feedback: any, i: number) {
    this.loading = true;
    if (confirm('Möchten Sie das Feedback wirklich löschen?')) {
      this.clientService.deleteFeedback(feedback).subscribe((res: any) => {
        this.loading = false;
        this.selectedFeedback = -1;
        this.feedbacks.splice(i, 1);
        this.snackBar.open('Das Feedback wurde gelöscht', '', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      });
    }
  }

  setDates(type: string, isLogs: boolean) {
    if (type == 'today') {
      this.dates.from = moment().format();
      this.dates.to = moment().format();
      this.todaySelect = true;
      this.yesterdaySelect = false;
      this.lastSevenSelect = false;
      this.thisWeekSelect = false;
      this.lastWeekSelect = false;
      this.thisMonthSelect = false;
      this.lastMonthSelect = false;
    }
    if (type == 'yesterday') {
      this.dates.from = moment()
        .subtract(1, 'days')
        .format();
      this.dates.to = moment()
        .subtract(1, 'days')
        .format();
      this.todaySelect = false;
      this.yesterdaySelect = true;
      this.lastSevenSelect = false;
      this.thisWeekSelect = false;
      this.lastWeekSelect = false;
      this.thisMonthSelect = false;
      this.lastMonthSelect = false;
    }
    if (type == 'thisWeek') {
      const date = moment();
      this.dates.from = date.startOf('week');
      this.dates.to = moment(this.dates.from).endOf('week');
      this.todaySelect = false;
      this.yesterdaySelect = false;
      this.lastSevenSelect = false;
      this.thisWeekSelect = true;
      this.lastWeekSelect = false;
      this.thisMonthSelect = false;
      this.lastMonthSelect = false;
    }
    if (type == 'lastSeven') {
      this.dates.from = moment()
        .subtract(6, 'days')
        .format();
      this.dates.to = moment().format();
      this.todaySelect = false;
      this.yesterdaySelect = false;
      this.lastSevenSelect = true;
      this.thisWeekSelect = false;
      this.lastWeekSelect = false;
      this.thisMonthSelect = false;
      this.lastMonthSelect = false;
    }
    if (type == 'lastWeek') {
      const date = moment().subtract(1, 'week');
      this.dates.from = date.startOf('week');
      this.dates.to = moment(this.dates.from).endOf('week');
      this.todaySelect = false;
      this.yesterdaySelect = false;
      this.lastSevenSelect = false;
      this.thisWeekSelect = false;
      this.lastWeekSelect = true;
      this.thisMonthSelect = false;
      this.lastMonthSelect = false;
    }
    if (type == 'lastMonth') {
      const date = moment().subtract(1, 'month');
      this.dates.from = date.startOf('month');
      this.dates.to = moment(this.dates.from).endOf('month');
      this.todaySelect = false;
      this.yesterdaySelect = false;
      this.lastSevenSelect = false;
      this.thisWeekSelect = false;
      this.lastWeekSelect = false;
      this.thisMonthSelect = false;
      this.lastMonthSelect = true;
    }
    if (type == 'thisMonth') {
      const date = moment();
      this.dates.from = date.startOf('month');
      this.dates.to = moment(this.dates.from).endOf('month');
      this.todaySelect = false;
      this.yesterdaySelect = false;
      this.lastSevenSelect = false;
      this.thisWeekSelect = false;
      this.lastWeekSelect = false;
      this.thisMonthSelect = true;
      this.lastMonthSelect = false;
    }
    this.getFeedbacks(false);
  }
}
