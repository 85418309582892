import { Component, OnInit } from '@angular/core';
import { ClientService } from '@app/core/client.service';
import { AddStaffMemberComponent } from './add-staff-member/add-staff-member.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { AuthenticationService } from '@app/core';
@Component({
  selector: 'app-client-staff',
  templateUrl: './client-staff.component.html',
  styleUrls: ['./client-staff.component.scss']
})
export class ClientStaffComponent implements OnInit {
  staffEntries: any[];
  public pageLimit: number = 50;
  requestStaffCode: boolean = false;
  activeClient: any;

  constructor(
    private clientService: ClientService,
    private modalService: NgbModal,
    private snackBar: MatSnackBar,
    private authService: AuthenticationService
  ) {
    this.authService.activeClient.subscribe(client => {
      this.activeClient = client;
      this.requestStaffCode = this.activeClient.requestStaffCode;
    });
  }

  ngOnInit() {
    this.getStaff();
  }

  getStaff(refresh: boolean = false) {
    this.clientService.getStaffCodes(refresh).subscribe(res => {
      this.staffEntries = res;
    });
  }

  saveClientRequestCodeSettings() {
    const data = { id: this.activeClient.id, requestStaffCode: this.requestStaffCode };
    this.clientService.saveClientRequestCodeSettings(data).subscribe((resp: any) => {
      this.requestStaffCode = resp.requestStaffCode;
      const snackBarRef = this.snackBar.open('Request updated successfully.', 'Ok', {
        duration: 3000
      });
    });
  }

  addStaffMember() {
    const modalRef = this.modalService.open(AddStaffMemberComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.staffEntries = [...this.staffEntries, receivedEntry];
      const snackBarRef = this.snackBar.open('Mitarbeiter erfolgreich hinzugefügt', 'Ok', {
        duration: 3000
      });
    });
  }

  editStaffMember(staffMember: any, index: number) {
    const modalRef = this.modalService.open(AddStaffMemberComponent, { windowClass: 'onboarding-modal' });
    modalRef.componentInstance.editStaffMember = staffMember;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry: any) => {
      this.staffEntries[index] = receivedEntry;
      this.getStaff(true);
      this.staffEntries = [...this.staffEntries];
      const snackBarRef = this.snackBar.open('Mitarbeiter erfolgreich gespeichert', 'Ok', {
        duration: 3000
      });
    });
  }

  deleteStaffMember(staffMember: any) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = 'Mitarbeiter löschen';
    modalRef.componentInstance.message = `Sind Sie sicher dass Sie den Mitarbeiter "${staffMember.name}" löschen möchten?`;

    modalRef.result.then(
      result => {
        if (result === 'ok') {
          this.clientService.deleteStaffMember(staffMember.id).subscribe(() => {
            // Delete from array
            this.staffEntries = this.staffEntries.filter((obj: any) => {
              return obj.id !== staffMember.id;
            });
            const snackBarRef = this.snackBar.open('Mitarbeiter erfolgreich gelöscht', 'Ok', {
              duration: 3000
            });
          });
        }
      },
      () => {}
    );
  }
}
